@import "../node_modules/bootstrap/scss/bootstrap";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");

@font-face {
    font-family: "Century Gothic";
    src: url('../cg/Century-Gothic.ttf') format('truetype'),
        url('../cg/gothicb.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Century Gothic";
    src: url('../cg/gothicb.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

/////////////////////////////
//MOBILE MENU NAVBAR
.navbar-mobile {
    height: 20vh;
    position: fixed;
    background: #000066;
    width: 100vw;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.aside-section {
    top: 0;
    bottom: 0;
    position: absolute;
    height: 100vh;
    height: -webkit-fill-available;
    height: fill-available;
    z-index: 9500;
}

.aside-left {
    display: block;
    width: 100vw;
    height: 100vh;
    left: 0;
    background: #000066;
    background-size: auto;
    -webkit-transform: translate(-50%, -100%);
    -moz-transform: translate(-50%, -100%);
    -ms-transform: translate(-50%, -100%);
    -o-transform: translate(-50%, -100%);
    transform: translate(-50%, -100%);
    transition: transform 0.4s ease-in-out;
    z-index: 9500 !important;
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
    .aside-left {
        height: -webkit-fill-available;
        height: fill-available;
    }
}

.aside-list {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 150px;
    text-align: left;
    padding-left: 50px;
}

.aside-content {
    padding: 0 40px;
    position: relative;
    color: white;
    text-align: center;
    height: 100vh;
    height: -webkit-fill-available;
    height: fill-available;
}

.aside-list li {
    margin-bottom: 20px;
}

.aside-anchor::after {
    content: "";
    position: absolute;
    bottom: 0;
    background-color: #f1f1f1;
    left: 0;
    right: 0;
    height: 3px;
    border-radius: 3px;
}

.aside-anchor::before {
    border-radius: 3px;
    content: "";
    position: absolute;
    bottom: 0;
    background-color: #fff;
    left: 0;
    height: 3px;
    z-index: 1;
    width: 50%;
    -webkit-transition: transform 0.2s ease-in-out;
    -o-transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
}

.aside-anchor:hover:before {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
}

.aside-anchor {
    padding-bottom: 7px;
    color: #fff;
    text-decoration: none;
    font-size: 30px;
    position: relative;
    font-weight: 500;
}

input[type="checkbox"] {
    display: none;
}

input[type="checkbox"]:checked ~ aside .aside-left {
   transform: translate(-50%,0%);
}

input[type="checkbox"]:checked ~ aside .aside-right {
    transform: translateX(0%);
}

input[type="checkbox"]:checked ~ label .bar {
    background-color: #f1f1f1;
    z-index: 9999 !important;

}

input[type="checkbox"]:checked ~ label .bar-top {
    -webkit-transform: translateY(0px) rotateZ(45deg);
    -moz-transform: translateY(0px) rotateZ(45deg);
    -ms-transform: translateY(0px) rotateZ(45deg);
    -o-transform: translateY(0px) rotateZ(45deg);
    transform: translateY(0px) rotateZ(45deg);

}

input[type="checkbox"]:checked ~ label .bar-bottom {
    -webkit-transform: translateY(-15px) rotateZ(-45deg);
    -moz-transform: translateY(-15px) rotateZ(-45deg);
    -ms-transform: translateY(-15px) rotateZ(-45deg);
    -o-transform: translateY(-15px) rotateZ(-45deg);
    transform: translateY(-15px) rotateZ(-45deg);

}

input[type="checkbox"]:checked ~ label .bar-middle {
    width: 0;
}

.aside-section .middle {
    margin: 0 auto;
}



.bar {
    display: block;
    background-color: #fff;
    width: 25px;
    height: 1.5px;
    border-radius: 5px;
    margin: 6.5px auto;
    transition: background-color 0.4s ease-in, transform 0.4s ease-in,
        width 0.4s ease-in;
    z-index: 9999 !important;
}

.bar:hover {
    cursor: pointer;
    z-index: 9999 !important;
}

.bar-white {
    display: block;
    background-color: #fff;
    width: 25px;
    height: 1.5px;
    border-radius: 5px;
    margin: 6.5px auto;
    transition: background-color 0.4s ease-in, transform 0.4s ease-in,
        width 0.4s ease-in;
    z-index: 9999 !important;
}

.bar-white:hover {
    cursor: pointer;
    z-index: 9999 !important;
}

.aside-section h1 {
    margin: 0;
    position: relative;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    text-align: center;
    font-size: 30px;
}

.aside-section h1 span {
    font-size: 20px;
    display: block;
}

.aside-section p {
    white-space: nowrap;
}

.aside-section .button {
    display: inline-block;
    background-image: none;
    border: none;
    background-color: transparent;
    padding-bottom: 7px;
    position: relative;
    cursor: pointer;
    font-size: 20px;
    color: white;
    padding: 7px 50px;
    border: 2px solid white;
}

@media (min-width: 992px) {
    h1 {
        font-size: 40px;
    }

    .aside-left {
        display: block;
    }

    .aside-right {
        width: 60%;
    }
}

.menu_content_container {
    display: table-cell;
    vertical-align: middle;
    text-align: justify;
    text-align-last: left;
    font-size: 16px;
    font-weight: 300;
    padding: 15vh 25vw;

}

.menu_content {
    position: relative;
    display: flex;
    vertical-align: middle;
    text-align: justify;
    text-align-last: left;
    font-size: 1.25rem;
    font-weight: 300;
    padding: 5vh 5vw 5vh 10vw;
    background: transparent;
    top: 25vh;
    margin: auto;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;

}

.menu_content a {
    color: #f1f1f1;
    text-decoration: none !important;
}

.menu {
    position: absolute;
    height: 100vh;
    width: auto;
    top: 0;
}

.menu_label {
    z-index: 9800;
    position: fixed;
    transform: translate(5vw, 10vh);
    left:0;
}

.menu-init {
    z-index: 9500;
    position: fixed;
}

.menu_label-init {
    z-index: 9999;
    position: absolute;
    transform: translate(5vw, 5vh);
}

.logo {
    z-index: 9500;
    position: fixed;
    text-align: center;
    width: 100vw;
}

.logo-mobile {
    width: 50vw;
    position: fixed;
    top: 7vh;
    z-index: 9999;
}

///////END MOBILE MENU///////
@media only screen and (min-width: 769px) and (max-width: 5000px) {
    .desktop-hide {
        display: none;
    }
}

@media only screen and (min-width: 0px) and (max-width: 768px) {
    .mobile-hide {
        display: none;
    }
}

//MOBILE
@media only screen and (min-width: 0px) and (max-width: 600px) {
    .logo-mobile {
        top: 8.5vh;
    }
    main {
        padding-top: 0vh;
    }

    body {
        font-family: 'Century Gothic', sans-serif;
    }

    body + services-btn:active {
        overflow: hidden;
    }

    b {
        font-weight: bold;
    }

    //DESKTOP MENU NAVBAR 
    .navbar {
        background-color: #fff;
        border-right: 1px solid #eee;
        display: flex;
        padding: 0vh 5vw;
        margin-top: 43px;
        position: fixed;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        top: -2vh;
        transition: left 0.25s ease;
        width: 100vw;
        height: 90px;
        z-index: 9;
        box-shadow: 1px 1px 10px rgb(0 0 0 / 25%);
    }

    .nav-link {
        color: #000;
        text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: 600;
    }

    .nav-link:hover {
        color: #000066;
        cursor: pointer;
    }

    .active-link {
        color: #000066;
    }

    .login-btn {
        color: #000066;
        text-transform: capitalize;
        text-decoration: none;
        font-size: 1rem;
        font-weight: 600;
    }

    .login-form-btn {
        background: #fff;
        color: #000066;
        line-height: 2;
        font-size: 1rem;
        border: 1px solid #000066;
        width: 10vw;
        border-radius: 2px;
        margin-top: 2vh;
        height: 5vh;
        width: 25vw;
        text-transform: uppercase;
        font-weight: 600;
        transition: 500ms ease;
    }

    .login-form-btn:hover {
        background: #000066;
        color: #fff;
        line-height: 2;
        font-size: 1rem;
        border: 1px solid #000066;
        width: 10vw;
        border-radius: 2px;
        margin-top: 2vh;
        height: 8vh;
        width: 15vw;
        text-transform: uppercase;
        font-weight: 600;
        transition: 500ms ease;
    }

    .form-text a {
        color: #6c757d;
        text-decoration: none;
    }

    .form-text a:hover {
        color: #000066;
    }

    .navbar > .container,
    .navbar > .container-fluid {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between
    }

    .navbar-brand {
        display: inline-block;
        padding-top: .3125rem;
        padding-bottom: .3125rem;
        margin-right: 1rem;
        font-size: 1.25rem;
        line-height: inherit;
        white-space: nowrap;
        width: 20vw;
    }

    .navbar-brand:focus,
    .navbar-brand:hover {
        text-decoration: none
    }

    .navbar-nav {
        display: flex;
        flex-direction: row;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
    }

    .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0
    }

    .navbar-nav .dropdown-menu {
        position: static;
        float: none
    }

    .navbar-text {
        display: inline-block;
        padding-top: .5rem;
        padding-bottom: .5rem
    }

    .navbar-collapse {
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center
    }

    .navbar-toggler {
        padding: .25rem .75rem;
        font-size: 1.25rem;
        line-height: 1;
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: .25rem
    }

    .navbar-toggler:focus,
    .navbar-toggler:hover {
        text-decoration: none
    }

    .navbar-toggler:not(:disabled):not(.disabled) {
        cursor: pointer
    }

    .navbar-toggler-icon {
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        vertical-align: middle;
        content: "";
        background: no-repeat center center;
        background-size: 100% 100%
    }

    @media (max-width:575.98px) {

        .navbar-expand-sm > .container,
        .navbar-expand-sm > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:576px) {
        .navbar-expand-sm {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-sm .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-sm .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-sm .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-sm > .container,
        .navbar-expand-sm > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-sm .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-sm .navbar-toggler {
            display: none
        }
    }

    @media (max-width:767.98px) {

        .navbar-expand-md > .container,
        .navbar-expand-md > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:768px) {
        .navbar-expand-md {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-md .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-md .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-md .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-md > .container,
        .navbar-expand-md > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-md .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-md .navbar-toggler {
            display: none
        }
    }

    @media (max-width:991.98px) {

        .navbar-expand-lg > .container,
        .navbar-expand-lg > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:992px) {
        .navbar-expand-lg {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-lg .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-lg .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-lg .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-lg > .container,
        .navbar-expand-lg > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-lg .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-lg .navbar-toggler {
            display: none
        }
    }

    @media (max-width:1199.98px) {

        .navbar-expand-xl > .container,
        .navbar-expand-xl > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:1200px) {
        .navbar-expand-xl {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-xl .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-xl .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-xl .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-xl > .container,
        .navbar-expand-xl > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-xl .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-xl .navbar-toggler {
            display: none
        }
    }

    @media (max-width:1399.98px) {

        .navbar-expand-xxl > .container,
        .navbar-expand-xxl > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:1400px) {
        .navbar-expand-xxl {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-xxl .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-xxl .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-xxl .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-xxl > .container,
        .navbar-expand-xxl > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-xxl .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-xxl .navbar-toggler {
            display: none
        }
    }

    @media (max-width:1599.98px) {

        .navbar-expand-xxxl > .container,
        .navbar-expand-xxxl > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:1600px) {
        .navbar-expand-xxxl {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-xxxl .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-xxxl .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-xxxl .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-xxxl > .container,
        .navbar-expand-xxxl > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-xxxl .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-xxxl .navbar-toggler {
            display: none
        }
    }

    @media (max-width:1920.98px) {

        .navbar-expand-uhd > .container,
        .navbar-expand-uhd > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:1921px) {
        .navbar-expand-uhd {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-uhd .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-uhd .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-uhd .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-uhd > .container,
        .navbar-expand-uhd > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-uhd .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-uhd .navbar-toggler {
            display: none
        }
    }

    .navbar-expand {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand > .container,
    .navbar-expand > .container-fluid {
        padding-right: 0;
        padding-left: 0
    }

    .navbar-expand .navbar-nav {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        width: 65vw;

    }

    .navbar-expand .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand > .container,
    .navbar-expand > .container-fluid {
        flex-wrap: nowrap
    }

    .navbar-expand .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
    }

    .navbar-expand .navbar-toggler {
        display: none
    }

    .navbar-light .navbar-brand {
        color: rgba(0, 0, 0, .9)
    }

    .navbar-light .navbar-brand:focus,
    .navbar-light .navbar-brand:hover {
        color: rgba(0, 0, 0, .9)
    }

    .navbar-light .navbar-nav .nav-link {
        color: rgba(0, 0, 0, .5)
    }

    .navbar-light .navbar-nav .nav-link:focus,
    .navbar-light .navbar-nav .nav-link:hover {
        color: rgba(0, 0, 0, .7)
    }

    .navbar-light .navbar-nav .nav-link.disabled {
        color: rgba(0, 0, 0, .3)
    }

    .navbar-light .navbar-nav .active > .nav-link,
    .navbar-light .navbar-nav .nav-link.active,
    .navbar-light .navbar-nav .nav-link.show,
    .navbar-light .navbar-nav .show > .nav-link {
        color: rgba(0, 0, 0, .9)
    }

    .navbar-light .navbar-toggler {
        color: rgba(0, 0, 0, .5);
        border-color: rgba(0, 0, 0, .1)
    }

    .navbar-light .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0,0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
    }

    .navbar-light .navbar-text {
        color: rgba(0, 0, 0, .5)
    }

    .navbar-light .navbar-text a {
        color: rgba(0, 0, 0, .9)
    }

    .navbar-light .navbar-text a:focus,
    .navbar-light .navbar-text a:hover {
        color: rgba(0, 0, 0, .9)
    }

    .navbar-dark .navbar-brand {
        color: #fff
    }

    .navbar-dark .navbar-brand:focus,
    .navbar-dark .navbar-brand:hover {
        color: #fff
    }

    .navbar-dark .navbar-nav .nav-link {
        color: rgba(255, 255, 255, .5)
    }

    .navbar-dark .navbar-nav .nav-link:focus,
    .navbar-dark .navbar-nav .nav-link:hover {
        color: rgba(255, 255, 255, .75)
    }

    .navbar-dark .navbar-nav .nav-link.disabled {
        color: rgba(255, 255, 255, .25)
    }

    .navbar-dark .navbar-nav .active > .nav-link,
    .navbar-dark .navbar-nav .nav-link.active,
    .navbar-dark .navbar-nav .nav-link.show,
    .navbar-dark .navbar-nav .show > .nav-link {
        color: #fff
    }

    .navbar-dark .navbar-toggler {
        color: rgba(255, 255, 255, .5);
        border-color: rgba(255, 255, 255, .1)
    }

    .navbar-dark .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
    }

    .navbar-dark .navbar-text {
        color: rgba(255, 255, 255, .5)
    }

    .navbar-dark .navbar-text a {
        color: #fff
    }

    .navbar-dark .navbar-text a:focus,
    .navbar-dark .navbar-text a:hover {
        color: #fff
    }

    //END NAVBAR
    //TICKER
    .ticker {
        width: 100vw;
        height: 27px;
        position: fixed;
        display: block;
        top: 0;
        z-index: 9999;
        padding: 0;
    }

    .ticket .row {
        height: auto;
    }

    .ticket .row .col-md-12 {}

    .breaking-news {
        display: block;
        position: relative;
        background: #3c3c3c;
        height: 27px;
    }

    .news-scroll {
        height: 27px;
        position: relative;
    }

    .news {
        width: 10vw;
        height: 5vh;
        min-height: 5vh;
        background: #000066;
        padding: 0;
        border: 0;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .news-scroll a {
        font-size: 0.8rem;
        font-weight: 300;
        color: #fff;
        text-transform: uppercase;
        text-decoration: none;
    }

    .dot {
        padding-right: 0.5vw;
    }

    .bold {
        font-weight: bold;
    }

    .img-fill {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden
    }

    .img-fill img {
        flex-shrink: 0;
        object-fit: cover;
    }

    footer {
        display: flex;
        background-color: #3c3c3c;
        border-top: 1px solid #888;
        color: #888;
        font-size: 1rem;
        padding: 15vh 0;
        position: relative;
        z-index: auto !important;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        height: auto;
        min-height: 100vh;
        z-index: 9500 !important;
    }

    footer a {
        color: #fff;
        font-size: 1rem;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;
    }

    footer a:hover {
        color: #f1f1f1
    }

    footer p {
        margin: 0;
        font-size: 1rem;
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
    }

    footer svg {
        margin: 0
    }

    footer .footer-row {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin-bottom: 10px;
        align-content: center;
        width: 100vw;
        padding: 0vh 5vw;
        height: auto;
        min-height: 80vh;
    }

    footer .footer-row .col-3 {
        width: 100%;
    }

    .footer-row .col-6 {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: flex-start;
        width: 100%;
    }

    .footer-info {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
    }

    .footer-row .col-3 p {
        font-size: 1rem;
        text-transform: capitalize;
        font-weight: 400;
        white-space: nowrap;
        text-align: center;
    }

    .footer-row .col-3 a {
        font-size: 1rem;
        text-transform: lowercase;
        font-weight: 400;
        white-space: nowrap;
    }

    .footer-info .col-3 {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: center;
        padding: 0vh 0vw 0vh 2vw;
        height: 10vh;
        width: 50% !important;
    }

    .footer-row .col-6 .footer-info .col-3 a {
        font-weight: 600;
        text-transform: uppercase;
    }

    .footer-social {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        padding: 1vh;
    }

    .footer-social a {
        font-size: 2rem !important;
    }

    footer .footer-row:last-child {
        font-size: 11px;
        margin-bottom: 0
    }

    body {
        color: #000;
        font-size: 1rem;
        font-weight: 300;
        line-height: 26px;
        position: relative;
    }

    .scrollbar {
        overflow: auto
    }

    .scrollbar::-webkit-scrollbar {
        width: 10px;
        height: 10px
    }

    .scrollbar::-webkit-scrollbar-track {
        background-color: #e9ecef
    }

    .scrollbar::-webkit-scrollbar-thumb {
        background: #adb5bd
    }

    .scrollbar::-webkit-scrollbar-thumb:hover {
        background: #495057
    }

    main {
        margin-left: 0;
        overflow: hidden;
    }

    section {
        padding-left: 5vw;
        padding-right: 5vw;
        position: relative;
        z-index: 100;
        z-index: 2 !important;
    }

    section.no-mt {
        padding-top: 0
    }

    section.no-mb {
        padding-bottom: 0
    }

    header {
        background-color: #fff;
        position: relative;
        z-index: 100
    }

    img {
        max-width: 100%
    }

    h1 {
        font-weight: 800;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0
    }

    p {
        line-height: 1.75
    }

    strong {
        font-weight: 700
    }

    .mobile-arrow {
        bottom: 0;
        display: flex;
        justify-content: center;
        left: 0;
        margin: 0 auto;
        padding-bottom: 25px;
        position: absolute;
        right: 0
    }

    .mobile-arrow a {
        animation-delay: 2.5s;
        animation-duration: 5s;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        animation-name: custom-bounce;
        display: block;
        transform-origin: center bottom
    }

    .anchors {
        align-items: center;
        display: flex;
        font-size: 1.25rem;
        padding-top: .5rem
    }

    @media (max-width:575.98px) {
        .anchors {
            justify-content: center;
            padding-top: .5rem
        }
    }

    .anchors svg {
        color: #000066;
        height: 1.75rem;
        margin: 0 1.25rem 0 0;
        width: auto
    }

    @media (max-width:575.98px) {
        .anchors svg {
            margin: 0
        }
    }

    .anchors .nav-link {
        color: #000;
        font-size: 14px;
        font-weight: 300;
        margin: 0 1.25rem;
        padding: 8px 0 5px;
        position: relative;
        text-decoration: none
    }

    @media (max-width:575.98px) {
        .anchors .nav-link {
            margin: 0 .75rem
        }
    }

    .anchors .nav-link::before {
        background-color: #000066;
        bottom: 8px;
        content: '';
        height: 1px;
        left: 0;
        opacity: 0;
        position: absolute;
        transition: opacity .25s ease, width .15s ease;
        width: 0
    }

    .anchors .nav-link:first-child {
        margin-left: 0
    }

    .anchors .nav-link:last-child {
        margin-right: 0
    }

    .anchors .nav-link.active::before,
    .anchors .nav-link:hover::before {
        opacity: 1;
        width: 100%
    }

    .negative-mt {
        margin-top: -150px
    }

    .custom-list,
    .section-about ul {
        columns: 2;
        margin-top: 2.5rem;
        width: auto
    }

    @media (max-width:991.98px) {

        .custom-list,
        .section-about ul {
            margin-bottom: 1.5rem;
            margin-top: 1.5rem
        }
    }

    @media (max-width:767.98px) {

        .custom-list,
        .section-about ul {
            columns: 1
        }
    }

    .custom-list li,
    .section-about ul li {
        padding: .25rem 0 .25rem 1.5rem;
        position: relative
    }

    .custom-list li::before,
    .section-about ul li::before {
        border: 1px solid #000066;
        border-radius: 50%;
        content: '';
        height: 7px;
        left: 0;
        position: absolute;
        top: 12px;
        width: 7px
    }

    .primary-border {
        border-right: 1px solid #000066;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        padding: 0 5vw 0 0;
        position: relative
    }

    @media (max-width:991.98px) {
        .primary-border {
            border: 0;
            min-height: auto;
            padding: 1rem 0
        }
    }

    .primary-border.spaced {
        padding: 5rem 0
    }

    @media (max-width:991.98px) {
        .primary-border.spaced {
            padding: 2rem 0
        }
    }

    .page-title {
        color: #000066;
        font-size: 4rem;
        font-weight: 300;
        text-transform: uppercase;
        padding-bottom: 5vh;
        text-align: center;
    }

    .page-title.outline-big-text {
        font-size: 2.5rem
    }

    .subtitle-wrapper {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding-bottom: 3rem
    }

    @media (max-width:575.98px) {
        .subtitle-wrapper {
            align-items: flex-start;
            flex-direction: column;
            justify-content: flex-start
        }
    }

    @media (max-width:991.98px) {
        .subtitle-wrapper {
            padding-bottom: 1rem
        }
    }

    .text-wrapper p:last-child {
        margin: 0
    }

    .img-wrapper {
        border-color: rgba(255, 255, 255, 0);
        border-style: solid;
        border-width: 1px 0;
        padding: 0;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;
        flex-direction: column;
        align-content: center;
        width: 100%;
    }

    .img-wrapper hr {
        height: 5px;
        color: #000066;
        width: 25%;
    }

    .img-wrapper.static {
        border: 0;
        padding: 0
    }

    .img-wrapper.static .img-legend {
        color: #000;
        padding: 10px 0 0
    }

    .img-wrapper.static .img-legend a {
        color: #000;
        display: block;
        font-size: 16px;
        font-weight: 700;
        padding: .15rem 0 0;
        text-decoration: none
    }

    .img-wrapper img {
        overflow: hidden;
        object-fit: cover;
        min-height: 100%;
        min-width: 100%;
        flex-shrink: 0;
    }

    .img-wrapper .img-legend {
        color: #343a40;
        display: block;
        padding: 15px 0 0
    }

    .img-wrapper .img-legend p {
        line-height: 16px;
        margin: 0
    }

    .img-wrapper .img-legend p a {
        color: #000;
        font-size: 14px;
        font-weight: 800
    }

    .img-wrapper .img-legend .date {
        font-size: 14px;
        font-weight: 300
    }

    .img-wrapper .news-img {
        height: 350px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
    }

    .project-figure {
        position: relative
    }

    .project-figure figcaption {
        bottom: 10px;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 1px;
        position: absolute;
        right: 20px
    }

    .outline-big-text {
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(166, 0, 0, .9);
        color: #000066;
        font-family: Gilroy;
        font-size: 1.5rem;
        font-weight: 800;
        letter-spacing: 1px;
        line-height: 1;
        white-space: nowrap
    }

    .citation-wrapper .outline-big-text {
        font-size: 1.5rem;
    }

    @media (max-width:1399.98px) {
        .outline-big-text {
            font-size: 45px
        }
    }

    @media (max-width:1199.98px) {
        .outline-big-text {
            font-size: 40px
        }
    }

    .outline-big-text.outline-white {
        -webkit-text-stroke-color: rgba(255, 255, 255, .9);
        color: #fff
    }

    .solid-big-text {
        color: #ffffff;
        font-size: 3rem;
        font-weight: 600
    }

    .citation-align {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        padding-left: 5vw;
    }

    .citation-align .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        height: 100%
    }

    .citation-wrapper {
        color: #000066;
        display: flex;
        flex-direction: column;
        font-family: Helvetica;
        font-size: 1.5rem;
        font-weight: 700;
        height: 100%;
        justify-content: center;
        line-height: 1;
        padding-bottom: 2.5vh;
    }

    @media (max-width:991.98px) {
        .citation-wrapper {
            font-size: 1.25rem;
            padding: 1rem 0;
            text-align: center
        }
    }

    .citation-wrapper.hp .outline-big-text,
    .citation-wrapper.hp .solid-big-text {
        font-size: 3rem
    }

    @media (max-width:1599.98px) {

        .citation-wrapper.hp .outline-big-text,
        .citation-wrapper.hp .solid-big-text {
            font-size: 3rem;
        }
    }

    @media (max-width:1199.98px) {

        .citation-wrapper.hp .outline-big-text,
        .citation-wrapper.hp .solid-big-text {
            font-size: 3rem
        }
    }

    .citation-wrapper.hp .solid-small-text {
        font-size: 1rem;

    }

    @media (max-width:1199.98px) {
        .citation-wrapper.hp .solid-small-text {
            font-size: 20px
        }
    }

    .list-align {
        height: 100%
    }

    .list-align > .row {
        height: 100%
    }

    //PROPERTY PAGE
    .property-header {
        padding: 0 !important;
        height: 500px;
    }

    .property-img {
        height: 500px;
        width: 100%;
    }

    .property-img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .property-title {
        width: 100vw;
        height: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        align-items: flex-start;
        justify-content: center;
        padding: 5vh 5vw;
    }

    .title h2 {
        font-size: 2rem;
        color: #000066;
        font-weight: 600;
        text-transform: capitalize;
    }

    .property-address p {
        font-size: 1.25rem;
        font-weight: 300;
        color: #3c3c3c;
        text-transform: capitalize;
    }

    .property {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        width: 100vw;
        padding: 0vh 5vw;
    }

    .property .col-6 {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
    }

    .property-details {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: nowrap;
        align-content: center;
        padding: 0vh 0vw 0vh 10vw;
    }

    .property-details ul {
        margin-bottom: 1.25rem;
        padding-bottom: 1.25rem;
        position: relative
    }

    .property-details ul::before {
        background-color: #000066;
        bottom: 0;
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        width: 25px
    }

    .property-details ul:last-child {
        margin-bottom: 0;
        padding-bottom: 0
    }

    .property-details ul:last-child::before {
        display: none
    }

    .property-details ul li {
        font-size: 14px
    }

    .property-details ul li span {
        color: #000066;
        font-weight: 600;
        padding-right: .75rem
    }

    .property-inquiry {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-top: 10vh;
    }

    .property-inquiry .row {
        display: inline-flex;
    }

    .property-inquiry .row button {
        text-transform: uppercase;
        background: #000066;
        color: #fff;
        width: 25vw;
        height: 5vh;
        border-radius: 2px;
        transition: 500ms;
        border: none;
    }

    .property-inquiry .row button:hover {
        background: #fff;
        color: #000066;
        width: 16vw;
        height: 8vh;
        border-radius: 2px;
        transition: 500ms;
        border: 1px solid #000066
    }

    .border-link {
        border-style: solid;
        border-width: 1px 0;
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        padding: .2rem .25rem .1rem;
        position: relative;
        transition: color .25s ease;
        white-space: nowrap;
        word-spacing: 2px
    }

    .border-link::before {
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        top: 0;
        transition: width .25s ease;
        width: 0;
        z-index: -1
    }

    .border-link:hover {
        text-decoration: none
    }

    .border-link:hover::before {
        width: 100%
    }

    .border-link.border-link-primary {
        border-color: #000066;
        color: #000066
    }

    .border-link.border-link-primary::before {
        background-color: #000066
    }

    .border-link.border-link-primary:hover {
        color: #fff
    }

    .border-link.border-link-white {
        border-color: #fff;
        color: #fff
    }

    .border-link.border-link-white::before {
        background-color: #fff
    }

    .border-link.border-link-white:hover {
        color: #000066
    }

    .border-link.no-effect::before {
        display: none
    }

    .border-link.no-effect:hover {
        color: #000066
    }

    .link-effect {
        position: relative;
        z-index: 1
    }

    .link-effect::before {
        background-color: #fff;
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        top: 0;
        transition: width .2s ease;
        width: 0;
        z-index: -1
    }

    .link-effect:hover::before {
        width: 100%
    }

    .nav .nav-link {
        color: #000;
        font-weight: 300;
        margin: 0 50px 0 0;
        padding-bottom: .25rem;
        padding-left: 0;
        padding-right: 0;
        position: relative
    }

    .nav .nav-link::before {
        background-color: #000066;
        bottom: 8px;
        content: '';
        height: 1px;
        left: 0;
        opacity: 0;
        position: absolute;
        transition: opacity .25s ease, width .15s ease;
        width: 0
    }

    .nav .nav-link.active::before,
    .nav .nav-link:hover::before {
        opacity: 1;
        width: 100%
    }

    .row-adjust {
        margin-left: -25px;
        margin-right: -25px
    }

    @media (max-width:991.98px) {
        .row-adjust {
            margin-left: -10px;
            margin-right: -10px
        }
    }

    .row-adjust > [class*=col] {
        padding-left: 25px;
        padding-right: 25px
    }

    @media (max-width:991.98px) {
        .row-adjust > [class*=col] {
            padding-left: 10px;
            padding-right: 10px
        }
    }

    .row-align {
        justify-content: center
    }

    .row-big {
        margin-left: -75px;
        margin-right: -75px
    }

    .row-big > [class*=col] {
        padding-left: 75px;
        padding-right: 75px
    }

    .black-filter {
        background-color: rgba(0, 0, 0, .5);
        bottom: 0;
        left: 0;
        opacity: 0;
        position: fixed;
        right: 0;
        top: 0;
        transition: opacity .25s ease;
        z-index: -1
    }

    .black-filter.active {
        opacity: 1;
        z-index: 2000
    }

    .red-card {
        background-color: #000066;
        font-family: Gilroy;
        height: 100%;
        min-height: 275px;
        overflow: hidden;
        padding: 1.5rem 1.5rem 1rem
    }

    .red-card a {
        color: #fff;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        text-decoration: none
    }

    .red-card a:hover {
        color: #fff
    }

    .red-card p {
        line-height: 1
    }

    .red-card .job-title {
        font-size: 35px;
        font-weight: 800
    }

    .red-card .job-description {
        font-size: 35px;
        font-weight: 300;
        margin-bottom: 1.5rem
    }

    .red-card .flex-wrapper {
        display: flex;
        justify-content: space-between
    }

    .red-card .flex-wrapper .apply {
        font-size: 1rem;
        font-weight: 800
    }

    .red-card .flex-wrapper .arrow {
        align-items: center;
        border-radius: 50%;
        display: flex;
        font-size: 1.5rem;
        height: 35px;
        justify-content: center;
        width: 35px
    }

    .red-card .flex-wrapper .arrow:hover {
        background-color: #fff;
        color: #000066
    }

    .scroll-top {
        align-items: center;
        bottom: 0;
        display: flex;
        justify-content: center;
        position: absolute;
        right: 50px
    }

    @media (max-width:991.98px) {
        .scroll-top {
            right: 25px
        }
    }

    @media (max-width:575.98px) {
        .scroll-top {
            right: 15px
        }
    }

    .scroll-top a {
        line-height: 1;
        text-align: center;
        text-decoration: none
    }

    .scroll-top a p {
        line-height: 18px;
        margin: 0;
        text-align: center
    }

    .scroll-top a .icon-wrapper {
        align-items: center;
        border: 1px solid #000066;
        border-radius: 50%;
        color: #000066;
        display: flex;
        height: 35px;
        justify-content: center;
        margin: 0;
        overflow: hidden;
        position: relative;
        transition: color .15s ease;
        width: 35px
    }

    .scroll-top a .icon-wrapper::before {
        background-color: #000066;
        bottom: 0;
        content: '';
        height: 0;
        left: -20px;
        position: absolute;
        right: -20px;
        transition: height .15s ease;
        z-index: -1
    }

    .scroll-top a:hover p {
        color: #000066
    }

    .scroll-top a:hover .icon-wrapper {
        color: #fff
    }

    .scroll-top a:hover .icon-wrapper::before {
        height: 100%
    }

    .v-line {
        bottom: 0;
        left: 0;
        margin: 0 auto;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 4px;
        width: 21px
    }

    @media (max-width:991.98px) {
        .v-line {
            display: none
        }
    }

    .v-line.project-line .arrow {
        bottom: 20vh;
        padding: 2rem 0 3rem;
        top: auto
    }

    .v-line .arrow {
        left: 0;
        margin: 0 auto;
        min-width: 21px;
        position: fixed;
        right: 0;
        top: 50%
    }

    .arrow-wrapper-white {
        bottom: 20vh;
        left: 155px;
        padding: 2rem 0 3rem;
        position: fixed;
        right: 0;
        text-align: center;
        z-index: -1
    }

    @media (max-width:991.98px) {
        .arrow-wrapper-white {
            display: none
        }
    }

    .arrow-wrapper-white.active {
        display: none
    }

    .arrow-wrapper-white .arrow {
        align-items: center;
        animation-delay: 2.5s;
        animation-duration: 5s;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        position: relative
    }

    .container-fluid.no-margin {
        padding: 0
    }

    .section-title {
        text-align: center;
        padding: 5vh 0vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-title hr {
        height: 1px;
        color: #000066;
        width: 10vw;
        opacity: 1;
    }

    .section-title.vertical {
        font-size: 16px;
        font-weight: 700;
        margin: 0;
        text-orientation: sideways-right;
        transform: rotate(180deg);
        writing-mode: vertical-rl
    }

    @media (max-width:575.98px) {
        .section-title.vertical {
            margin-bottom: 15px;
            text-orientation: initial;
            transform: rotate(0);
            writing-mode: initial
        }
    }

    .section-title.red {
        color: #000066
    }

    .section-title.white {
        color: #fff
    }

    .section-banner {
        padding: 0
    }

    .section-banner.banner-spaced {
        padding: 0 50px
    }

    @media (max-width:991.98px) {
        .section-banner.banner-spaced {
            padding-left: 25px;
            padding-right: 25px
        }
    }

    @media (max-width:575.98px) {
        .section-banner.banner-spaced {
            padding-left: 10px;
            padding-right: 10px
        }
    }

    .section-banner iframe {
        height: 500px;
        max-width: 100%;
        width: 800px
    }

    .section-banner .banner {
        background-position: 50% 100%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
        position: relative;
        z-index: -10
    }

    .section-banner .banner.medium-banner {
        background-position: 50% 50%;
        height: 80vh;
        z-index: 2
    }

    .section-banner .banner.small-banner {
        background-position: 50% 50%;
        height: 60vh;
        z-index: 0
    }

    .section-banner .back {
        position: absolute;
        right: 25px;
        top: 25px;
        z-index: 5
    }

    .section-banner .back a {
        color: #fff
    }

    .section-banner .back a svg {
        height: 20px;
        width: auto
    }

    .section-banner .flex-wrapper {
        align-items: center;
        bottom: 0;
        display: flex;
        flex-direction: column;
        height: 100vh;
        justify-content: center;
        pointer-events: none;
        position: fixed;
        z-index: 1;
        flex-wrap: nowrap;
        align-content: center;
        width: 100vw;
    }

    @media (max-width:575.98px) {
        .section-banner .flex-wrapper {
            left: 0
        }
    }

    .section-banner .flex-wrapper .v-line {
        height: 0;
        margin: 50px auto 0;
        position: relative
    }

    @media (max-width:991.98px) {
        .section-banner .flex-wrapper .v-line {
            display: none
        }
    }

    .section-banner .flex-wrapper .v-line .arrow {
        left: 0;
        margin: 0 auto;
        opacity: 1;
        position: absolute;
        right: 0
    }

    .section-banner .flex-wrapper .v-line.active {
        animation: banner-line .85s ease-in;
        animation-delay: .25s;
        animation-fill-mode: forwards
    }

    .section-banner .flex-wrapper .v-line.active .arrow {
        animation: banner-arrow .75s ease;
        animation-delay: 1.1s;
        animation-fill-mode: forwards
    }

    .section-banner .flex-wrapper #animation-wrapper {
        max-width: 40%;
        position: relative;
        width: 100%
    }

    @media (max-width:575.98px) {
        .section-banner .flex-wrapper #animation-wrapper {
            max-width: 70%
        }
    }

    @media (max-width:991.98px) {
        .section-banner .flex-wrapper #animation-wrapper {
            max-width: 60%
        }
    }

    @media (max-width:1199.98px) {
        .section-banner .flex-wrapper #animation-wrapper {
            max-width: 50%
        }
    }

    .section-top-page {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 34vh;
        justify-content: space-evenly;
        z-index: 200;
        padding-top: 0vh;
        align-content: center;
        flex-wrap: nowrap;
    }

    .section-top-page .nav {
        display: flex;
        flex-wrap: nowrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        flex-direction: row;
        align-content: center;
        justify-content: space-around;
        width: 80vw;
        align-items: center;
    }

    .section-top-page .nav .nav-link {
        margin: 0;
        font-size: 0.8rem;
    }

    @media (max-width:575.98px) {
        .section-top-page {
            align-items: center
        }
    }

    .section-top-page.stickyTop {
        background-color: #fff;
        left: 155px;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 900
    }

    @media (max-width:575.98px) {
        .section-top-page.stickyTop {
            left: 0
        }
    }

    @media (max-width:575.98px) {
        .section-top-page .flex-wrapper {
            display: block
        }
    }

    .section-top-page .flex-wrapper .page-title {
        align-items: center;
        display: flex;
        margin-bottom: 0
    }

    @media (max-width:575.98px) {
        .section-top-page .flex-wrapper .page-title {
            justify-content: center
        }
    }

    .section-top-page .dropdown .dropdown-toggle {
        height: 34px;
        line-height: 34px;
        padding: 0 15px
    }

    .section-middle-line {
        padding-bottom: 5rem;
        padding-top: 5rem;
    }

    @media (max-width:991.98px) {
        .section-middle-line {
            padding-bottom: 1.5rem;
            padding-top: 1.5rem
        }
    }

    .section-middle-line.small-margin-top {
        padding-top: 1.5rem
    }

    .section-middle-line.reversed .text-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-start
    }

    @media (max-width:991.98px) {
        .section-middle-line.reversed .text-wrapper {
            margin: 0;
            width: 100%
        }
    }

    .section-middle-line.reversed .primary-border {
        border: 0;
        height: 100%;
        justify-content: flex-start
    }

    .section-middle-line.reversed .primary-border.spaced {
        padding-top: 0
    }

    .section-middle-line.reversed .citation-wrapper {
        justify-content: flex-start
    }

    .section-middle-line.reversed .button-wrapper {
        text-align: center
    }

    @media (max-width:991.98px) {
        .section-middle-line.reversed .button-wrapper {
            margin: 2.5rem auto 0
        }
    }

    .section-middle-line.about-details {
        margin-bottom: 5rem;
        padding: 10vh 10vw
    }

    @media (max-width:991.98px) {
        .section-middle-line.about-details {
            padding: 0 25px
        }
    }

    .about-details .row {
        padding-top: 5vh;
    }

    .about-details .row .col-6 {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
    }

    .about-details .row .col-6 .citation-wrapper {
        font-size: 2.5rem;
        padding-left: 15vw;
    }

    .about-details .row .col-6 .citation-wrapper .outline-big-text {
        font-size: 2rem;
    }

    .about-details .row .col-6 .text-wrapper {
        padding-left: 10vw;
        padding-right: 10vw;
    }

    .section-middle-line.about-details .citation-wrapper {
        text-align: left
    }

    .section-middle-line.about-details .primary-border {
        padding: 0
    }

    @media (max-width:991.98px) {
        .section-middle-line.about-details .text-wrapper {
            margin-bottom: 50px
        }
    }

    .section-middle-line.about-details .row.marged {
        margin-bottom: 100px
    }

    @media (max-width:991.98px) {
        .section-middle-line.about-details .row.marged {
            margin-bottom: 0
        }
    }

    @media (max-width:991.98px) {
        .section-middle-line .text-wrapper p {
            text-align: justify
        }
    }

    th {
        width: 25%;
    }

    .sold .table thead tr th {
        color: #000066;
        text-transform: uppercase;
        font-weight: 600;
    }

    .sold {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        overflow-x:scroll;
    }

    .sold-img {
        width: 100%;
        min-width: 20vw;
    }

    .sold .card-description {
        height: auto;
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
    }

    .sold .card-text {
        display: none;
    }

    .sold .card-inquiry {
        display: none;
    }

    .sold-price {
        color: white;
        background: #000066;
        text-align: center;
    }

    .listings {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: flex-start;
    }

    .listings .card {
        width: 80vw;
        margin: 10vh 0 0 0;
    }

    .exclusive-listings {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
    }

    .exclusive-listings .card {
        width: 30vh;
        margin: 5vh 0 0 0;
    }

    .property-status {
        font-weight: 700;
        color: #000;
        font-size: 1rem;
        text-align: center;
        border-top: none !important;
        height: 50px;
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: stretch;
    }

    .card {
        height: auto;
    }

    .card-title {
        font-size: 1rem;
        color: #000066;
        line-height: 1.5rem;
        font-weight: 400;
        text-transform: uppercase;
    }

    .card-text {
        font-size: 1rem;
    }

    .card-link {
        color: #ffffff;
        font-size: 1rem;
        text-decoration: none;
    }

    .card-link:hover {
        color: #f1f1f1;
        font-size: 1rem;
        text-decoration: none;
        cursor: pointer;
    }

    .card-inquiry {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: center;
        background: #000066;
        border-bottom-left-radius: calc(0.25rem - 1px);
        border-bottom-right-radius: calc(0.25rem - 1px);
        height: auto;
        min-height: 65px;
        padding: 1vh;
    }

    .card-description {
        height: 185px;
    }

    .card-img {
        height: auto;
        object-fit: cover;
    }

    .section-platforms {
        padding-bottom: 5rem;
        padding-top: 5rem
    }

    .section-platforms .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-platforms .row .col-6 {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-right: 5vw !important;
    }

    @media (max-width:1199.98px) {
        .section-platforms {
            max-height: initial;
            min-height: initial;
            overflow: initial
        }
    }

    @media (max-width:991.98px) {
        .section-platforms {
            padding-bottom: 2.5rem;
            padding-top: 2.5rem
        }
    }

    @media (max-width:767.98px) {
        .section-platforms table {
            width: 100%
        }
    }

    @media (max-width:767.98px) {
        .section-platforms table tr {
            display: flex;
            flex-direction: column;
            margin-bottom: 25px
        }
    }

    @media (max-width:767.98px) {
        .section-platforms table tr td {
            text-align: left
        }
    }

    .section-platforms .citation-align {
        border-left: 1px solid #000066
    }

    @media (max-width:1399.98px) {
        .section-platforms .citation-align {
            border-left: 0
        }
    }

    .section-platforms .platform-title {
        align-items: center;
        border-bottom: 1px solid #000;
        display: flex;
        font-size: 21px;
        font-weight: 700;
        height: 140px;
        line-height: 30px;
        width: auto
    }

    @media (max-width:767.98px) {
        .section-platforms .platform-title {
            border-bottom: 0;
            height: auto
        }
    }

    .section-platforms .platform-title p {
        margin-bottom: 0;
        padding-right: 10px;
        width: auto
    }

    @media (max-width:767.98px) {
        .section-platforms .platform-title p {
            padding-right: 0;
            width: 100%
        }
    }

    .section-platforms .platform-paragraph {
        padding-left: 5vw
    }

    @media (max-width:767.98px) {
        .section-platforms .platform-paragraph {
            padding-left: 0
        }
    }

    .section-platforms .platform-paragraph p {
        margin-bottom: 0;
        width: 250px
    }

    @media (max-width:767.98px) {
        .section-platforms .platform-paragraph p {
            width: auto
        }
    }

    //CTA INVESTMENT HOME PAGE SECTION
    .section-investment {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-bottom: 5rem;
        padding-top: 5rem;
        height: 75vh;
    }

    .section-investment .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-investment .row .col-6 {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-right: 5vw !important;
    }

    .section-investment .row .col-6 .inner {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        align-items: flex-end;
        justify-content: center;
    }

    @media (max-width:1199.98px) {
        .section-investment {
            max-height: initial;
            min-height: initial;
            overflow: initial
        }
    }

    @media (max-width:991.98px) {
        .section-investment {
            padding-bottom: 2.5rem;
            padding-top: 2.5rem
        }
    }

    @media (max-width:767.98px) {
        .section-investment table {
            width: 100%
        }
    }

    @media (max-width:767.98px) {
        .section-investment table tr {
            display: flex;
            flex-direction: column;
            margin-bottom: 25px
        }
    }

    @media (max-width:767.98px) {
        .section-investment table tr td {
            text-align: left
        }
    }

    .section-investment .citation-align {
        border-left: 1px solid #000066
    }

    @media (max-width:1399.98px) {
        .section-investment .citation-align {
            border-left: 0
        }
    }

    .section-investment .platform-title {
        align-items: center;
        border-bottom: 1px solid #000;
        display: flex;
        font-size: 21px;
        font-weight: 700;
        height: 140px;
        line-height: 30px;
        width: auto
    }

    @media (max-width:767.98px) {
        .section-investment .platform-title {
            border-bottom: 0;
            height: auto
        }
    }

    .section-investment .platform-title p {
        margin-bottom: 0;
        padding-right: 10px;
        width: auto
    }

    @media (max-width:767.98px) {
        .section-investment .platform-title p {
            padding-right: 0;
            width: 100%
        }
    }

    .section-investment .platform-paragraph {
        padding-left: 5vw
    }

    @media (max-width:767.98px) {
        .section-investment .platform-paragraph {
            padding-left: 0
        }
    }

    .section-investment .platform-paragraph p {
        margin-bottom: 0;
        width: 250px
    }

    @media (max-width:767.98px) {
        .section-investment .platform-paragraph p {
            width: auto
        }
    }

    //END
    .section-title {
        text-align: center;
        padding: 15vh 0vw;
    }

    .section-title h2 {
        color: #000066;
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: 300;
    }

    .section-cta {
        text-align: center;
        padding: 10vh 10vw 5vh 10vw;
    }

    .section-cta button {
        background: #000066;
        border: 1px solid #000066;
        height: auto;
        width: 40vw;
        border-radius: 2px;
        margin: 1vw;
        margin-left: 0;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.05vw;
        transition: 500ms !important;
    }

    .section-cta button:hover {
        background: #000066;
        border: 1px solid #000066;
        height: auto;
        width: 40vw;
        border-radius: 2px;
        margin: 1vw;
        margin-left: 0;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.05vw;
        transition: 500ms !important;
    }

    .section-full-primary {
        background-color: #fff;
        padding-bottom: 10vh;
        padding-top: 20vh;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        overflow-x: scroll;
        padding-right:0;
    }

    .section-full-primary.no-carousel img {
        height: auto;
        width: 100%
    }

    .section-full-primary.no-carousel .job-image {
        background-position: 50% 50%;
        background-size: cover;
        height: 275px;
        width: 100%
    }

    .section-full-primary.jobs-carousel .red-card {
        height: 275px
    }

    .section-full-primary .row h2 {
        font-size: 1rem;
        color: #000066;
        text-transform: uppercase;
        font-weight: 400;
    }

    @media (max-width:1399.98px) {
        .section-full-primary .row {
            margin-left: -5px;
            margin-right: -5px
        }
    }

    .section-full-primary .row [class*=col] {
        margin-bottom: 25px
    }

    @media (max-width:1399.98px) {
        .section-full-primary .row [class*=col] {
            padding-left: 5px;
            padding-right: 5px
        }
    }

    .section-full-primary .subtitle-wrapper {
        padding-bottom: .5rem
    }

    .section-full-primary .subtitle-wrapper h2 {
        margin-bottom: 0
    }

    @media (max-width:575.98px) {
        .section-full-primary .subtitle-wrapper h2 {
            margin-bottom: .5rem
        }
    }

    .section-full-primary .job-title h3 {
        font-size: 1.75rem;
        font-weight: 800;
        margin-bottom: .25rem
    }

    .section-full-primary .job-description p {
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.25
    }

    .section-team-primary {
        background-color: #fff;
        padding-bottom: 10vh;
        padding-top: 20vh;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .team-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: flex-start;
        padding: 5vh 0vw;
    }

    .team-list-line {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        padding: 5vh 0vw 5vh 0vw;
    }

    .team-list-line .card {
        width: 90vw;
        margin: 0;
        height: 100px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: stretch;
        border: none;
    }

    .team-list-line .card .team-name {
        width: 150px;
        text-align: left;
    }

    .team-list-line .card .card-title {
        width: 400px;
        text-align: center;
    }

    .team-list-line .card .card-text {
        width: 150px;
        text-align: left;
    }

    .team-list-line .card .card-description {
        text-align: center;
        height: auto;
        background: #fff;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: baseline;
        padding-top: 1vh;
    }

    .team-list-line .card .card-description a {
        color: #000;
    }

    .team-list-line .card .card-description a:hover {
        color: #000066;
        cursor: pointer;
    }

    .team-list .card {
        width: 60%;
        margin: 0vh 1vw 9vh 1vw;
        height: 550px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: stretch;
        border: none;
    }

    .team-img {
        height: 350px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    .team-img img {
        flex-shrink: 1;
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
    }

    .team-list .card .card-description {
        text-align: center;
        height: auto;
        background: #fff;
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding: 2vh 2vw;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
    }

    .team-list .card .card-inquiry {
        height: 6vh;
    }

    .team-name {
        font-weight: 700;
        color: #000;
        font-size: 1.25rem;
        text-align: center;
        border-top: none !important;
    }

    .team-filter {
        width: 100vw;
        padding-top: 10vh;
        display: flex;
        position: relative;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;

    }

    .team-filter .inner {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: baseline;
        width: 50vw;
    }

    .team-filter .inner p {
        color: #000066;
        font: 1rem;
    }

    .section-team-primary.no-carousel img {
        height: auto;
        width: 100%
    }

    .section-team-primary.no-carousel .job-image {
        background-position: 50% 50%;
        background-size: cover;
        height: 275px;
        width: 100%
    }

    .section-team-primary.jobs-carousel .red-card {
        height: 275px
    }

    @media (max-width:1399.98px) {
        .section-team-primary .row {
            margin-left: -5px;
            margin-right: -5px
        }
    }

    .section-team-primary .row [class*=col] {
        margin-bottom: 25px
    }

    @media (max-width:1399.98px) {
        .section-team-primary .row [class*=col] {
            padding-left: 5px;
            padding-right: 5px
        }
    }

    .section-team-primary .subtitle-wrapper {
        padding-bottom: .5rem
    }

    .section-team-primary .subtitle-wrapper h2 {
        margin-bottom: 0
    }

    @media (max-width:575.98px) {
        .section-team-primary .subtitle-wrapper h2 {
            margin-bottom: .5rem
        }
    }

    .section-team-primary .job-title h3 {
        font-size: 1.75rem;
        font-weight: 800;
        margin-bottom: .25rem
    }

    .section-team-primary .job-description p {
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.25
    }

    .section-search-simple .row {
        height: 100%;
    }

    .section-search-simple .row .col-6 {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-search-simple .row .col-6 .citation-align {
        align-items: flex-end;
        padding: 0;

    }

    .section-search-simple .row .col-6 .citation-align .citation-wrapper {
        align-items: flex-end;

        padding-right: 10vw;
        padding-bottom: 0;
    }

    .search-text {
        color: #fff;
        font-size: 2rem;
        text-align: right;
        line-height: 1.5;

    }


    .sign-up-btn-light {
        background: #fff;
        color: #000066;
        border: none;
        border-radius: 1px;
        width: 21vw;
        font-size: 1rem;
        line-height: 2;
        margin-top: 5vh;
        text-transform: uppercase;
        font-weight: 300;
    }

    .sign-up-btn-light:hover {
        background: #000066;
        color: #fff;
        border: none;
        border-radius: 1px;
        width: 21vw;
        font-size: 1rem;
        line-height: 2;
        margin-top: 5vh;
        text-transform: uppercase;
        font-weight: 300;
    }

    @media (max-width:991.98px) {
        .section-search-simple {
            padding-bottom: 2.5rem;
            padding-top: 2.5rem
        }
    }

    @media (max-width:767.98px) {
        .section-search-simple img {
            margin-bottom: 2rem
        }
    }

    .section-image-double {
        padding-bottom: 50px;
        padding-top: 5rem
    }

    @media (max-width:991.98px) {
        .section-image-double {
            padding-bottom: 2.5rem;
            padding-top: 20px
        }
    }

    @media (max-width:991.98px) {
        .section-image-double img {
            margin-bottom: 20px
        }
    }

    .section-image-double .flex-wrapper {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center
    }

    @media (max-width:1199.98px) {
        .section-image-double .flex-wrapper {
            align-items: flex-start
        }
    }

    .section-image-double .flex-wrapper .citation {
        border-color: #000066;
        border-style: solid;
        border-width: 1px 0;
        color: #000;
        padding: 1.5rem 0 1.5rem;
        width: 400px
    }

    @media (max-width:767.98px) {
        .section-image-double .flex-wrapper .citation {
            margin-top: 25px
        }
    }

    @media (max-width:1199.98px) {
        .section-image-double .flex-wrapper .citation {
            padding: 10px 0;
            width: 100%
        }
    }

    .section-image-double .flex-wrapper .citation p {
        font-family: Gilroy;
        font-size: 26px;
        font-weight: 800;
        line-height: 32px;
        margin-bottom: 2rem
    }

    @media (max-width:1599.98px) {
        .section-image-double .flex-wrapper .citation p {
            font-size: 20px;
            line-height: 24px
        }
    }

    @media (max-width:1199.98px) {
        .section-image-double .flex-wrapper .citation p {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 1rem
        }
    }

    @media (max-width:991.98px) {
        .section-image-double .flex-wrapper .citation p {
            font-size: 18px;
            line-height: 22px
        }
    }

    .section-image-double .flex-wrapper .citation span {
        display: block;
        line-height: 1.35
    }

    .section-image-double .flex-wrapper .citation span.author {
        font-weight: 600
    }

    @media (max-width:991.98px) {

        .section-image-double.section-image-4 [class*=col]:nth-child(1) img,
        .section-image-double.section-image-4 [class*=col]:nth-child(2) img {
            margin-bottom: 25px
        }
    }

    @media (max-width:991.98px) {

        .section-image-double.section-image-4 [class*=col]:nth-child(1) img,
        .section-image-double.section-image-4 [class*=col]:nth-child(2) img,
        .section-image-double.section-image-4 [class*=col]:nth-child(3) img {
            margin-bottom: 25px
        }
    }

    .section-gallery .gallery-wrapper {
        padding-bottom: 25px
    }

    .section-gallery .gallery-wrapper .row {
        margin-left: -10px;
        margin-right: -10px
    }

    .section-gallery .gallery-wrapper .row [class*=col] {
        margin: 0 0 10px;
        padding-left: 10px;
        padding-right: 10px;
        height: 300px;
    }

    .section-gallery .gallery-image-wrapper {
        overflow: hidden;
        position: relative;
        height: 250px;
    }

    .section-gallery .gallery-image-wrapper:hover .image-effect {
        left: 0
    }

    .section-gallery .gallery-image-wrapper:hover .image-effect [class*=image] {
        opacity: 1
    }

    .section-gallery .gallery-dots {
        margin: 2.5rem auto;
        text-align: center
    }

    .section-gallery .gallery-dots .btn {
        align-items: center;
        cursor: default;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        opacity: 0;
        padding: 5px
    }

    .section-gallery .image-effect {
        background-color: #000066;
        bottom: 0;
        cursor: pointer;
        font-family: Gilroy;
        left: -100%;
        position: absolute;
        top: 0;
        transition: left .25s ease;
        width: 100%
    }

    .section-gallery .image-effect p {
        font-size: 1rem;
        line-height: 1.25;
        margin: 0
    }

    .section-gallery .image-effect [class*=image] {
        color: #fff;
        opacity: 0;
        transition: opacity .25s ease;
        transition-delay: .15s
    }

    .section-gallery .image-effect .image-text {
        font-size: 1.25rem;
        left: 15px;
        position: absolute;
        right: 15px;
        top: 15px
    }

    .section-gallery .image-effect .image-text h3 {
        font-weight: 800;
        margin-bottom: .25rem
    }

    .section-gallery .image-effect .image-text .image-description {
        font-weight: 300;
        max-width: 60%
    }

    .section-gallery .image-effect .image-infos {
        bottom: 15px;
        font-family: Helvetica, Arial;
        font-size: 1.25rem;
        position: absolute;
        right: 15px;
        text-orientation: sideways-right;
        transform: rotate(180deg);
        writing-mode: vertical-rl
    }

    .section-gallery .image-effect .image-infos .image-locality {
        font-size: 14px;
        font-weight: 300;
        margin-left: .25rem
    }

    .section-gallery .image-effect .image-infos .image-date {
        font-size: 14px;
        font-weight: 800
    }

    .section-gallery .image-effect .image-arrow {
        bottom: 15px;
        font-size: 1.25rem;
        left: 15px;
        position: absolute
    }

    .section-gallery .image-effect .image-arrow:hover .icon-wrapper {
        background-color: #fff;
        color: #000066
    }

    .section-gallery .image-effect .image-arrow .icon-wrapper {
        align-items: center;
        border-radius: 50%;
        display: flex;
        height: 35px;
        justify-content: center;
        width: 35px
    }

    .section-carousel {
        background-color: #fff;
        padding-bottom: 0;
        padding-top: 5rem
    }

    @media (max-width:991.98px) {
        .section-carousel {
            padding-bottom: 20px;
            padding-top: 0
        }
    }

    .section-carousel .owl-carousel .owl-stage {
        display: -webkit-box !important;
        display: -moz-box !important;
        display: -ms-box !important;
        display: box !important
    }

    .section-carousel .owl-carousel .owl-item img {
        display: block;
        height: 400px;
        margin: 0 auto;
        max-height: 400px;
        width: auto
    }

    .section-share {
        background-color: #fff;
        padding-bottom: 0;
        padding: 10vh;
    }

    .section-share .flex-wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center
    }

    .section-share .flex-wrapper .text-wrapper {
        letter-spacing: 1px;
        margin-bottom: .75rem
    }

    .section-share .flex-wrapper .icons-wrapper a {
        color: #343a40;
        margin: 0 .5rem;
        text-decoration: none
    }

    .section-share .flex-wrapper .icons-wrapper a:hover {
        color: #000066
    }

    .section-about .intro {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        padding: 20vh 10vw;
    }

    .section-about .intro .inner_L {
        width: 55%;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-about .intro .inner_L .txt {
        white-space: nowrap;
        text-align: center;
        border: none;
    }

    .section-about .intro .inner_L .txt h2 {
        padding: 0;
        text-transform: uppercase;
    }

    .section-about .intro .inner_R {
        width: 45%;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
    }

    .section-about .intro .inner_R .txt {
        padding: 0;
        text-align: center;
    }

    .section-about .row .col-6 {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    .section-about .row .col-6 .img-wrapper {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: center;
    }

    .about {
        height: auto;
        width: 100vw;
        padding: 20vh 10vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
    }


    .section-team .intro {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        padding: 20vh 10vw;
    }

    .section-team .intro .inner_L {
        width: 55%;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-team .intro .inner_L .txt {
        white-space: nowrap;
        text-align: center;
        border: none;
    }

    .section-team .intro .inner_L .txt h2 {
        padding: 0;
        text-transform: uppercase;
    }

    .section-team .intro .inner_R {
        width: 45%;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
    }

    .section-team .intro .inner_R .txt {
        padding: 0;
        text-align: justify;
    }

    .section-team .intro .inner_R .txt p {
        text-align: justify;
        font-size: 1rem;
    }

    .section-team .row .col-6 {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    .section-team .row .col-6 .img-wrapper {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: center;
    }

    .section-mission {
        height: auto;
        width: 100vw;
        padding: 10vh 10vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        background: linear-gradient(rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), url(../images/services-overlay_bg.jpg) no-repeat center center;
        background-size: cover;
    }

    .section-mission .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .mission-row {
        text-align: center;
        display: flex;
        width: 80vw;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        padding: 5vh 0vw;
    }

    .mission-row .col-3 {
        padding: 2vh 5vw 2vh 5vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        height: auto;
        width: 100%;
    }

    .mission-row .col-3 p {
        text-align: justify;
        text-align-last: left;
        padding-top: 2vh;
    }

    .section-mission h2 {
        font-size: 4rem;
        color: #000066;
        text-transform: uppercase;
        font-weight: 300;
    }

    .section-mission .row .col-12 {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0;
    }

    .section-mission .col-3 hr {
        height: 5px;
        width: 25%;
        color: #000066;
        opacity: 1 !important;
    }

    .section-mission .dropcap {
        color: #57a6dc;
        font-weight: 600;
        font-size: 5rem;
        line-height: 0.5
    }

    .mission-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        width: 80vw;
    }

    .mission-list {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-end;
        width: 60vw;
        padding-bottom: 10vh;
    }

    .mission-list hr {
        color: #000066;
        opacity: 1;
        height: 1px;
        width: 80%;
        text-align: right;
        margin: 2vh 0vw;
    }

    .mission-list .row {
        width: 90vw;
        height: 25vh;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
        align-content: flex-start;
    }

    .mission-list .row .col-3 {
        display: inline-flex;
        text-align: left;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    .mission-list .row h4 {
        transform: rotate(-90deg);
        font-size: 2rem;
        font-weight: 600;
        color: #000;
        display: inline-flex;
        flex-direction: column;
        text-align: center;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .mission-list .row h4 span {
        font-size: 1.5rem;
        font-weight: 600;
        color: #9a9a9a;
    }

    .mission-list .row .col-6 p {
        font-weight: 600;
        color: #1d2054;
        font-size: 1.75rem;
        line-height: 1.75;
    }

    .mission-header h3 {
        font-size: 4rem;
        font-weight: 600;
        color: #000;
        text-transform: uppercase;
    }

    .stats-banner {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        height: auto;
        background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(../images/gfi_stats.jpg) no-repeat center center;
        background-size: cover;
        z-index: auto !important;
        padding:10vh 0vw
    }

    .stats-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-top: 0vh;
    }

    .stats-header h3 {
        font-size: 2rem;
        font-weight: 400;
        color: #fff;
        text-transform: uppercase;
        line-height: 1;
        white-space: nowrap;
        transform: scaleY(1.1) !important;
    }

    .inner-stats {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-bottom: 5vh;
    }

    .stats-banner hr {
        width: 10vw;
        color: #fff;
        height: 1px;
        opacity: 1;
    }

    .stats-col-R {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
        width: 30vw;
        padding-left: 2vw;
        text-align: center;
        height: 50vh;
    }

    .stats-col-R .col-4 {
        height: 19vh;
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: flex-start;
    }

    .stats-banner h2 {
        font-size: 2rem;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 900;
    }

    .stats-col-R h3 {
        color: #fff;
    }

    .stats-col-R h4 {
        font-size: 1.75rem;
        text-transform: uppercase;
        color: #fff;
        font-weight: 600;
        text-align: left;
        display: flex;
        flex-direction: column;
    }

    .stats-col-R h4 span {
        font-weight: 400;
        font-size: 1.5rem;
        white-space: nowrap;
    }

    .stats-col-L {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
        width: 30vw;
        text-align: center;
        height: 50vh;
    }

    .stats-col-L .col-4 {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: flex-end;
        height: 20vh;
    }

    .stats-col-L h3 {
        color: #fff;
    }

    .stats-col-L h4 {
        font-size: 3rem;
        text-transform: uppercase;
        color: #fff;
        font-weight: 600;
        text-align: left;
        display: flex;
        flex-direction: column;
    }

    .stats-col-L h4 span {
        font-weight: 400;
        font-size: 2rem;
    }

    .email-banner {
        width: 100vw;
        height: auto;
        background: url(../images/cta.jpg) no-repeat center center;
        background-size: cover;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding: 10vh 0vw;
    }

    .email-banner .inner {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;

    }

    .email-banner .inner p {
        color: #fff;
        font-weight: 600;
        font-size: 1.5rem;
        text-transform: uppercase;
        transform: scaleY(1.1) !important;
        text-align: center;
        padding-bottom: 5vh;
        width: 90vw;
        padding-left: 5vw;
padding-right: 5vw;
    }

    .services {
        height: auto;
        width: 100vw;
        padding: 20vh 10vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        background: #fff;
        padding: 10vh 10vw;


    }

    .services-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-content: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        text-align: left;
    }

    .services-title h3 {
        font-size: 3rem;
        font-weight: 600;
        text-transform: uppercase;
        color: #ffffff;
        display: flex;
        flex-direction: column;

    }

    .services-title span {
        color: #f1f1f1;
        font-weight: 400;
        font-size: 2rem;
        white-space: nowrap;

    }

    .mission-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-content: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        text-align: center;
    }

    .mission-title h3 {
        font-size: 2rem;
        font-weight: 600;
        text-transform: uppercase;
        color: #000066;
        display: flex;
        flex-direction: column;

    }

    .mission-title span {
        color: #939393;
        font-weight: 400;
        font-size: 1.5rem;
        white-space: nowrap;

    }

    .services .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        width: 100vw;
        padding: 2vh 5vw 2vh 5vw;
        text-align: center;

    }

    .services .row .col-12 {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0;
    }

    .services h2 {
        font-size: 4rem;
        color: #000066;
        text-transform: uppercase;
        font-weight: 300;

    }

    .services-row {
        text-align: center;
        display: flex;
        width: 90vw;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        padding: 5vh 0vw 10vh 0vw;
    }

    .services-row .col-4 {
        margin: 2vh 2.5vw 2vh 2.5vw;
        padding: 5vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: flex-start;
        height: auto;
        background: #000066;
        background-size: cover;
        width: 95%;
    }

    .services-row .col-4 span {
        width: 225px;
        text-align: left;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: baseline;
        transition: 500ms ease;
        transform: translateX(0vw);
    }

    .services-row .col-4 span:hover {
        transition: 500ms ease;
        transform: translateX(1vw)
    }

    .services-row .col-4 span i {
        position: relative;
        padding: 0vh 50% 0vh 0;
        color: #ffff;
    }

    .services-row h3 {
        font-size: 2rem;
        text-transform: uppercase;
        text-align: left;
        line-height: 1;
        color: #fff;
    }

    .services-row .col-4 p {
        text-align: justify;
        text-align-last: left;
        padding-top: 2vh;
        color: #fff;
        hyphens: auto;
        -webkit-hyphens: auto;
    }

    .services-btn {
        border: none;
        border-radius: 2vw;
        width: auto;
        background: transparent;
        color: #fff;
        font-size: 1rem;
        transition: 500ms ease;
        padding: 0;
        text-align: left;
        white-space: nowrap;
    }

    .services-btn:hover {
        width: auto;
        background: transparent;
        color: #f1f1f1;
        font-size: 1rem;
        padding: 0;
        transition: 500ms ease;
        cursor: pointer;
        white-space: nowrap;
    }

    .services-row hr {
        color: #fff;
        height: 1px;
        width: 5vw;
    }

    @media (max-width:991.98px) {
        .section-about {
            padding-bottom: 2.5rem
        }
    }

    .section-about h2 {
        align-items: flex-end;
        color: #000;
        display: flex;
        font-size: 1.5rem;
        font-weight: 400;
        height: 60px;
        line-height: 1;
        margin-bottom: 0;
        padding-bottom: 10px
    }

    .section-about [class*=col] {
        margin-bottom: 75px
    }

    @media (max-width:991.98px) {
        .section-about [class*=col] {
            margin-bottom: 25px
        }
    }

    .section-about [class*=col].related {
        margin-bottom: 0
    }

    @media (max-width:991.98px) {
        .section-about [class*=col].related {
            margin-bottom: 50px
        }
    }

    .section-about [class*=col]:nth-child(1) {
        order: 1
    }

    @media (max-width:991.98px) {
        .section-about [class*=col]:nth-child(1) {
            order: 1
        }
    }

    .section-about [class*=col]:nth-child(2) {
        order: 2
    }

    @media (max-width:991.98px) {
        .section-about [class*=col]:nth-child(2) {
            order: 3
        }
    }

    .section-about [class*=col]:nth-child(3) {
        order: 3
    }

    @media (max-width:991.98px) {
        .section-about [class*=col]:nth-child(3) {
            order: 2
        }
    }

    .section-about [class*=col]:nth-child(4) {
        order: 4
    }

    @media (max-width:991.98px) {
        .section-about [class*=col]:nth-child(4) {
            margin-bottom: 0;
            order: 4
        }
    }

    .section-about .v-line {
        bottom: 5rem;
        top: 60px
    }

    .section-about .img-wrapper {
        margin-bottom: 50px
    }

    @media (max-width:991.98px) {
        .section-about .img-wrapper {
            margin-bottom: 15px
        }
    }

    .section-about .nav-pills {
        margin-bottom: 4rem
    }

    @media (max-width:991.98px) {
        .section-about .nav-pills {
            margin-bottom: 1.5rem
        }
    }

    .section-about .nav-pills .nav-link {
        border-width: 2px 0 1px
    }

    .section-about .tab-content {
        height: auto
    }

    .paragraph-align {
        text-align: justify;
        hyphens: auto;
        -webkit-hyphens: auto;
    }

    .section-about .outline-big-text {
        font-size: 35px
    }

    .section-about .owl-nav {
        bottom: 0;
        color: red;
        left: 0;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0
    }

    .section-about .owl-nav .owl-prev {
        left: -25px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%)
    }

    .section-about .owl-nav .owl-next {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%)
    }

    .section-partners {
        margin-bottom: 2rem
    }

    .section-partners .partners-image {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 30%;
        height: 50vh;
        min-height: 300px;
        margin-right: 1rem
    }

    @media (max-width:991.98px) {
        .section-partners .partners-image {
            height: 40vh;
            min-height: auto;
            margin-right: 0
        }
    }

    .section-partners .partners {
        display: flex;
        flex-direction: column;
        height: 50vh;
        min-height: 300px;
        justify-content: flex-start;
        align-items: stretch;
        margin-left: 1rem;
        overflow: auto;
        max-width: 600px
    }

    @media (max-width:991.98px) {
        .section-partners .partners {
            height: auto;
            min-height: auto;
            margin-left: 0;
            margin-top: 1rem
        }
    }

    .section-partners .partners .partner {
        margin-top: 1.5rem
    }

    @media (min-width:1600px) {
        .section-partners .partners .partner {
            margin-top: 2.5rem
        }
    }

    @media (min-width:1921px) {
        .section-partners .partners .partner {
            margin-top: 3.5rem
        }
    }

    .section-partners .partners .partner:first-child {
        margin-top: 0
    }

    .section-partners .partners .partner .name {
        line-height: 26px;
        font-size: 14px;
        font-weight: 700;
        margin: 0
    }

    .section-partners .partners .partner .function {
        color: #000066
    }

    .section-partners .partners .partner .function::after {
        color: #000;
        content: " | ";
        display: inline;
        margin: 0 .25rem;
        vertical-align: middle
    }

    .section-partners .partners .partner .email {
        color: #000
    }

    .section-partners .partners .partner .description {
        margin-top: .5rem;
        line-height: 26px
    }

    .section-teams .outline-big-text {
        margin-bottom: 1.5rem
    }

    .section-teams .team-carousel {
        position: relative
    }

    .section-teams .team-carousel .member {
        padding: 20px
    }

    .section-teams .team-carousel .member img {
        border-color: rgba(255, 255, 255, 0);
        border-style: solid;
        border-width: 1px 0
    }

    .section-teams .team-carousel .member .member-infos {
        align-items: center;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        opacity: 0;
        padding-top: 1rem
    }

    .section-teams .team-carousel .member .member-infos .function,
    .section-teams .team-carousel .member .member-infos .name,
    .section-teams .team-carousel .member .member-infos .role {
        line-height: 26px
    }

    .section-teams .team-carousel .member .member-infos .name {
        color: #000066;
        font-weight: 700
    }

    .section-teams .team-carousel .member .member-infos .role {
        font-weight: 400
    }

    .section-teams .team-carousel .member .member-infos .function {
        color: #000066
    }

    .section-teams .team-carousel .member .member-infos .email a {
        color: #000
    }

    .section-teams .team-carousel .member:hover img {
        border-color: #000066
    }

    .section-teams .team-carousel .member:hover .member-infos {
        opacity: 1
    }

    .section-teams .team-carousel .owl-nav {
        color: #000066
    }

    .section-teams .team-carousel .owl-nav svg {
        height: auto;
        width: 25px
    }

    .section-teams .team-carousel .owl-nav .owl-next,
    .section-teams .team-carousel .owl-nav .owl-prev {
        align-items: center;
        background-color: #fff;
        border-radius: 50%;
        display: flex;
        height: 40px;
        justify-content: center;
        position: absolute;
        top: calc(50% - 75px);
        width: 40px
    }

    .section-teams .team-carousel .owl-nav .owl-next:active,
    .section-teams .team-carousel .owl-nav .owl-next:focus,
    .section-teams .team-carousel .owl-nav .owl-prev:active,
    .section-teams .team-carousel .owl-nav .owl-prev:focus {
        outline: 0
    }

    .section-teams .team-carousel .owl-nav .owl-next:hover,
    .section-teams .team-carousel .owl-nav .owl-prev:hover {
        background-color: #000066;
        color: #fff
    }

    .section-teams .team-carousel .owl-nav .owl-prev {
        left: 50px;
        transform: translateX(100%) translateY(-50%)
    }

    @media (max-width:991.98px) {
        .section-teams .team-carousel .owl-nav .owl-prev {
            left: 0
        }
    }

    .section-teams .team-carousel .owl-nav .owl-next {
        right: 50px;
        transform: translateX(-100%) translateY(-50%)
    }

    @media (max-width:991.98px) {
        .section-teams .team-carousel .owl-nav .owl-next {
            right: 0
        }
    }

    .section-worked-with-us {
        padding-bottom: 5rem;
        padding-top: 5rem
    }

    @media (max-width:991.98px) {
        .section-worked-with-us {
            padding-bottom: 2.5rem;
            padding-top: 2.5rem
        }
    }

    .section-worked-with-us p {
        line-height: 2;
        margin-bottom: 0;
        padding-right: 0;
        text-align: justify
    }

    @media (max-width:575.98px) {
        .section-worked-with-us p {
            padding-right: 0
        }
    }

    @media (max-width:767.98px) {
        .section-worked-with-us p {
            padding-right: 0
        }
    }

    .section-worked-with-us p span {
        color: #000066;
        padding: 0 .25rem
    }

    .section-contact {
        padding-bottom: 5rem;
        padding-top: 5rem
    }

    @media (max-width:991.98px) {
        .section-contact {
            padding-bottom: 2.5rem;
            padding-top: 0
        }
    }

    .section-contact a {
        color: #343a40
    }

    .section-contact a:hover {
        color: #000066
    }

    .section-contact iframe {
        height: 400px;
        width: 100%
    }

    .section-contact .flex-wrapper {
        display: flex
    }

    @media (max-width:991.98px) {
        .section-contact .flex-wrapper {
            justify-content: flex-start
        }
    }

    .section-contact .fa-ul {
        margin-left: 3rem
    }

    .section-contact .fa-ul li {
        margin-bottom: 2rem
    }

    .section-contact .fa-ul li .fa-li {
        color: #ff6a6a;
        font-size: 1.5rem;
        left: -2.5em;
        margin-top: .5rem
    }

    .section-contact .fa-ul p {
        line-height: 1.75;
        padding-left: 1rem;
        padding-top: .5rem
    }

    .section-contact .fa-ul p svg {
        margin-left: .5rem
    }

    .section-contact .follow-us {
        font-size: 1rem
    }

    .section-contact .follow-us span {
        display: block;
        margin-bottom: .25rem
    }

    .section-contact .follow-us a {
        margin-right: .5rem
    }

    .section-news {
        padding-bottom: 10vh;
        padding-top: 20vh;
    }

    @media (max-width:991.98px) {
        .section-news {
            padding-bottom: 2.5rem
        }
    }

    .section-news .news-wrapper a {
        text-decoration: none
    }

    .section-news .news-wrapper .row {
        margin-left: 0;
        margin-right: 0;
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        align-content: center;
        justify-content: center;
        width: 100%;
    }

    .section-news .news-wrapper .row > [class*=col] {
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 5vh;
        padding-top: 5vh;
        width: 100%;

    }

    .section-news .news-wrapper .news-item::before {
        display: none
    }

    .section-news .news-wrapper .news-item::after {
        display: none
    }

    .section-news .news-wrapper .news-item .img-effect a {
        display: block
    }

    .section-news .news-wrapper .news-title {
        color: #000;
        display: block;
        margin-top: 1rem
    }

    .section-news .news-wrapper .news-title h3 {
        color: #343a40;
        font-size: 16px;
        font-weight: 800;
        letter-spacing: .5px;
        margin-bottom: 3px
    }

    .section-news .news-wrapper .news-infos {
        color: #343a40;
        font-size: 13px
    }

    .section-news .news-wrapper .news-category {
        color: #343a40;
        font-weight: 600;
        padding-right: .25rem
    }

    .section-news .news-wrapper .news-date {
        padding-left: .25rem
    }

    .section-news .news-pagination {
        margin: 2.5rem auto;
        text-align: center
    }

    .section-news .news-pagination ul {
        justify-content: center
    }

    .section-news .news-pagination ul li a {
        align-items: center;
        background: rgba(255, 255, 255, 0);
        border: 1px solid #000066;
        border-radius: 50%;
        display: flex;
        height: 30px;
        justify-content: center;
        margin: 0 3px;
        padding-top: 4px;
        width: 30px
    }

    .section-news .news-pagination ul li a:hover {
        background-color: #000066;
        color: #fff
    }

    .section-news .news-pagination ul li.active a {
        background-color: #000066;
        color: #fff
    }

    .section-news .news-content p {
        line-height: 1.75
    }

    .section-news .news-content p:last-child {
        margin-bottom: 0
    }

    .section-news .news-author {
        border-color: #000066;
        border-style: solid;
        border-width: 1px 0;
        margin-top: 3rem;
        padding: 1rem 0
    }

    .section-news .news-author p {
        margin: 0
    }

    .section-news .news-author .name {
        font-weight: 600
    }

    .section-news .news-share {
        color: #343a40;
        text-align: center
    }

    .section-news .news-share p {
        font-size: 1rem;
        letter-spacing: 1px;
        margin-bottom: .25rem
    }

    .section-news .news-share .icons-wrapper a {
        color: #343a40;
        margin: 0 .5rem
    }

    .section-news .news-share .icons-wrapper a:hover {
        color: #000066
    }

    .section-introduction {
        padding-bottom: 5rem;
        padding-top: 5rem
    }

    @media (max-width:991.98px) {
        .section-introduction {
            padding-bottom: 1.5rem;
            padding-top: 1.5rem
        }
    }

    .section-bio {
        padding-top: 25vh;
        height: 100vh;
    }

    .section-bio .row {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .section-bio .row .col-4 {
        display: inline-flex;
        width: 100%;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    .bio-content {
        width: 90vw;
    }

    @media (max-width:991.98px) {
        .section-bio {
            padding-bottom: 1.5rem;
            padding-top: 1.5rem
        }
    }

    @media (max-width:1199.98px) {
        .section-bio {
            margin-bottom: 2.5rem
        }
    }

    .bio-name {
        color: #000066;
        font-size: 2rem;
        font-weight: 200;
        padding-left: 0vw;
    }

    .bio-title {
        color: rgba(60, 60, 60, 0.6392156863);
        font-size: 1.15rem;
        padding-left: 0vw;
        text-align: center;
    }

    .bio-img {
        padding-bottom: 2vh;
    }

    .bio-img img {
        height: 40vh;
        width: auto;
    }

    .bio-contact {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        padding-left: 0vw;
        width: 80%;
        padding-top: 5vh;

    }

    .bio-contact-line {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: stretch;
    }

    .bio-contact-line i {
        padding-right: 1vw;
    }

    .bio-contact-line a {
        color: #000;
        text-decoration: none;
    }

    .section-job {
        margin-bottom: 5rem
    }

    @media (max-width:991.98px) {
        .section-job {
            padding-bottom: 1.5rem;
            padding-top: 1.5rem
        }
    }

    @media (max-width:1199.98px) {
        .section-job {
            margin-bottom: 2.5rem
        }
    }

    .section-job.section-top-page {
        margin-bottom: 0
    }

    .section-job.section-top-page .flex-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%
    }

    .section-job.section-top-page .times-wrapper {
        align-items: flex-start;
        color: #000066;
        display: flex;
        font-size: 1.5rem;
        opacity: .85;
        padding-top: .25rem;
        transition: opacity .25s ease
    }

    .section-job.section-top-page .times-wrapper:hover {
        color: #000066;
        opacity: 1
    }

    .section-job.section-full-primary {
        margin-bottom: 5rem
    }

    .section-job .job-header {
        display: flex;
        flex-direction: column;
        margin-top: -.25rem
    }

    @media (max-width:575.98px) {
        .section-job .job-header {
            padding-left: 50px
        }
    }

    .section-job .job-header .page-title {
        margin-bottom: 1rem
    }

    @media (max-width:575.98px) {
        .section-job .job-header .page-title {
            justify-content: flex-start
        }
    }

    .section-job .job-header .job-title h3 {
        font-size: 1.75rem;
        font-weight: 800;
        margin-bottom: .25rem
    }

    .section-job .job-header .job-description p {
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.25
    }

    .section-job .job-content {
        padding-right: 5rem
    }

    @media (max-width:1399.98px) {
        .section-job .job-content {
            padding-right: 2.5rem
        }
    }

    @media (max-width:991.98px) {
        .section-job .job-content {
            padding-right: 0
        }
    }

    .section-job .job-content h3 {
        color: #000066;
        font-size: .9rem;
        font-weight: 700;
        margin-bottom: 1.5rem;
        text-transform: uppercase
    }

    .section-job .job-content p {
        margin-bottom: 2rem
    }

    @media (max-width:991.98px) {
        .section-job .job-content p {
            text-align: justify
        }
    }

    .section-job .job-content ul {
        margin-bottom: 4rem
    }

    .section-job .job-content ul li {
        margin-bottom: 1rem;
        padding-left: 35px;
        position: relative
    }

    @media (max-width:991.98px) {
        .section-job .job-content ul li {
            text-align: justify
        }
    }

    .section-job .job-content ul li::before {
        background-color: #000066;
        content: '';
        height: 2px;
        left: 0;
        position: absolute;
        top: .75em;
        width: 8px
    }

    .section-job .job-content :last-child {
        margin-bottom: 0
    }

    .section-job .job-contact {
        height: 100%;
        min-height: 400px;
        padding-left: 5rem;
        position: relative
    }

    @media (max-width:1399.98px) {
        .section-job .job-contact {
            padding-left: 2.5rem
        }
    }

    @media (max-width:1199.98px) {
        .section-job .job-contact {
            min-height: auto;
            padding-left: 0;
            padding-top: 2.5rem
        }
    }

    .section-job .job-contact::before {
        background-color: #000066;
        content: '';
        height: 50%;
        left: 0;
        min-height: 400px;
        position: absolute;
        top: 0;
        width: 1px
    }

    @media (max-width:1199.98px) {
        .section-job .job-contact::before {
            display: none
        }
    }

    .section-job .job-contact p {
        margin-bottom: 1.5rem
    }

    .section-job .job-contact p span {
        font-weight: 700
    }

    .section-job .job-contact .btn {
        font-family: Gilroy;
        font-weight: 800
    }

    .section-latest-news {
        display: flex;
        height: auto;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        padding-top: 10vh;
    }

    .section-latest-news .row {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        width: 80vw;
    }

    .section-latest-news .row > [class*=col] {
        padding-left: 10px;
        padding-right: 10px;
        width: 80vw;
    }

    .article {
        padding: 10vh 2vw 0vh 2vw;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
    }

    .article-heading {
        padding: 1.5rem 0;
        text-align: center
    }

    .article-heading h1 {
        display: block;
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 0;
        padding: 2.5rem 25px 1.5rem
    }

    .article-heading .tags {
        display: flex;
        justify-content: center;
        margin-top: 2.5rem
    }

    .article-heading .tags li a {
        display: block;
        line-height: 1.25;
        padding: .25rem 1rem 0;
        position: relative
    }

    .article-heading .tags li a::before {
        background-color: #000066;
        bottom: 4px;
        content: '';
        position: absolute;
        right: 0;
        top: 4px;
        width: 1px
    }

    .article-heading .tags li:last-child a::before {
        display: none
    }

    .owl-carousel {
        z-index: 0
    }

    .owl-theme .owl-dots {
        bottom: 5px;
        margin: 0;
        position: absolute;
        right: 15px
    }

    .owl-theme .owl-dots .owl-dot {
        outline: 0
    }

    .owl-theme .owl-dots .owl-dot span {
        background-color: #fff;
        border: 1px solid #000066
    }

    .owl-theme .owl-dots .owl-dot.active span,
    .owl-theme .owl-dots .owl-dot:hover span {
        background-color: #000066;
        border: 1px solid #000066
    }

    .btn {
        border-radius: 0;
        padding: .5rem 1rem
    }

    .btn.big-text {
        font-family: Gilroy;
        font-size: 1.5rem;
        font-weight: 800
    }

    .arrow-wrapper-white .arrow,
    .custom-bounce {
        animation-name: custom-bounce;
        transform-origin: center bottom
    }

    .customFadeInUp {
        animation-name: customFadeInUp
    }

    .customSlideInUp {
        animation-name: customFadeInUp
    }

    @keyframes custom-bounce {

        10.6%,
        16%,
        4%,
        from,
        to {
            animation-timing-function: cubic-bezier(.215, .61, .355, 1);
            transform: translate3d(0, 0, 0)
        }

        8%,
        8.6% {
            animation-timing-function: cubic-bezier(.755, .05, .855, .06);
            transform: translate3d(0, -30px, 0)
        }

        14% {
            animation-timing-function: cubic-bezier(.755, .05, .855, .06);
            transform: translate3d(0, -15px, 0)
        }

        18% {
            transform: translate3d(0, 0, 0)
        }
    }

    @keyframes fade-from-none {
        0% {
            display: none;
            opacity: 0
        }

        1% {
            display: block;
            opacity: 0
        }

        100% {
            display: block;
            opacity: 1
        }
    }

    @keyframes banner-line {
        0% {
            height: 0
        }

        100% {
            height: 100%
        }
    }

    @keyframes banner-arrow {
        0% {
            opacity: 0;
            top: 0;
            transform: translateY(0)
        }

        100% {
            opacity: 1;
            top: 50%;
            transform: translateY(-100%)
        }
    }

    @keyframes customFadeInUp {
        from {
            opacity: 0;
            transform: translate3d(0, 50px, 0);
            visibility: visible
        }

        to {
            opacity: 1;
            transform: translate3d(0, 0, 0)
        }
    }

    @keyframes customSlideInUp {
        from {
            opacity: 0;
            transform: translate3d(0, 25px, 0);
            visibility: visible
        }

        to {
            opacity: 1;
            transform: translate3d(0, 0, 0)
        }
    }

    .admin-container {
        margin-top: -150px;
        padding: 50px
    }

    .admin-container input {
        min-width: 300px
    }

    .admin-container .btn {
        border-radius: 4px;
        line-height: 1;
        padding-top: 10px
    }

    #ie-banner {
        text-align: center;
        padding-top: 15vh;
        position: absolute;
    }

    #ie-banner img {
        max-height: 30vh;
        max-width: 80%;
        width: auto
    }

    .ie #ie-banner {
        display: block
    }

    .ie .section-banner #animation-wrapper {
        display: none
    }

    .ie .section-worked-with-us .col-auto {
        width: 100%
    }

    .ie .section-worked-with-us .section-title.vertical {
        margin-bottom: 15px;
        text-orientation: initial;
        transform: none;
        writing-mode: initial !important
    }

    .ie .section-gallery .image-infos {
        text-align: right;
        transform: none
    }

    .ie-banner-1 {
        width: 100vw;
    }

    .ie-banner-2 {
        width: 100vw;
    }

    .ie-banner-3 {
        width: 100vw;
    }

    .ie-banner-4 {
        width: 100vw;
    }

    .ie-banner-5 {
        width: 80vw;
    }

    .login-section {
        height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-left: 0;
    }

    .login-inner {
        padding-left: 15vw;
        height: 50vh;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: flex-start;
    }

    .login-img {
        background: url(../images/banner/login.jpg) center center no-repeat;
        height: 100vh;
    }

    .title-row {
        display: inline-flex;
    }

    .contact-section {
        height: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding: 30vh 5vw 10vh 5vw;
    }

    .contact-section .row {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: nowrap;
        align-content: center;
        align-items: center;
        height: auto;
    }

    .contact-section .row .col-6 {
        width: 100%;
    }

    .contact-inner {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: stretch;
        width: 100%;
        height: auto;
    }

    .contact-info {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .contact-info-inner {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: flex-start;
        height: 40vh;
    }

    .contact-info-inner .bi {
        margin-right: 2vw;
    }

    .contact-info-inner a {
        color: #000066;
        font-size: 1rem;
        line-height: 1.5;
        text-decoration: none;

    }

    .contact-form {
        height: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    .contact-form .row {
        flex-direction: column;
    }
    .contact-form .row .col {
        padding-top:5vh;
    }

    .mb-3 {
        width: auto;
    }

    .contact-control {
        border: none;
        border-bottom: 1px solid #ced4da;
        border-radius: 0px !important;
    }

    .contact-control:focus {
        border-bottom: 1px solid #000066;
        border-radius: 0px !important;
        box-shadow: none !important;
    }

    .contact-select {
        border: none;
        border-bottom: 1px solid #ced4da;
        border-radius: 0px !important;
    }

    .contact-select:focus {
        border-bottom: 1px solid #000066;
        border-radius: 0px !important;
        box-shadow: none !important;
    }

    //JOIN TEAM FORM
    .join-team-form {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .join-team-form .form-control {
        background: rgba(255, 255, 255, 0.8509803922);
        width: 35vh;
        height: 5vh;
        border-radius: 2px;
    }

    .join-team-form .row {
        padding-bottom: 0vh;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    .join-team-form .row .col-6 {
        width: auto;
        padding-bottom: 1vh;
    }
    .join-team-form .row .col-12 {
        padding-top:5vh;
    }

    .form-file input[type=file]::-webkit-file-upload-button {
        height: 5vh;

    }

    .form-file input[type=file]::file-selector-button {
        height: 5vh;
    }

    //HOVER UNDERLINE
    .hover-underline {
        position: relative;
    }

    .hover-underline:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        transition: width 0s ease, background .5s ease;
    }

    .hover-underline:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        background: #000066;
        transition: width .5s ease;
    }

    .hover-underline:hover:before {
        width: 100%;
        background: #000066;
        transition: width .5s ease;
    }

    .hover-underline:hover:after {
        width: 100%;
        background: transparent;
        transition: all 0s ease;
    }

    //HOVER UNDERLINE WHITE
    .hover-underline-white {
        position: relative;
    }

    .hover-underline-white:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        transition: width 0s ease, background .5s ease;
    }

    .hover-underline-white:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        background: #f1f1f1;
        transition: width .5s ease;
    }

    .hover-underline-white:hover:before {
        width: 100%;
        background: #f1f1f1;
        transition: width .5s ease;
    }

    .hover-underline-white:hover:after {
        width: 100%;
        background: transparent;
        transition: all 0s ease;
    }

    //HOVER UNDERLINE RED
    .hover-underline-red {
        position: relative;
    }

    .hover-underline-red:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        transition: width 0s ease, background .5s ease;
    }

    .hover-underline-red:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        background: #000066;
        transition: width .5s ease;
    }

    .hover-underline-red:hover:before {
        width: 100%;
        background: #000066;
        transition: width .5s ease;
    }

    .hover-underline-red:hover:after {
        width: 100%;
        background: transparent;
        transition: all 0s ease;
    }

    //ARROW DOWN 
    .arrow-down {
        display: inline-flex;
        position: fixed;
        bottom: 5vh;
        width: 1.5vw;
    }

    //ARROW DOWN HOME PAGE
    .arrow-down-animate {
        animation-name: loop-down;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    @keyframes loop-down {
        0% {
            transform: translateY(0vh);
            opacity: 0
        }

        25% {
            transform: translateY(1vh);
            opacity: 0.5
        }

        50% {
            transform: translateY(2vh);
            opacity: 1
        }

        75% {
            transform: translateY(3vh);
            opacity: 0.5
        }

        100% {
            transform: translateY(4vh);
            opacity: 0
        }
    }

    //COUNTERS
    #counters_1,
    #counters_2,
    #counters_3 {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
        height: 20vh;
        width: 30vw;
    }

    .counter {
        font-size: 3rem;

    }

    .counter-wrapper {
        display: flex;
        height: 10vh;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-end;
    }

    //SWUP
    .transition-fade {
        transition: 0.4s;
        opacity: 1;
    }

    html.is-animating .transition-fade {
        opacity: 0;
    }

    //HOME
    .home-header {
        height: 110vh;
        widows: 100vh;
        object-fit: cover;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        background: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.8)), url(../images/banner/header.jpg) no-repeat center center;
        background-size: cover;
    }

    .first-letter {
        -webkit-initial-letter: 4;
        initial-letter: 4;
        color: orange;
        font-weight: bold;
        margin-right: .75em;
    }

    .intro {
        height: 50vh;
        width: 100vw;
        display: flex;
        z-index: 9500;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        position: absolute;
        padding: 0vh 10vw 5vh 10vw;
        left: 0;
        text-align: left;
        top: 40vh;
    }

    .intro .txt hr {
        width: 80vw;
        height: 2px;
        opacity: 1;
        color: #fff;
    }

    .intro .txt h3 {
        font-size: 2rem;
        font-weight: 400;
        color: #fff;
        text-transform: uppercase;
        line-height: 1;
        white-space: nowrap;
        transform: scaleY(1.1) !important;
    }

    .intro .txt p {
        font-size: 1rem;
        font-weight: 400;
        color: #fff;
        line-height: 1.5;
        text-align: justify;
    }

    .dropcap {
        background: transparent;
        color: #FDF9F2;
        float: left;
        font-size: 3.0rem;
        line-height: 1;
        margin: 0.2em 0.1em 0em 0;
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    .dropcap:before,
    .dropcap:after {
        content: "";
        display: block;
    }

    .dropcap:before {
        margin-top: -0.2em;
    }

    .dropcap:after {
        margin-bottom: -0.15em;
    }

    .sr-only:not(:focus):not(:active) {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    .intro-btn {
        background: #fff;
        border: 1px solid #fff;
        height: 10vh;
        width: 40vw;
        border-radius: 2px;
        margin: 1vw;
        margin-left: 0;
        color: #000;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.05vw;
        transition: 500ms !important;
    }

    .intro-btn:hover {
        background: #fff;
        border: 2px solid #fff;
        height: 10vh;
        width: 40vw;
        border-radius: 2px;
        margin: 1vw;
        margin-left: 0;
        color: #000066;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.05vw;
        transition: 500ms !important;
    }

    .video-overlay {
        background: rgba(0, 0, 0, 0.4);
        height: 100vh;
        width: 100vw;
        position: absolute;
        top: 0;
        z-index: 9000;
    }

    .intro_1 {
        height: auto;
        width: 100vw;
        background: #fff;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding: 25vh 5vw 10vh 5vw;
    }

    .intro_1 h1 {
        color: #000066;
        font-size: 4rem;
        font-weight: 300;
        text-transform: uppercase;
        margin: 0;
        padding-bottom: 2vh;
        text-align: center;
    }

    .top-img {
        position: absolute;
        width: 15%;
        z-index: 8500;
        right: 5vw;
        bottom: 60vh;
        box-shadow: black;
        -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
        box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
    }

    .mid-img {
        position: absolute;
        right: 7vw;
        bottom: 23vh;
        width: 40%;
        box-shadow: black;
        -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
        box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
    }

    .bot-img {
        position: absolute;
        width: 25%;
        right: 10vw;
        bottom: 20vh;
        box-shadow: black;
        -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
        box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
    }

    .listings-btn {
        background: #fff;
        border: none;
        height: 4vh;
        width: 10vw;
        border-radius: 2px;
        margin: 1vw;
        margin-left: 0;
    }

    //SLIDE OVER SECTIONS
    .info-contain {
        width: 100vw;
        height: 100vh;
        height: 100%;
        height: fill-available;
        transition-duration: 600ms;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.8, 0, 0, 1);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 4;
        background: #fff;
        background-size: cover;
        -ms-transform: translate(100%, 0);
        /* IE 9 */
        -webkit-transform: translate(100%, 0);
        /* Safari */
        transform: translate(100%, 0);
        /* Standard syntax */
        padding-top: 30vh;
        overflow: scroll;
        margin: 0;
    }

    .info-contain.opened {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100vh;
        height: 100%;
        height: fill-available;
        transition-duration: 600ms;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.8, 0, 0, 1);
        -ms-transform: translate(0, 0);
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
        z-index: 9999 !important;
        padding-top: 30vh;
        overflow: scroll;
        margin: 0;
    }

    .overlay-element-innovation {
        width: 100vw;
        display: table;
        float: left;
        height: 100vh;
        color: #fff;
    }

    .overlay-txt {
        display: flex;
        vertical-align: middle;
        text-align: justify;
        text-align-last: left;
        font-size: 16px;
        font-weight: 300;
        padding: 5vh 10vw;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100vh;
        color: #000;
    }

    .overlay-txt h2 {
        font-size: 1.25rem;
        color: #000066;
        text-transform: uppercase;
        font-weight: 900;
    }

    .overlay-txt h3 {
        font-size: 2rem;
        font-weight: 900;
        color: #000;
        text-transform: uppercase;
    }

    .close {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1;
        color: #fff;
        text-shadow: 0 1px 0 rgb(0 0 0 / 50%);
        opacity: 1;
        display: flex;
        width: 35vw;
        position: relative;
        margin-top: 5vh;
        white-space: nowrap;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: stretch;
    }

    .close p {
        font-weight: 400;
        font-size: 0.8rem;
        padding-left: 2vw;
        text-transform: uppercase;
        text-shadow: none;
        color: #000066;
    }

    .close:hover {
        color: #000066;
        text-shadow: 0 1px 0 black;
        transition: all 0.3s;
        cursor: pointer;
        transition: all 0.5s;
        transform: translateX(-1vw);
    }

    .divider {
        width: 50vw;
        margin: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 7vh;
    }

    //PRESS
    .news-img {
        width: auto;
        height: 255px !important;
        transition: 500ms;
    }

    .news-img:hover {
        transition: 500ms;
        transform: scale(1.05)
    }

    .press-img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-top-left-radius: calc(0.25rem - 1px);
        border-top-right-radius: calc(0.25rem - 1px);
    }

    //ANIMATION LOADER//
    #loader-wrapper .loader-section {
        position: fixed;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: 9998;
        text-align: center;
        background: #fff;
    }

    #loader {
        z-index: 9999;
        position: fixed;
        height: 100vh;
        width: 100vw;
        text-align: center;
        background: #fff;
    }

    .loader-logo {
        position: relative;
        top: 3vh;
        height: auto;
        width: auto;
        text-align: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .loader_img_1 {
        animation: loader_1 4s ease;
        opacity: 0;
        width: 30%;
    }

    .loader_img_2 {
        animation: loader_2 4s ease;
        opacity: 0;
        Width: 30%;
    }

    @keyframes loader_1 {
        0% {
            transform: translate(100vw, 40vh);
            opacity: 0;
        }

        60% {
            transform: translate(0vw, 40vh);
            opacity: 1;
        }

        70% {
            transform: translate(0vw, 40vh);
            opacity: 1;
        }

        100% {
            transform: translate(0vw, 40vh);
            opacity: 0;
        }
    }

    @keyframes loader_2 {
        0% {
            transform: translate(50vw, 40vh);
            opacity: 0;
        }

        40% {
            opacity: 0;
        }

        60% {
            transform: translate(0vw, 40vh);
            opacity: 1;
        }

        70% {
            transform: translate(0vw, 40vh);
            opacity: 1;
        }

        100% {
            transform: translate(0vw, 40vh);
            opacity: 0;
        }
    }

    .loader-text-fade-out {
        opacity: 0;
        animation: fade-text 4s;
        font-weight: 600;
        font-size: 1rem;
    }

    @keyframes fade-text {
        0% {
            opacity: 0;
        }

        30% {
            opacity: 1;
        }

        90% {
            opacity: 1;
        }

        100% {
            opacity: 0;
            visibility: hidden;
        }
    }

    #loader {
        opacity: 0;
        visibility: hidden;
        animation: fade-background 4s;
    }

    @keyframes fade-background {
        0% {
            opacity: 1;
            visibility: visible;
        }

        80% {
            opacity: 1;
            visibility: visible;
        }

        100% {
            opacity: 0;
            visibility: hidden;
        }
    }

    .sign-up-form {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        width: 70vh;

    }

    .sign-up-form .form-control {
        background: #ffffffd9;
        width: 35vh;
        height: 5vh;
        border-radius: 2px;
    }

    .sign-up-form .form-control:focus {
        background: #ffffff;
        width: 35vh;
        height: 5vh;
        border-radius: 10px;
        border-color: #000066
    }

    .sign-up-btn {
        background: #ffffff;
        color: #000;
        height: auto;
        width: 40vw;
        border: 1px solid #fff;
        border-radius: 2px;
        font-weight: 600;
        text-transform: uppercase;
    }

    .sign-up-btn:hover {
        color: #000066;
        border-color: #000066;
        font-weight: 600;
        text-transform: uppercase;
        height: auto;
        width: 40vw;
    }

    .join-team {
        background: linear-gradient(rgba(241, 241, 241, 0.6), rgba(241, 241, 241, 0.6)), url(../images/join-team.jpg) no-repeat center center;
        background-size: cover;
        height: auto;
        width: 100vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .join-team .inner {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        background: transparent;
        width: 100%;
        height: 50%;
        padding: 10vh 0vw;
    }

    .join-team .inner h3 {
        color: #000066;
        font-size: 2rem;
        font-weight: 300;
        white-space: nowrap;
        text-transform: uppercase;
    }

    .join-team .inner hr {
        width: 25%;
        color: #000066;
        opacity: 1;
        height: 2px;
    }

    .join-team .inner p {
        color: #000066;
        font-size: 1.15rem;
        font-weight: 600;
        white-space: wrap;
        text-transform: uppercase;
        padding: 5vh 0vw 5vh 0vw;
        text-align: center;
    }

    .join-btn {
        font-weight: 600;
        height: 8vh;
        width: 15vw;
        background: #fff;
        border: 1px solid #000066;
        border-radius: 2px;
        color: #000066;
        text-transform: uppercase;
        transition: 500ms ease;
    }

    .join-btn:hover {
        font-weight: 600;
        height: 8vh;
        width: 15vw;
        background: #000066;
        border: 1px solid #000066;
        border-radius: 2px;
        color: #fff;
        text-transform: uppercase;
        transition: 500ms ease;
        cursor: pointer;
    }

    .join-text {
        padding: 0 !important;
        font-size: 1rem !important;

    }
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
    main {
        padding-top: 0vh;
    }

    body {
        font-family: 'Century Gothic', sans-serif;
    }

    body + services-btn:active {
        overflow: hidden;
    }

    b {
        font-weight: bold;
    }

    //DESKTOP MENU NAVBAR 
    .navbar {
        background-color: #fff;
        border-right: 1px solid #eee;
        display: flex;
        padding: 0vh 5vw;
        margin-top: 43px;
        position: fixed;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        top: -2vh;
        transition: left 0.25s ease;
        width: 100vw;
        height: 90px;
        z-index: 9;
        box-shadow: 1px 1px 10px rgb(0 0 0 / 25%);
    }

    .nav-link {
        color: #000;
        text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: 600;
    }

    .nav-link:hover {
        color: #000066;
        cursor: pointer;
    }

    .active-link {
        color: #000066;
    }

    .login-btn {
        color: #000066;
        text-transform: capitalize;
        text-decoration: none;
        font-size: 1rem;
        font-weight: 600;
    }

    .login-form-btn {
        background: #fff;
        color: #000066;
        line-height: 2;
        font-size: 1rem;
        border: 1px solid #000066;
        width: 10vw;
        border-radius: 2px;
        margin-top: 2vh;
        height: 5vh;
        width: 25vw;
        text-transform: uppercase;
        font-weight: 600;
        transition: 500ms ease;
    }

    .login-form-btn:hover {
        background: #000066;
        color: #fff;
        line-height: 2;
        font-size: 1rem;
        border: 1px solid #000066;
        width: 10vw;
        border-radius: 2px;
        margin-top: 2vh;
        height: 8vh;
        width: 15vw;
        text-transform: uppercase;
        font-weight: 600;
        transition: 500ms ease;
    }

    .form-text a {
        color: #6c757d;
        text-decoration: none;
    }

    .form-text a:hover {
        color: #000066;
    }

    .navbar > .container,
    .navbar > .container-fluid {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between
    }

    .navbar-brand {
        display: inline-block;
        padding-top: .3125rem;
        padding-bottom: .3125rem;
        margin-right: 1rem;
        font-size: 1.25rem;
        line-height: inherit;
        white-space: nowrap;
        width: 20vw;
    }

    .navbar-brand:focus,
    .navbar-brand:hover {
        text-decoration: none
    }

    .navbar-nav {
        display: flex;
        flex-direction: row;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
    }

    .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0
    }

    .navbar-nav .dropdown-menu {
        position: static;
        float: none
    }

    .navbar-text {
        display: inline-block;
        padding-top: .5rem;
        padding-bottom: .5rem
    }

    .navbar-collapse {
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center
    }

    .navbar-toggler {
        padding: .25rem .75rem;
        font-size: 1.25rem;
        line-height: 1;
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: .25rem
    }

    .navbar-toggler:focus,
    .navbar-toggler:hover {
        text-decoration: none
    }

    .navbar-toggler:not(:disabled):not(.disabled) {
        cursor: pointer
    }

    .navbar-toggler-icon {
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        vertical-align: middle;
        content: "";
        background: no-repeat center center;
        background-size: 100% 100%
    }

    @media (max-width:575.98px) {

        .navbar-expand-sm > .container,
        .navbar-expand-sm > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:576px) {
        .navbar-expand-sm {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-sm .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-sm .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-sm .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-sm > .container,
        .navbar-expand-sm > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-sm .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-sm .navbar-toggler {
            display: none
        }
    }

    @media (max-width:767.98px) {

        .navbar-expand-md > .container,
        .navbar-expand-md > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:768px) {
        .navbar-expand-md {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-md .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-md .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-md .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-md > .container,
        .navbar-expand-md > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-md .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-md .navbar-toggler {
            display: none
        }
    }

    @media (max-width:991.98px) {

        .navbar-expand-lg > .container,
        .navbar-expand-lg > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:992px) {
        .navbar-expand-lg {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-lg .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-lg .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-lg .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-lg > .container,
        .navbar-expand-lg > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-lg .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-lg .navbar-toggler {
            display: none
        }
    }

    @media (max-width:1199.98px) {

        .navbar-expand-xl > .container,
        .navbar-expand-xl > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:1200px) {
        .navbar-expand-xl {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-xl .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-xl .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-xl .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-xl > .container,
        .navbar-expand-xl > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-xl .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-xl .navbar-toggler {
            display: none
        }
    }

    @media (max-width:1399.98px) {

        .navbar-expand-xxl > .container,
        .navbar-expand-xxl > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:1400px) {
        .navbar-expand-xxl {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-xxl .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-xxl .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-xxl .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-xxl > .container,
        .navbar-expand-xxl > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-xxl .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-xxl .navbar-toggler {
            display: none
        }
    }

    @media (max-width:1599.98px) {

        .navbar-expand-xxxl > .container,
        .navbar-expand-xxxl > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:1600px) {
        .navbar-expand-xxxl {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-xxxl .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-xxxl .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-xxxl .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-xxxl > .container,
        .navbar-expand-xxxl > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-xxxl .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-xxxl .navbar-toggler {
            display: none
        }
    }

    @media (max-width:1920.98px) {

        .navbar-expand-uhd > .container,
        .navbar-expand-uhd > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:1921px) {
        .navbar-expand-uhd {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-uhd .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-uhd .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-uhd .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-uhd > .container,
        .navbar-expand-uhd > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-uhd .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-uhd .navbar-toggler {
            display: none
        }
    }

    .navbar-expand {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand > .container,
    .navbar-expand > .container-fluid {
        padding-right: 0;
        padding-left: 0
    }

    .navbar-expand .navbar-nav {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        width: 65vw;

    }

    .navbar-expand .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand > .container,
    .navbar-expand > .container-fluid {
        flex-wrap: nowrap
    }

    .navbar-expand .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
    }

    .navbar-expand .navbar-toggler {
        display: none
    }

    .navbar-light .navbar-brand {
        color: rgba(0, 0, 0, .9)
    }

    .navbar-light .navbar-brand:focus,
    .navbar-light .navbar-brand:hover {
        color: rgba(0, 0, 0, .9)
    }

    .navbar-light .navbar-nav .nav-link {
        color: rgba(0, 0, 0, .5)
    }

    .navbar-light .navbar-nav .nav-link:focus,
    .navbar-light .navbar-nav .nav-link:hover {
        color: rgba(0, 0, 0, .7)
    }

    .navbar-light .navbar-nav .nav-link.disabled {
        color: rgba(0, 0, 0, .3)
    }

    .navbar-light .navbar-nav .active > .nav-link,
    .navbar-light .navbar-nav .nav-link.active,
    .navbar-light .navbar-nav .nav-link.show,
    .navbar-light .navbar-nav .show > .nav-link {
        color: rgba(0, 0, 0, .9)
    }

    .navbar-light .navbar-toggler {
        color: rgba(0, 0, 0, .5);
        border-color: rgba(0, 0, 0, .1)
    }

    .navbar-light .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0,0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
    }

    .navbar-light .navbar-text {
        color: rgba(0, 0, 0, .5)
    }

    .navbar-light .navbar-text a {
        color: rgba(0, 0, 0, .9)
    }

    .navbar-light .navbar-text a:focus,
    .navbar-light .navbar-text a:hover {
        color: rgba(0, 0, 0, .9)
    }

    .navbar-dark .navbar-brand {
        color: #fff
    }

    .navbar-dark .navbar-brand:focus,
    .navbar-dark .navbar-brand:hover {
        color: #fff
    }

    .navbar-dark .navbar-nav .nav-link {
        color: rgba(255, 255, 255, .5)
    }

    .navbar-dark .navbar-nav .nav-link:focus,
    .navbar-dark .navbar-nav .nav-link:hover {
        color: rgba(255, 255, 255, .75)
    }

    .navbar-dark .navbar-nav .nav-link.disabled {
        color: rgba(255, 255, 255, .25)
    }

    .navbar-dark .navbar-nav .active > .nav-link,
    .navbar-dark .navbar-nav .nav-link.active,
    .navbar-dark .navbar-nav .nav-link.show,
    .navbar-dark .navbar-nav .show > .nav-link {
        color: #fff
    }

    .navbar-dark .navbar-toggler {
        color: rgba(255, 255, 255, .5);
        border-color: rgba(255, 255, 255, .1)
    }

    .navbar-dark .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
    }

    .navbar-dark .navbar-text {
        color: rgba(255, 255, 255, .5)
    }

    .navbar-dark .navbar-text a {
        color: #fff
    }

    .navbar-dark .navbar-text a:focus,
    .navbar-dark .navbar-text a:hover {
        color: #fff
    }

    //END NAVBAR
    //TICKER
    .ticker {
        width: 100vw;
        height: 27px;
        position: fixed;
        display: block;
        top: 0;
        z-index: 9999;
        padding: 0;
    }

    .ticket .row {
        height: auto;
    }

    .ticket .row .col-md-12 {}

    .breaking-news {
        display: block;
        position: relative;
        background: #3c3c3c;
        height: 27px;
    }

    .news-scroll {
        height: 27px;
        position: relative;
    }

    .news {
        width: 10vw;
        height: 5vh;
        min-height: 5vh;
        background: #000066;
        padding: 0;
        border: 0;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .news-scroll a {
        font-size: 0.8rem;
        font-weight: 300;
        color: #fff;
        text-transform: uppercase;
        text-decoration: none;
    }

    .dot {
        padding-right: 0.5vw;
    }

    .bold {
        font-weight: bold;
    }

    .img-fill {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden
    }

    .img-fill img {
        flex-shrink: 0;
        object-fit: cover;
    }

    footer {
        display: flex;
        background-color: #3c3c3c;
        border-top: 1px solid #888;
        color: #888;
        font-size: 1rem;
        padding: 15vh 0;
        position: relative;
        z-index: auto !important;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        height: auto;
        min-height: 100vh;
        z-index: 9500 !important;
    }

    footer a {
        color: #fff;
        font-size: 1rem;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;
    }

    footer a:hover {
        color: #f1f1f1
    }

    footer p {
        margin: 0;
        font-size: 1rem;
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
    }

    footer svg {
        margin: 0
    }

    footer .footer-row {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin-bottom: 10px;
        align-content: center;
        width: 100vw;
        padding: 0vh 5vw;
        height: auto;
        min-height: 80vh;
    }

    footer .footer-row .col-3 {
        width: 100%;
    }

    .footer-row .col-6 {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: flex-start;
        width: 100%;
    }

    .footer-info {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
    }

    .footer-row .col-3 p {
        font-size: 1rem;
        text-transform: capitalize;
        font-weight: 400;
        white-space: nowrap;
        text-align: center;
    }

    .footer-row .col-3 a {
        font-size: 1rem;
        text-transform: lowercase;
        font-weight: 400;
        white-space: nowrap;
    }

    .footer-info .col-3 {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: center;
        padding: 0vh 0vw 0vh 2vw;
        height: 10vh;
        width: 50% !important;
    }

    .footer-row .col-6 .footer-info .col-3 a {
        font-weight: 600;
        text-transform: uppercase;
    }

    .footer-social {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        padding: 1vh;
    }

    .footer-social a {
        font-size: 2rem !important;
    }

    footer .footer-row:last-child {
        font-size: 11px;
        margin-bottom: 0
    }

    body {
        color: #000;
        font-size: 1rem;
        font-weight: 300;
        line-height: 26px;
        position: relative;
    }

    .scrollbar {
        overflow: auto
    }

    .scrollbar::-webkit-scrollbar {
        width: 10px;
        height: 10px
    }

    .scrollbar::-webkit-scrollbar-track {
        background-color: #e9ecef
    }

    .scrollbar::-webkit-scrollbar-thumb {
        background: #adb5bd
    }

    .scrollbar::-webkit-scrollbar-thumb:hover {
        background: #495057
    }

    main {
        margin-left: 0;
        overflow: hidden;
    }

    section {
        padding-left: 5vw;
        padding-right: 5vw;
        position: relative;
        z-index: 100;
        z-index: 2 !important;
    }

    section.no-mt {
        padding-top: 0
    }

    section.no-mb {
        padding-bottom: 0
    }

    header {
        background-color: #fff;
        position: relative;
        z-index: 100
    }

    img {
        max-width: 100%
    }

    h1 {
        font-weight: 800;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0
    }

    p {
        line-height: 1.75
    }

    strong {
        font-weight: 700
    }

    .mobile-arrow {
        bottom: 0;
        display: flex;
        justify-content: center;
        left: 0;
        margin: 0 auto;
        padding-bottom: 25px;
        position: absolute;
        right: 0
    }

    .mobile-arrow a {
        animation-delay: 2.5s;
        animation-duration: 5s;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        animation-name: custom-bounce;
        display: block;
        transform-origin: center bottom
    }

    .anchors {
        align-items: center;
        display: flex;
        font-size: 1.25rem;
        padding-top: .5rem
    }

    @media (max-width:575.98px) {
        .anchors {
            justify-content: center;
            padding-top: .5rem
        }
    }

    .anchors svg {
        color: #000066;
        height: 1.75rem;
        margin: 0 1.25rem 0 0;
        width: auto
    }

    @media (max-width:575.98px) {
        .anchors svg {
            margin: 0
        }
    }

    .anchors .nav-link {
        color: #000;
        font-size: 14px;
        font-weight: 300;
        margin: 0 1.25rem;
        padding: 8px 0 5px;
        position: relative;
        text-decoration: none
    }

    @media (max-width:575.98px) {
        .anchors .nav-link {
            margin: 0 .75rem
        }
    }

    .anchors .nav-link::before {
        background-color: #000066;
        bottom: 8px;
        content: '';
        height: 1px;
        left: 0;
        opacity: 0;
        position: absolute;
        transition: opacity .25s ease, width .15s ease;
        width: 0
    }

    .anchors .nav-link:first-child {
        margin-left: 0
    }

    .anchors .nav-link:last-child {
        margin-right: 0
    }

    .anchors .nav-link.active::before,
    .anchors .nav-link:hover::before {
        opacity: 1;
        width: 100%
    }

    .negative-mt {
        margin-top: -150px
    }

    .custom-list,
    .section-about ul {
        columns: 2;
        margin-top: 2.5rem;
        width: auto
    }

    @media (max-width:991.98px) {

        .custom-list,
        .section-about ul {
            margin-bottom: 1.5rem;
            margin-top: 1.5rem
        }
    }

    @media (max-width:767.98px) {

        .custom-list,
        .section-about ul {
            columns: 1
        }
    }

    .custom-list li,
    .section-about ul li {
        padding: .25rem 0 .25rem 1.5rem;
        position: relative
    }

    .custom-list li::before,
    .section-about ul li::before {
        border: 1px solid #000066;
        border-radius: 50%;
        content: '';
        height: 7px;
        left: 0;
        position: absolute;
        top: 12px;
        width: 7px
    }

    .primary-border {
        border-right: 1px solid #000066;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        padding: 0 5vw 0 0;
        position: relative
    }

    @media (max-width:991.98px) {
        .primary-border {
            border: 0;
            min-height: auto;
            padding: 1rem 0
        }
    }

    .primary-border.spaced {
        padding: 5rem 0
    }

    @media (max-width:991.98px) {
        .primary-border.spaced {
            padding: 2rem 0
        }
    }

    .page-title {
        color: #000066;
        font-size: 4rem;
        font-weight: 300;
        text-transform: uppercase;
        padding-bottom: 5vh;
        text-align: center;
    }

    .page-title.outline-big-text {
        font-size: 2.5rem
    }

    .subtitle-wrapper {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding-bottom: 3rem
    }

    @media (max-width:575.98px) {
        .subtitle-wrapper {
            align-items: flex-start;
            flex-direction: column;
            justify-content: flex-start
        }
    }

    @media (max-width:991.98px) {
        .subtitle-wrapper {
            padding-bottom: 1rem
        }
    }

    .text-wrapper p:last-child {
        margin: 0
    }

    .img-wrapper {
        border-color: rgba(255, 255, 255, 0);
        border-style: solid;
        border-width: 1px 0;
        padding: 0;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;
        flex-direction: column;
        align-content: center;
        width: 100%;
    }

    .img-wrapper hr {
        height: 5px;
        color: #000066;
        width: 25%;
    }

    .img-wrapper.static {
        border: 0;
        padding: 0
    }

    .img-wrapper.static .img-legend {
        color: #000;
        padding: 10px 0 0
    }

    .img-wrapper.static .img-legend a {
        color: #000;
        display: block;
        font-size: 16px;
        font-weight: 700;
        padding: .15rem 0 0;
        text-decoration: none
    }

    .img-wrapper img {
        overflow: hidden;
        object-fit: cover;
        min-height: 100%;
        min-width: 100%;
        flex-shrink: 0;
    }

    .img-wrapper .img-legend {
        color: #343a40;
        display: block;
        padding: 15px 0 0
    }

    .img-wrapper .img-legend p {
        line-height: 16px;
        margin: 0
    }

    .img-wrapper .img-legend p a {
        color: #000;
        font-size: 14px;
        font-weight: 800
    }

    .img-wrapper .img-legend .date {
        font-size: 14px;
        font-weight: 300
    }

    .img-wrapper .news-img {
        height: 350px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
    }

    .project-figure {
        position: relative
    }

    .project-figure figcaption {
        bottom: 10px;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 1px;
        position: absolute;
        right: 20px
    }

    .outline-big-text {
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(166, 0, 0, .9);
        color: #000066;
        font-family: Gilroy;
        font-size: 1.5rem;
        font-weight: 800;
        letter-spacing: 1px;
        line-height: 1;
        white-space: nowrap
    }

    .citation-wrapper .outline-big-text {
        font-size: 1.5rem;
    }

    @media (max-width:1399.98px) {
        .outline-big-text {
            font-size: 45px
        }
    }

    @media (max-width:1199.98px) {
        .outline-big-text {
            font-size: 40px
        }
    }

    .outline-big-text.outline-white {
        -webkit-text-stroke-color: rgba(255, 255, 255, .9);
        color: #fff
    }

    .solid-big-text {
        color: #ffffff;
        font-size: 4rem;
        font-weight: 600
    }

    .citation-align {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        padding-left: 5vw;
    }

    .citation-align .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        height: 100%
    }

    .citation-wrapper {
        color: #000066;
        display: flex;
        flex-direction: column;
        font-family: Helvetica;
        font-size: 1.5rem;
        font-weight: 700;
        height: 100%;
        justify-content: center;
        line-height: 1;
        padding-bottom: 2.5vh;
    }

    @media (max-width:991.98px) {
        .citation-wrapper {
            font-size: 1.25rem;
            padding: 1rem 0;
            text-align: center
        }
    }

    .citation-wrapper.hp .outline-big-text,
    .citation-wrapper.hp .solid-big-text {
        font-size: 3rem
    }

    @media (max-width:1599.98px) {

        .citation-wrapper.hp .outline-big-text,
        .citation-wrapper.hp .solid-big-text {
            font-size: 3rem;
        }
    }

    @media (max-width:1199.98px) {

        .citation-wrapper.hp .outline-big-text,
        .citation-wrapper.hp .solid-big-text {
            font-size: 3rem
        }
    }

    .citation-wrapper.hp .solid-small-text {
        font-size: 1rem;

    }

    @media (max-width:1199.98px) {
        .citation-wrapper.hp .solid-small-text {
            font-size: 20px
        }
    }

    .list-align {
        height: 100%
    }

    .list-align > .row {
        height: 100%
    }

    //PROPERTY PAGE
    .property-header {
        padding: 0 !important;
        height: 500px;
    }

    .property-img {
        height: 500px;
        width: 100%;
    }

    .property-img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .property-title {
        width: 100vw;
        height: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        align-items: flex-start;
        justify-content: center;
        padding: 5vh 5vw;
    }

    .title h2 {
        font-size: 2rem;
        color: #000066;
        font-weight: 600;
        text-transform: capitalize;
    }

    .property-address p {
        font-size: 1.25rem;
        font-weight: 300;
        color: #3c3c3c;
        text-transform: capitalize;
    }

    .property {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        width: 100vw;
        padding: 0vh 5vw;
    }

    .property .col-6 {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
    }

    .property-details {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: nowrap;
        align-content: center;
        padding: 0vh 0vw 0vh 10vw;
    }

    .property-details ul {
        margin-bottom: 1.25rem;
        padding-bottom: 1.25rem;
        position: relative
    }

    .property-details ul::before {
        background-color: #000066;
        bottom: 0;
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        width: 25px
    }

    .property-details ul:last-child {
        margin-bottom: 0;
        padding-bottom: 0
    }

    .property-details ul:last-child::before {
        display: none
    }

    .property-details ul li {
        font-size: 14px
    }

    .property-details ul li span {
        color: #000066;
        font-weight: 600;
        padding-right: .75rem
    }

    .property-inquiry {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-top: 10vh;
    }

    .property-inquiry .row {
        display: inline-flex;
    }

    .property-inquiry .row button {
        text-transform: uppercase;
        background: #000066;
        color: #fff;
        width: 25vw;
        height: 5vh;
        border-radius: 2px;
        transition: 500ms;
        border: none;
    }

    .property-inquiry .row button:hover {
        background: #fff;
        color: #000066;
        width: 16vw;
        height: 8vh;
        border-radius: 2px;
        transition: 500ms;
        border: 1px solid #000066
    }

    .border-link {
        border-style: solid;
        border-width: 1px 0;
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        padding: .2rem .25rem .1rem;
        position: relative;
        transition: color .25s ease;
        white-space: nowrap;
        word-spacing: 2px
    }

    .border-link::before {
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        top: 0;
        transition: width .25s ease;
        width: 0;
        z-index: -1
    }

    .border-link:hover {
        text-decoration: none
    }

    .border-link:hover::before {
        width: 100%
    }

    .border-link.border-link-primary {
        border-color: #000066;
        color: #000066
    }

    .border-link.border-link-primary::before {
        background-color: #000066
    }

    .border-link.border-link-primary:hover {
        color: #fff
    }

    .border-link.border-link-white {
        border-color: #fff;
        color: #fff
    }

    .border-link.border-link-white::before {
        background-color: #fff
    }

    .border-link.border-link-white:hover {
        color: #000066
    }

    .border-link.no-effect::before {
        display: none
    }

    .border-link.no-effect:hover {
        color: #000066
    }

    .link-effect {
        position: relative;
        z-index: 1
    }

    .link-effect::before {
        background-color: #fff;
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        top: 0;
        transition: width .2s ease;
        width: 0;
        z-index: -1
    }

    .link-effect:hover::before {
        width: 100%
    }

    .nav .nav-link {
        color: #000;
        font-weight: 300;
        margin: 0 50px 0 0;
        padding-bottom: .25rem;
        padding-left: 0;
        padding-right: 0;
        position: relative
    }

    .nav .nav-link::before {
        background-color: #000066;
        bottom: 8px;
        content: '';
        height: 1px;
        left: 0;
        opacity: 0;
        position: absolute;
        transition: opacity .25s ease, width .15s ease;
        width: 0
    }

    .nav .nav-link.active::before,
    .nav .nav-link:hover::before {
        opacity: 1;
        width: 100%
    }

    .row-adjust {
        margin-left: -25px;
        margin-right: -25px
    }

    @media (max-width:991.98px) {
        .row-adjust {
            margin-left: -10px;
            margin-right: -10px
        }
    }

    .row-adjust > [class*=col] {
        padding-left: 25px;
        padding-right: 25px
    }

    @media (max-width:991.98px) {
        .row-adjust > [class*=col] {
            padding-left: 10px;
            padding-right: 10px
        }
    }

    .row-align {
        justify-content: center
    }

    .row-big {
        margin-left: -75px;
        margin-right: -75px
    }

    .row-big > [class*=col] {
        padding-left: 75px;
        padding-right: 75px
    }

    .black-filter {
        background-color: rgba(0, 0, 0, .5);
        bottom: 0;
        left: 0;
        opacity: 0;
        position: fixed;
        right: 0;
        top: 0;
        transition: opacity .25s ease;
        z-index: -1
    }

    .black-filter.active {
        opacity: 1;
        z-index: 2000
    }

    .red-card {
        background-color: #000066;
        font-family: Gilroy;
        height: 100%;
        min-height: 275px;
        overflow: hidden;
        padding: 1.5rem 1.5rem 1rem
    }

    .red-card a {
        color: #fff;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        text-decoration: none
    }

    .red-card a:hover {
        color: #fff
    }

    .red-card p {
        line-height: 1
    }

    .red-card .job-title {
        font-size: 35px;
        font-weight: 800
    }

    .red-card .job-description {
        font-size: 35px;
        font-weight: 300;
        margin-bottom: 1.5rem
    }

    .red-card .flex-wrapper {
        display: flex;
        justify-content: space-between
    }

    .red-card .flex-wrapper .apply {
        font-size: 1rem;
        font-weight: 800
    }

    .red-card .flex-wrapper .arrow {
        align-items: center;
        border-radius: 50%;
        display: flex;
        font-size: 1.5rem;
        height: 35px;
        justify-content: center;
        width: 35px
    }

    .red-card .flex-wrapper .arrow:hover {
        background-color: #fff;
        color: #000066
    }

    .scroll-top {
        align-items: center;
        bottom: 0;
        display: flex;
        justify-content: center;
        position: absolute;
        right: 50px
    }

    @media (max-width:991.98px) {
        .scroll-top {
            right: 25px
        }
    }

    @media (max-width:575.98px) {
        .scroll-top {
            right: 15px
        }
    }

    .scroll-top a {
        line-height: 1;
        text-align: center;
        text-decoration: none
    }

    .scroll-top a p {
        line-height: 18px;
        margin: 0;
        text-align: center
    }

    .scroll-top a .icon-wrapper {
        align-items: center;
        border: 1px solid #000066;
        border-radius: 50%;
        color: #000066;
        display: flex;
        height: 35px;
        justify-content: center;
        margin: 0;
        overflow: hidden;
        position: relative;
        transition: color .15s ease;
        width: 35px
    }

    .scroll-top a .icon-wrapper::before {
        background-color: #000066;
        bottom: 0;
        content: '';
        height: 0;
        left: -20px;
        position: absolute;
        right: -20px;
        transition: height .15s ease;
        z-index: -1
    }

    .scroll-top a:hover p {
        color: #000066
    }

    .scroll-top a:hover .icon-wrapper {
        color: #fff
    }

    .scroll-top a:hover .icon-wrapper::before {
        height: 100%
    }

    .v-line {
        bottom: 0;
        left: 0;
        margin: 0 auto;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 4px;
        width: 21px
    }

    @media (max-width:991.98px) {
        .v-line {
            display: none
        }
    }

    .v-line.project-line .arrow {
        bottom: 20vh;
        padding: 2rem 0 3rem;
        top: auto
    }

    .v-line .arrow {
        left: 0;
        margin: 0 auto;
        min-width: 21px;
        position: fixed;
        right: 0;
        top: 50%
    }

    .arrow-wrapper-white {
        bottom: 20vh;
        left: 155px;
        padding: 2rem 0 3rem;
        position: fixed;
        right: 0;
        text-align: center;
        z-index: -1
    }

    @media (max-width:991.98px) {
        .arrow-wrapper-white {
            display: none
        }
    }

    .arrow-wrapper-white.active {
        display: none
    }

    .arrow-wrapper-white .arrow {
        align-items: center;
        animation-delay: 2.5s;
        animation-duration: 5s;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        position: relative
    }

    .container-fluid.no-margin {
        padding: 0
    }

    .section-title {
        text-align: center;
        padding: 5vh 0vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-title hr {
        height: 1px;
        color: #000066;
        width: 10vw;
        opacity: 1;
    }

    .section-title.vertical {
        font-size: 16px;
        font-weight: 700;
        margin: 0;
        text-orientation: sideways-right;
        transform: rotate(180deg);
        writing-mode: vertical-rl
    }

    @media (max-width:575.98px) {
        .section-title.vertical {
            margin-bottom: 15px;
            text-orientation: initial;
            transform: rotate(0);
            writing-mode: initial
        }
    }

    .section-title.red {
        color: #000066
    }

    .section-title.white {
        color: #fff
    }

    .section-banner {
        padding: 0
    }

    .section-banner.banner-spaced {
        padding: 0 50px
    }

    @media (max-width:991.98px) {
        .section-banner.banner-spaced {
            padding-left: 25px;
            padding-right: 25px
        }
    }

    @media (max-width:575.98px) {
        .section-banner.banner-spaced {
            padding-left: 10px;
            padding-right: 10px
        }
    }

    .section-banner iframe {
        height: 500px;
        max-width: 100%;
        width: 800px
    }

    .section-banner .banner {
        background-position: 50% 100%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
        position: relative;
        z-index: -10
    }

    .section-banner .banner.medium-banner {
        background-position: 50% 50%;
        height: 80vh;
        z-index: 2
    }

    .section-banner .banner.small-banner {
        background-position: 50% 50%;
        height: 60vh;
        z-index: 0
    }

    .section-banner .back {
        position: absolute;
        right: 25px;
        top: 25px;
        z-index: 5
    }

    .section-banner .back a {
        color: #fff
    }

    .section-banner .back a svg {
        height: 20px;
        width: auto
    }

    .section-banner .flex-wrapper {
        align-items: center;
        bottom: 0;
        display: flex;
        flex-direction: column;
        height: 100vh;
        justify-content: center;
        pointer-events: none;
        position: fixed;
        z-index: 1;
        flex-wrap: nowrap;
        align-content: center;
        width: 100vw;
    }

    @media (max-width:575.98px) {
        .section-banner .flex-wrapper {
            left: 0
        }
    }

    .section-banner .flex-wrapper .v-line {
        height: 0;
        margin: 50px auto 0;
        position: relative
    }

    @media (max-width:991.98px) {
        .section-banner .flex-wrapper .v-line {
            display: none
        }
    }

    .section-banner .flex-wrapper .v-line .arrow {
        left: 0;
        margin: 0 auto;
        opacity: 1;
        position: absolute;
        right: 0
    }

    .section-banner .flex-wrapper .v-line.active {
        animation: banner-line .85s ease-in;
        animation-delay: .25s;
        animation-fill-mode: forwards
    }

    .section-banner .flex-wrapper .v-line.active .arrow {
        animation: banner-arrow .75s ease;
        animation-delay: 1.1s;
        animation-fill-mode: forwards
    }

    .section-banner .flex-wrapper #animation-wrapper {
        max-width: 40%;
        position: relative;
        width: 100%
    }

    @media (max-width:575.98px) {
        .section-banner .flex-wrapper #animation-wrapper {
            max-width: 70%
        }
    }

    @media (max-width:991.98px) {
        .section-banner .flex-wrapper #animation-wrapper {
            max-width: 60%
        }
    }

    @media (max-width:1199.98px) {
        .section-banner .flex-wrapper #animation-wrapper {
            max-width: 50%
        }
    }

    .section-top-page {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 34vh;
        justify-content: space-evenly;
        z-index: 200;
        padding-top: 0vh;
        align-content: center;
        flex-wrap: nowrap;
    }

    .section-top-page .nav {
        display: flex;
        flex-wrap: nowrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        flex-direction: row;
        align-content: center;
        justify-content: space-around;
        width: 80vw;
        align-items: center;
    }

    .section-top-page .nav .nav-link {
        margin: 0;
        font-size: 0.8rem;
    }

    @media (max-width:575.98px) {
        .section-top-page {
            align-items: center
        }
    }

    .section-top-page.stickyTop {
        background-color: #fff;
        left: 155px;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 900
    }

    @media (max-width:575.98px) {
        .section-top-page.stickyTop {
            left: 0
        }
    }

    @media (max-width:575.98px) {
        .section-top-page .flex-wrapper {
            display: block
        }
    }

    .section-top-page .flex-wrapper .page-title {
        align-items: center;
        display: flex;
        margin-bottom: 0
    }

    @media (max-width:575.98px) {
        .section-top-page .flex-wrapper .page-title {
            justify-content: center
        }
    }

    .section-top-page .dropdown .dropdown-toggle {
        height: 34px;
        line-height: 34px;
        padding: 0 15px
    }

    .section-middle-line {
        padding-bottom: 5rem;
        padding-top: 5rem;
    }

    @media (max-width:991.98px) {
        .section-middle-line {
            padding-bottom: 1.5rem;
            padding-top: 1.5rem
        }
    }

    .section-middle-line.small-margin-top {
        padding-top: 1.5rem
    }

    .section-middle-line.reversed .text-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-start
    }

    @media (max-width:991.98px) {
        .section-middle-line.reversed .text-wrapper {
            margin: 0;
            width: 100%
        }
    }

    .section-middle-line.reversed .primary-border {
        border: 0;
        height: 100%;
        justify-content: flex-start
    }

    .section-middle-line.reversed .primary-border.spaced {
        padding-top: 0
    }

    .section-middle-line.reversed .citation-wrapper {
        justify-content: flex-start
    }

    .section-middle-line.reversed .button-wrapper {
        text-align: center
    }

    @media (max-width:991.98px) {
        .section-middle-line.reversed .button-wrapper {
            margin: 2.5rem auto 0
        }
    }

    .section-middle-line.about-details {
        margin-bottom: 5rem;
        padding: 10vh 10vw
    }

    @media (max-width:991.98px) {
        .section-middle-line.about-details {
            padding: 0 25px
        }
    }

    .about-details .row {
        padding-top: 5vh;
    }

    .about-details .row .col-6 {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
    }

    .about-details .row .col-6 .citation-wrapper {
        font-size: 2.5rem;
        padding-left: 15vw;
    }

    .about-details .row .col-6 .citation-wrapper .outline-big-text {
        font-size: 2rem;
    }

    .about-details .row .col-6 .text-wrapper {
        padding-left: 10vw;
        padding-right: 10vw;
    }

    .section-middle-line.about-details .citation-wrapper {
        text-align: left
    }

    .section-middle-line.about-details .primary-border {
        padding: 0
    }

    @media (max-width:991.98px) {
        .section-middle-line.about-details .text-wrapper {
            margin-bottom: 50px
        }
    }

    .section-middle-line.about-details .row.marged {
        margin-bottom: 100px
    }

    @media (max-width:991.98px) {
        .section-middle-line.about-details .row.marged {
            margin-bottom: 0
        }
    }

    @media (max-width:991.98px) {
        .section-middle-line .text-wrapper p {
            text-align: justify
        }
    }

    th {
        width: 25%;
    }

    .sold .table thead tr th {
        color: #000066;
        text-transform: uppercase;
        font-weight: 600;
    }

    .sold {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
    }

    .sold-img {
        width: 100%;
    }

    .sold .card-description {
        height: auto;
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
    }

    .sold .card-text {
        display: none;
    }

    .sold .card-inquiry {
        display: none;
    }

    .sold-price {
        color: white;
        background: #000066;
        text-align: center;
    }

    .listings {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: flex-start;
    }

    .listings .card {
        width: 80vw;
        margin: 10vh 0 0 0;
    }

    .exclusive-listings {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
    }

    .exclusive-listings .card {
        width: 30vh;
        margin: 5vh 0 0 0;
    }

    .property-status {
        font-weight: 700;
        color: #000;
        font-size: 1rem;
        text-align: center;
        border-top: none !important;
        height: 50px;
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: stretch;
    }

    .card {
        height: auto;
    }

    .card-title {
        font-size: 1rem;
        color: #000066;
        line-height: 1.5rem;
        font-weight: 400;
        text-transform: uppercase;
    }

    .card-text {
        font-size: 1rem;
    }

    .card-link {
        color: #ffffff;
        font-size: 1rem;
        text-decoration: none;
    }

    .card-link:hover {
        color: #f1f1f1;
        font-size: 1rem;
        text-decoration: none;
        cursor: pointer;
    }

    .card-inquiry {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: center;
        background: #000066;
        border-bottom-left-radius: calc(0.25rem - 1px);
        border-bottom-right-radius: calc(0.25rem - 1px);
        height: auto;
        min-height: 65px;
        padding: 1vh;
    }

    .card-description {
        height: 185px;
    }

    .card-img {
        height: auto;
        object-fit: cover;
    }

    .section-platforms {
        padding-bottom: 5rem;
        padding-top: 5rem
    }

    .section-platforms .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-platforms .row .col-6 {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-right: 5vw !important;
    }

    @media (max-width:1199.98px) {
        .section-platforms {
            max-height: initial;
            min-height: initial;
            overflow: initial
        }
    }

    @media (max-width:991.98px) {
        .section-platforms {
            padding-bottom: 2.5rem;
            padding-top: 2.5rem
        }
    }

    @media (max-width:767.98px) {
        .section-platforms table {
            width: 100%
        }
    }

    @media (max-width:767.98px) {
        .section-platforms table tr {
            display: flex;
            flex-direction: column;
            margin-bottom: 25px
        }
    }

    @media (max-width:767.98px) {
        .section-platforms table tr td {
            text-align: left
        }
    }

    .section-platforms .citation-align {
        border-left: 1px solid #000066
    }

    @media (max-width:1399.98px) {
        .section-platforms .citation-align {
            border-left: 0
        }
    }

    .section-platforms .platform-title {
        align-items: center;
        border-bottom: 1px solid #000;
        display: flex;
        font-size: 21px;
        font-weight: 700;
        height: 140px;
        line-height: 30px;
        width: auto
    }

    @media (max-width:767.98px) {
        .section-platforms .platform-title {
            border-bottom: 0;
            height: auto
        }
    }

    .section-platforms .platform-title p {
        margin-bottom: 0;
        padding-right: 10px;
        width: auto
    }

    @media (max-width:767.98px) {
        .section-platforms .platform-title p {
            padding-right: 0;
            width: 100%
        }
    }

    .section-platforms .platform-paragraph {
        padding-left: 5vw
    }

    @media (max-width:767.98px) {
        .section-platforms .platform-paragraph {
            padding-left: 0
        }
    }

    .section-platforms .platform-paragraph p {
        margin-bottom: 0;
        width: 250px
    }

    @media (max-width:767.98px) {
        .section-platforms .platform-paragraph p {
            width: auto
        }
    }

    //CTA INVESTMENT HOME PAGE SECTION
    .section-investment {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-bottom: 5rem;
        padding-top: 5rem;
        height: 75vh;
    }

    .section-investment .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-investment .row .col-6 {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-right: 5vw !important;
    }

    .section-investment .row .col-6 .inner {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        align-items: flex-end;
        justify-content: center;
    }

    @media (max-width:1199.98px) {
        .section-investment {
            max-height: initial;
            min-height: initial;
            overflow: initial
        }
    }

    @media (max-width:991.98px) {
        .section-investment {
            padding-bottom: 2.5rem;
            padding-top: 2.5rem
        }
    }

    @media (max-width:767.98px) {
        .section-investment table {
            width: 100%
        }
    }

    @media (max-width:767.98px) {
        .section-investment table tr {
            display: flex;
            flex-direction: column;
            margin-bottom: 25px
        }
    }

    @media (max-width:767.98px) {
        .section-investment table tr td {
            text-align: left
        }
    }

    .section-investment .citation-align {
        border-left: 1px solid #000066
    }

    @media (max-width:1399.98px) {
        .section-investment .citation-align {
            border-left: 0
        }
    }

    .section-investment .platform-title {
        align-items: center;
        border-bottom: 1px solid #000;
        display: flex;
        font-size: 21px;
        font-weight: 700;
        height: 140px;
        line-height: 30px;
        width: auto
    }

    @media (max-width:767.98px) {
        .section-investment .platform-title {
            border-bottom: 0;
            height: auto
        }
    }

    .section-investment .platform-title p {
        margin-bottom: 0;
        padding-right: 10px;
        width: auto
    }

    @media (max-width:767.98px) {
        .section-investment .platform-title p {
            padding-right: 0;
            width: 100%
        }
    }

    .section-investment .platform-paragraph {
        padding-left: 5vw
    }

    @media (max-width:767.98px) {
        .section-investment .platform-paragraph {
            padding-left: 0
        }
    }

    .section-investment .platform-paragraph p {
        margin-bottom: 0;
        width: 250px
    }

    @media (max-width:767.98px) {
        .section-investment .platform-paragraph p {
            width: auto
        }
    }

    //END
    .section-title {
        text-align: center;
        padding: 15vh 0vw;
    }

    .section-title h2 {
        color: #000066;
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: 300;
    }

    .section-cta {
        text-align: center;
        padding: 10vh 10vw 5vh 10vw;
    }

    .section-cta button {
        background: #000066;
        border: 1px solid #000066;
        height: 5vh;
        width: 25vw;
        border-radius: 2px;
        margin: 1vw;
        margin-left: 0;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.05vw;
        transition: 500ms !important;
    }

    .section-cta button:hover {
        background: #000066;
        border: 1px solid #000066;
        height: 8vh;
        width: 16vw;
        border-radius: 2px;
        margin: 1vw;
        margin-left: 0;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.05vw;
        transition: 500ms !important;
    }

    .section-full-primary {
        background-color: #fff;
        padding-bottom: 10vh;
        padding-top: 20vh;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-full-primary.no-carousel img {
        height: auto;
        width: 100%
    }

    .section-full-primary.no-carousel .job-image {
        background-position: 50% 50%;
        background-size: cover;
        height: 275px;
        width: 100%
    }

    .section-full-primary.jobs-carousel .red-card {
        height: 275px
    }

    .section-full-primary .row h2 {
        font-size: 1rem;
        color: #000066;
        text-transform: uppercase;
        font-weight: 400;
    }

    @media (max-width:1399.98px) {
        .section-full-primary .row {
            margin-left: -5px;
            margin-right: -5px
        }
    }

    .section-full-primary .row [class*=col] {
        margin-bottom: 25px
    }

    @media (max-width:1399.98px) {
        .section-full-primary .row [class*=col] {
            padding-left: 5px;
            padding-right: 5px
        }
    }

    .section-full-primary .subtitle-wrapper {
        padding-bottom: .5rem
    }

    .section-full-primary .subtitle-wrapper h2 {
        margin-bottom: 0
    }

    @media (max-width:575.98px) {
        .section-full-primary .subtitle-wrapper h2 {
            margin-bottom: .5rem
        }
    }

    .section-full-primary .job-title h3 {
        font-size: 1.75rem;
        font-weight: 800;
        margin-bottom: .25rem
    }

    .section-full-primary .job-description p {
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.25
    }

    .section-team-primary {
        background-color: #fff;
        padding-bottom: 10vh;
        padding-top: 20vh;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .team-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: flex-start;
        padding: 5vh 0vw;
    }

    .team-list-line {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        padding: 5vh 0vw 5vh 0vw;
    }

    .team-list-line .card {
        width: 90vw;
        margin: 0;
        height: 100px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: stretch;
        border: none;
    }

    .team-list-line .card .team-name {
        width: 150px;
        text-align: left;
    }

    .team-list-line .card .card-title {
        width: 400px;
        text-align: center;
    }

    .team-list-line .card .card-text {
        width: 150px;
        text-align: left;
    }

    .team-list-line .card .card-description {
        text-align: center;
        height: auto;
        background: #fff;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: baseline;
        padding-top: 1vh;
    }

    .team-list-line .card .card-description a {
        color: #000;
    }

    .team-list-line .card .card-description a:hover {
        color: #000066;
        cursor: pointer;
    }

    .team-list .card {
        width: 60%;
        margin: 0vh 1vw 9vh 1vw;
        height: 550px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: stretch;
        border: none;
    }

    .team-img {
        height: 350px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    .team-img img {
        flex-shrink: 1;
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
    }

    .team-list .card .card-description {
        text-align: center;
        height: auto;
        background: #fff;
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding: 2vh 2vw;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
    }

    .team-list .card .card-inquiry {
        height: 6vh;
    }

    .team-name {
        font-weight: 700;
        color: #000;
        font-size: 1.25rem;
        text-align: center;
        border-top: none !important;
    }

    .team-filter {
        width: 100vw;
        padding-top: 10vh;
        display: flex;
        position: relative;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;

    }

    .team-filter .inner {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: baseline;
        width: 50vw;
    }

    .team-filter .inner p {
        color: #000066;
        font: 1rem;
    }

    .section-team-primary.no-carousel img {
        height: auto;
        width: 100%
    }

    .section-team-primary.no-carousel .job-image {
        background-position: 50% 50%;
        background-size: cover;
        height: 275px;
        width: 100%
    }

    .section-team-primary.jobs-carousel .red-card {
        height: 275px
    }

    @media (max-width:1399.98px) {
        .section-team-primary .row {
            margin-left: -5px;
            margin-right: -5px
        }
    }

    .section-team-primary .row [class*=col] {
        margin-bottom: 25px
    }

    @media (max-width:1399.98px) {
        .section-team-primary .row [class*=col] {
            padding-left: 5px;
            padding-right: 5px
        }
    }

    .section-team-primary .subtitle-wrapper {
        padding-bottom: .5rem
    }

    .section-team-primary .subtitle-wrapper h2 {
        margin-bottom: 0
    }

    @media (max-width:575.98px) {
        .section-team-primary .subtitle-wrapper h2 {
            margin-bottom: .5rem
        }
    }

    .section-team-primary .job-title h3 {
        font-size: 1.75rem;
        font-weight: 800;
        margin-bottom: .25rem
    }

    .section-team-primary .job-description p {
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.25
    }

    .section-search-simple .row {
        height: 100%;
    }

    .section-search-simple .row .col-6 {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-search-simple .row .col-6 .citation-align {
        align-items: flex-end;
        padding: 0;

    }

    .section-search-simple .row .col-6 .citation-align .citation-wrapper {
        align-items: flex-end;

        padding-right: 10vw;
        padding-bottom: 0;
    }

    .search-text {
        color: #fff;
        font-size: 2rem;
        text-align: right;
        line-height: 1.5;

    }


    .sign-up-btn-light {
        background: #fff;
        color: #000066;
        border: none;
        border-radius: 1px;
        width: 21vw;
        font-size: 1rem;
        line-height: 2;
        margin-top: 5vh;
        text-transform: uppercase;
        font-weight: 300;
    }

    .sign-up-btn-light:hover {
        background: #000066;
        color: #fff;
        border: none;
        border-radius: 1px;
        width: 21vw;
        font-size: 1rem;
        line-height: 2;
        margin-top: 5vh;
        text-transform: uppercase;
        font-weight: 300;
    }

    @media (max-width:991.98px) {
        .section-search-simple {
            padding-bottom: 2.5rem;
            padding-top: 2.5rem
        }
    }

    @media (max-width:767.98px) {
        .section-search-simple img {
            margin-bottom: 2rem
        }
    }

    .section-image-double {
        padding-bottom: 50px;
        padding-top: 5rem
    }

    @media (max-width:991.98px) {
        .section-image-double {
            padding-bottom: 2.5rem;
            padding-top: 20px
        }
    }

    @media (max-width:991.98px) {
        .section-image-double img {
            margin-bottom: 20px
        }
    }

    .section-image-double .flex-wrapper {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center
    }

    @media (max-width:1199.98px) {
        .section-image-double .flex-wrapper {
            align-items: flex-start
        }
    }

    .section-image-double .flex-wrapper .citation {
        border-color: #000066;
        border-style: solid;
        border-width: 1px 0;
        color: #000;
        padding: 1.5rem 0 1.5rem;
        width: 400px
    }

    @media (max-width:767.98px) {
        .section-image-double .flex-wrapper .citation {
            margin-top: 25px
        }
    }

    @media (max-width:1199.98px) {
        .section-image-double .flex-wrapper .citation {
            padding: 10px 0;
            width: 100%
        }
    }

    .section-image-double .flex-wrapper .citation p {
        font-family: Gilroy;
        font-size: 26px;
        font-weight: 800;
        line-height: 32px;
        margin-bottom: 2rem
    }

    @media (max-width:1599.98px) {
        .section-image-double .flex-wrapper .citation p {
            font-size: 20px;
            line-height: 24px
        }
    }

    @media (max-width:1199.98px) {
        .section-image-double .flex-wrapper .citation p {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 1rem
        }
    }

    @media (max-width:991.98px) {
        .section-image-double .flex-wrapper .citation p {
            font-size: 18px;
            line-height: 22px
        }
    }

    .section-image-double .flex-wrapper .citation span {
        display: block;
        line-height: 1.35
    }

    .section-image-double .flex-wrapper .citation span.author {
        font-weight: 600
    }

    @media (max-width:991.98px) {

        .section-image-double.section-image-4 [class*=col]:nth-child(1) img,
        .section-image-double.section-image-4 [class*=col]:nth-child(2) img {
            margin-bottom: 25px
        }
    }

    @media (max-width:991.98px) {

        .section-image-double.section-image-4 [class*=col]:nth-child(1) img,
        .section-image-double.section-image-4 [class*=col]:nth-child(2) img,
        .section-image-double.section-image-4 [class*=col]:nth-child(3) img {
            margin-bottom: 25px
        }
    }

    .section-gallery .gallery-wrapper {
        padding-bottom: 25px
    }

    .section-gallery .gallery-wrapper .row {
        margin-left: -10px;
        margin-right: -10px
    }

    .section-gallery .gallery-wrapper .row [class*=col] {
        margin: 0 0 10px;
        padding-left: 10px;
        padding-right: 10px;
        height: 300px;
    }

    .section-gallery .gallery-image-wrapper {
        overflow: hidden;
        position: relative;
        height: 250px;
    }

    .section-gallery .gallery-image-wrapper:hover .image-effect {
        left: 0
    }

    .section-gallery .gallery-image-wrapper:hover .image-effect [class*=image] {
        opacity: 1
    }

    .section-gallery .gallery-dots {
        margin: 2.5rem auto;
        text-align: center
    }

    .section-gallery .gallery-dots .btn {
        align-items: center;
        cursor: default;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        opacity: 0;
        padding: 5px
    }

    .section-gallery .image-effect {
        background-color: #000066;
        bottom: 0;
        cursor: pointer;
        font-family: Gilroy;
        left: -100%;
        position: absolute;
        top: 0;
        transition: left .25s ease;
        width: 100%
    }

    .section-gallery .image-effect p {
        font-size: 1rem;
        line-height: 1.25;
        margin: 0
    }

    .section-gallery .image-effect [class*=image] {
        color: #fff;
        opacity: 0;
        transition: opacity .25s ease;
        transition-delay: .15s
    }

    .section-gallery .image-effect .image-text {
        font-size: 1.25rem;
        left: 15px;
        position: absolute;
        right: 15px;
        top: 15px
    }

    .section-gallery .image-effect .image-text h3 {
        font-weight: 800;
        margin-bottom: .25rem
    }

    .section-gallery .image-effect .image-text .image-description {
        font-weight: 300;
        max-width: 60%
    }

    .section-gallery .image-effect .image-infos {
        bottom: 15px;
        font-family: Helvetica, Arial;
        font-size: 1.25rem;
        position: absolute;
        right: 15px;
        text-orientation: sideways-right;
        transform: rotate(180deg);
        writing-mode: vertical-rl
    }

    .section-gallery .image-effect .image-infos .image-locality {
        font-size: 14px;
        font-weight: 300;
        margin-left: .25rem
    }

    .section-gallery .image-effect .image-infos .image-date {
        font-size: 14px;
        font-weight: 800
    }

    .section-gallery .image-effect .image-arrow {
        bottom: 15px;
        font-size: 1.25rem;
        left: 15px;
        position: absolute
    }

    .section-gallery .image-effect .image-arrow:hover .icon-wrapper {
        background-color: #fff;
        color: #000066
    }

    .section-gallery .image-effect .image-arrow .icon-wrapper {
        align-items: center;
        border-radius: 50%;
        display: flex;
        height: 35px;
        justify-content: center;
        width: 35px
    }

    .section-carousel {
        background-color: #fff;
        padding-bottom: 0;
        padding-top: 5rem
    }

    @media (max-width:991.98px) {
        .section-carousel {
            padding-bottom: 20px;
            padding-top: 0
        }
    }

    .section-carousel .owl-carousel .owl-stage {
        display: -webkit-box !important;
        display: -moz-box !important;
        display: -ms-box !important;
        display: box !important
    }

    .section-carousel .owl-carousel .owl-item img {
        display: block;
        height: 400px;
        margin: 0 auto;
        max-height: 400px;
        width: auto
    }

    .section-share {
        background-color: #fff;
        padding-bottom: 0;
        padding: 10vh;
    }

    .section-share .flex-wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center
    }

    .section-share .flex-wrapper .text-wrapper {
        letter-spacing: 1px;
        margin-bottom: .75rem
    }

    .section-share .flex-wrapper .icons-wrapper a {
        color: #343a40;
        margin: 0 .5rem;
        text-decoration: none
    }

    .section-share .flex-wrapper .icons-wrapper a:hover {
        color: #000066
    }


    .section-about .intro {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        padding: 20vh 10vw;
    }

    .section-about .intro .inner_L {
        width: 55%;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-about .intro .inner_L .txt {
        white-space: nowrap;
        text-align: center;
        border: none;
    }

    .section-about .intro .inner_L .txt h2 {
        padding: 0;
        text-transform: uppercase;
    }

    .section-about .intro .inner_R {
        width: 45%;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
    }

    .section-about .intro .inner_R .txt {
        padding: 0;
        text-align: center;
    }

    .section-about .row .col-6 {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    .section-about .row .col-6 .img-wrapper {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: center;
    }

    .about {
        height: auto;
        width: 100vw;
        padding: 20vh 10vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
    }

    .section-team .intro {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        padding: 20vh 10vw;
    }

    .section-team .intro .inner_L {
        width: 55%;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-team .intro .inner_L .txt {
        white-space: nowrap;
        text-align: center;
        border: none;
    }

    .section-team .intro .inner_L .txt h2 {
        padding: 0;
        text-transform: uppercase;
    }

    .section-team .intro .inner_R {
        width: 45%;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
    }

    .section-team .intro .inner_R .txt {
        padding: 0;
        text-align: justify;
    }

    .section-team .intro .inner_R .txt p {
        text-align: justify;
        font-size: 1rem;
    }

    .section-team .row .col-6 {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    .section-team .row .col-6 .img-wrapper {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: center;
    }

    .section-mission {
        height: auto;
        width: 100vw;
        padding: 10vh 10vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        background: linear-gradient(rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), url(../images/services-overlay_bg.jpg) no-repeat center center;
        background-size: cover;
    }

    .section-mission .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .mission-row {
        text-align: center;
        display: flex;
        width: 80vw;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        padding: 5vh 0vw;
    }

    .mission-row .col-3 {
        padding: 2vh 5vw 2vh 5vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        height: auto;
        width: 100%;
    }

    .mission-row .col-3 p {
        text-align: justify;
        text-align-last: left;
        padding-top: 2vh;
    }

    .section-mission h2 {
        font-size: 4rem;
        color: #000066;
        text-transform: uppercase;
        font-weight: 300;
    }

    .section-mission .row .col-12 {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0;
    }

    .section-mission .col-3 hr {
        height: 5px;
        width: 25%;
        color: #000066;
        opacity: 1 !important;
    }

    .section-mission .dropcap {
        color: #57a6dc;
        font-weight: 600;
        font-size: 5rem;
        line-height: 0.5
    }

    .mission-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        width: 80vw;
    }

    .mission-list {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-end;
        width: 60vw;
        padding-bottom: 10vh;
    }

    .mission-list hr {
        color: #000066;
        opacity: 1;
        height: 1px;
        width: 80%;
        text-align: right;
        margin: 2vh 0vw;
    }

    .mission-list .row {
        width: 90vw;
        height: 25vh;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
        align-content: flex-start;
    }

    .mission-list .row .col-3 {
        display: inline-flex;
        text-align: left;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    .mission-list .row h4 {
        transform: rotate(-90deg);
        font-size: 2rem;
        font-weight: 600;
        color: #000;
        display: inline-flex;
        flex-direction: column;
        text-align: center;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .mission-list .row h4 span {
        font-size: 1.5rem;
        font-weight: 600;
        color: #9a9a9a;
    }

    .mission-list .row .col-6 p {
        font-weight: 600;
        color: #1d2054;
        font-size: 1.75rem;
        line-height: 1.75;
    }

    .mission-header h3 {
        font-size: 4rem;
        font-weight: 600;
        color: #000;
        text-transform: uppercase;
    }

    .stats-banner {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        height: 125vh;
        background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(../images/gfi_stats.jpg) no-repeat center center;
        background-size: cover;
        background-attachment: fixed;
        z-index: auto !important;
    }

    .stats-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-top: 5vh;
    }

    .stats-header h3 {
        font-size: 3rem;
        font-weight: 400;
        color: #fff;
        text-transform: uppercase;
        line-height: 1;
        white-space: nowrap;
        transform: scaleY(1.1) !important;
    }

    .inner-stats {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-bottom: 5vh;
    }

    .stats-banner hr {
        width: 10vw;
        color: #fff;
        height: 1px;
        opacity: 1;
    }

    .stats-col-R {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
        width: 30vw;
        padding-left: 2vw;
        text-align: center;
    }

    .stats-col-R .col-4 {
        height: 20vh;
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: flex-start;
    }

    .stats-banner h2 {
        font-size: 2rem;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 900;
    }

    .stats-col-R h3 {
        color: #fff;
    }

    .stats-col-R h4 {
        font-size: 2rem;
        text-transform: uppercase;
        color: #fff;
        font-weight: 600;
        text-align: left;
        display: flex;
        flex-direction: column;
    }

    .stats-col-R h4 span {
        font-weight: 400;
        font-size: 1.5rem;
        white-space: nowrap;
    }

    .stats-col-L {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
        width: 30vw;
        text-align: center;
    }

    .stats-col-L .col-4 {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: flex-end;
        height: 20vh;
    }

    .stats-col-L h3 {
        color: #fff;
    }

    .stats-col-L h4 {
        font-size: 3rem;
        text-transform: uppercase;
        color: #fff;
        font-weight: 600;
        text-align: left;
        display: flex;
        flex-direction: column;
    }

    .stats-col-L h4 span {
        font-weight: 400;
        font-size: 2rem;
    }

    .email-banner {
        width: 100vw;
        height: auto;
        background: url(../images/cta.jpg) no-repeat center center;
        background-size: cover;
        background-attachment: fixed;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding: 10vh 0vw;
    }

    .email-banner .inner {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;

    }

    .email-banner .inner p {
        color: #fff;
        font-weight: 600;
        font-size: 2rem;
        text-transform: uppercase;
        transform: scaleY(1.1) !important;
        text-align: center;
        padding-bottom: 5vh;
    }

    .services {
        height: auto;
        width: 100vw;
        padding: 20vh 10vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        background: #fff;
        padding: 10vh 10vw;


    }

    .services-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-content: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        text-align: left;
    }

    .services-title h3 {
        font-size: 3rem;
        font-weight: 600;
        text-transform: uppercase;
        color: #ffffff;
        display: flex;
        flex-direction: column;

    }

    .services-title span {
        color: #f1f1f1;
        font-weight: 400;
        font-size: 2rem;
        white-space: nowrap;

    }

    .mission-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-content: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        text-align: center;
    }

    .mission-title h3 {
        font-size: 2rem;
        font-weight: 600;
        text-transform: uppercase;
        color: #000066;
        display: flex;
        flex-direction: column;

    }

    .mission-title span {
        color: #939393;
        font-weight: 400;
        font-size: 1.5rem;
        white-space: nowrap;

    }

    .services .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        width: 100vw;
        padding: 2vh 5vw 2vh 5vw;
        text-align: center;

    }

    .services .row .col-12 {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0;
    }

    .services h2 {
        font-size: 4rem;
        color: #000066;
        text-transform: uppercase;
        font-weight: 300;

    }

    .services-row {
        text-align: center;
        display: flex;
        width: 90vw;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        padding: 5vh 0vw 10vh 0vw;
    }

    .services-row .col-4 {
        margin: 2vh 2.5vw 2vh 2.5vw;
        padding: 5vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: flex-start;
        height: 315px;
        background: #000066;
        background-size: cover;
        width: 95%;
    }

    .services-row .col-4 span {
        width: 225px;
        text-align: left;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: baseline;
        transition: 500ms ease;
        transform: translateX(0vw);
    }

    .services-row .col-4 span:hover {
        transition: 500ms ease;
        transform: translateX(1vw)
    }

    .services-row .col-4 span i {
        position: relative;
        padding: 0vh 50% 0vh 0;
        color: #ffff;
    }

    .services-row h3 {
        font-size: 2rem;
        text-transform: uppercase;
        text-align: left;
        line-height: 1;
        color: #fff;
    }

    .services-row .col-4 p {
        text-align: justify;
        text-align-last: left;
        padding-top: 2vh;
        color: #fff;
        hyphens: auto;
        -webkit-hyphens: auto;
    }

    .services-btn {
        border: none;
        border-radius: 2vw;
        width: auto;
        background: transparent;
        color: #fff;
        font-size: 1rem;
        transition: 500ms ease;
        padding: 0;
        text-align: left;
        white-space: nowrap;
    }

    .services-btn:hover {
        width: auto;
        background: transparent;
        color: #f1f1f1;
        font-size: 1rem;
        padding: 0;
        transition: 500ms ease;
        cursor: pointer;
        white-space: nowrap;
    }

    .services-row hr {
        color: #fff;
        height: 1px;
        width: 5vw;
    }

    @media (max-width:991.98px) {
        .section-about {
            padding-bottom: 2.5rem
        }
    }

    .section-about h2 {
        align-items: flex-end;
        color: #000;
        display: flex;
        font-size: 1.5rem;
        font-weight: 400;
        height: 60px;
        line-height: 1;
        margin-bottom: 0;
        padding-bottom: 10px
    }

    .section-about [class*=col] {
        margin-bottom: 75px
    }

    @media (max-width:991.98px) {
        .section-about [class*=col] {
            margin-bottom: 25px
        }
    }

    .section-about [class*=col].related {
        margin-bottom: 0
    }

    @media (max-width:991.98px) {
        .section-about [class*=col].related {
            margin-bottom: 50px
        }
    }

    .section-about [class*=col]:nth-child(1) {
        order: 1
    }

    @media (max-width:991.98px) {
        .section-about [class*=col]:nth-child(1) {
            order: 1
        }
    }

    .section-about [class*=col]:nth-child(2) {
        order: 2
    }

    @media (max-width:991.98px) {
        .section-about [class*=col]:nth-child(2) {
            order: 3
        }
    }

    .section-about [class*=col]:nth-child(3) {
        order: 3
    }

    @media (max-width:991.98px) {
        .section-about [class*=col]:nth-child(3) {
            order: 2
        }
    }

    .section-about [class*=col]:nth-child(4) {
        order: 4
    }

    @media (max-width:991.98px) {
        .section-about [class*=col]:nth-child(4) {
            margin-bottom: 0;
            order: 4
        }
    }

    .section-about .v-line {
        bottom: 5rem;
        top: 60px
    }

    .section-about .img-wrapper {
        margin-bottom: 50px
    }

    @media (max-width:991.98px) {
        .section-about .img-wrapper {
            margin-bottom: 15px
        }
    }

    .section-about .nav-pills {
        margin-bottom: 4rem
    }

    @media (max-width:991.98px) {
        .section-about .nav-pills {
            margin-bottom: 1.5rem
        }
    }

    .section-about .nav-pills .nav-link {
        border-width: 2px 0 1px
    }

    .section-about .tab-content {
        height: auto
    }

    .paragraph-align {
        text-align: justify;
        hyphens: auto;
        -webkit-hyphens: auto;
    }

    .section-about .outline-big-text {
        font-size: 35px
    }

    .section-about .owl-nav {
        bottom: 0;
        color: red;
        left: 0;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0
    }

    .section-about .owl-nav .owl-prev {
        left: -25px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%)
    }

    .section-about .owl-nav .owl-next {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%)
    }

    .section-partners {
        margin-bottom: 2rem
    }

    .section-partners .partners-image {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 30%;
        height: 50vh;
        min-height: 300px;
        margin-right: 1rem
    }

    @media (max-width:991.98px) {
        .section-partners .partners-image {
            height: 40vh;
            min-height: auto;
            margin-right: 0
        }
    }

    .section-partners .partners {
        display: flex;
        flex-direction: column;
        height: 50vh;
        min-height: 300px;
        justify-content: flex-start;
        align-items: stretch;
        margin-left: 1rem;
        overflow: auto;
        max-width: 600px
    }

    @media (max-width:991.98px) {
        .section-partners .partners {
            height: auto;
            min-height: auto;
            margin-left: 0;
            margin-top: 1rem
        }
    }

    .section-partners .partners .partner {
        margin-top: 1.5rem
    }

    @media (min-width:1600px) {
        .section-partners .partners .partner {
            margin-top: 2.5rem
        }
    }

    @media (min-width:1921px) {
        .section-partners .partners .partner {
            margin-top: 3.5rem
        }
    }

    .section-partners .partners .partner:first-child {
        margin-top: 0
    }

    .section-partners .partners .partner .name {
        line-height: 26px;
        font-size: 14px;
        font-weight: 700;
        margin: 0
    }

    .section-partners .partners .partner .function {
        color: #000066
    }

    .section-partners .partners .partner .function::after {
        color: #000;
        content: " | ";
        display: inline;
        margin: 0 .25rem;
        vertical-align: middle
    }

    .section-partners .partners .partner .email {
        color: #000
    }

    .section-partners .partners .partner .description {
        margin-top: .5rem;
        line-height: 26px
    }

    .section-teams .outline-big-text {
        margin-bottom: 1.5rem
    }

    .section-teams .team-carousel {
        position: relative
    }

    .section-teams .team-carousel .member {
        padding: 20px
    }

    .section-teams .team-carousel .member img {
        border-color: rgba(255, 255, 255, 0);
        border-style: solid;
        border-width: 1px 0
    }

    .section-teams .team-carousel .member .member-infos {
        align-items: center;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        opacity: 0;
        padding-top: 1rem
    }

    .section-teams .team-carousel .member .member-infos .function,
    .section-teams .team-carousel .member .member-infos .name,
    .section-teams .team-carousel .member .member-infos .role {
        line-height: 26px
    }

    .section-teams .team-carousel .member .member-infos .name {
        color: #000066;
        font-weight: 700
    }

    .section-teams .team-carousel .member .member-infos .role {
        font-weight: 400
    }

    .section-teams .team-carousel .member .member-infos .function {
        color: #000066
    }

    .section-teams .team-carousel .member .member-infos .email a {
        color: #000
    }

    .section-teams .team-carousel .member:hover img {
        border-color: #000066
    }

    .section-teams .team-carousel .member:hover .member-infos {
        opacity: 1
    }

    .section-teams .team-carousel .owl-nav {
        color: #000066
    }

    .section-teams .team-carousel .owl-nav svg {
        height: auto;
        width: 25px
    }

    .section-teams .team-carousel .owl-nav .owl-next,
    .section-teams .team-carousel .owl-nav .owl-prev {
        align-items: center;
        background-color: #fff;
        border-radius: 50%;
        display: flex;
        height: 40px;
        justify-content: center;
        position: absolute;
        top: calc(50% - 75px);
        width: 40px
    }

    .section-teams .team-carousel .owl-nav .owl-next:active,
    .section-teams .team-carousel .owl-nav .owl-next:focus,
    .section-teams .team-carousel .owl-nav .owl-prev:active,
    .section-teams .team-carousel .owl-nav .owl-prev:focus {
        outline: 0
    }

    .section-teams .team-carousel .owl-nav .owl-next:hover,
    .section-teams .team-carousel .owl-nav .owl-prev:hover {
        background-color: #000066;
        color: #fff
    }

    .section-teams .team-carousel .owl-nav .owl-prev {
        left: 50px;
        transform: translateX(100%) translateY(-50%)
    }

    @media (max-width:991.98px) {
        .section-teams .team-carousel .owl-nav .owl-prev {
            left: 0
        }
    }

    .section-teams .team-carousel .owl-nav .owl-next {
        right: 50px;
        transform: translateX(-100%) translateY(-50%)
    }

    @media (max-width:991.98px) {
        .section-teams .team-carousel .owl-nav .owl-next {
            right: 0
        }
    }

    .section-worked-with-us {
        padding-bottom: 5rem;
        padding-top: 5rem
    }

    @media (max-width:991.98px) {
        .section-worked-with-us {
            padding-bottom: 2.5rem;
            padding-top: 2.5rem
        }
    }

    .section-worked-with-us p {
        line-height: 2;
        margin-bottom: 0;
        padding-right: 0;
        text-align: justify
    }

    @media (max-width:575.98px) {
        .section-worked-with-us p {
            padding-right: 0
        }
    }

    @media (max-width:767.98px) {
        .section-worked-with-us p {
            padding-right: 0
        }
    }

    .section-worked-with-us p span {
        color: #000066;
        padding: 0 .25rem
    }

    .section-contact {
        padding-bottom: 5rem;
        padding-top: 5rem
    }

    @media (max-width:991.98px) {
        .section-contact {
            padding-bottom: 2.5rem;
            padding-top: 0
        }
    }

    .section-contact a {
        color: #343a40
    }

    .section-contact a:hover {
        color: #000066
    }

    .section-contact iframe {
        height: 400px;
        width: 100%
    }

    .section-contact .flex-wrapper {
        display: flex
    }

    @media (max-width:991.98px) {
        .section-contact .flex-wrapper {
            justify-content: flex-start
        }
    }

    .section-contact .fa-ul {
        margin-left: 3rem
    }

    .section-contact .fa-ul li {
        margin-bottom: 2rem
    }

    .section-contact .fa-ul li .fa-li {
        color: #ff6a6a;
        font-size: 1.5rem;
        left: -2.5em;
        margin-top: .5rem
    }

    .section-contact .fa-ul p {
        line-height: 1.75;
        padding-left: 1rem;
        padding-top: .5rem
    }

    .section-contact .fa-ul p svg {
        margin-left: .5rem
    }

    .section-contact .follow-us {
        font-size: 1rem
    }

    .section-contact .follow-us span {
        display: block;
        margin-bottom: .25rem
    }

    .section-contact .follow-us a {
        margin-right: .5rem
    }

    .section-news {
        padding-bottom: 10vh;
        padding-top: 20vh;
    }

    @media (max-width:991.98px) {
        .section-news {
            padding-bottom: 2.5rem
        }
    }

    .section-news .news-wrapper a {
        text-decoration: none
    }

    .section-news .news-wrapper .row {
        margin-left: 0;
        margin-right: 0;
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        align-content: center;
        justify-content: center;
        width: 100%;
    }

    .section-news .news-wrapper .row > [class*=col] {
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 5vh;
        padding-top: 5vh;
        width: 100%;

    }

    .section-news .news-wrapper .news-item::before {
        display: none
    }

    .section-news .news-wrapper .news-item::after {
        display: none
    }

    .section-news .news-wrapper .news-item .img-effect a {
        display: block
    }

    .section-news .news-wrapper .news-title {
        color: #000;
        display: block;
        margin-top: 1rem
    }

    .section-news .news-wrapper .news-title h3 {
        color: #343a40;
        font-size: 16px;
        font-weight: 800;
        letter-spacing: .5px;
        margin-bottom: 3px
    }

    .section-news .news-wrapper .news-infos {
        color: #343a40;
        font-size: 13px
    }

    .section-news .news-wrapper .news-category {
        color: #343a40;
        font-weight: 600;
        padding-right: .25rem
    }

    .section-news .news-wrapper .news-date {
        padding-left: .25rem
    }

    .section-news .news-pagination {
        margin: 2.5rem auto;
        text-align: center
    }

    .section-news .news-pagination ul {
        justify-content: center
    }

    .section-news .news-pagination ul li a {
        align-items: center;
        background: rgba(255, 255, 255, 0);
        border: 1px solid #000066;
        border-radius: 50%;
        display: flex;
        height: 30px;
        justify-content: center;
        margin: 0 3px;
        padding-top: 4px;
        width: 30px
    }

    .section-news .news-pagination ul li a:hover {
        background-color: #000066;
        color: #fff
    }

    .section-news .news-pagination ul li.active a {
        background-color: #000066;
        color: #fff
    }

    .section-news .news-content p {
        line-height: 1.75
    }

    .section-news .news-content p:last-child {
        margin-bottom: 0
    }

    .section-news .news-author {
        border-color: #000066;
        border-style: solid;
        border-width: 1px 0;
        margin-top: 3rem;
        padding: 1rem 0
    }

    .section-news .news-author p {
        margin: 0
    }

    .section-news .news-author .name {
        font-weight: 600
    }

    .section-news .news-share {
        color: #343a40;
        text-align: center
    }

    .section-news .news-share p {
        font-size: 1rem;
        letter-spacing: 1px;
        margin-bottom: .25rem
    }

    .section-news .news-share .icons-wrapper a {
        color: #343a40;
        margin: 0 .5rem
    }

    .section-news .news-share .icons-wrapper a:hover {
        color: #000066
    }

    .section-introduction {
        padding-bottom: 5rem;
        padding-top: 5rem
    }

    @media (max-width:991.98px) {
        .section-introduction {
            padding-bottom: 1.5rem;
            padding-top: 1.5rem
        }
    }

    .section-bio {
        padding-top: 25vh;
        height: 100vh;
    }

    .section-bio .row {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .section-bio .row .col-4 {
        display: inline-flex;
        width: 100%;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    .bio-content {
        width: 90vw;
    }

    @media (max-width:991.98px) {
        .section-bio {
            padding-bottom: 1.5rem;
            padding-top: 1.5rem
        }
    }

    @media (max-width:1199.98px) {
        .section-bio {
            margin-bottom: 2.5rem
        }
    }

    .bio-name {
        color: #000066;
        font-size: 2rem;
        font-weight: 200;
        padding-left: 0vw;
    }

    .bio-title {
        color: rgba(60, 60, 60, 0.6392156863);
        font-size: 1.15rem;
        padding-left: 0vw;
        text-align: center;
    }

    .bio-img {
        padding-bottom: 2vh;
    }

    .bio-img img {
        height: 40vh;
        width: auto;
    }

    .bio-contact {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        padding-left: 0vw;
        width: 80%;
        padding-top: 5vh;

    }

    .bio-contact-line {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: stretch;
    }

    .bio-contact-line i {
        padding-right: 1vw;
    }

    .bio-contact-line a {
        color: #000;
        text-decoration: none;
    }

    .section-job {
        margin-bottom: 5rem
    }

    @media (max-width:991.98px) {
        .section-job {
            padding-bottom: 1.5rem;
            padding-top: 1.5rem
        }
    }

    @media (max-width:1199.98px) {
        .section-job {
            margin-bottom: 2.5rem
        }
    }

    .section-job.section-top-page {
        margin-bottom: 0
    }

    .section-job.section-top-page .flex-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%
    }

    .section-job.section-top-page .times-wrapper {
        align-items: flex-start;
        color: #000066;
        display: flex;
        font-size: 1.5rem;
        opacity: .85;
        padding-top: .25rem;
        transition: opacity .25s ease
    }

    .section-job.section-top-page .times-wrapper:hover {
        color: #000066;
        opacity: 1
    }

    .section-job.section-full-primary {
        margin-bottom: 5rem
    }

    .section-job .job-header {
        display: flex;
        flex-direction: column;
        margin-top: -.25rem
    }

    @media (max-width:575.98px) {
        .section-job .job-header {
            padding-left: 50px
        }
    }

    .section-job .job-header .page-title {
        margin-bottom: 1rem
    }

    @media (max-width:575.98px) {
        .section-job .job-header .page-title {
            justify-content: flex-start
        }
    }

    .section-job .job-header .job-title h3 {
        font-size: 1.75rem;
        font-weight: 800;
        margin-bottom: .25rem
    }

    .section-job .job-header .job-description p {
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.25
    }

    .section-job .job-content {
        padding-right: 5rem
    }

    @media (max-width:1399.98px) {
        .section-job .job-content {
            padding-right: 2.5rem
        }
    }

    @media (max-width:991.98px) {
        .section-job .job-content {
            padding-right: 0
        }
    }

    .section-job .job-content h3 {
        color: #000066;
        font-size: .9rem;
        font-weight: 700;
        margin-bottom: 1.5rem;
        text-transform: uppercase
    }

    .section-job .job-content p {
        margin-bottom: 2rem
    }

    @media (max-width:991.98px) {
        .section-job .job-content p {
            text-align: justify
        }
    }

    .section-job .job-content ul {
        margin-bottom: 4rem
    }

    .section-job .job-content ul li {
        margin-bottom: 1rem;
        padding-left: 35px;
        position: relative
    }

    @media (max-width:991.98px) {
        .section-job .job-content ul li {
            text-align: justify
        }
    }

    .section-job .job-content ul li::before {
        background-color: #000066;
        content: '';
        height: 2px;
        left: 0;
        position: absolute;
        top: .75em;
        width: 8px
    }

    .section-job .job-content :last-child {
        margin-bottom: 0
    }

    .section-job .job-contact {
        height: 100%;
        min-height: 400px;
        padding-left: 5rem;
        position: relative
    }

    @media (max-width:1399.98px) {
        .section-job .job-contact {
            padding-left: 2.5rem
        }
    }

    @media (max-width:1199.98px) {
        .section-job .job-contact {
            min-height: auto;
            padding-left: 0;
            padding-top: 2.5rem
        }
    }

    .section-job .job-contact::before {
        background-color: #000066;
        content: '';
        height: 50%;
        left: 0;
        min-height: 400px;
        position: absolute;
        top: 0;
        width: 1px
    }

    @media (max-width:1199.98px) {
        .section-job .job-contact::before {
            display: none
        }
    }

    .section-job .job-contact p {
        margin-bottom: 1.5rem
    }

    .section-job .job-contact p span {
        font-weight: 700
    }

    .section-job .job-contact .btn {
        font-family: Gilroy;
        font-weight: 800
    }

    .section-latest-news {
        display: flex;
        height: auto;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        padding-top: 10vh;
    }

    .section-latest-news .row {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        width: 80vw;
    }

    .section-latest-news .row > [class*=col] {
        padding-left: 10px;
        padding-right: 10px;
        width: 80vw;
    }

    .article {
        padding: 10vh 2vw 0vh 2vw;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
    }

    .article-heading {
        padding: 1.5rem 0;
        text-align: center
    }

    .article-heading h1 {
        display: block;
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 0;
        padding: 2.5rem 25px 1.5rem
    }

    .article-heading .tags {
        display: flex;
        justify-content: center;
        margin-top: 2.5rem
    }

    .article-heading .tags li a {
        display: block;
        line-height: 1.25;
        padding: .25rem 1rem 0;
        position: relative
    }

    .article-heading .tags li a::before {
        background-color: #000066;
        bottom: 4px;
        content: '';
        position: absolute;
        right: 0;
        top: 4px;
        width: 1px
    }

    .article-heading .tags li:last-child a::before {
        display: none
    }

    .owl-carousel {
        z-index: 0
    }

    .owl-theme .owl-dots {
        bottom: 5px;
        margin: 0;
        position: absolute;
        right: 15px
    }

    .owl-theme .owl-dots .owl-dot {
        outline: 0
    }

    .owl-theme .owl-dots .owl-dot span {
        background-color: #fff;
        border: 1px solid #000066
    }

    .owl-theme .owl-dots .owl-dot.active span,
    .owl-theme .owl-dots .owl-dot:hover span {
        background-color: #000066;
        border: 1px solid #000066
    }

    .btn {
        border-radius: 0;
        padding: .5rem 1rem
    }

    .btn.big-text {
        font-family: Gilroy;
        font-size: 1.5rem;
        font-weight: 800
    }

    .arrow-wrapper-white .arrow,
    .custom-bounce {
        animation-name: custom-bounce;
        transform-origin: center bottom
    }

    .customFadeInUp {
        animation-name: customFadeInUp
    }

    .customSlideInUp {
        animation-name: customFadeInUp
    }

    @keyframes custom-bounce {

        10.6%,
        16%,
        4%,
        from,
        to {
            animation-timing-function: cubic-bezier(.215, .61, .355, 1);
            transform: translate3d(0, 0, 0)
        }

        8%,
        8.6% {
            animation-timing-function: cubic-bezier(.755, .05, .855, .06);
            transform: translate3d(0, -30px, 0)
        }

        14% {
            animation-timing-function: cubic-bezier(.755, .05, .855, .06);
            transform: translate3d(0, -15px, 0)
        }

        18% {
            transform: translate3d(0, 0, 0)
        }
    }

    @keyframes fade-from-none {
        0% {
            display: none;
            opacity: 0
        }

        1% {
            display: block;
            opacity: 0
        }

        100% {
            display: block;
            opacity: 1
        }
    }

    @keyframes banner-line {
        0% {
            height: 0
        }

        100% {
            height: 100%
        }
    }

    @keyframes banner-arrow {
        0% {
            opacity: 0;
            top: 0;
            transform: translateY(0)
        }

        100% {
            opacity: 1;
            top: 50%;
            transform: translateY(-100%)
        }
    }

    @keyframes customFadeInUp {
        from {
            opacity: 0;
            transform: translate3d(0, 50px, 0);
            visibility: visible
        }

        to {
            opacity: 1;
            transform: translate3d(0, 0, 0)
        }
    }

    @keyframes customSlideInUp {
        from {
            opacity: 0;
            transform: translate3d(0, 25px, 0);
            visibility: visible
        }

        to {
            opacity: 1;
            transform: translate3d(0, 0, 0)
        }
    }

    .admin-container {
        margin-top: -150px;
        padding: 50px
    }

    .admin-container input {
        min-width: 300px
    }

    .admin-container .btn {
        border-radius: 4px;
        line-height: 1;
        padding-top: 10px
    }

    #ie-banner {
        text-align: center;
        padding-top: 15vh;
        position: absolute;
    }

    #ie-banner img {
        max-height: 30vh;
        max-width: 80%;
        width: auto
    }

    .ie #ie-banner {
        display: block
    }

    .ie .section-banner #animation-wrapper {
        display: none
    }

    .ie .section-worked-with-us .col-auto {
        width: 100%
    }

    .ie .section-worked-with-us .section-title.vertical {
        margin-bottom: 15px;
        text-orientation: initial;
        transform: none;
        writing-mode: initial !important
    }

    .ie .section-gallery .image-infos {
        text-align: right;
        transform: none
    }

    .ie-banner-1 {
        width: 100vw;
    }

    .ie-banner-2 {
        width: 100vw;
    }

    .ie-banner-3 {
        width: 100vw;
    }

    .ie-banner-4 {
        width: 100vw;
    }

    .ie-banner-5 {
        width: 80vw;
    }

    .login-section {
        height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-left: 0;
    }

    .login-inner {
        padding-left: 15vw;
        height: 50vh;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: flex-start;
    }

    .login-img {
        background: url(../images/banner/login.jpg) center center no-repeat;
        height: 100vh;
    }

    .title-row {
        display: inline-flex;
    }

    .contact-section {
        height: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding: 30vh 5vw 10vh 5vw;
    }

    .contact-section .row {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: nowrap;
        align-content: center;
        align-items: center;
        height: auto;
    }

    .contact-section .row .col-6 {
        width: 100%;
    }

    .contact-inner {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: stretch;
        width: 100%;
        height: auto;
    }

    .contact-info {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .contact-info-inner {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: flex-start;
        height: 40vh;
    }

    .contact-info-inner .bi {
        margin-right: 2vw;
    }

    .contact-info-inner a {
        color: #000066;
        font-size: 1rem;
        line-height: 1.5;
        text-decoration: none;

    }

    .contact-form {
        height: 75vh;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
    }

    .mb-3 {
        width: 100%;
    }

    .contact-control {
        border: none;
        border-bottom: 1px solid #ced4da;
        border-radius: 0px !important;
    }

    .contact-control:focus {
        border-bottom: 1px solid #000066;
        border-radius: 0px !important;
        box-shadow: none !important;
    }

    .contact-select {
        border: none;
        border-bottom: 1px solid #ced4da;
        border-radius: 0px !important;
    }

    .contact-select:focus {
        border-bottom: 1px solid #000066;
        border-radius: 0px !important;
        box-shadow: none !important;
    }

    //JOIN TEAM FORM
    .join-team-form {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .join-team-form .form-control {
        background: rgba(255, 255, 255, 0.8509803922);
        width: 35vh;
        height: 5vh;
        border-radius: 2px;
    }

    .join-team-form .row {
        padding-bottom: 5vh;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .form-file input[type=file]::-webkit-file-upload-button {
        height: 5vh;

    }

    .form-file input[type=file]::file-selector-button {
        height: 5vh;
    }

    //HOVER UNDERLINE
    .hover-underline {
        position: relative;
    }

    .hover-underline:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        transition: width 0s ease, background .5s ease;
    }

    .hover-underline:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        background: #000066;
        transition: width .5s ease;
    }

    .hover-underline:hover:before {
        width: 100%;
        background: #000066;
        transition: width .5s ease;
    }

    .hover-underline:hover:after {
        width: 100%;
        background: transparent;
        transition: all 0s ease;
    }

    //HOVER UNDERLINE WHITE
    .hover-underline-white {
        position: relative;
    }

    .hover-underline-white:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        transition: width 0s ease, background .5s ease;
    }

    .hover-underline-white:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        background: #f1f1f1;
        transition: width .5s ease;
    }

    .hover-underline-white:hover:before {
        width: 100%;
        background: #f1f1f1;
        transition: width .5s ease;
    }

    .hover-underline-white:hover:after {
        width: 100%;
        background: transparent;
        transition: all 0s ease;
    }

    //HOVER UNDERLINE RED
    .hover-underline-red {
        position: relative;
    }

    .hover-underline-red:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        transition: width 0s ease, background .5s ease;
    }

    .hover-underline-red:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        background: #000066;
        transition: width .5s ease;
    }

    .hover-underline-red:hover:before {
        width: 100%;
        background: #000066;
        transition: width .5s ease;
    }

    .hover-underline-red:hover:after {
        width: 100%;
        background: transparent;
        transition: all 0s ease;
    }

    //ARROW DOWN 
    .arrow-down {
        display: inline-flex;
        position: fixed;
        bottom: 5vh;
        width: 1.5vw;
    }

    //ARROW DOWN HOME PAGE
    .arrow-down-animate {
        animation-name: loop-down;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    @keyframes loop-down {
        0% {
            transform: translateY(0vh);
            opacity: 0
        }

        25% {
            transform: translateY(1vh);
            opacity: 0.5
        }

        50% {
            transform: translateY(2vh);
            opacity: 1
        }

        75% {
            transform: translateY(3vh);
            opacity: 0.5
        }

        100% {
            transform: translateY(4vh);
            opacity: 0
        }
    }

    //COUNTERS
    #counters_1,
    #counters_2,
    #counters_3 {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
        height: 20vh;
        width: 30vw;
    }

    .counter {
        font-size: 4rem;

    }

    .counter-wrapper {
        display: flex;
        height: 10vh;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-end;
    }

    //SWUP
    .transition-fade {
        transition: 0.4s;
        opacity: 1;
    }

    html.is-animating .transition-fade {
        opacity: 0;
    }

    //HOME
    .home-header {
        height: 150vh;
        widows: 100vh;
        object-fit: cover;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        background: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.8)), url(../images/banner/header.jpg) no-repeat center center;
        background-size: cover;
        background-attachment: fixed;
    }

    .first-letter {
        -webkit-initial-letter: 4;
        initial-letter: 4;
        color: orange;
        font-weight: bold;
        margin-right: .75em;
    }

    .intro {
        height: 50vh;
        width: 100vw;
        display: flex;
        z-index: 9500;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        position: absolute;
        padding: 0vh 10vw 5vh 10vw;
        left: 0;
        text-align: left;
        top: 50vh;
    }

    .intro .txt hr {
        width: 80vw;
        height: 2px;
        opacity: 1;
        color: #fff;
    }

    .intro .txt h3 {
        font-size: 3rem;
        font-weight: 400;
        color: #fff;
        text-transform: uppercase;
        line-height: 1;
        white-space: nowrap;
        transform: scaleY(1.1) !important;
    }

    .intro .txt p {
        font-size: 1.15rem;
        font-weight: 400;
        color: #fff;
        line-height: 1.5;
        text-align: justify;
    }

    .dropcap {
        background: transparent;
        color: #FDF9F2;
        float: left;
        font-size: 4.0rem;
        line-height: 1;
        margin: 0.2em 0.1em 0em 0;
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    .dropcap:before,
    .dropcap:after {
        content: "";
        display: block;
    }

    .dropcap:before {
        margin-top: -0.2em;
    }

    .dropcap:after {
        margin-bottom: -0.15em;
    }

    .sr-only:not(:focus):not(:active) {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    .intro-btn {
        background: #fff;
        border: 1px solid #fff;
        height: 5vh;
        width: 25vw;
        border-radius: 2px;
        margin: 1vw;
        margin-left: 0;
        color: #000;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.05vw;
        transition: 500ms !important;
    }

    .intro-btn:hover {
        background: #fff;
        border: 2px solid #fff;
        height: 8vh;
        width: 16vw;
        border-radius: 2px;
        margin: 1vw;
        margin-left: 0;
        color: #000066;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.05vw;
        transition: 500ms !important;
    }

    .video-overlay {
        background: rgba(0, 0, 0, 0.4);
        height: 100vh;
        width: 100vw;
        position: absolute;
        top: 0;
        z-index: 9000;
    }

    .intro_1 {
        height: auto;
        width: 100vw;
        background: #fff;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding: 25vh 5vw 10vh 5vw;
    }

    .intro_1 h1 {
        color: #000066;
        font-size: 4rem;
        font-weight: 300;
        text-transform: uppercase;
        margin: 0;
        padding-bottom: 2vh;
        text-align: center;
    }

    .top-img {
        position: absolute;
        width: 15%;
        z-index: 8500;
        right: 5vw;
        bottom: 60vh;
        box-shadow: black;
        -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
        box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
    }

    .mid-img {
        position: absolute;
        right: 7vw;
        bottom: 23vh;
        width: 40%;
        box-shadow: black;
        -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
        box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
    }

    .bot-img {
        position: absolute;
        width: 25%;
        right: 10vw;
        bottom: 20vh;
        box-shadow: black;
        -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
        box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
    }

    .listings-btn {
        background: #fff;
        border: none;
        height: 4vh;
        width: 10vw;
        border-radius: 2px;
        margin: 1vw;
        margin-left: 0;
    }

    //SLIDE OVER SECTIONS
    .info-contain {
        width: 100vw;
        height: 100vh;
        height: 100%;
        height: fill-available;
        transition-duration: 600ms;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.8, 0, 0, 1);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 4;
        background: #fff;
        background-size: cover;
        -ms-transform: translate(100%, 0);
        /* IE 9 */
        -webkit-transform: translate(100%, 0);
        /* Safari */
        transform: translate(100%, 0);
        /* Standard syntax */
        padding-top: 20vh;
        overflow: scroll;
        margin: 0;
    }

    .info-contain.opened {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100vh;
        height: 100%;
        height: fill-available;
        transition-duration: 600ms;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.8, 0, 0, 1);
        -ms-transform: translate(0, 0);
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
        z-index: 9999 !important;
        padding-top: 30vh;
        overflow: scroll;
        margin: 0;
    }

    .overlay-element-innovation {
        width: 100vw;
        display: table;
        float: left;
        height: 100vh;
        color: #fff;
    }

    .overlay-txt {
        display: flex;
        vertical-align: middle;
        text-align: justify;
        text-align-last: left;
        font-size: 16px;
        font-weight: 300;
        padding: 5vh 10vw;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100vh;
        color: #000;
    }

    .overlay-txt h2 {
        font-size: 1.25rem;
        color: #000066;
        text-transform: uppercase;
        font-weight: 900;
    }

    .overlay-txt h3 {
        font-size: 2rem;
        font-weight: 900;
        color: #000;
        text-transform: uppercase;
    }

    .close {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1;
        color: #fff;
        text-shadow: 0 1px 0 rgb(0 0 0 / 50%);
        opacity: 1;
        display: flex;
        width: 15vw;
        position: relative;
        margin-top: 5vh;
        white-space: nowrap;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: stretch;
    }

    .close p {
        font-weight: 400;
        font-size: 0.8rem;
        padding-left: 2vw;
        text-transform: uppercase;
        text-shadow: none;
        color: #000066;
    }

    .close:hover {
        color: #000066;
        text-shadow: 0 1px 0 black;
        transition: all 0.3s;
        cursor: pointer;
        transition: all 0.5s;
        transform: translateX(-1vw);
    }

    .divider {
        width: 50vw;
        margin: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 7vh;
    }

    //PRESS
    .news-img {
        width: auto;
        height: 255px !important;
        transition: 500ms;
    }

    .news-img:hover {
        transition: 500ms;
        transform: scale(1.05)
    }

    .press-img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-top-left-radius: calc(0.25rem - 1px);
        border-top-right-radius: calc(0.25rem - 1px);
    }

    //ANIMATION LOADER//
    #loader-wrapper .loader-section {
        position: fixed;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: 9998;
        text-align: center;
        background: #fff;
    }

    #loader {
        z-index: 9999;
        position: fixed;
        height: 100vh;
        width: 100vw;
        text-align: center;
        background: #fff;
    }

    .loader-logo {
        position: relative;
        top: 3vh;
        height: auto;
        width: auto;
        text-align: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .loader_img_1 {
        animation: loader_1 4s ease;
        opacity: 0;
    }

    .loader_img_2 {
        animation: loader_2 4s ease;
        opacity: 0;
    }

    @keyframes loader_1 {
        0% {
            transform: translate(100vw, 40vh);
            opacity: 0;
        }

        60% {
            transform: translate(0vw, 40vh);
            opacity: 1;
        }

        70% {
            transform: translate(0vw, 40vh);
            opacity: 1;
        }

        100% {
            transform: translate(0vw, 40vh);
            opacity: 0;
        }
    }

    @keyframes loader_2 {
        0% {
            transform: translate(50vw, 40vh);
            opacity: 0;
        }

        40% {
            opacity: 0;
        }

        60% {
            transform: translate(0vw, 40vh);
            opacity: 1;
        }

        70% {
            transform: translate(0vw, 40vh);
            opacity: 1;
        }

        100% {
            transform: translate(0vw, 40vh);
            opacity: 0;
        }
    }

    .loader-text-fade-out {
        opacity: 0;
        animation: fade-text 4s;
        font-weight: 600;
        font-size: 1rem;
    }

    @keyframes fade-text {
        0% {
            opacity: 0;
        }

        30% {
            opacity: 1;
        }

        90% {
            opacity: 1;
        }

        100% {
            opacity: 0;
            visibility: hidden;
        }
    }

    #loader {
        opacity: 0;
        visibility: hidden;
        animation: fade-background 4s;
    }

    @keyframes fade-background {
        0% {
            opacity: 1;
            visibility: visible;
        }

        80% {
            opacity: 1;
            visibility: visible;
        }

        100% {
            opacity: 0;
            visibility: hidden;
        }
    }

    .sign-up-form {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        width: 70vh;

    }

    .sign-up-form .form-control {
        background: #ffffffd9;
        width: 35vh;
        height: 5vh;
        border-radius: 2px;
    }

    .sign-up-form .form-control:focus {
        background: #ffffff;
        width: 35vh;
        height: 5vh;
        border-radius: 10px;
        border-color: #000066
    }

    .sign-up-btn {
        background: #ffffff;
        color: #000;
        height: 5vh;
        width: 25vw;
        border: 1px solid #fff;
        border-radius: 2px;
        font-weight: 600;
        text-transform: uppercase;
    }

    .sign-up-btn:hover {
        color: #000066;
        border-color: #000066;
        font-weight: 600;
        text-transform: uppercase;
    }

    .join-team {
        background: linear-gradient(rgba(241, 241, 241, 0.6), rgba(241, 241, 241, 0.6)), url(../images/join-team.jpg) no-repeat center center;
        background-size: cover;
        height: auto;
        width: 100vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .join-team .inner {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        background: transparent;
        width: 100%;
        height: 50%;
        padding: 10vh 0vw;
    }

    .join-team .inner h3 {
        color: #000066;
        font-size: 4rem;
        font-weight: 300;
        white-space: nowrap;
        text-transform: uppercase;
    }

    .join-team .inner hr {
        width: 25%;
        color: #000066;
        opacity: 1;
        height: 2px;
    }

    .join-team .inner p {
        color: #000066;
        font-size: 1.15rem;
        font-weight: 600;
        white-space: wrap;
        text-transform: uppercase;
        padding: 5vh 0vw 5vh 0vw;
    }

    .join-btn {
        font-weight: 600;
        height: 8vh;
        width: 15vw;
        background: #fff;
        border: 1px solid #000066;
        border-radius: 2px;
        color: #000066;
        text-transform: uppercase;
        transition: 500ms ease;
    }

    .join-btn:hover {
        font-weight: 600;
        height: 8vh;
        width: 15vw;
        background: #000066;
        border: 1px solid #000066;
        border-radius: 2px;
        color: #fff;
        text-transform: uppercase;
        transition: 500ms ease;
        cursor: pointer;
    }

    .join-text {
        padding: 0 !important;
        font-size: 1rem !important;

    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    body {
        font-family: 'Century Gothic', sans-serif;
    }

    body + services-btn:active {
        overflow: hidden;
    }

    @media only screen and (min-width: 769px) and (max-width: 5000px) {
        .desktop-hide {
            display: none;
        }
    }

    @media only screen and (min-width: 0px) and (max-width: 768px) {
        .mobile-hide {
            display: none;
        }
    }

    b {
        font-weight: bold;
    }

    //DESKTOP MENU NAVBAR 
    .navbar {
        background-color: #fff;
        border-right: 1px solid #eee;
        display: flex;
        padding: 0vh 5vw;
        margin-top: 43px;
        position: fixed;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        top: -2vh;
        transition: left 0.25s ease;
        width: 100vw;
        height: 90px;
        z-index: 9;
        box-shadow: 1px 1px 10px rgb(0 0 0 / 25%);
    }

    .nav-link {
        color: #000;
        text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: 600;
    }

    .nav-link:hover {
        color: #000066;
        cursor: pointer;
    }

    .active-link {
        color: #000066;
    }

    .login-btn {
        color: #000066;
        text-transform: capitalize;
        text-decoration: none;
        font-size: 1rem;
        font-weight: 600;
    }

    .login-form-btn {
        background: #fff;
        color: #000066;
        line-height: 2;
        font-size: 1rem;
        border: 1px solid #000066;
        width: 10vw;
        border-radius: 2px;
        margin-top: 2vh;
        height: 5vh;
        width: 25vw;
        text-transform: uppercase;
        font-weight: 600;
        transition: 500ms ease;
    }

    .login-form-btn:hover {
        background: #000066;
        color: #fff;
        line-height: 2;
        font-size: 1rem;
        border: 1px solid #000066;
        width: 10vw;
        border-radius: 2px;
        margin-top: 2vh;
        height: 8vh;
        width: 15vw;
        text-transform: uppercase;
        font-weight: 600;
        transition: 500ms ease;
    }

    .form-text a {
        color: #6c757d;
        text-decoration: none;
    }

    .form-text a:hover {
        color: #000066;
    }

    .navbar > .container,
    .navbar > .container-fluid {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between
    }

    .navbar-brand {
        display: inline-block;
        padding-top: .3125rem;
        padding-bottom: .3125rem;
        margin-right: 1rem;
        font-size: 1.25rem;
        line-height: inherit;
        white-space: nowrap;
        width: 20vw;
    }

    .navbar-brand:focus,
    .navbar-brand:hover {
        text-decoration: none
    }

    .navbar-nav {
        display: flex;
        flex-direction: row;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
    }

    .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0
    }

    .navbar-nav .dropdown-menu {
        position: static;
        float: none
    }

    .navbar-text {
        display: inline-block;
        padding-top: .5rem;
        padding-bottom: .5rem
    }

    .navbar-collapse {
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center
    }

    .navbar-toggler {
        padding: .25rem .75rem;
        font-size: 1.25rem;
        line-height: 1;
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: .25rem
    }

    .navbar-toggler:focus,
    .navbar-toggler:hover {
        text-decoration: none
    }

    .navbar-toggler:not(:disabled):not(.disabled) {
        cursor: pointer
    }

    .navbar-toggler-icon {
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        vertical-align: middle;
        content: "";
        background: no-repeat center center;
        background-size: 100% 100%
    }

    @media (max-width:575.98px) {

        .navbar-expand-sm > .container,
        .navbar-expand-sm > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:576px) {
        .navbar-expand-sm {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-sm .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-sm .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-sm .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-sm > .container,
        .navbar-expand-sm > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-sm .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-sm .navbar-toggler {
            display: none
        }
    }

    @media (max-width:767.98px) {

        .navbar-expand-md > .container,
        .navbar-expand-md > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:768px) {
        .navbar-expand-md {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-md .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-md .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-md .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-md > .container,
        .navbar-expand-md > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-md .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-md .navbar-toggler {
            display: none
        }
    }

    @media (max-width:991.98px) {

        .navbar-expand-lg > .container,
        .navbar-expand-lg > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:992px) {
        .navbar-expand-lg {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-lg .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-lg .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-lg .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-lg > .container,
        .navbar-expand-lg > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-lg .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-lg .navbar-toggler {
            display: none
        }
    }

    @media (max-width:1199.98px) {

        .navbar-expand-xl > .container,
        .navbar-expand-xl > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:1200px) {
        .navbar-expand-xl {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-xl .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-xl .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-xl .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-xl > .container,
        .navbar-expand-xl > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-xl .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-xl .navbar-toggler {
            display: none
        }
    }

    @media (max-width:1399.98px) {

        .navbar-expand-xxl > .container,
        .navbar-expand-xxl > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:1400px) {
        .navbar-expand-xxl {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-xxl .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-xxl .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-xxl .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-xxl > .container,
        .navbar-expand-xxl > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-xxl .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-xxl .navbar-toggler {
            display: none
        }
    }

    @media (max-width:1599.98px) {

        .navbar-expand-xxxl > .container,
        .navbar-expand-xxxl > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:1600px) {
        .navbar-expand-xxxl {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-xxxl .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-xxxl .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-xxxl .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-xxxl > .container,
        .navbar-expand-xxxl > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-xxxl .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-xxxl .navbar-toggler {
            display: none
        }
    }

    @media (max-width:1920.98px) {

        .navbar-expand-uhd > .container,
        .navbar-expand-uhd > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:1921px) {
        .navbar-expand-uhd {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-uhd .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-uhd .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-uhd .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-uhd > .container,
        .navbar-expand-uhd > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-uhd .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-uhd .navbar-toggler {
            display: none
        }
    }

    .navbar-expand {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand > .container,
    .navbar-expand > .container-fluid {
        padding-right: 0;
        padding-left: 0
    }

    .navbar-expand .navbar-nav {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        width: 65vw;

    }

    .navbar-expand .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand > .container,
    .navbar-expand > .container-fluid {
        flex-wrap: nowrap
    }

    .navbar-expand .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
    }

    .navbar-expand .navbar-toggler {
        display: none
    }

    .navbar-light .navbar-brand {
        color: rgba(0, 0, 0, .9)
    }

    .navbar-light .navbar-brand:focus,
    .navbar-light .navbar-brand:hover {
        color: rgba(0, 0, 0, .9)
    }

    .navbar-light .navbar-nav .nav-link {
        color: rgba(0, 0, 0, .5)
    }

    .navbar-light .navbar-nav .nav-link:focus,
    .navbar-light .navbar-nav .nav-link:hover {
        color: rgba(0, 0, 0, .7)
    }

    .navbar-light .navbar-nav .nav-link.disabled {
        color: rgba(0, 0, 0, .3)
    }

    .navbar-light .navbar-nav .active > .nav-link,
    .navbar-light .navbar-nav .nav-link.active,
    .navbar-light .navbar-nav .nav-link.show,
    .navbar-light .navbar-nav .show > .nav-link {
        color: rgba(0, 0, 0, .9)
    }

    .navbar-light .navbar-toggler {
        color: rgba(0, 0, 0, .5);
        border-color: rgba(0, 0, 0, .1)
    }

    .navbar-light .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0,0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
    }

    .navbar-light .navbar-text {
        color: rgba(0, 0, 0, .5)
    }

    .navbar-light .navbar-text a {
        color: rgba(0, 0, 0, .9)
    }

    .navbar-light .navbar-text a:focus,
    .navbar-light .navbar-text a:hover {
        color: rgba(0, 0, 0, .9)
    }

    .navbar-dark .navbar-brand {
        color: #fff
    }

    .navbar-dark .navbar-brand:focus,
    .navbar-dark .navbar-brand:hover {
        color: #fff
    }

    .navbar-dark .navbar-nav .nav-link {
        color: rgba(255, 255, 255, .5)
    }

    .navbar-dark .navbar-nav .nav-link:focus,
    .navbar-dark .navbar-nav .nav-link:hover {
        color: rgba(255, 255, 255, .75)
    }

    .navbar-dark .navbar-nav .nav-link.disabled {
        color: rgba(255, 255, 255, .25)
    }

    .navbar-dark .navbar-nav .active > .nav-link,
    .navbar-dark .navbar-nav .nav-link.active,
    .navbar-dark .navbar-nav .nav-link.show,
    .navbar-dark .navbar-nav .show > .nav-link {
        color: #fff
    }

    .navbar-dark .navbar-toggler {
        color: rgba(255, 255, 255, .5);
        border-color: rgba(255, 255, 255, .1)
    }

    .navbar-dark .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
    }

    .navbar-dark .navbar-text {
        color: rgba(255, 255, 255, .5)
    }

    .navbar-dark .navbar-text a {
        color: #fff
    }

    .navbar-dark .navbar-text a:focus,
    .navbar-dark .navbar-text a:hover {
        color: #fff
    }

    //END NAVBAR
    //TICKER
    .ticker {
        width: 100vw;
        height: 27px;
        position: fixed;
        display: block;
        top: 0;
        z-index: 9999;
        padding: 0;
    }

    .ticket .row {
        height: auto;
    }

    .ticket .row .col-md-12 {}

    .breaking-news {
        display: block;
        position: relative;
        background: #3c3c3c;
        height: 27px;
    }

    .news-scroll {
        height: 27px;
        position: relative;
    }

    .news {
        width: 10vw;
        height: 5vh;
        min-height: 5vh;
        background: #000066;
        padding: 0;
        border: 0;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .news-scroll a {
        font-size: 0.8rem;
        font-weight: 300;
        color: #fff;
        text-transform: uppercase;
        text-decoration: none;
    }

    .dot {
        padding-right: 0.5vw;
    }

    .bold {
        font-weight: bold;
    }

    .img-fill {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden
    }

    .img-fill img {
        flex-shrink: 0;
        object-fit: cover;
    }

    footer {
        display: flex;
        background-color: #3c3c3c;
        border-top: 1px solid #888;
        color: #888;
        font-size: 1rem;
        padding: 15vh 0;
        position: relative;
        z-index: auto !important;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        height: auto;
        z-index: 9500 !important;
    }

    footer a {
        color: #fff;
        font-size: 0.8rem;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;
    }

    footer a:hover {
        color: #f1f1f1
    }

    footer p {
        margin: 0;
        font-size: 0.8rem;
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
    }

    footer svg {
        margin: 0
    }

    footer .footer-row {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 10px;
        align-content: center;
        width: 100vw;
        padding: 0vh 5vw;
    }

    .footer-row .col-6 {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: flex-start;
    }

    .footer-info {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
    }

    .footer-row .col-3 p {
        font-size: 0.8rem;
        text-transform: capitalize;
        font-weight: 400;
        white-space: nowrap;
    }

    .footer-row .col-3 a {
        font-size: 0.8rem;
        text-transform: lowercase;
        font-weight: 400;
        white-space: nowrap;
    }

    .footer-info .col-3 {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: flex-start;
        padding: 0vh 0vw 0vh 2vw;
        height: 10vh;
    }

    .footer-row .col-6 .footer-info .col-3 a {
        font-weight: 600;
        text-transform: uppercase;
    }

    .footer-social {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        padding: 1vh;
    }

    .footer-social a {
        font-size: 1.25rem !important;
    }

    footer .footer-row:last-child {
        font-size: 11px;
        margin-bottom: 0
    }

    body {
        color: #000;
        font-size: 1rem;
        font-weight: 300;
        line-height: 26px;
        position: relative;
    }

    .scrollbar {
        overflow: auto
    }

    .scrollbar::-webkit-scrollbar {
        width: 10px;
        height: 10px
    }

    .scrollbar::-webkit-scrollbar-track {
        background-color: #e9ecef
    }

    .scrollbar::-webkit-scrollbar-thumb {
        background: #adb5bd
    }

    .scrollbar::-webkit-scrollbar-thumb:hover {
        background: #495057
    }

    main {
        margin-left: 0;
        overflow: hidden;
    }

    section {
        padding-left: 5vw;
        padding-right: 5vw;
        position: relative;
        z-index: 100;
        z-index: 2 !important;
    }

    section.no-mt {
        padding-top: 0
    }

    section.no-mb {
        padding-bottom: 0
    }

    header {
        background-color: #fff;
        position: relative;
        z-index: 100
    }

    img {
        max-width: 100%
    }

    h1 {
        font-weight: 800;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0
    }

    p {
        line-height: 1.75
    }

    strong {
        font-weight: 700
    }

    .mobile-arrow {
        bottom: 0;
        display: flex;
        justify-content: center;
        left: 0;
        margin: 0 auto;
        padding-bottom: 25px;
        position: absolute;
        right: 0
    }

    .mobile-arrow a {
        animation-delay: 2.5s;
        animation-duration: 5s;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        animation-name: custom-bounce;
        display: block;
        transform-origin: center bottom
    }

    .anchors {
        align-items: center;
        display: flex;
        font-size: 1.25rem;
        padding-top: .5rem
    }

    @media (max-width:575.98px) {
        .anchors {
            justify-content: center;
            padding-top: .5rem
        }
    }

    .anchors svg {
        color: #000066;
        height: 1.75rem;
        margin: 0 1.25rem 0 0;
        width: auto
    }

    @media (max-width:575.98px) {
        .anchors svg {
            margin: 0
        }
    }

    .anchors .nav-link {
        color: #000;
        font-size: 14px;
        font-weight: 300;
        margin: 0 1.25rem;
        padding: 8px 0 5px;
        position: relative;
        text-decoration: none
    }

    @media (max-width:575.98px) {
        .anchors .nav-link {
            margin: 0 .75rem
        }
    }

    .anchors .nav-link::before {
        background-color: #000066;
        bottom: 8px;
        content: '';
        height: 1px;
        left: 0;
        opacity: 0;
        position: absolute;
        transition: opacity .25s ease, width .15s ease;
        width: 0
    }

    .anchors .nav-link:first-child {
        margin-left: 0
    }

    .anchors .nav-link:last-child {
        margin-right: 0
    }

    .anchors .nav-link.active::before,
    .anchors .nav-link:hover::before {
        opacity: 1;
        width: 100%
    }

    .negative-mt {
        margin-top: -150px
    }

    .custom-list,
    .section-about ul {
        columns: 2;
        margin-top: 2.5rem;
        width: auto
    }

    @media (max-width:991.98px) {

        .custom-list,
        .section-about ul {
            margin-bottom: 1.5rem;
            margin-top: 1.5rem
        }
    }

    @media (max-width:767.98px) {

        .custom-list,
        .section-about ul {
            columns: 1
        }
    }

    .custom-list li,
    .section-about ul li {
        padding: .25rem 0 .25rem 1.5rem;
        position: relative
    }

    .custom-list li::before,
    .section-about ul li::before {
        border: 1px solid #000066;
        border-radius: 50%;
        content: '';
        height: 7px;
        left: 0;
        position: absolute;
        top: 12px;
        width: 7px
    }

    .primary-border {
        border-right: 1px solid #000066;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        padding: 0 5vw 0 0;
        position: relative
    }

    @media (max-width:991.98px) {
        .primary-border {
            border: 0;
            min-height: auto;
            padding: 1rem 0
        }
    }

    .primary-border.spaced {
        padding: 5rem 0
    }

    @media (max-width:991.98px) {
        .primary-border.spaced {
            padding: 2rem 0
        }
    }

    .page-title {
        color: #000066;
        font-size: 4rem;
        font-weight: 300;
        text-transform: uppercase;
        padding-bottom: 5vh;
    }

    .page-title.outline-big-text {
        font-size: 2.5rem
    }

    .subtitle-wrapper {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding-bottom: 3rem
    }

    @media (max-width:575.98px) {
        .subtitle-wrapper {
            align-items: flex-start;
            flex-direction: column;
            justify-content: flex-start
        }
    }

    @media (max-width:991.98px) {
        .subtitle-wrapper {
            padding-bottom: 1rem
        }
    }

    .text-wrapper p:last-child {
        margin: 0
    }

    .img-wrapper {
        border-color: rgba(255, 255, 255, 0);
        border-style: solid;
        border-width: 1px 0;
        padding: 0;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;
        flex-direction: column;
        align-content: center;
        width: 200px;
    }

    .img-wrapper hr {
        height: 5px;
        color: #000066;
        width: 25%;
    }

    .img-wrapper.static {
        border: 0;
        padding: 0
    }

    .img-wrapper.static .img-legend {
        color: #000;
        padding: 10px 0 0
    }

    .img-wrapper.static .img-legend a {
        color: #000;
        display: block;
        font-size: 16px;
        font-weight: 700;
        padding: .15rem 0 0;
        text-decoration: none
    }

    .img-wrapper img {
        overflow: hidden;
        object-fit: cover;
        min-height: 100%;
        min-width: 100%;
        flex-shrink: 0;
    }

    .img-wrapper .img-legend {
        color: #343a40;
        display: block;
        padding: 15px 0 0
    }

    .img-wrapper .img-legend p {
        line-height: 16px;
        margin: 0
    }

    .img-wrapper .img-legend p a {
        color: #000;
        font-size: 14px;
        font-weight: 800
    }

    .img-wrapper .img-legend .date {
        font-size: 14px;
        font-weight: 300
    }

    .img-wrapper .news-img {
        height: 350px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
    }

    .project-figure {
        position: relative
    }

    .project-figure figcaption {
        bottom: 10px;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 1px;
        position: absolute;
        right: 20px
    }

    .outline-big-text {
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(166, 0, 0, .9);
        color: #000066;
        font-family: Gilroy;
        font-size: 1.5rem;
        font-weight: 800;
        letter-spacing: 1px;
        line-height: 1;
        white-space: nowrap
    }

    .citation-wrapper .outline-big-text {
        font-size: 1.5rem;
    }

    @media (max-width:1399.98px) {
        .outline-big-text {
            font-size: 45px
        }
    }

    @media (max-width:1199.98px) {
        .outline-big-text {
            font-size: 40px
        }
    }

    .outline-big-text.outline-white {
        -webkit-text-stroke-color: rgba(255, 255, 255, .9);
        color: #fff
    }

    .solid-big-text {
        color: #ffffff;
        font-size: 5rem;
        font-weight: 600
    }

    .citation-align {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        padding-left: 5vw;
    }

    .citation-align .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        height: 100%
    }

    .citation-wrapper {
        color: #000066;
        display: flex;
        flex-direction: column;
        font-family: Helvetica;
        font-size: 1.5rem;
        font-weight: 700;
        height: 100%;
        justify-content: center;
        line-height: 1;
        padding-bottom: 2.5vh;
    }

    @media (max-width:991.98px) {
        .citation-wrapper {
            font-size: 1.25rem;
            padding: 1rem 0;
            text-align: center
        }
    }

    .citation-wrapper.hp .outline-big-text,
    .citation-wrapper.hp .solid-big-text {
        font-size: 3rem
    }

    @media (max-width:1599.98px) {

        .citation-wrapper.hp .outline-big-text,
        .citation-wrapper.hp .solid-big-text {
            font-size: 3rem;
        }
    }

    @media (max-width:1199.98px) {

        .citation-wrapper.hp .outline-big-text,
        .citation-wrapper.hp .solid-big-text {
            font-size: 3rem
        }
    }

    .citation-wrapper.hp .solid-small-text {
        font-size: 1rem;

    }

    @media (max-width:1199.98px) {
        .citation-wrapper.hp .solid-small-text {
            font-size: 20px
        }
    }

    .list-align {
        height: 100%
    }

    .list-align > .row {
        height: 100%
    }

    //PROPERTY PAGE
    .property-header {
        padding: 0 !important;
        height: 500px;
    }

    .property-img {
        height: 500px;
        width: 100%;
    }

    .property-img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .property-title {
        width: 100vw;
        height: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        align-items: flex-start;
        justify-content: center;
        padding: 5vh 5vw;
    }

    .title h2 {
        font-size: 2rem;
        color: #000066;
        font-weight: 600;
        text-transform: capitalize;
    }

    .property-address p {
        font-size: 1.25rem;
        font-weight: 300;
        color: #3c3c3c;
        text-transform: capitalize;
    }

    .property {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        width: 100vw;
        padding: 0vh 5vw;
    }

    .property .col-6 {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
    }

    .property-details {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: nowrap;
        align-content: center;
        padding: 0vh 0vw 0vh 10vw;
    }

    .property-details ul {
        margin-bottom: 1.25rem;
        padding-bottom: 1.25rem;
        position: relative
    }

    .property-details ul::before {
        background-color: #000066;
        bottom: 0;
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        width: 25px
    }

    .property-details ul:last-child {
        margin-bottom: 0;
        padding-bottom: 0
    }

    .property-details ul:last-child::before {
        display: none
    }

    .property-details ul li {
        font-size: 14px
    }

    .property-details ul li span {
        color: #000066;
        font-weight: 600;
        padding-right: .75rem
    }

    .property-inquiry {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-top: 10vh;
    }

    .property-inquiry .row {
        display: inline-flex;
    }

    .property-inquiry .row button {
        text-transform: uppercase;
        background: #000066;
        color: #fff;
        width: 25vw;
        height: 5vh;
        border-radius: 2px;
        transition: 500ms;
        border: none;
    }

    .property-inquiry .row button:hover {
        background: #fff;
        color: #000066;
        width: 16vw;
        height: 8vh;
        border-radius: 2px;
        transition: 500ms;
        border: 1px solid #000066
    }

    .border-link {
        border-style: solid;
        border-width: 1px 0;
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        padding: .2rem .25rem .1rem;
        position: relative;
        transition: color .25s ease;
        white-space: nowrap;
        word-spacing: 2px
    }

    .border-link::before {
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        top: 0;
        transition: width .25s ease;
        width: 0;
        z-index: -1
    }

    .border-link:hover {
        text-decoration: none
    }

    .border-link:hover::before {
        width: 100%
    }

    .border-link.border-link-primary {
        border-color: #000066;
        color: #000066
    }

    .border-link.border-link-primary::before {
        background-color: #000066
    }

    .border-link.border-link-primary:hover {
        color: #fff
    }

    .border-link.border-link-white {
        border-color: #fff;
        color: #fff
    }

    .border-link.border-link-white::before {
        background-color: #fff
    }

    .border-link.border-link-white:hover {
        color: #000066
    }

    .border-link.no-effect::before {
        display: none
    }

    .border-link.no-effect:hover {
        color: #000066
    }

    .link-effect {
        position: relative;
        z-index: 1
    }

    .link-effect::before {
        background-color: #fff;
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        top: 0;
        transition: width .2s ease;
        width: 0;
        z-index: -1
    }

    .link-effect:hover::before {
        width: 100%
    }

    .nav .nav-link {
        color: #000;
        font-weight: 300;
        margin: 0 50px 0 0;
        padding-bottom: .25rem;
        padding-left: 0;
        padding-right: 0;
        position: relative
    }

    .nav .nav-link::before {
        background-color: #000066;
        bottom: 8px;
        content: '';
        height: 1px;
        left: 0;
        opacity: 0;
        position: absolute;
        transition: opacity .25s ease, width .15s ease;
        width: 0
    }

    .nav .nav-link.active::before,
    .nav .nav-link:hover::before {
        opacity: 1;
        width: 100%
    }

    .row-adjust {
        margin-left: -25px;
        margin-right: -25px
    }

    @media (max-width:991.98px) {
        .row-adjust {
            margin-left: -10px;
            margin-right: -10px
        }
    }

    .row-adjust > [class*=col] {
        padding-left: 25px;
        padding-right: 25px
    }

    @media (max-width:991.98px) {
        .row-adjust > [class*=col] {
            padding-left: 10px;
            padding-right: 10px
        }
    }

    .row-align {
        justify-content: center
    }

    .row-big {
        margin-left: -75px;
        margin-right: -75px
    }

    .row-big > [class*=col] {
        padding-left: 75px;
        padding-right: 75px
    }

    .black-filter {
        background-color: rgba(0, 0, 0, .5);
        bottom: 0;
        left: 0;
        opacity: 0;
        position: fixed;
        right: 0;
        top: 0;
        transition: opacity .25s ease;
        z-index: -1
    }

    .black-filter.active {
        opacity: 1;
        z-index: 2000
    }

    .red-card {
        background-color: #000066;
        font-family: Gilroy;
        height: 100%;
        min-height: 275px;
        overflow: hidden;
        padding: 1.5rem 1.5rem 1rem
    }

    .red-card a {
        color: #fff;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        text-decoration: none
    }

    .red-card a:hover {
        color: #fff
    }

    .red-card p {
        line-height: 1
    }

    .red-card .job-title {
        font-size: 35px;
        font-weight: 800
    }

    .red-card .job-description {
        font-size: 35px;
        font-weight: 300;
        margin-bottom: 1.5rem
    }

    .red-card .flex-wrapper {
        display: flex;
        justify-content: space-between
    }

    .red-card .flex-wrapper .apply {
        font-size: 1rem;
        font-weight: 800
    }

    .red-card .flex-wrapper .arrow {
        align-items: center;
        border-radius: 50%;
        display: flex;
        font-size: 1.5rem;
        height: 35px;
        justify-content: center;
        width: 35px
    }

    .red-card .flex-wrapper .arrow:hover {
        background-color: #fff;
        color: #000066
    }

    .scroll-top {
        align-items: center;
        bottom: 0;
        display: flex;
        justify-content: center;
        position: absolute;
        right: 50px
    }

    @media (max-width:991.98px) {
        .scroll-top {
            right: 25px
        }
    }

    @media (max-width:575.98px) {
        .scroll-top {
            right: 15px
        }
    }

    .scroll-top a {
        line-height: 1;
        text-align: center;
        text-decoration: none
    }

    .scroll-top a p {
        line-height: 18px;
        margin: 0;
        text-align: center
    }

    .scroll-top a .icon-wrapper {
        align-items: center;
        border: 1px solid #000066;
        border-radius: 50%;
        color: #000066;
        display: flex;
        height: 35px;
        justify-content: center;
        margin: 0;
        overflow: hidden;
        position: relative;
        transition: color .15s ease;
        width: 35px
    }

    .scroll-top a .icon-wrapper::before {
        background-color: #000066;
        bottom: 0;
        content: '';
        height: 0;
        left: -20px;
        position: absolute;
        right: -20px;
        transition: height .15s ease;
        z-index: -1
    }

    .scroll-top a:hover p {
        color: #000066
    }

    .scroll-top a:hover .icon-wrapper {
        color: #fff
    }

    .scroll-top a:hover .icon-wrapper::before {
        height: 100%
    }

    .v-line {
        bottom: 0;
        left: 0;
        margin: 0 auto;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 4px;
        width: 21px
    }

    @media (max-width:991.98px) {
        .v-line {
            display: none
        }
    }

    .v-line.project-line .arrow {
        bottom: 20vh;
        padding: 2rem 0 3rem;
        top: auto
    }

    .v-line .arrow {
        left: 0;
        margin: 0 auto;
        min-width: 21px;
        position: fixed;
        right: 0;
        top: 50%
    }

    .arrow-wrapper-white {
        bottom: 20vh;
        left: 155px;
        padding: 2rem 0 3rem;
        position: fixed;
        right: 0;
        text-align: center;
        z-index: -1
    }

    @media (max-width:991.98px) {
        .arrow-wrapper-white {
            display: none
        }
    }

    .arrow-wrapper-white.active {
        display: none
    }

    .arrow-wrapper-white .arrow {
        align-items: center;
        animation-delay: 2.5s;
        animation-duration: 5s;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        position: relative
    }

    .container-fluid.no-margin {
        padding: 0
    }

    .section-title {
        text-align: center;
        padding: 5vh 0vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-title hr {
        height: 1px;
        color: #000066;
        width: 10vw;
        opacity: 1;
    }

    .section-title.vertical {
        font-size: 16px;
        font-weight: 700;
        margin: 0;
        text-orientation: sideways-right;
        transform: rotate(180deg);
        writing-mode: vertical-rl
    }

    @media (max-width:575.98px) {
        .section-title.vertical {
            margin-bottom: 15px;
            text-orientation: initial;
            transform: rotate(0);
            writing-mode: initial
        }
    }

    .section-title.red {
        color: #000066
    }

    .section-title.white {
        color: #fff
    }

    .section-banner {
        padding: 0
    }

    .section-banner.banner-spaced {
        padding: 0 50px
    }

    @media (max-width:991.98px) {
        .section-banner.banner-spaced {
            padding-left: 25px;
            padding-right: 25px
        }
    }

    @media (max-width:575.98px) {
        .section-banner.banner-spaced {
            padding-left: 10px;
            padding-right: 10px
        }
    }

    .section-banner iframe {
        height: 500px;
        max-width: 100%;
        width: 800px
    }

    .section-banner .banner {
        background-position: 50% 100%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
        position: relative;
        z-index: -10
    }

    .section-banner .banner.medium-banner {
        background-position: 50% 50%;
        height: 80vh;
        z-index: 2
    }

    .section-banner .banner.small-banner {
        background-position: 50% 50%;
        height: 60vh;
        z-index: 0
    }

    .section-banner .back {
        position: absolute;
        right: 25px;
        top: 25px;
        z-index: 5
    }

    .section-banner .back a {
        color: #fff
    }

    .section-banner .back a svg {
        height: 20px;
        width: auto
    }

    .section-banner .flex-wrapper {
        align-items: center;
        bottom: 0;
        display: flex;
        flex-direction: column;
        height: 100vh;
        justify-content: center;
        pointer-events: none;
        position: fixed;
        z-index: 1;
        flex-wrap: nowrap;
        align-content: center;
        width: 100vw;
    }

    @media (max-width:575.98px) {
        .section-banner .flex-wrapper {
            left: 0
        }
    }

    .section-banner .flex-wrapper .v-line {
        height: 0;
        margin: 50px auto 0;
        position: relative
    }

    @media (max-width:991.98px) {
        .section-banner .flex-wrapper .v-line {
            display: none
        }
    }

    .section-banner .flex-wrapper .v-line .arrow {
        left: 0;
        margin: 0 auto;
        opacity: 1;
        position: absolute;
        right: 0
    }

    .section-banner .flex-wrapper .v-line.active {
        animation: banner-line .85s ease-in;
        animation-delay: .25s;
        animation-fill-mode: forwards
    }

    .section-banner .flex-wrapper .v-line.active .arrow {
        animation: banner-arrow .75s ease;
        animation-delay: 1.1s;
        animation-fill-mode: forwards
    }

    .section-banner .flex-wrapper #animation-wrapper {
        max-width: 40%;
        position: relative;
        width: 100%
    }

    @media (max-width:575.98px) {
        .section-banner .flex-wrapper #animation-wrapper {
            max-width: 70%
        }
    }

    @media (max-width:991.98px) {
        .section-banner .flex-wrapper #animation-wrapper {
            max-width: 60%
        }
    }

    @media (max-width:1199.98px) {
        .section-banner .flex-wrapper #animation-wrapper {
            max-width: 50%
        }
    }

    .section-top-page {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 34vh;
        justify-content: space-evenly;
        z-index: 200;
        padding-top: 0vh;
        align-content: center;
        flex-wrap: nowrap;
    }

    .section-top-page .nav {
        display: flex;
        flex-wrap: nowrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        flex-direction: row;
        align-content: center;
        justify-content: space-around;
        width: 80vw;
        align-items: center;
    }

    .section-top-page .nav .nav-link {
        margin: 0;
        font-size: 0.8rem;
    }

    @media (max-width:575.98px) {
        .section-top-page {
            align-items: center
        }
    }

    .section-top-page.stickyTop {
        background-color: #fff;
        left: 155px;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 900
    }

    @media (max-width:575.98px) {
        .section-top-page.stickyTop {
            left: 0
        }
    }

    @media (max-width:575.98px) {
        .section-top-page .flex-wrapper {
            display: block
        }
    }

    .section-top-page .flex-wrapper .page-title {
        align-items: center;
        display: flex;
        margin-bottom: 0
    }

    @media (max-width:575.98px) {
        .section-top-page .flex-wrapper .page-title {
            justify-content: center
        }
    }

    .section-top-page .dropdown .dropdown-toggle {
        height: 34px;
        line-height: 34px;
        padding: 0 15px
    }

    .section-middle-line {
        padding-bottom: 5rem;
        padding-top: 5rem;
    }

    @media (max-width:991.98px) {
        .section-middle-line {
            padding-bottom: 1.5rem;
            padding-top: 1.5rem
        }
    }

    .section-middle-line.small-margin-top {
        padding-top: 1.5rem
    }

    .section-middle-line.reversed .text-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-start
    }

    @media (max-width:991.98px) {
        .section-middle-line.reversed .text-wrapper {
            margin: 0;
            width: 100%
        }
    }

    .section-middle-line.reversed .primary-border {
        border: 0;
        height: 100%;
        justify-content: flex-start
    }

    .section-middle-line.reversed .primary-border.spaced {
        padding-top: 0
    }

    .section-middle-line.reversed .citation-wrapper {
        justify-content: flex-start
    }

    .section-middle-line.reversed .button-wrapper {
        text-align: center
    }

    @media (max-width:991.98px) {
        .section-middle-line.reversed .button-wrapper {
            margin: 2.5rem auto 0
        }
    }

    .section-middle-line.about-details {
        margin-bottom: 5rem;
        padding: 10vh 10vw
    }

    @media (max-width:991.98px) {
        .section-middle-line.about-details {
            padding: 0 25px
        }
    }

    .about-details .row {
        padding-top: 5vh;
    }

    .about-details .row .col-6 {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
    }

    .about-details .row .col-6 .citation-wrapper {
        font-size: 2.5rem;
        padding-left: 15vw;
    }

    .about-details .row .col-6 .citation-wrapper .outline-big-text {
        font-size: 2rem;
    }

    .about-details .row .col-6 .text-wrapper {
        padding-left: 10vw;
        padding-right: 10vw;
    }

    .section-middle-line.about-details .citation-wrapper {
        text-align: left
    }

    .section-middle-line.about-details .primary-border {
        padding: 0
    }

    @media (max-width:991.98px) {
        .section-middle-line.about-details .text-wrapper {
            margin-bottom: 50px
        }
    }

    .section-middle-line.about-details .row.marged {
        margin-bottom: 100px
    }

    @media (max-width:991.98px) {
        .section-middle-line.about-details .row.marged {
            margin-bottom: 0
        }
    }

    @media (max-width:991.98px) {
        .section-middle-line .text-wrapper p {
            text-align: justify
        }
    }

    th {
        width: 25%;
    }

    .sold .table thead tr th {
        color: #000066;
        text-transform: uppercase;
        font-weight: 600;
    }

    .sold {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
    }

    .sold-img {
        width: 100%;
    }

    .sold .card-description {
        height: auto;
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
    }

    .sold .card-text {
        display: none;
    }

    .sold .card-inquiry {
        display: none;
    }

    .sold-price {
        color: white;
        background: #000066;
        text-align: center;
    }

    .listings {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: flex-start;
    }

    .listings .card {
        width: 250px;
        margin: 10vh 0 0 0;
    }

    .exclusive-listings {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
    }

    .exclusive-listings .card {
        width: 30vh;
        margin: 5vh 0 0 0;
    }

    .property-status {
        font-weight: 700;
        color: #000;
        font-size: 1rem;
        text-align: center;
        border-top: none !important;
        height: 50px;
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: stretch;
    }

    .card {
        height: 515px;
    }

    .card-title {
        font-size: 0.8rem;
        color: #000066;
        line-height: 1.5rem;
        font-weight: 400;
        text-transform: uppercase;
    }

    .card-text {
        font-size: 0.8rem;
    }

    .card-link {
        color: #ffffff;
        font-size: 1rem;
        text-decoration: none;
    }

    .card-link:hover {
        color: #f1f1f1;
        font-size: 1rem;
        text-decoration: none;
        cursor: pointer;
    }

    .card-inquiry {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: center;
        background: #000066;
        border-bottom-left-radius: calc(0.25rem - 1px);
        border-bottom-right-radius: calc(0.25rem - 1px);
        height: 20px;
        padding: 1vh;
    }

    .card-description {
        height: 185px;
    }

    .card-img {
        height: 155px;
        object-fit: cover;
    }

    .section-platforms {
        padding-bottom: 5rem;
        padding-top: 5rem
    }

    .section-platforms .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-platforms .row .col-6 {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-right: 5vw !important;
    }

    @media (max-width:1199.98px) {
        .section-platforms {
            max-height: initial;
            min-height: initial;
            overflow: initial
        }
    }

    @media (max-width:991.98px) {
        .section-platforms {
            padding-bottom: 2.5rem;
            padding-top: 2.5rem
        }
    }

    @media (max-width:767.98px) {
        .section-platforms table {
            width: 100%
        }
    }

    @media (max-width:767.98px) {
        .section-platforms table tr {
            display: flex;
            flex-direction: column;
            margin-bottom: 25px
        }
    }

    @media (max-width:767.98px) {
        .section-platforms table tr td {
            text-align: left
        }
    }

    .section-platforms .citation-align {
        border-left: 1px solid #000066
    }

    @media (max-width:1399.98px) {
        .section-platforms .citation-align {
            border-left: 0
        }
    }

    .section-platforms .platform-title {
        align-items: center;
        border-bottom: 1px solid #000;
        display: flex;
        font-size: 21px;
        font-weight: 700;
        height: 140px;
        line-height: 30px;
        width: auto
    }

    @media (max-width:767.98px) {
        .section-platforms .platform-title {
            border-bottom: 0;
            height: auto
        }
    }

    .section-platforms .platform-title p {
        margin-bottom: 0;
        padding-right: 10px;
        width: auto
    }

    @media (max-width:767.98px) {
        .section-platforms .platform-title p {
            padding-right: 0;
            width: 100%
        }
    }

    .section-platforms .platform-paragraph {
        padding-left: 5vw
    }

    @media (max-width:767.98px) {
        .section-platforms .platform-paragraph {
            padding-left: 0
        }
    }

    .section-platforms .platform-paragraph p {
        margin-bottom: 0;
        width: 250px
    }

    @media (max-width:767.98px) {
        .section-platforms .platform-paragraph p {
            width: auto
        }
    }

    //CTA INVESTMENT HOME PAGE SECTION
    .section-investment {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-bottom: 5rem;
        padding-top: 5rem;
        height: 75vh;
    }

    .section-investment .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-investment .row .col-6 {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-right: 5vw !important;
    }

    .section-investment .row .col-6 .inner {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        align-items: flex-end;
        justify-content: center;
    }

    @media (max-width:1199.98px) {
        .section-investment {
            max-height: initial;
            min-height: initial;
            overflow: initial
        }
    }

    @media (max-width:991.98px) {
        .section-investment {
            padding-bottom: 2.5rem;
            padding-top: 2.5rem
        }
    }

    @media (max-width:767.98px) {
        .section-investment table {
            width: 100%
        }
    }

    @media (max-width:767.98px) {
        .section-investment table tr {
            display: flex;
            flex-direction: column;
            margin-bottom: 25px
        }
    }

    @media (max-width:767.98px) {
        .section-investment table tr td {
            text-align: left
        }
    }

    .section-investment .citation-align {
        border-left: 1px solid #000066
    }

    @media (max-width:1399.98px) {
        .section-investment .citation-align {
            border-left: 0
        }
    }

    .section-investment .platform-title {
        align-items: center;
        border-bottom: 1px solid #000;
        display: flex;
        font-size: 21px;
        font-weight: 700;
        height: 140px;
        line-height: 30px;
        width: auto
    }

    @media (max-width:767.98px) {
        .section-investment .platform-title {
            border-bottom: 0;
            height: auto
        }
    }

    .section-investment .platform-title p {
        margin-bottom: 0;
        padding-right: 10px;
        width: auto
    }

    @media (max-width:767.98px) {
        .section-investment .platform-title p {
            padding-right: 0;
            width: 100%
        }
    }

    .section-investment .platform-paragraph {
        padding-left: 5vw
    }

    @media (max-width:767.98px) {
        .section-investment .platform-paragraph {
            padding-left: 0
        }
    }

    .section-investment .platform-paragraph p {
        margin-bottom: 0;
        width: 250px
    }

    @media (max-width:767.98px) {
        .section-investment .platform-paragraph p {
            width: auto
        }
    }

    //END
    .section-title {
        text-align: center;
        padding: 15vh 0vw;
    }

    .section-title h2 {
        color: #000066;
        text-transform: uppercase;
        font-size: 4rem;
        font-weight: 300;
    }

    .section-cta {
        text-align: center;
        padding: 10vh 10vw 5vh 10vw;
    }

    .section-cta button {
        background: #000066;
        border: 1px solid #000066;
        height: 8vh;
        width: 16vw;
        border-radius: 2px;
        margin: 1vw;
        margin-left: 0;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.05vw;
        transition: 500ms !important;
    }

    .section-cta button:hover {
        background: #000066;
        border: 1px solid #000066;
        height: 8vh;
        width: 16vw;
        border-radius: 2px;
        margin: 1vw;
        margin-left: 0;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.05vw;
        transition: 500ms !important;
    }

    .section-full-primary {
        background-color: #fff;
        padding-bottom: 10vh;
        padding-top: 20vh;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-full-primary.no-carousel img {
        height: auto;
        width: 100%
    }

    .section-full-primary.no-carousel .job-image {
        background-position: 50% 50%;
        background-size: cover;
        height: 275px;
        width: 100%
    }

    .section-full-primary.jobs-carousel .red-card {
        height: 275px
    }

    .section-full-primary .row h2 {
        font-size: 1rem;
        color: #000066;
        text-transform: uppercase;
        font-weight: 400;
    }

    @media (max-width:1399.98px) {
        .section-full-primary .row {
            margin-left: -5px;
            margin-right: -5px
        }
    }

    .section-full-primary .row [class*=col] {
        margin-bottom: 25px
    }

    @media (max-width:1399.98px) {
        .section-full-primary .row [class*=col] {
            padding-left: 5px;
            padding-right: 5px
        }
    }

    .section-full-primary .subtitle-wrapper {
        padding-bottom: .5rem
    }

    .section-full-primary .subtitle-wrapper h2 {
        margin-bottom: 0
    }

    @media (max-width:575.98px) {
        .section-full-primary .subtitle-wrapper h2 {
            margin-bottom: .5rem
        }
    }

    .section-full-primary .job-title h3 {
        font-size: 1.75rem;
        font-weight: 800;
        margin-bottom: .25rem
    }

    .section-full-primary .job-description p {
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.25
    }

    .section-team-primary {
        background-color: #fff;
        padding-bottom: 10vh;
        padding-top: 20vh;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .team-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: flex-start;
        padding: 5vh 0vw;
    }

    .team-list-line {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        padding: 5vh 0vw 5vh 0vw;
    }

    .team-list-line .card {
        width: 80vw;
        margin: 0;
        height: 100px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: stretch;
        border: none;
    }

    .team-list-line .card .team-name {
        width: 150px;
        text-align: left;
    }

    .team-list-line .card .card-title {
        width: 400px;
        text-align: center;
    }

    .team-list-line .card .card-text {
        width: 150px;
        text-align: left;
    }

    .team-list-line .card .card-description {
        text-align: center;
        height: auto;
        background: #fff;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: baseline;
        padding-top: 1vh;
    }

    .team-list-line .card .card-description a {
        color: #000;
    }

    .team-list-line .card .card-description a:hover {
        color: #000066;
        cursor: pointer;
    }

    .team-list .card {
        width: 250px;
        margin: 0vh 1vw 9vh 1vw;
        height: 550px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: stretch;
        border: none;
    }

    .team-img {
        height: 350px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    .team-img img {
        flex-shrink: 0;
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
    }

    .team-list .card .card-description {
        text-align: center;
        height: 160px;
        background: #fff;
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        padding-top: 1vh;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
    }

    .team-list .card .card-inquiry {
        height: 6vh;
    }

    .team-name {
        font-weight: 700;
        color: #000;
        font-size: 1rem;
        text-align: center;
        border-top: none !important;
    }

    .team-filter {
        width: 100vw;
        padding-top: 10vh;
        display: flex;
        position: relative;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;

    }

    .team-filter .inner {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: baseline;
        width: 50vw;
    }

    .team-filter .inner p {
        color: #000066;
        font: 1rem;
    }

    .section-team-primary.no-carousel img {
        height: auto;
        width: 100%
    }

    .section-team-primary.no-carousel .job-image {
        background-position: 50% 50%;
        background-size: cover;
        height: 275px;
        width: 100%
    }

    .section-team-primary.jobs-carousel .red-card {
        height: 275px
    }

    @media (max-width:1399.98px) {
        .section-team-primary .row {
            margin-left: -5px;
            margin-right: -5px
        }
    }

    .section-team-primary .row [class*=col] {
        margin-bottom: 25px
    }

    @media (max-width:1399.98px) {
        .section-team-primary .row [class*=col] {
            padding-left: 5px;
            padding-right: 5px
        }
    }

    .section-team-primary .subtitle-wrapper {
        padding-bottom: .5rem
    }

    .section-team-primary .subtitle-wrapper h2 {
        margin-bottom: 0
    }

    @media (max-width:575.98px) {
        .section-team-primary .subtitle-wrapper h2 {
            margin-bottom: .5rem
        }
    }

    .section-team-primary .job-title h3 {
        font-size: 1.75rem;
        font-weight: 800;
        margin-bottom: .25rem
    }

    .section-team-primary .job-description p {
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.25
    }

    .section-search-simple .row {
        height: 100%;
    }

    .section-search-simple .row .col-6 {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-search-simple .row .col-6 .citation-align {
        align-items: flex-end;
        padding: 0;

    }

    .section-search-simple .row .col-6 .citation-align .citation-wrapper {
        align-items: flex-end;

        padding-right: 10vw;
        padding-bottom: 0;
    }

    .search-text {
        color: #fff;
        font-size: 2rem;
        text-align: right;
        line-height: 1.5;

    }


    .sign-up-btn-light {
        background: #fff;
        color: #000066;
        border: none;
        border-radius: 1px;
        width: 21vw;
        font-size: 1rem;
        line-height: 2;
        margin-top: 5vh;
        text-transform: uppercase;
        font-weight: 300;
    }

    .sign-up-btn-light:hover {
        background: #000066;
        color: #fff;
        border: none;
        border-radius: 1px;
        width: 21vw;
        font-size: 1rem;
        line-height: 2;
        margin-top: 5vh;
        text-transform: uppercase;
        font-weight: 300;
    }

    @media (max-width:991.98px) {
        .section-search-simple {
            padding-bottom: 2.5rem;
            padding-top: 2.5rem
        }
    }

    @media (max-width:767.98px) {
        .section-search-simple img {
            margin-bottom: 2rem
        }
    }

    .section-image-double {
        padding-bottom: 50px;
        padding-top: 5rem
    }

    @media (max-width:991.98px) {
        .section-image-double {
            padding-bottom: 2.5rem;
            padding-top: 20px
        }
    }

    @media (max-width:991.98px) {
        .section-image-double img {
            margin-bottom: 20px
        }
    }

    .section-image-double .flex-wrapper {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center
    }

    @media (max-width:1199.98px) {
        .section-image-double .flex-wrapper {
            align-items: flex-start
        }
    }

    .section-image-double .flex-wrapper .citation {
        border-color: #000066;
        border-style: solid;
        border-width: 1px 0;
        color: #000;
        padding: 1.5rem 0 1.5rem;
        width: 400px
    }

    @media (max-width:767.98px) {
        .section-image-double .flex-wrapper .citation {
            margin-top: 25px
        }
    }

    @media (max-width:1199.98px) {
        .section-image-double .flex-wrapper .citation {
            padding: 10px 0;
            width: 100%
        }
    }

    .section-image-double .flex-wrapper .citation p {
        font-family: Gilroy;
        font-size: 26px;
        font-weight: 800;
        line-height: 32px;
        margin-bottom: 2rem
    }

    @media (max-width:1599.98px) {
        .section-image-double .flex-wrapper .citation p {
            font-size: 20px;
            line-height: 24px
        }
    }

    @media (max-width:1199.98px) {
        .section-image-double .flex-wrapper .citation p {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 1rem
        }
    }

    @media (max-width:991.98px) {
        .section-image-double .flex-wrapper .citation p {
            font-size: 18px;
            line-height: 22px
        }
    }

    .section-image-double .flex-wrapper .citation span {
        display: block;
        line-height: 1.35
    }

    .section-image-double .flex-wrapper .citation span.author {
        font-weight: 600
    }

    @media (max-width:991.98px) {

        .section-image-double.section-image-4 [class*=col]:nth-child(1) img,
        .section-image-double.section-image-4 [class*=col]:nth-child(2) img {
            margin-bottom: 25px
        }
    }

    @media (max-width:991.98px) {

        .section-image-double.section-image-4 [class*=col]:nth-child(1) img,
        .section-image-double.section-image-4 [class*=col]:nth-child(2) img,
        .section-image-double.section-image-4 [class*=col]:nth-child(3) img {
            margin-bottom: 25px
        }
    }

    .section-gallery .gallery-wrapper {
        padding-bottom: 25px
    }

    .section-gallery .gallery-wrapper .row {
        margin-left: -10px;
        margin-right: -10px
    }

    .section-gallery .gallery-wrapper .row [class*=col] {
        margin: 0 0 10px;
        padding-left: 10px;
        padding-right: 10px;
        height: 300px;
    }

    .section-gallery .gallery-image-wrapper {
        overflow: hidden;
        position: relative;
        height: 250px;
    }

    .section-gallery .gallery-image-wrapper:hover .image-effect {
        left: 0
    }

    .section-gallery .gallery-image-wrapper:hover .image-effect [class*=image] {
        opacity: 1
    }

    .section-gallery .gallery-dots {
        margin: 2.5rem auto;
        text-align: center
    }

    .section-gallery .gallery-dots .btn {
        align-items: center;
        cursor: default;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        opacity: 0;
        padding: 5px
    }

    .section-gallery .image-effect {
        background-color: #000066;
        bottom: 0;
        cursor: pointer;
        font-family: Gilroy;
        left: -100%;
        position: absolute;
        top: 0;
        transition: left .25s ease;
        width: 100%
    }

    .section-gallery .image-effect p {
        font-size: 1rem;
        line-height: 1.25;
        margin: 0
    }

    .section-gallery .image-effect [class*=image] {
        color: #fff;
        opacity: 0;
        transition: opacity .25s ease;
        transition-delay: .15s
    }

    .section-gallery .image-effect .image-text {
        font-size: 1.25rem;
        left: 15px;
        position: absolute;
        right: 15px;
        top: 15px
    }

    .section-gallery .image-effect .image-text h3 {
        font-weight: 800;
        margin-bottom: .25rem
    }

    .section-gallery .image-effect .image-text .image-description {
        font-weight: 300;
        max-width: 60%
    }

    .section-gallery .image-effect .image-infos {
        bottom: 15px;
        font-family: Helvetica, Arial;
        font-size: 1.25rem;
        position: absolute;
        right: 15px;
        text-orientation: sideways-right;
        transform: rotate(180deg);
        writing-mode: vertical-rl
    }

    .section-gallery .image-effect .image-infos .image-locality {
        font-size: 14px;
        font-weight: 300;
        margin-left: .25rem
    }

    .section-gallery .image-effect .image-infos .image-date {
        font-size: 14px;
        font-weight: 800
    }

    .section-gallery .image-effect .image-arrow {
        bottom: 15px;
        font-size: 1.25rem;
        left: 15px;
        position: absolute
    }

    .section-gallery .image-effect .image-arrow:hover .icon-wrapper {
        background-color: #fff;
        color: #000066
    }

    .section-gallery .image-effect .image-arrow .icon-wrapper {
        align-items: center;
        border-radius: 50%;
        display: flex;
        height: 35px;
        justify-content: center;
        width: 35px
    }

    .section-carousel {
        background-color: #fff;
        padding-bottom: 0;
        padding-top: 5rem
    }

    @media (max-width:991.98px) {
        .section-carousel {
            padding-bottom: 20px;
            padding-top: 0
        }
    }

    .section-carousel .owl-carousel .owl-stage {
        display: -webkit-box !important;
        display: -moz-box !important;
        display: -ms-box !important;
        display: box !important
    }

    .section-carousel .owl-carousel .owl-item img {
        display: block;
        height: 400px;
        margin: 0 auto;
        max-height: 400px;
        width: auto
    }

    .section-share {
        background-color: #fff;
        padding-bottom: 0;
        padding: 10vh;
    }

    .section-share .flex-wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center
    }

    .section-share .flex-wrapper .text-wrapper {
        letter-spacing: 1px;
        margin-bottom: .75rem
    }

    .section-share .flex-wrapper .icons-wrapper a {
        color: #343a40;
        margin: 0 .5rem;
        text-decoration: none
    }

    .section-share .flex-wrapper .icons-wrapper a:hover {
        color: #000066
    }

    .section-about .intro {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        padding: 20vh 10vw;
    }

    .section-about .intro .inner_L {
        width: 55%;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-about .intro .inner_L .txt {
        white-space: nowrap;
        text-align: center;
        border: none;
    }

    .section-about .intro .inner_L .txt h2 {
        padding: 0;
        text-transform: uppercase;
    }

    .section-about .intro .inner_R {
        width: 45%;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
    }

    .section-about .intro .inner_R .txt {
        padding: 0;
        text-align: center;
    }

    .section-about .row .col-6 {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    .section-about .row .col-6 .img-wrapper {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: center;
    }

    .about {
        height: auto;
        width: 100vw;
        padding: 20vh 10vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
    }

    .section-team .intro {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        padding: 20vh 10vw;
    }

    .section-team .intro .inner_L {
        width: 55%;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-team .intro .inner_L .txt {
        white-space: nowrap;
        text-align: center;
        border: none;
    }

    .section-team .intro .inner_L .txt h2 {
        padding: 0;
        text-transform: uppercase;
    }

    .section-team .intro .inner_R {
        width: 45%;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
    }

    .section-team .intro .inner_R .txt {
        padding: 0;
        text-align: justify;
    }

    .section-team .intro .inner_R .txt p {
        text-align: justify;
        font-size: 1rem;
    }

    .section-team .row .col-6 {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    .section-team .row .col-6 .img-wrapper {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: center;
    }

    .section-mission {
        height: auto;
        width: 100vw;
        padding: 10vh 10vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        background: linear-gradient(rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), url(../images/services-overlay_bg.jpg) no-repeat center center;
        background-size: cover;
    }

    .section-mission .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .mission-row {
        text-align: center;
        display: flex;
        width: 80vw;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        padding: 5vh 0vw;
    }

    .mission-row .col-3 {
        padding: 2vh 5vw 2vh 0vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: flex-start;
        height: auto;
        width: 100%;
    }

    .mission-row .col-3 p {
        text-align: justify;
        text-align-last: left;
        padding-top: 2vh;
    }

    .section-mission h2 {
        font-size: 4rem;
        color: #000066;
        text-transform: uppercase;
        font-weight: 300;
    }

    .section-mission .row .col-12 {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
        padding: 0;
    }

    .section-mission .col-3 hr {
        height: 5px;
        width: 25%;
        color: #000066;
        opacity: 1 !important;
    }

    .section-mission .dropcap {
        color: #57a6dc;
        font-weight: 600;
        font-size: 5rem;
        line-height: 0.5
    }

    .mission-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        width: 80vw;
    }

    .mission-list {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-end;
        width: 60vw;
        padding-bottom: 10vh;
    }

    .mission-list hr {
        color: #000066;
        opacity: 1;
        height: 1px;
        width: 80%;
        text-align: right;
        margin: 2vh 0vw;
    }

    .mission-list .row {
        width: 90vw;
        height: 25vh;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
        align-content: flex-start;
    }

    .mission-list .row .col-3 {
        display: inline-flex;
        text-align: left;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    .mission-list .row h4 {
        transform: rotate(-90deg);
        font-size: 2rem;
        font-weight: 600;
        color: #000;
        display: inline-flex;
        flex-direction: column;
        text-align: center;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .mission-list .row h4 span {
        font-size: 1.5rem;
        font-weight: 600;
        color: #9a9a9a;
    }

    .mission-list .row .col-6 p {
        font-weight: 600;
        color: #1d2054;
        font-size: 1.75rem;
        line-height: 1.75;
    }

    .mission-header h3 {
        font-size: 4rem;
        font-weight: 600;
        color: #000;
        text-transform: uppercase;
    }

    .stats-banner {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        height: 125vh;
        background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(../images/gfi_stats.jpg) no-repeat center center;
        background-size: cover;
        background-attachment: fixed;
        z-index: auto !important;
    }

    .stats-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-top: 5vh;
    }

    .stats-header h3 {
        font-size: 4rem;
        font-weight: 400;
        color: #fff;
        text-transform: uppercase;
        line-height: 1;
        white-space: nowrap;
        transform: scaleY(1.1) !important;
    }

    .inner-stats {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-bottom: 5vh;
    }

    .stats-banner hr {
        width: 10vw;
        color: #fff;
        height: 1px;
        opacity: 1;
    }

    .stats-col-R {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
        width: 30vw;
        padding-left: 2vw;
        text-align: center;
    }

    .stats-col-R .col-4 {
        height: 20vh;
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: flex-start;
    }

    .stats-banner h2 {
        font-size: 2rem;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 900;
    }

    .stats-col-R h3 {
        color: #fff;
    }

    .stats-col-R h4 {
        font-size: 2rem;
        text-transform: uppercase;
        color: #fff;
        font-weight: 600;
        text-align: left;
        display: flex;
        flex-direction: column;
    }

    .stats-col-R h4 span {
        font-weight: 400;
        font-size: 1.5rem;
        white-space: nowrap;
    }

    .stats-col-L {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
        width: 30vw;
        text-align: center;
    }

    .stats-col-L .col-4 {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: flex-end;
        height: 20vh;
    }

    .stats-col-L h3 {
        color: #fff;
    }

    .stats-col-L h4 {
        font-size: 3rem;
        text-transform: uppercase;
        color: #fff;
        font-weight: 600;
        text-align: left;
        display: flex;
        flex-direction: column;
    }

    .stats-col-L h4 span {
        font-weight: 400;
        font-size: 2rem;
    }

    .email-banner {
        width: 100vw;
        height: auto;
        background: url(../images/cta.jpg) no-repeat center center;
        background-size: cover;
        background-attachment: fixed;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding: 10vh 0vw;
    }

    .email-banner .inner {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;

    }

    .email-banner .inner p {
        color: #fff;
        font-weight: 600;
        font-size: 2rem;
        text-transform: uppercase;
        transform: scaleY(1.1) !important;
        text-align: center;
        padding-bottom: 5vh;
    }

    .services {
        height: auto;
        width: 100vw;
        padding: 20vh 10vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        background: #fff;
        padding: 10vh 10vw;


    }

    .services-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-content: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        text-align: left;
    }

    .services-title h3 {
        font-size: 3rem;
        font-weight: 600;
        text-transform: uppercase;
        color: #ffffff;
        display: flex;
        flex-direction: column;

    }

    .services-title span {
        color: #f1f1f1;
        font-weight: 400;
        font-size: 2rem;
        white-space: nowrap;

    }

    .mission-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-content: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        text-align: left;
    }

    .mission-title h3 {
        font-size: 2rem;
        font-weight: 600;
        text-transform: uppercase;
        color: #000066;
        display: flex;
        flex-direction: column;

    }

    .mission-title span {
        color: #939393;
        font-weight: 400;
        font-size: 1.5rem;
        white-space: nowrap;

    }

    .services .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        width: 100vw;
        padding: 10vh 5vw 10vh 7vw;
        text-align: left;

    }

    .services .row .col-12 {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        text-align: right;
        padding: 0;
    }

    .services h2 {
        font-size: 4rem;
        color: #000066;
        text-transform: uppercase;
        font-weight: 300;

    }

    .services-row {
        text-align: center;
        display: flex;
        width: 90vw;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        padding: 5vh 0vw 10vh 0vw;
    }

    .services-row .col-4 {
        margin: 2vh 2.5vw 2vh 2.5vw;
        padding: 5vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: flex-start;
        height: 315px;
        background: #000066;
        background-size: cover;
        width: 95%;
    }

    .services-row .col-4 span {
        width: 225px;
        text-align: left;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: baseline;
        transition: 500ms ease;
        transform: translateX(0vw);
    }

    .services-row .col-4 span:hover {
        transition: 500ms ease;
        transform: translateX(1vw)
    }

    .services-row .col-4 span i {
        position: relative;
        padding: 0vh 50% 0vh 0;
        color: #ffff;
    }

    .services-row h3 {
        font-size: 2rem;
        text-transform: uppercase;
        text-align: left;
        line-height: 1;
        color: #fff;
    }

    .services-row .col-4 p {
        text-align: justify;
        text-align-last: left;
        padding-top: 2vh;
        color: #fff;
        hyphens: auto;
        -webkit-hyphens: auto;
    }

    .services-btn {
        border: none;
        border-radius: 2vw;
        width: auto;
        background: transparent;
        color: #fff;
        font-size: 1rem;
        transition: 500ms ease;
        padding: 0;
        text-align: left;
        white-space: nowrap;
    }

    .services-btn:hover {
        width: auto;
        background: transparent;
        color: #f1f1f1;
        font-size: 1rem;
        padding: 0;
        transition: 500ms ease;
        cursor: pointer;
        white-space: nowrap;
    }

    .services-row hr {
        color: #fff;
        height: 1px;
        width: 5vw;
    }

    @media (max-width:991.98px) {
        .section-about {
            padding-bottom: 2.5rem
        }
    }

    .section-about h2 {
        align-items: flex-end;
        color: #000;
        display: flex;
        font-size: 1.5rem;
        font-weight: 400;
        height: 60px;
        line-height: 1;
        margin-bottom: 0;
        padding-bottom: 10px
    }

    .section-about [class*=col] {
        margin-bottom: 75px
    }

    @media (max-width:991.98px) {
        .section-about [class*=col] {
            margin-bottom: 25px
        }
    }

    .section-about [class*=col].related {
        margin-bottom: 0
    }

    @media (max-width:991.98px) {
        .section-about [class*=col].related {
            margin-bottom: 50px
        }
    }

    .section-about [class*=col]:nth-child(1) {
        order: 1
    }

    @media (max-width:991.98px) {
        .section-about [class*=col]:nth-child(1) {
            order: 1
        }
    }

    .section-about [class*=col]:nth-child(2) {
        order: 2
    }

    @media (max-width:991.98px) {
        .section-about [class*=col]:nth-child(2) {
            order: 3
        }
    }

    .section-about [class*=col]:nth-child(3) {
        order: 3
    }

    @media (max-width:991.98px) {
        .section-about [class*=col]:nth-child(3) {
            order: 2
        }
    }

    .section-about [class*=col]:nth-child(4) {
        order: 4
    }

    @media (max-width:991.98px) {
        .section-about [class*=col]:nth-child(4) {
            margin-bottom: 0;
            order: 4
        }
    }

    .section-about .v-line {
        bottom: 5rem;
        top: 60px
    }

    .section-about .img-wrapper {
        margin-bottom: 50px
    }

    @media (max-width:991.98px) {
        .section-about .img-wrapper {
            margin-bottom: 15px
        }
    }

    .section-about .nav-pills {
        margin-bottom: 4rem
    }

    @media (max-width:991.98px) {
        .section-about .nav-pills {
            margin-bottom: 1.5rem
        }
    }

    .section-about .nav-pills .nav-link {
        border-width: 2px 0 1px
    }

    .section-about .tab-content {
        height: auto
    }

    .paragraph-align {
        text-align: justify;
        hyphens: auto;
        -webkit-hyphens: auto;
    }

    .section-about .outline-big-text {
        font-size: 35px
    }

    .section-about .owl-nav {
        bottom: 0;
        color: red;
        left: 0;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0
    }

    .section-about .owl-nav .owl-prev {
        left: -25px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%)
    }

    .section-about .owl-nav .owl-next {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%)
    }

    .section-partners {
        margin-bottom: 2rem
    }

    .section-partners .partners-image {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 30%;
        height: 50vh;
        min-height: 300px;
        margin-right: 1rem
    }

    @media (max-width:991.98px) {
        .section-partners .partners-image {
            height: 40vh;
            min-height: auto;
            margin-right: 0
        }
    }

    .section-partners .partners {
        display: flex;
        flex-direction: column;
        height: 50vh;
        min-height: 300px;
        justify-content: flex-start;
        align-items: stretch;
        margin-left: 1rem;
        overflow: auto;
        max-width: 600px
    }

    @media (max-width:991.98px) {
        .section-partners .partners {
            height: auto;
            min-height: auto;
            margin-left: 0;
            margin-top: 1rem
        }
    }

    .section-partners .partners .partner {
        margin-top: 1.5rem
    }

    @media (min-width:1600px) {
        .section-partners .partners .partner {
            margin-top: 2.5rem
        }
    }

    @media (min-width:1921px) {
        .section-partners .partners .partner {
            margin-top: 3.5rem
        }
    }

    .section-partners .partners .partner:first-child {
        margin-top: 0
    }

    .section-partners .partners .partner .name {
        line-height: 26px;
        font-size: 14px;
        font-weight: 700;
        margin: 0
    }

    .section-partners .partners .partner .function {
        color: #000066
    }

    .section-partners .partners .partner .function::after {
        color: #000;
        content: " | ";
        display: inline;
        margin: 0 .25rem;
        vertical-align: middle
    }

    .section-partners .partners .partner .email {
        color: #000
    }

    .section-partners .partners .partner .description {
        margin-top: .5rem;
        line-height: 26px
    }

    .section-teams .outline-big-text {
        margin-bottom: 1.5rem
    }

    .section-teams .team-carousel {
        position: relative
    }

    .section-teams .team-carousel .member {
        padding: 20px
    }

    .section-teams .team-carousel .member img {
        border-color: rgba(255, 255, 255, 0);
        border-style: solid;
        border-width: 1px 0
    }

    .section-teams .team-carousel .member .member-infos {
        align-items: center;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        opacity: 0;
        padding-top: 1rem
    }

    .section-teams .team-carousel .member .member-infos .function,
    .section-teams .team-carousel .member .member-infos .name,
    .section-teams .team-carousel .member .member-infos .role {
        line-height: 26px
    }

    .section-teams .team-carousel .member .member-infos .name {
        color: #000066;
        font-weight: 700
    }

    .section-teams .team-carousel .member .member-infos .role {
        font-weight: 400
    }

    .section-teams .team-carousel .member .member-infos .function {
        color: #000066
    }

    .section-teams .team-carousel .member .member-infos .email a {
        color: #000
    }

    .section-teams .team-carousel .member:hover img {
        border-color: #000066
    }

    .section-teams .team-carousel .member:hover .member-infos {
        opacity: 1
    }

    .section-teams .team-carousel .owl-nav {
        color: #000066
    }

    .section-teams .team-carousel .owl-nav svg {
        height: auto;
        width: 25px
    }

    .section-teams .team-carousel .owl-nav .owl-next,
    .section-teams .team-carousel .owl-nav .owl-prev {
        align-items: center;
        background-color: #fff;
        border-radius: 50%;
        display: flex;
        height: 40px;
        justify-content: center;
        position: absolute;
        top: calc(50% - 75px);
        width: 40px
    }

    .section-teams .team-carousel .owl-nav .owl-next:active,
    .section-teams .team-carousel .owl-nav .owl-next:focus,
    .section-teams .team-carousel .owl-nav .owl-prev:active,
    .section-teams .team-carousel .owl-nav .owl-prev:focus {
        outline: 0
    }

    .section-teams .team-carousel .owl-nav .owl-next:hover,
    .section-teams .team-carousel .owl-nav .owl-prev:hover {
        background-color: #000066;
        color: #fff
    }

    .section-teams .team-carousel .owl-nav .owl-prev {
        left: 50px;
        transform: translateX(100%) translateY(-50%)
    }

    @media (max-width:991.98px) {
        .section-teams .team-carousel .owl-nav .owl-prev {
            left: 0
        }
    }

    .section-teams .team-carousel .owl-nav .owl-next {
        right: 50px;
        transform: translateX(-100%) translateY(-50%)
    }

    @media (max-width:991.98px) {
        .section-teams .team-carousel .owl-nav .owl-next {
            right: 0
        }
    }

    .section-worked-with-us {
        padding-bottom: 5rem;
        padding-top: 5rem
    }

    @media (max-width:991.98px) {
        .section-worked-with-us {
            padding-bottom: 2.5rem;
            padding-top: 2.5rem
        }
    }

    .section-worked-with-us p {
        line-height: 2;
        margin-bottom: 0;
        padding-right: 0;
        text-align: justify
    }

    @media (max-width:575.98px) {
        .section-worked-with-us p {
            padding-right: 0
        }
    }

    @media (max-width:767.98px) {
        .section-worked-with-us p {
            padding-right: 0
        }
    }

    .section-worked-with-us p span {
        color: #000066;
        padding: 0 .25rem
    }

    .section-contact {
        padding-bottom: 5rem;
        padding-top: 5rem
    }

    @media (max-width:991.98px) {
        .section-contact {
            padding-bottom: 2.5rem;
            padding-top: 0
        }
    }

    .section-contact a {
        color: #343a40
    }

    .section-contact a:hover {
        color: #000066
    }

    .section-contact iframe {
        height: 400px;
        width: 100%
    }

    .section-contact .flex-wrapper {
        display: flex
    }

    @media (max-width:991.98px) {
        .section-contact .flex-wrapper {
            justify-content: flex-start
        }
    }

    .section-contact .fa-ul {
        margin-left: 3rem
    }

    .section-contact .fa-ul li {
        margin-bottom: 2rem
    }

    .section-contact .fa-ul li .fa-li {
        color: #ff6a6a;
        font-size: 1.5rem;
        left: -2.5em;
        margin-top: .5rem
    }

    .section-contact .fa-ul p {
        line-height: 1.75;
        padding-left: 1rem;
        padding-top: .5rem
    }

    .section-contact .fa-ul p svg {
        margin-left: .5rem
    }

    .section-contact .follow-us {
        font-size: 1rem
    }

    .section-contact .follow-us span {
        display: block;
        margin-bottom: .25rem
    }

    .section-contact .follow-us a {
        margin-right: .5rem
    }

    .section-news {
        padding-bottom: 10vh;
        padding-top: 20vh;
    }

    @media (max-width:991.98px) {
        .section-news {
            padding-bottom: 2.5rem
        }
    }

    .section-news .news-wrapper a {
        text-decoration: none
    }

    .section-news .news-wrapper .row {
        margin-left: -10px;
        margin-right: -10px
    }

    .section-news .news-wrapper .row > [class*=col] {
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 5vh;
        padding-top: 5vh;

    }

    .section-news .news-wrapper .news-item::before {
        display: none
    }

    .section-news .news-wrapper .news-item::after {
        display: none
    }

    .section-news .news-wrapper .news-item .img-effect a {
        display: block
    }

    .section-news .news-wrapper .news-title {
        color: #000;
        display: block;
        margin-top: 1rem
    }

    .section-news .news-wrapper .news-title h3 {
        color: #343a40;
        font-size: 16px;
        font-weight: 800;
        letter-spacing: .5px;
        margin-bottom: 3px
    }

    .section-news .news-wrapper .news-infos {
        color: #343a40;
        font-size: 13px
    }

    .section-news .news-wrapper .news-category {
        color: #343a40;
        font-weight: 600;
        padding-right: .25rem
    }

    .section-news .news-wrapper .news-date {
        padding-left: .25rem
    }

    .section-news .news-pagination {
        margin: 2.5rem auto;
        text-align: center
    }

    .section-news .news-pagination ul {
        justify-content: center
    }

    .section-news .news-pagination ul li a {
        align-items: center;
        background: rgba(255, 255, 255, 0);
        border: 1px solid #000066;
        border-radius: 50%;
        display: flex;
        height: 30px;
        justify-content: center;
        margin: 0 3px;
        padding-top: 4px;
        width: 30px
    }

    .section-news .news-pagination ul li a:hover {
        background-color: #000066;
        color: #fff
    }

    .section-news .news-pagination ul li.active a {
        background-color: #000066;
        color: #fff
    }

    .section-news .news-content p {
        line-height: 1.75
    }

    .section-news .news-content p:last-child {
        margin-bottom: 0
    }

    .section-news .news-author {
        border-color: #000066;
        border-style: solid;
        border-width: 1px 0;
        margin-top: 3rem;
        padding: 1rem 0
    }

    .section-news .news-author p {
        margin: 0
    }

    .section-news .news-author .name {
        font-weight: 600
    }

    .section-news .news-share {
        color: #343a40;
        text-align: center
    }

    .section-news .news-share p {
        font-size: 1rem;
        letter-spacing: 1px;
        margin-bottom: .25rem
    }

    .section-news .news-share .icons-wrapper a {
        color: #343a40;
        margin: 0 .5rem
    }

    .section-news .news-share .icons-wrapper a:hover {
        color: #000066
    }

    .section-introduction {
        padding-bottom: 5rem;
        padding-top: 5rem
    }

    @media (max-width:991.98px) {
        .section-introduction {
            padding-bottom: 1.5rem;
            padding-top: 1.5rem
        }
    }

    .section-bio {
        padding-top: 25vh;
        height: 100vh;
    }

    .section-bio .row {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .section-bio .row .col-4 {
        display: inline-flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    .bio-content {
        width: 90vw;
    }

    @media (max-width:991.98px) {
        .section-bio {
            padding-bottom: 1.5rem;
            padding-top: 1.5rem
        }
    }

    @media (max-width:1199.98px) {
        .section-bio {
            margin-bottom: 2.5rem
        }
    }

    .bio-name {
        color: #000066;
        font-size: 2rem;
        font-weight: 200;
        padding-left: 2vw;
    }

    .bio-title {
        color: rgba(60, 60, 60, 0.6392156863);
        font-size: 1.15rem;
        padding-left: 2vw;
    }

    .bio-img {
        padding-bottom: 2vh;
    }

    .bio-img img {
        height: 25vh;
        width: auto;
    }

    .bio-contact {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        padding-left: 5vw;

    }

    .bio-contact-line {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: stretch;
    }

    .bio-contact-line i {
        padding-right: 1vw;
    }

    .bio-contact-line a {
        color: #000;
        text-decoration: none;
    }

    .section-job {
        margin-bottom: 5rem
    }

    @media (max-width:991.98px) {
        .section-job {
            padding-bottom: 1.5rem;
            padding-top: 1.5rem
        }
    }

    @media (max-width:1199.98px) {
        .section-job {
            margin-bottom: 2.5rem
        }
    }

    .section-job.section-top-page {
        margin-bottom: 0
    }

    .section-job.section-top-page .flex-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%
    }

    .section-job.section-top-page .times-wrapper {
        align-items: flex-start;
        color: #000066;
        display: flex;
        font-size: 1.5rem;
        opacity: .85;
        padding-top: .25rem;
        transition: opacity .25s ease
    }

    .section-job.section-top-page .times-wrapper:hover {
        color: #000066;
        opacity: 1
    }

    .section-job.section-full-primary {
        margin-bottom: 5rem
    }

    .section-job .job-header {
        display: flex;
        flex-direction: column;
        margin-top: -.25rem
    }

    @media (max-width:575.98px) {
        .section-job .job-header {
            padding-left: 50px
        }
    }

    .section-job .job-header .page-title {
        margin-bottom: 1rem
    }

    @media (max-width:575.98px) {
        .section-job .job-header .page-title {
            justify-content: flex-start
        }
    }

    .section-job .job-header .job-title h3 {
        font-size: 1.75rem;
        font-weight: 800;
        margin-bottom: .25rem
    }

    .section-job .job-header .job-description p {
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.25
    }

    .section-job .job-content {
        padding-right: 5rem
    }

    @media (max-width:1399.98px) {
        .section-job .job-content {
            padding-right: 2.5rem
        }
    }

    @media (max-width:991.98px) {
        .section-job .job-content {
            padding-right: 0
        }
    }

    .section-job .job-content h3 {
        color: #000066;
        font-size: .9rem;
        font-weight: 700;
        margin-bottom: 1.5rem;
        text-transform: uppercase
    }

    .section-job .job-content p {
        margin-bottom: 2rem
    }

    @media (max-width:991.98px) {
        .section-job .job-content p {
            text-align: justify
        }
    }

    .section-job .job-content ul {
        margin-bottom: 4rem
    }

    .section-job .job-content ul li {
        margin-bottom: 1rem;
        padding-left: 35px;
        position: relative
    }

    @media (max-width:991.98px) {
        .section-job .job-content ul li {
            text-align: justify
        }
    }

    .section-job .job-content ul li::before {
        background-color: #000066;
        content: '';
        height: 2px;
        left: 0;
        position: absolute;
        top: .75em;
        width: 8px
    }

    .section-job .job-content :last-child {
        margin-bottom: 0
    }

    .section-job .job-contact {
        height: 100%;
        min-height: 400px;
        padding-left: 5rem;
        position: relative
    }

    @media (max-width:1399.98px) {
        .section-job .job-contact {
            padding-left: 2.5rem
        }
    }

    @media (max-width:1199.98px) {
        .section-job .job-contact {
            min-height: auto;
            padding-left: 0;
            padding-top: 2.5rem
        }
    }

    .section-job .job-contact::before {
        background-color: #000066;
        content: '';
        height: 50%;
        left: 0;
        min-height: 400px;
        position: absolute;
        top: 0;
        width: 1px
    }

    @media (max-width:1199.98px) {
        .section-job .job-contact::before {
            display: none
        }
    }

    .section-job .job-contact p {
        margin-bottom: 1.5rem
    }

    .section-job .job-contact p span {
        font-weight: 700
    }

    .section-job .job-contact .btn {
        font-family: Gilroy;
        font-weight: 800
    }

    .section-latest-news {
        display: flex;
        height: auto;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        padding-top: 10vh;
    }

    .section-latest-news .row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
    }

    .section-latest-news .row > [class*=col] {
        padding-left: 10px;
        padding-right: 10px
    }

    .article {
        padding: 10vh 2vw 0vh 2vw;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
    }

    .article-heading {
        padding: 1.5rem 0;
        text-align: center
    }

    .article-heading h1 {
        display: block;
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 0;
        padding: 2.5rem 25px 1.5rem
    }

    .article-heading .tags {
        display: flex;
        justify-content: center;
        margin-top: 2.5rem
    }

    .article-heading .tags li a {
        display: block;
        line-height: 1.25;
        padding: .25rem 1rem 0;
        position: relative
    }

    .article-heading .tags li a::before {
        background-color: #000066;
        bottom: 4px;
        content: '';
        position: absolute;
        right: 0;
        top: 4px;
        width: 1px
    }

    .article-heading .tags li:last-child a::before {
        display: none
    }

    .owl-carousel {
        z-index: 0
    }

    .owl-theme .owl-dots {
        bottom: 5px;
        margin: 0;
        position: absolute;
        right: 15px
    }

    .owl-theme .owl-dots .owl-dot {
        outline: 0
    }

    .owl-theme .owl-dots .owl-dot span {
        background-color: #fff;
        border: 1px solid #000066
    }

    .owl-theme .owl-dots .owl-dot.active span,
    .owl-theme .owl-dots .owl-dot:hover span {
        background-color: #000066;
        border: 1px solid #000066
    }

    .btn {
        border-radius: 0;
        padding: .5rem 1rem
    }

    .btn.big-text {
        font-family: Gilroy;
        font-size: 1.5rem;
        font-weight: 800
    }

    .arrow-wrapper-white .arrow,
    .custom-bounce {
        animation-name: custom-bounce;
        transform-origin: center bottom
    }

    .customFadeInUp {
        animation-name: customFadeInUp
    }

    .customSlideInUp {
        animation-name: customFadeInUp
    }

    @keyframes custom-bounce {

        10.6%,
        16%,
        4%,
        from,
        to {
            animation-timing-function: cubic-bezier(.215, .61, .355, 1);
            transform: translate3d(0, 0, 0)
        }

        8%,
        8.6% {
            animation-timing-function: cubic-bezier(.755, .05, .855, .06);
            transform: translate3d(0, -30px, 0)
        }

        14% {
            animation-timing-function: cubic-bezier(.755, .05, .855, .06);
            transform: translate3d(0, -15px, 0)
        }

        18% {
            transform: translate3d(0, 0, 0)
        }
    }

    @keyframes fade-from-none {
        0% {
            display: none;
            opacity: 0
        }

        1% {
            display: block;
            opacity: 0
        }

        100% {
            display: block;
            opacity: 1
        }
    }

    @keyframes banner-line {
        0% {
            height: 0
        }

        100% {
            height: 100%
        }
    }

    @keyframes banner-arrow {
        0% {
            opacity: 0;
            top: 0;
            transform: translateY(0)
        }

        100% {
            opacity: 1;
            top: 50%;
            transform: translateY(-100%)
        }
    }

    @keyframes customFadeInUp {
        from {
            opacity: 0;
            transform: translate3d(0, 50px, 0);
            visibility: visible
        }

        to {
            opacity: 1;
            transform: translate3d(0, 0, 0)
        }
    }

    @keyframes customSlideInUp {
        from {
            opacity: 0;
            transform: translate3d(0, 25px, 0);
            visibility: visible
        }

        to {
            opacity: 1;
            transform: translate3d(0, 0, 0)
        }
    }

    .admin-container {
        margin-top: -150px;
        padding: 50px
    }

    .admin-container input {
        min-width: 300px
    }

    .admin-container .btn {
        border-radius: 4px;
        line-height: 1;
        padding-top: 10px
    }

    .login-section {
        height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-left: 0;
    }

    .login-inner {
        padding-left: 15vw;
        height: 50vh;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: flex-start;
    }

    .login-img {
        background: url(../images/banner/login.jpg) center center no-repeat;
        height: 100vh;
    }

    .title-row {
        display: inline-flex;
    }

    .contact-section {
        height: 100vh;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding: 20vh 5vw;
    }

    .contact-section .row {
        width: 100%;
    }

    .contact-inner {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: stretch;
        width: 110%;
    }

    .contact-info {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .contact-info-inner {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: flex-start;
        height: 40vh;
    }

    .contact-info-inner .bi {
        margin-right: 2vw;
    }

    .contact-info-inner a {
        color: #000066;
        font-size: 1rem;
        line-height: 1.5;
        text-decoration: none;

    }

    .contact-form {
        height: 40vh;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: stretch;
    }

    .contact-control {
        border: none;
        border-bottom: 1px solid #ced4da;
        border-radius: 0px !important;
    }

    .contact-control:focus {
        border-bottom: 1px solid #000066;
        border-radius: 0px !important;
        box-shadow: none !important;
    }

    .contact-select {
        border: none;
        border-bottom: 1px solid #ced4da;
        border-radius: 0px !important;
    }

    .contact-select:focus {
        border-bottom: 1px solid #000066;
        border-radius: 0px !important;
        box-shadow: none !important;
    }

    //JOIN TEAM FORM
    .join-team-form {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .join-team-form .form-control {
        background: rgba(255, 255, 255, 0.8509803922);
        width: 35vh;
        height: 5vh;
        border-radius: 2px;
    }

    .join-team-form .row {
        padding-bottom: 5vh;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .form-file input[type=file]::-webkit-file-upload-button {
        height: 5vh;

    }

    .form-file input[type=file]::file-selector-button {
        height: 5vh;
    }

    //HOVER UNDERLINE
    .hover-underline {
        position: relative;
    }

    .hover-underline:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        transition: width 0s ease, background .5s ease;
    }

    .hover-underline:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        background: #000066;
        transition: width .5s ease;
    }

    .hover-underline:hover:before {
        width: 100%;
        background: #000066;
        transition: width .5s ease;
    }

    .hover-underline:hover:after {
        width: 100%;
        background: transparent;
        transition: all 0s ease;
    }

    //HOVER UNDERLINE WHITE
    .hover-underline-white {
        position: relative;
    }

    .hover-underline-white:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        transition: width 0s ease, background .5s ease;
    }

    .hover-underline-white:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        background: #f1f1f1;
        transition: width .5s ease;
    }

    .hover-underline-white:hover:before {
        width: 100%;
        background: #f1f1f1;
        transition: width .5s ease;
    }

    .hover-underline-white:hover:after {
        width: 100%;
        background: transparent;
        transition: all 0s ease;
    }

    //HOVER UNDERLINE RED
    .hover-underline-red {
        position: relative;
    }

    .hover-underline-red:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        transition: width 0s ease, background .5s ease;
    }

    .hover-underline-red:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        background: #000066;
        transition: width .5s ease;
    }

    .hover-underline-red:hover:before {
        width: 100%;
        background: #000066;
        transition: width .5s ease;
    }

    .hover-underline-red:hover:after {
        width: 100%;
        background: transparent;
        transition: all 0s ease;
    }

    //ARROW DOWN 
    .arrow-down {
        display: inline-flex;
        position: fixed;
        bottom: 5vh;
        width: 1.5vw;
    }

    //ARROW DOWN HOME PAGE
    .arrow-down-animate {
        animation-name: loop-down;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    @keyframes loop-down {
        0% {
            transform: translateY(0vh);
            opacity: 0
        }

        25% {
            transform: translateY(1vh);
            opacity: 0.5
        }

        50% {
            transform: translateY(2vh);
            opacity: 1
        }

        75% {
            transform: translateY(3vh);
            opacity: 0.5
        }

        100% {
            transform: translateY(4vh);
            opacity: 0
        }
    }

    //COUNTERS
    #counters_1,
    #counters_2,
    #counters_3 {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
        height: 20vh;
        width: 30vw;
    }

    .counter {
        font-size: 5rem;

    }

    .counter-wrapper {
        display: flex;
        height: 10vh;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-end;
    }
    //HOME
    .home-header {
        height: 150vh;
        widows: 100vh;
        object-fit: cover;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        background: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.8)), url(../images/banner/header.jpg) no-repeat center center;
        background-size: cover;
        background-attachment: fixed;
    }

    .first-letter {
        -webkit-initial-letter: 4;
        initial-letter: 4;
        color: orange;
        font-weight: bold;
        margin-right: .75em;
    }

    .intro {
        height: 50vh;
        width: 100vw;
        display: flex;
        z-index: 9500;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        position: absolute;
        padding: 0vh 10vw 5vh 10vw;
        left: 0;
        text-align: left;
        top: 50vh;
    }

    .intro .txt hr {
        width: 85vw;
        height: 2px;
        opacity: 1;
        color: #fff;
    }

    .intro .txt h3 {
        font-size: 4rem;
        font-weight: 400;
        color: #fff;
        text-transform: uppercase;
        line-height: 1;
        white-space: nowrap;
        transform: scaleY(1.1) !important;
    }

    .intro .txt p {
        font-size: 1.15rem;
        font-weight: 400;
        color: #fff;
        line-height: 1.5;
        text-align: justify;
    }

    .dropcap {
        background: transparent;
        color: #FDF9F2;
        float: left;
        font-size: 4.0rem;
        line-height: 1;
        margin: 0.2em 0.1em 0em 0;
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    .dropcap:before,
    .dropcap:after {
        content: "";
        display: block;
    }

    .dropcap:before {
        margin-top: -0.2em;
    }

    .dropcap:after {
        margin-bottom: -0.15em;
    }

    .sr-only:not(:focus):not(:active) {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    .intro-btn {
        background: #fff;
        border: 1px solid #fff;
        height: 5vh;
        width: 25vw;
        border-radius: 2px;
        margin: 1vw;
        margin-left: 0;
        color: #000;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.05vw;
        transition: 500ms !important;
    }

    .intro-btn:hover {
        background: #fff;
        border: 2px solid #fff;
        height: 8vh;
        width: 16vw;
        border-radius: 2px;
        margin: 1vw;
        margin-left: 0;
        color: #000066;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.05vw;
        transition: 500ms !important;
    }

    .video-overlay {
        background: rgba(0, 0, 0, 0.4);
        height: 100vh;
        width: 100vw;
        position: absolute;
        top: 0;
        z-index: 9000;
    }

    .intro_1 {
        height: auto;
        width: 100vw;
        background: #fff;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding: 20vh 10vw;
    }

    .intro_1 h1 {
        color: #000066;
        font-size: 4rem;
        font-weight: 300;
        text-transform: uppercase;
        margin: 0;
        padding-bottom: 2vh;
    }

    .top-img {
        position: absolute;
        width: 15%;
        z-index: 8500;
        right: 5vw;
        bottom: 60vh;
        box-shadow: black;
        -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
        box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
    }

    .mid-img {
        position: absolute;
        right: 7vw;
        bottom: 23vh;
        width: 40%;
        box-shadow: black;
        -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
        box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
    }

    .bot-img {
        position: absolute;
        width: 25%;
        right: 10vw;
        bottom: 20vh;
        box-shadow: black;
        -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
        box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
    }

    .listings-btn {
        background: #fff;
        border: none;
        height: 4vh;
        width: 10vw;
        border-radius: 2px;
        margin: 1vw;
        margin-left: 0;
    }

    //SLIDE OVER SECTIONS
    .info-contain {
        width: 100vw;
        height: 100vh;
        height: 100%;
        height: fill-available;
        transition-duration: 600ms;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.8, 0, 0, 1);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 4;
        background: #fff;
        background-size: cover;
        -ms-transform: translate(100%, 0);
        /* IE 9 */
        -webkit-transform: translate(100%, 0);
        /* Safari */
        transform: translate(100%, 0);
        /* Standard syntax */
        padding-top: 20vh;
        overflow: scroll;
        margin: 0;
    }

    .info-contain.opened {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100vh;
        height: 100%;
        height: fill-available;
        transition-duration: 600ms;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.8, 0, 0, 1);
        -ms-transform: translate(0, 0);
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
        z-index: 9999 !important;
        padding-top: 20vh;
        overflow: scroll;
        margin: 0;
    }

    .overlay-element-innovation {
        width: 100vw;
        display: table;
        float: left;
        height: 100vh;
        color: #fff;
    }

    .overlay-txt {
        display: flex;
        vertical-align: middle;
        text-align: justify;
        text-align-last: left;
        font-size: 16px;
        font-weight: 300;
        padding: 5vh 10vw;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100vh;
        color: #000;
    }

    .overlay-txt h2 {
        font-size: 1.25rem;
        color: #000066;
        text-transform: uppercase;
        font-weight: 900;
    }

    .overlay-txt h3 {
        font-size: 2rem;
        font-weight: 900;
        color: #000;
        text-transform: uppercase;
    }

    .close {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1;
        color: #fff;
        text-shadow: 0 1px 0 rgb(0 0 0 / 50%);
        opacity: 1;
        display: flex;
        width: 15vw;
        position: relative;
        margin-top: 5vh;
        white-space: nowrap;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: stretch;
    }

    .close p {
        font-weight: 400;
        font-size: 0.8rem;
        padding-left: 2vw;
        text-transform: uppercase;
        text-shadow: none;
        color: #000066;
    }

    .close:hover {
        color: #000066;
        text-shadow: 0 1px 0 black;
        transition: all 0.3s;
        cursor: pointer;
        transition: all 0.5s;
        transform: translateX(-1vw);
    }

    .divider {
        width: 50vw;
        margin: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 7vh;
    }

    //PRESS
    .news-img {
        width: auto;
        height: 155px !important;
        transition: 500ms;
    }

    .news-img:hover {
        transition: 500ms;
        transform: scale(1.05)
    }

    .press-img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-top-left-radius: calc(0.25rem - 1px);
        border-top-right-radius: calc(0.25rem - 1px);
    }

    //ANIMATION LOADER//
    #loader-wrapper .loader-section {
        position: fixed;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: 9998;
        text-align: center;
        background: #fff;
    }

    #loader {
        z-index: 9999;
        position: fixed;
        height: 100vh;
        width: 100vw;
        text-align: center;
        background: #fff;
    }

    .loader-logo {
        position: relative;
        top: 3vh;
        height: auto;
        width: auto;
        text-align: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .loader_img_1 {
        animation: loader_1 4s ease;
        opacity: 0;
    }

    .loader_img_2 {
        animation: loader_2 4s ease;
        opacity: 0;
    }

    @keyframes loader_1 {
        0% {
            transform: translate(100vw, 40vh);
            opacity: 0;
        }

        60% {
            transform: translate(0vw, 40vh);
            opacity: 1;
        }

        70% {
            transform: translate(0vw, 40vh);
            opacity: 1;
        }

        100% {
            transform: translate(0vw, 40vh);
            opacity: 0;
        }
    }

    @keyframes loader_2 {
        0% {
            transform: translate(50vw, 40vh);
            opacity: 0;
        }

        40% {
            opacity: 0;
        }

        60% {
            transform: translate(0vw, 40vh);
            opacity: 1;
        }

        70% {
            transform: translate(0vw, 40vh);
            opacity: 1;
        }

        100% {
            transform: translate(0vw, 40vh);
            opacity: 0;
        }
    }

    .loader-text-fade-out {
        opacity: 0;
        animation: fade-text 4s;
        font-weight: 600;
        font-size: 1rem;
    }

    @keyframes fade-text {
        0% {
            opacity: 0;
        }

        30% {
            opacity: 1;
        }

        90% {
            opacity: 1;
        }

        100% {
            opacity: 0;
            visibility: hidden;
        }
    }

    #loader {
        opacity: 0;
        visibility: hidden;
        animation: fade-background 4s;
    }

    @keyframes fade-background {
        0% {
            opacity: 1;
            visibility: visible;
        }

        80% {
            opacity: 1;
            visibility: visible;
        }

        100% {
            opacity: 0;
            visibility: hidden;
        }
    }

    .sign-up-form {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: baseline;
        width: 65vh;

    }

    .sign-up-form .form-control {
        background: #ffffffd9;
        width: 35vh;
        height: 5vh;
        border-radius: 2px;
    }

    .sign-up-form .form-control:focus {
        background: #ffffff;
        width: 35vh;
        height: 5vh;
        border-radius: 10px;
        border-color: #000066
    }

    .sign-up-btn {
        background: #ffffff;
        color: #000;
        height: 5vh;
        width: 15vw;
        border: 1px solid #fff;
        border-radius: 2px;
        font-weight: 600;
        text-transform: uppercase;
    }

    .sign-up-btn:hover {
        color: #000066;
        border-color: #000066;
        font-weight: 600;
        text-transform: uppercase;
    }

    .join-team {
        background: linear-gradient(rgba(241, 241, 241, 0.6), rgba(241, 241, 241, 0.6)), url(../images/join-team.jpg) no-repeat center center;
        background-size: cover;
        height: auto;
        width: 100vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .join-team .inner {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        background: transparent;
        width: 100%;
        height: 50%;
        padding: 10vh 0vw;
    }

    .join-team .inner h3 {
        color: #000066;
        font-size: 4rem;
        font-weight: 300;
        white-space: nowrap;
        text-transform: uppercase;
    }

    .join-team .inner hr {
        width: 25%;
        color: #000066;
        opacity: 1;
        height: 2px;
    }

    .join-team .inner p {
        color: #000066;
        font-size: 1.15rem;
        font-weight: 600;
        white-space: wrap;
        text-transform: uppercase;
        padding: 5vh 0vw 5vh 0vw;
    }

    .join-btn {
        font-weight: 600;
        height: 8vh;
        width: 15vw;
        background: #fff;
        border: 1px solid #000066;
        border-radius: 2px;
        color: #000066;
        text-transform: uppercase;
        transition: 500ms ease;
    }

    .join-btn:hover {
        font-weight: 600;
        height: 8vh;
        width: 15vw;
        background: #000066;
        border: 1px solid #000066;
        border-radius: 2px;
        color: #fff;
        text-transform: uppercase;
        transition: 500ms ease;
        cursor: pointer;
    }

    .join-text {
        padding: 0 !important;
        font-size: 1rem !important;

    }
}

@media only screen and (min-width:1025px) and (max-width: 1245px) {

    body {
        font-family: 'Century Gothic', sans-serif;
    }

    body + services-btn:active {
        overflow: hidden;
    }

    @media only screen and (min-width: 769px) and (max-width: 5000px) {
        .desktop-hide {
            display: none;
        }
    }

    @media only screen and (min-width: 0px) and (max-width: 768px) {
        .mobile-hide {
            display: none;
        }
    }

    b {
        font-weight: bold;
    }

    //DESKTOP MENU NAVBAR 
    .navbar {
        background-color: #fff;
        border-right: 1px solid #eee;
        display: flex;
        padding: 0vh 10vw;
        margin-top: 43px;
        position: fixed;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        top: -2vh;
        transition: left 0.25s ease;
        width: 100vw;
        height: 90px;
        z-index: 9;
        box-shadow: 1px 1px 10px rgb(0 0 0 / 25%);
    }

    .nav-link {
        color: #000;
        text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: 600;
    }

    .nav-link:hover {
        color: #000066;
        cursor: pointer;
    }

    .active-link {
        color: #000066;
    }

    .login-btn {
        color: #000066;
        text-transform: capitalize;
        text-decoration: none;
        font-size: 1rem;
        font-weight: 600;
    }

    .login-form-btn {
        background: #fff;
        color: #000066;
        line-height: 2;
        font-size: 1rem;
        border: 1px solid #000066;
        width: 10vw;
        border-radius: 2px;
        margin-top: 2vh;
        height: 8vh;
        width: 15vw;
        text-transform: uppercase;
        font-weight: 600;
        transition: 500ms ease;
    }

    .login-form-btn:hover {
        background: #000066;
        color: #fff;
        line-height: 2;
        font-size: 1rem;
        border: 1px solid #000066;
        width: 10vw;
        border-radius: 2px;
        margin-top: 2vh;
        height: 8vh;
        width: 15vw;
        text-transform: uppercase;
        font-weight: 600;
        transition: 500ms ease;
    }

    .form-text a {
        color: #6c757d;
        text-decoration: none;
    }

    .form-text a:hover {
        color: #000066;
    }

    .navbar > .container,
    .navbar > .container-fluid {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between
    }

    .navbar-brand {
        display: inline-block;
        padding-top: .3125rem;
        padding-bottom: .3125rem;
        margin-right: 1rem;
        font-size: 1.25rem;
        line-height: inherit;
        white-space: nowrap;
        width: 20vw;
    }

    .navbar-brand:focus,
    .navbar-brand:hover {
        text-decoration: none
    }

    .navbar-nav {
        display: flex;
        flex-direction: row;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
    }

    .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0
    }

    .navbar-nav .dropdown-menu {
        position: static;
        float: none
    }

    .navbar-text {
        display: inline-block;
        padding-top: .5rem;
        padding-bottom: .5rem
    }

    .navbar-collapse {
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center
    }

    .navbar-toggler {
        padding: .25rem .75rem;
        font-size: 1.25rem;
        line-height: 1;
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: .25rem
    }

    .navbar-toggler:focus,
    .navbar-toggler:hover {
        text-decoration: none
    }

    .navbar-toggler:not(:disabled):not(.disabled) {
        cursor: pointer
    }

    .navbar-toggler-icon {
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        vertical-align: middle;
        content: "";
        background: no-repeat center center;
        background-size: 100% 100%
    }

    @media (max-width:575.98px) {

        .navbar-expand-sm > .container,
        .navbar-expand-sm > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:576px) {
        .navbar-expand-sm {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-sm .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-sm .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-sm .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-sm > .container,
        .navbar-expand-sm > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-sm .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-sm .navbar-toggler {
            display: none
        }
    }

    @media (max-width:767.98px) {

        .navbar-expand-md > .container,
        .navbar-expand-md > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:768px) {
        .navbar-expand-md {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-md .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-md .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-md .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-md > .container,
        .navbar-expand-md > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-md .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-md .navbar-toggler {
            display: none
        }
    }

    @media (max-width:991.98px) {

        .navbar-expand-lg > .container,
        .navbar-expand-lg > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:992px) {
        .navbar-expand-lg {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-lg .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-lg .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-lg .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-lg > .container,
        .navbar-expand-lg > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-lg .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-lg .navbar-toggler {
            display: none
        }
    }

    @media (max-width:1199.98px) {

        .navbar-expand-xl > .container,
        .navbar-expand-xl > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:1200px) {
        .navbar-expand-xl {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-xl .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-xl .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-xl .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-xl > .container,
        .navbar-expand-xl > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-xl .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-xl .navbar-toggler {
            display: none
        }
    }

    @media (max-width:1399.98px) {

        .navbar-expand-xxl > .container,
        .navbar-expand-xxl > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:1400px) {
        .navbar-expand-xxl {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-xxl .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-xxl .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-xxl .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-xxl > .container,
        .navbar-expand-xxl > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-xxl .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-xxl .navbar-toggler {
            display: none
        }
    }

    @media (max-width:1599.98px) {

        .navbar-expand-xxxl > .container,
        .navbar-expand-xxxl > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:1600px) {
        .navbar-expand-xxxl {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-xxxl .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-xxxl .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-xxxl .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-xxxl > .container,
        .navbar-expand-xxxl > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-xxxl .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-xxxl .navbar-toggler {
            display: none
        }
    }

    @media (max-width:1920.98px) {

        .navbar-expand-uhd > .container,
        .navbar-expand-uhd > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:1921px) {
        .navbar-expand-uhd {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-uhd .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-uhd .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-uhd .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-uhd > .container,
        .navbar-expand-uhd > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-uhd .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-uhd .navbar-toggler {
            display: none
        }
    }

    .navbar-expand {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand > .container,
    .navbar-expand > .container-fluid {
        padding-right: 0;
        padding-left: 0
    }

    .navbar-expand .navbar-nav {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        width: 50vw;

    }

    .navbar-expand .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand > .container,
    .navbar-expand > .container-fluid {
        flex-wrap: nowrap
    }

    .navbar-expand .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
    }

    .navbar-expand .navbar-toggler {
        display: none
    }

    .navbar-light .navbar-brand {
        color: rgba(0, 0, 0, .9)
    }

    .navbar-light .navbar-brand:focus,
    .navbar-light .navbar-brand:hover {
        color: rgba(0, 0, 0, .9)
    }

    .navbar-light .navbar-nav .nav-link {
        color: rgba(0, 0, 0, .5)
    }

    .navbar-light .navbar-nav .nav-link:focus,
    .navbar-light .navbar-nav .nav-link:hover {
        color: rgba(0, 0, 0, .7)
    }

    .navbar-light .navbar-nav .nav-link.disabled {
        color: rgba(0, 0, 0, .3)
    }

    .navbar-light .navbar-nav .active > .nav-link,
    .navbar-light .navbar-nav .nav-link.active,
    .navbar-light .navbar-nav .nav-link.show,
    .navbar-light .navbar-nav .show > .nav-link {
        color: rgba(0, 0, 0, .9)
    }

    .navbar-light .navbar-toggler {
        color: rgba(0, 0, 0, .5);
        border-color: rgba(0, 0, 0, .1)
    }

    .navbar-light .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0,0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
    }

    .navbar-light .navbar-text {
        color: rgba(0, 0, 0, .5)
    }

    .navbar-light .navbar-text a {
        color: rgba(0, 0, 0, .9)
    }

    .navbar-light .navbar-text a:focus,
    .navbar-light .navbar-text a:hover {
        color: rgba(0, 0, 0, .9)
    }

    .navbar-dark .navbar-brand {
        color: #fff
    }

    .navbar-dark .navbar-brand:focus,
    .navbar-dark .navbar-brand:hover {
        color: #fff
    }

    .navbar-dark .navbar-nav .nav-link {
        color: rgba(255, 255, 255, .5)
    }

    .navbar-dark .navbar-nav .nav-link:focus,
    .navbar-dark .navbar-nav .nav-link:hover {
        color: rgba(255, 255, 255, .75)
    }

    .navbar-dark .navbar-nav .nav-link.disabled {
        color: rgba(255, 255, 255, .25)
    }

    .navbar-dark .navbar-nav .active > .nav-link,
    .navbar-dark .navbar-nav .nav-link.active,
    .navbar-dark .navbar-nav .nav-link.show,
    .navbar-dark .navbar-nav .show > .nav-link {
        color: #fff
    }

    .navbar-dark .navbar-toggler {
        color: rgba(255, 255, 255, .5);
        border-color: rgba(255, 255, 255, .1)
    }

    .navbar-dark .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
    }

    .navbar-dark .navbar-text {
        color: rgba(255, 255, 255, .5)
    }

    .navbar-dark .navbar-text a {
        color: #fff
    }

    .navbar-dark .navbar-text a:focus,
    .navbar-dark .navbar-text a:hover {
        color: #fff
    }

    //END NAVBAR
    //TICKER
    .ticker {
        width: 100vw;
        height: 27px;
        position: fixed;
        display: block;
        top: 0;
        z-index: 9999;
        padding: 0;
    }

    .ticket .row {
        height: auto;
    }

    .ticket .row .col-md-12 {}

    .breaking-news {
        display: block;
        position: relative;
        background: #3c3c3c;
        height: 27px;
    }

    .news-scroll {
        height: 27px;
        position: relative;
    }

    .news {
        width: 10vw;
        height: 5vh;
        min-height: 5vh;
        background: #000066;
        padding: 0;
        border: 0;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .news-scroll a {
        font-size: 0.8rem;
        font-weight: 300;
        color: #fff;
        text-transform: uppercase;
        text-decoration: none;
    }

    .dot {
        padding-right: 0.5vw;
    }

    .bold {
        font-weight: bold;
    }

    .img-fill {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden
    }

    .img-fill img {
        flex-shrink: 0;
        object-fit: cover;
    }

    footer {
        display: flex;
        background-color: #3c3c3c;
        border-top: 1px solid #888;
        color: #888;
        font-size: 1rem;
        padding: 2vh 0;
        position: relative;
        z-index: auto !important;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        height: 200px;
        z-index: 9500 !important;
    }

    footer a {
        color: #fff;
        font-size: 0.8rem;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;
    }

    footer a:hover {
        color: #f1f1f1
    }

    footer p {
        margin: 0;
        font-size: 0.8rem;
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
    }

    footer svg {
        margin: 0
    }

    footer .footer-row {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 10px;
        align-content: center;
        width: 100vw;
        padding: 0vh 10vw;
    }

    .footer-row .col-6 {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: flex-start;
    }

    .footer-info {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
    }

    .footer-row .col-3 p {
        font-size: 0.8rem;
        text-transform: capitalize;
        font-weight: 400;
        white-space: nowrap;
    }

    .footer-row .col-3 a {
        font-size: 0.8rem;
        text-transform: lowercase;
        font-weight: 400;
        white-space: nowrap;
    }

    .footer-info .col-3 {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: flex-start;
        padding: 0vh 0vw 0vh 2vw;
        height: 10vh;
    }

    .footer-row .col-6 .footer-info .col-3 a {
        font-weight: 600;
        text-transform: uppercase;
    }

    .footer-social {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        padding: 1vh;
    }

    .footer-social a {
        font-size: 1.25rem !important;
    }

    @media (max-width:1024.00px) {
        footer .footer-row {
            align-items: flex-start;
            flex-direction: column;
            justify-content: flex-start
        }
    }

    @media (max-width:767.98px) {
        footer .footer-row {
            align-items: center;
            flex-direction: column;
            justify-content: flex-start
        }
    }

    footer .footer-row:last-child {
        font-size: 11px;
        margin-bottom: 0
    }

    body {
        color: #000;
        font-size: 1rem;
        font-weight: 300;
        line-height: 26px;
        position: relative;
    }

    .scrollbar {
        overflow: auto
    }

    .scrollbar::-webkit-scrollbar {
        width: 10px;
        height: 10px
    }

    .scrollbar::-webkit-scrollbar-track {
        background-color: #e9ecef
    }

    .scrollbar::-webkit-scrollbar-thumb {
        background: #adb5bd
    }

    .scrollbar::-webkit-scrollbar-thumb:hover {
        background: #495057
    }

    main {
        margin-left: 0;
        overflow: hidden;
    }

    section {
        padding-left: 10vw;
        padding-right: 10vw;
        position: relative;
        z-index: 100;
        z-index: 2 !important;
    }

    section.no-mt {
        padding-top: 0
    }

    section.no-mb {
        padding-bottom: 0
    }

    header {
        background-color: #fff;
        position: relative;
        z-index: 100
    }

    img {
        max-width: 100%
    }

    h1 {
        font-weight: 800;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0
    }

    p {
        line-height: 1.75
    }

    strong {
        font-weight: 700
    }

    .mobile-arrow {
        bottom: 0;
        display: flex;
        justify-content: center;
        left: 0;
        margin: 0 auto;
        padding-bottom: 25px;
        position: absolute;
        right: 0
    }

    .mobile-arrow a {
        animation-delay: 2.5s;
        animation-duration: 5s;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        animation-name: custom-bounce;
        display: block;
        transform-origin: center bottom
    }

    .anchors {
        align-items: center;
        display: flex;
        font-size: 1.25rem;
        padding-top: .5rem
    }

    @media (max-width:575.98px) {
        .anchors {
            justify-content: center;
            padding-top: .5rem
        }
    }

    .anchors svg {
        color: #000066;
        height: 1.75rem;
        margin: 0 1.25rem 0 0;
        width: auto
    }

    @media (max-width:575.98px) {
        .anchors svg {
            margin: 0
        }
    }

    .anchors .nav-link {
        color: #000;
        font-size: 14px;
        font-weight: 300;
        margin: 0 1.25rem;
        padding: 8px 0 5px;
        position: relative;
        text-decoration: none
    }

    @media (max-width:575.98px) {
        .anchors .nav-link {
            margin: 0 .75rem
        }
    }

    .anchors .nav-link::before {
        background-color: #000066;
        bottom: 8px;
        content: '';
        height: 1px;
        left: 0;
        opacity: 0;
        position: absolute;
        transition: opacity .25s ease, width .15s ease;
        width: 0
    }

    .anchors .nav-link:first-child {
        margin-left: 0
    }

    .anchors .nav-link:last-child {
        margin-right: 0
    }

    .anchors .nav-link.active::before,
    .anchors .nav-link:hover::before {
        opacity: 1;
        width: 100%
    }

    .negative-mt {
        margin-top: -150px
    }

    .custom-list,
    .section-about ul {
        columns: 2;
        margin-top: 2.5rem;
        width: auto
    }

    @media (max-width:991.98px) {

        .custom-list,
        .section-about ul {
            margin-bottom: 1.5rem;
            margin-top: 1.5rem
        }
    }

    @media (max-width:767.98px) {

        .custom-list,
        .section-about ul {
            columns: 1
        }
    }

    .custom-list li,
    .section-about ul li {
        padding: .25rem 0 .25rem 1.5rem;
        position: relative
    }

    .custom-list li::before,
    .section-about ul li::before {
        border: 1px solid #000066;
        border-radius: 50%;
        content: '';
        height: 7px;
        left: 0;
        position: absolute;
        top: 12px;
        width: 7px
    }

    .primary-border {
        border-right: 1px solid #000066;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        padding: 0 5vw 0 0;
        position: relative
    }

    @media (max-width:991.98px) {
        .primary-border {
            border: 0;
            min-height: auto;
            padding: 1rem 0
        }
    }

    .primary-border.spaced {
        padding: 5rem 0
    }

    @media (max-width:991.98px) {
        .primary-border.spaced {
            padding: 2rem 0
        }
    }

    .page-title {
        color: #000066;
        font-size: 6rem;
        font-weight: 300;
        text-transform: uppercase;
        padding-bottom: 5vh;
    }

    .page-title.outline-big-text {
        font-size: 2.5rem
    }

    .subtitle-wrapper {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding-bottom: 3rem
    }

    @media (max-width:575.98px) {
        .subtitle-wrapper {
            align-items: flex-start;
            flex-direction: column;
            justify-content: flex-start
        }
    }

    @media (max-width:991.98px) {
        .subtitle-wrapper {
            padding-bottom: 1rem
        }
    }

    .text-wrapper p:last-child {
        margin: 0
    }

    .img-wrapper {
        border-color: rgba(255, 255, 255, 0);
        border-style: solid;
        border-width: 1px 0;
        padding: 0;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;
        flex-direction: column;
        align-content: center;
        width: 200px;
    }

    .img-wrapper hr {
        height: 5px;
        color: #000066;
        width: 25%;
    }

    .img-wrapper.static {
        border: 0;
        padding: 0
    }

    .img-wrapper.static .img-legend {
        color: #000;
        padding: 10px 0 0
    }

    .img-wrapper.static .img-legend a {
        color: #000;
        display: block;
        font-size: 16px;
        font-weight: 700;
        padding: .15rem 0 0;
        text-decoration: none
    }

    .img-wrapper img {
        overflow: hidden;
        object-fit: cover;
        min-height: 100%;
        min-width: 100%;
        flex-shrink: 0;
    }

    .img-wrapper .img-legend {
        color: #343a40;
        display: block;
        padding: 15px 0 0
    }

    .img-wrapper .img-legend p {
        line-height: 16px;
        margin: 0
    }

    .img-wrapper .img-legend p a {
        color: #000;
        font-size: 14px;
        font-weight: 800
    }

    .img-wrapper .img-legend .date {
        font-size: 14px;
        font-weight: 300
    }

    .img-wrapper .news-img {
        height: 350px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
    }

    .project-figure {
        position: relative
    }

    .project-figure figcaption {
        bottom: 10px;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 1px;
        position: absolute;
        right: 20px
    }

    .outline-big-text {
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(166, 0, 0, .9);
        color: #000066;
        font-family: Gilroy;
        font-size: 1.5rem;
        font-weight: 800;
        letter-spacing: 1px;
        line-height: 1;
        white-space: nowrap
    }

    .citation-wrapper .outline-big-text {
        font-size: 1.5rem;
    }

    @media (max-width:1399.98px) {
        .outline-big-text {
            font-size: 45px
        }
    }

    @media (max-width:1199.98px) {
        .outline-big-text {
            font-size: 40px
        }
    }

    .outline-big-text.outline-white {
        -webkit-text-stroke-color: rgba(255, 255, 255, .9);
        color: #fff
    }

    .solid-big-text {
        color: #ffffff;
        font-size: 7rem;
        font-weight: 600
    }

    .citation-align {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        padding-left: 5vw;
    }

    .citation-align .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        height: 100%
    }

    .citation-wrapper {
        color: #000066;
        display: flex;
        flex-direction: column;
        font-family: Helvetica;
        font-size: 1.5rem;
        font-weight: 700;
        height: 100%;
        justify-content: center;
        line-height: 1;
        padding-bottom: 2.5vh;
    }

    @media (max-width:991.98px) {
        .citation-wrapper {
            font-size: 1.25rem;
            padding: 1rem 0;
            text-align: center
        }
    }

    .citation-wrapper.hp .outline-big-text,
    .citation-wrapper.hp .solid-big-text {
        font-size: 3rem
    }

    @media (max-width:1599.98px) {

        .citation-wrapper.hp .outline-big-text,
        .citation-wrapper.hp .solid-big-text {
            font-size: 3rem;
        }
    }

    @media (max-width:1199.98px) {

        .citation-wrapper.hp .outline-big-text,
        .citation-wrapper.hp .solid-big-text {
            font-size: 3rem
        }
    }

    .citation-wrapper.hp .solid-small-text {
        font-size: 1rem;

    }

    @media (max-width:1199.98px) {
        .citation-wrapper.hp .solid-small-text {
            font-size: 20px
        }
    }

    .list-align {
        height: 100%
    }

    .list-align > .row {
        height: 100%
    }

    //PROPERTY PAGE
    .property-header {
        padding: 0 !important;
        height: 500px;
    }

    .property-img {
        height: 500px;
        width: 100%;
    }

    .property-img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .property-title {
        width: 100vw;
        height: 15vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        align-items: flex-start;
        justify-content: center;
    }

    .title h2 {
        font-size: 2rem;
        color: #000066;
        font-weight: 600;
        text-transform: capitalize;
    }

    .property-address p {
        font-size: 1.25rem;
        font-weight: 300;
        color: #3c3c3c;
        text-transform: capitalize;
    }

    .property {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        width: 100vw;
        padding: 0vh 10vw;
    }

    .property .col-6 {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
    }

    .property-details {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: nowrap;
        align-content: center;
        padding: 0vh 0vw 0vh 10vw;
    }

    .property-details ul {
        margin-bottom: 1.25rem;
        padding-bottom: 1.25rem;
        position: relative
    }

    .property-details ul::before {
        background-color: #000066;
        bottom: 0;
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        width: 25px
    }

    .property-details ul:last-child {
        margin-bottom: 0;
        padding-bottom: 0
    }

    .property-details ul:last-child::before {
        display: none
    }

    .property-details ul li {
        font-size: 14px
    }

    .property-details ul li span {
        color: #000066;
        font-weight: 600;
        padding-right: .75rem
    }

    .property-inquiry {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-top: 10vh;
    }

    .property-inquiry .row {
        display: inline-flex;
    }

    .property-inquiry .row button {
        text-transform: uppercase;
        background: #000066;
        color: #fff;
        width: 14vw;
        height: 7vh;
        border-radius: 2px;
        transition: 500ms;
        border: none;
    }

    .property-inquiry .row button:hover {
        background: #fff;
        color: #000066;
        width: 16vw;
        height: 8vh;
        border-radius: 2px;
        transition: 500ms;
        border: 1px solid #000066
    }

    .border-link {
        border-style: solid;
        border-width: 1px 0;
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        padding: .2rem .25rem .1rem;
        position: relative;
        transition: color .25s ease;
        white-space: nowrap;
        word-spacing: 2px
    }

    .border-link::before {
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        top: 0;
        transition: width .25s ease;
        width: 0;
        z-index: -1
    }

    .border-link:hover {
        text-decoration: none
    }

    .border-link:hover::before {
        width: 100%
    }

    .border-link.border-link-primary {
        border-color: #000066;
        color: #000066
    }

    .border-link.border-link-primary::before {
        background-color: #000066
    }

    .border-link.border-link-primary:hover {
        color: #fff
    }

    .border-link.border-link-white {
        border-color: #fff;
        color: #fff
    }

    .border-link.border-link-white::before {
        background-color: #fff
    }

    .border-link.border-link-white:hover {
        color: #000066
    }

    .border-link.no-effect::before {
        display: none
    }

    .border-link.no-effect:hover {
        color: #000066
    }

    .link-effect {
        position: relative;
        z-index: 1
    }

    .link-effect::before {
        background-color: #fff;
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        top: 0;
        transition: width .2s ease;
        width: 0;
        z-index: -1
    }

    .link-effect:hover::before {
        width: 100%
    }

    .nav .nav-link {
        color: #000;
        font-weight: 300;
        margin: 0 50px 0 0;
        padding-bottom: .25rem;
        padding-left: 0;
        padding-right: 0;
        position: relative
    }

    .nav .nav-link::before {
        background-color: #000066;
        bottom: 8px;
        content: '';
        height: 1px;
        left: 0;
        opacity: 0;
        position: absolute;
        transition: opacity .25s ease, width .15s ease;
        width: 0
    }

    .nav .nav-link.active::before,
    .nav .nav-link:hover::before {
        opacity: 1;
        width: 100%
    }

    .row-adjust {
        margin-left: -25px;
        margin-right: -25px
    }

    @media (max-width:991.98px) {
        .row-adjust {
            margin-left: -10px;
            margin-right: -10px
        }
    }

    .row-adjust > [class*=col] {
        padding-left: 25px;
        padding-right: 25px
    }

    @media (max-width:991.98px) {
        .row-adjust > [class*=col] {
            padding-left: 10px;
            padding-right: 10px
        }
    }

    .row-align {
        justify-content: center
    }

    .row-big {
        margin-left: -75px;
        margin-right: -75px
    }

    .row-big > [class*=col] {
        padding-left: 75px;
        padding-right: 75px
    }

    .red-card {
        background-color: #000066;
        font-family: Gilroy;
        height: 100%;
        min-height: 275px;
        overflow: hidden;
        padding: 1.5rem 1.5rem 1rem
    }

    .red-card a {
        color: #fff;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        text-decoration: none
    }

    .red-card a:hover {
        color: #fff
    }

    .red-card p {
        line-height: 1
    }

    .red-card .job-title {
        font-size: 35px;
        font-weight: 800
    }

    .red-card .job-description {
        font-size: 35px;
        font-weight: 300;
        margin-bottom: 1.5rem
    }

    .red-card .flex-wrapper {
        display: flex;
        justify-content: space-between
    }

    .red-card .flex-wrapper .apply {
        font-size: 1rem;
        font-weight: 800
    }

    .red-card .flex-wrapper .arrow {
        align-items: center;
        border-radius: 50%;
        display: flex;
        font-size: 1.5rem;
        height: 35px;
        justify-content: center;
        width: 35px
    }

    .red-card .flex-wrapper .arrow:hover {
        background-color: #fff;
        color: #000066
    }

    .scroll-top {
        align-items: center;
        bottom: 0;
        display: flex;
        justify-content: center;
        position: absolute;
        right: 50px
    }

    @media (max-width:991.98px) {
        .scroll-top {
            right: 25px
        }
    }

    @media (max-width:575.98px) {
        .scroll-top {
            right: 15px
        }
    }

    .scroll-top a {
        line-height: 1;
        text-align: center;
        text-decoration: none
    }

    .scroll-top a p {
        line-height: 18px;
        margin: 0;
        text-align: center
    }

    .scroll-top a .icon-wrapper {
        align-items: center;
        border: 1px solid #000066;
        border-radius: 50%;
        color: #000066;
        display: flex;
        height: 35px;
        justify-content: center;
        margin: 0;
        overflow: hidden;
        position: relative;
        transition: color .15s ease;
        width: 35px
    }

    .scroll-top a .icon-wrapper::before {
        background-color: #000066;
        bottom: 0;
        content: '';
        height: 0;
        left: -20px;
        position: absolute;
        right: -20px;
        transition: height .15s ease;
        z-index: -1
    }

    .scroll-top a:hover p {
        color: #000066
    }

    .scroll-top a:hover .icon-wrapper {
        color: #fff
    }

    .scroll-top a:hover .icon-wrapper::before {
        height: 100%
    }

    .v-line {
        bottom: 0;
        left: 0;
        margin: 0 auto;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 4px;
        width: 21px
    }

    @media (max-width:991.98px) {
        .v-line {
            display: none
        }
    }

    .v-line.project-line .arrow {
        bottom: 20vh;
        padding: 2rem 0 3rem;
        top: auto
    }

    .v-line .arrow {
        left: 0;
        margin: 0 auto;
        min-width: 21px;
        position: fixed;
        right: 0;
        top: 50%
    }

    .arrow-wrapper-white {
        bottom: 20vh;
        left: 155px;
        padding: 2rem 0 3rem;
        position: fixed;
        right: 0;
        text-align: center;
        z-index: -1
    }

    @media (max-width:991.98px) {
        .arrow-wrapper-white {
            display: none
        }
    }

    .arrow-wrapper-white.active {
        display: none
    }

    .arrow-wrapper-white .arrow {
        align-items: center;
        animation-delay: 2.5s;
        animation-duration: 5s;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        position: relative
    }

    .container-fluid.no-margin {
        padding: 0
    }

    .section-title {
        text-align: center;
        padding: 5vh 0vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-title hr {
        height: 1px;
        color: #000066;
        width: 10vw;
        opacity: 1;
    }

    .section-title.vertical {
        font-size: 16px;
        font-weight: 700;
        margin: 0;
        text-orientation: sideways-right;
        transform: rotate(180deg);
        writing-mode: vertical-rl
    }

    @media (max-width:575.98px) {
        .section-title.vertical {
            margin-bottom: 15px;
            text-orientation: initial;
            transform: rotate(0);
            writing-mode: initial
        }
    }

    .section-title.red {
        color: #000066
    }

    .section-title.white {
        color: #fff
    }

    .section-banner {
        padding: 0
    }

    .section-banner.banner-spaced {
        padding: 0 50px
    }

    @media (max-width:991.98px) {
        .section-banner.banner-spaced {
            padding-left: 25px;
            padding-right: 25px
        }
    }

    @media (max-width:575.98px) {
        .section-banner.banner-spaced {
            padding-left: 10px;
            padding-right: 10px
        }
    }

    .section-banner iframe {
        height: 500px;
        max-width: 100%;
        width: 800px
    }

    .section-banner .banner {
        background-position: 50% 100%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
        position: relative;
        z-index: -10
    }

    .section-banner .banner.medium-banner {
        background-position: 50% 50%;
        height: 80vh;
        z-index: 2
    }

    .section-banner .banner.small-banner {
        background-position: 50% 50%;
        height: 60vh;
        z-index: 0
    }

    .section-banner .back {
        position: absolute;
        right: 25px;
        top: 25px;
        z-index: 5
    }

    .section-banner .back a {
        color: #fff
    }

    .section-banner .back a svg {
        height: 20px;
        width: auto
    }

    .section-banner .flex-wrapper {
        align-items: center;
        bottom: 0;
        display: flex;
        flex-direction: column;
        height: 100vh;
        justify-content: center;
        pointer-events: none;
        position: fixed;
        z-index: 1;
        flex-wrap: nowrap;
        align-content: center;
        width: 100vw;
    }

    @media (max-width:575.98px) {
        .section-banner .flex-wrapper {
            left: 0
        }
    }

    .section-banner .flex-wrapper .v-line {
        height: 0;
        margin: 50px auto 0;
        position: relative
    }

    @media (max-width:991.98px) {
        .section-banner .flex-wrapper .v-line {
            display: none
        }
    }

    .section-banner .flex-wrapper .v-line .arrow {
        left: 0;
        margin: 0 auto;
        opacity: 1;
        position: absolute;
        right: 0
    }

    .section-banner .flex-wrapper .v-line.active {
        animation: banner-line .85s ease-in;
        animation-delay: .25s;
        animation-fill-mode: forwards
    }

    .section-banner .flex-wrapper .v-line.active .arrow {
        animation: banner-arrow .75s ease;
        animation-delay: 1.1s;
        animation-fill-mode: forwards
    }

    .section-banner .flex-wrapper #animation-wrapper {
        max-width: 40%;
        position: relative;
        width: 100%
    }

    @media (max-width:575.98px) {
        .section-banner .flex-wrapper #animation-wrapper {
            max-width: 70%
        }
    }

    @media (max-width:991.98px) {
        .section-banner .flex-wrapper #animation-wrapper {
            max-width: 60%
        }
    }

    @media (max-width:1199.98px) {
        .section-banner .flex-wrapper #animation-wrapper {
            max-width: 50%
        }
    }

    .section-top-page {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 34vh;
        justify-content: space-evenly;
        z-index: 200;
        padding-top: 0vh;
        align-content: center;
        flex-wrap: nowrap;
    }

    .section-top-page .nav {
        display: flex;
        flex-wrap: nowrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        flex-direction: row;
        align-content: center;
        justify-content: space-around;
        width: 80vw;
        align-items: center;
    }

    .section-top-page .nav .nav-link {
        margin: 0;
        font-size: 0.8rem;
    }

    @media (max-width:575.98px) {
        .section-top-page {
            align-items: center
        }
    }

    .section-top-page.stickyTop {
        background-color: #fff;
        left: 155px;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 900
    }

    @media (max-width:575.98px) {
        .section-top-page.stickyTop {
            left: 0
        }
    }

    @media (max-width:575.98px) {
        .section-top-page .flex-wrapper {
            display: block
        }
    }

    .section-top-page .flex-wrapper .page-title {
        align-items: center;
        display: flex;
        margin-bottom: 0
    }

    @media (max-width:575.98px) {
        .section-top-page .flex-wrapper .page-title {
            justify-content: center
        }
    }

    .section-top-page .dropdown .dropdown-toggle {
        height: 34px;
        line-height: 34px;
        padding: 0 15px
    }

    .section-middle-line {
        padding-bottom: 5rem;
        padding-top: 5rem;
    }

    @media (max-width:991.98px) {
        .section-middle-line {
            padding-bottom: 1.5rem;
            padding-top: 1.5rem
        }
    }

    .section-middle-line.small-margin-top {
        padding-top: 1.5rem
    }

    .section-middle-line.reversed .text-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-start
    }

    @media (max-width:991.98px) {
        .section-middle-line.reversed .text-wrapper {
            margin: 0;
            width: 100%
        }
    }

    .section-middle-line.reversed .primary-border {
        border: 0;
        height: 100%;
        justify-content: flex-start
    }

    .section-middle-line.reversed .primary-border.spaced {
        padding-top: 0
    }

    .section-middle-line.reversed .citation-wrapper {
        justify-content: flex-start
    }

    .section-middle-line.reversed .button-wrapper {
        text-align: center
    }

    @media (max-width:991.98px) {
        .section-middle-line.reversed .button-wrapper {
            margin: 2.5rem auto 0
        }
    }

    .section-middle-line.about-details {
        margin-bottom: 5rem;
        padding: 10vh 10vw
    }

    @media (max-width:991.98px) {
        .section-middle-line.about-details {
            padding: 0 25px
        }
    }

    .about-details .row {
        padding-top: 5vh;
    }

    .about-details .row .col-6 {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
    }

    .about-details .row .col-6 .citation-wrapper {
        font-size: 2.5rem;
        padding-left: 15vw;
    }

    .about-details .row .col-6 .citation-wrapper .outline-big-text {
        font-size: 2rem;
    }

    .about-details .row .col-6 .text-wrapper {
        padding-left: 10vw;
        padding-right: 10vw;
    }

    .section-middle-line.about-details .citation-wrapper {
        text-align: left
    }

    .section-middle-line.about-details .primary-border {
        padding: 0
    }

    @media (max-width:991.98px) {
        .section-middle-line.about-details .text-wrapper {
            margin-bottom: 50px
        }
    }

    .section-middle-line.about-details .row.marged {
        margin-bottom: 100px
    }

    @media (max-width:991.98px) {
        .section-middle-line.about-details .row.marged {
            margin-bottom: 0
        }
    }

    @media (max-width:991.98px) {
        .section-middle-line .text-wrapper p {
            text-align: justify
        }
    }

    th {
        width: 25%;
    }

    .sold .table thead tr th {
        color: #000066;
        text-transform: uppercase;
        font-weight: 600;
    }

    .sold {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
        width: 75vw;
    }

    .sold-img {
        width: 100%;
    }

    .sold .card-description {
        height: auto;
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
    }

    .sold .card-text {
        display: none;
    }

    .sold .card-inquiry {
        display: none;
    }

    .sold-price {
        color: white;
        background: #000066;
        text-align: center;
    }

    .listings {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
    }

    .listings .card {
        width: 250px;
        margin: 10vh 0 0 0;
    }

    .exclusive-listings {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
    }

    .exclusive-listings .card {
        width: 30vh;
        margin: 5vh 0 0 0;
    }

    .property-status {
        font-weight: 700;
        color: #000;
        font-size: 1rem;
        text-align: center;
        border-top: none !important;
        height: 50px;
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: stretch;
    }

    .card {
        height: 515px;
    }

    .card-title {
        font-size: 0.8rem;
        color: #000066;
        line-height: 1.5rem;
        font-weight: 400;
        text-transform: uppercase;
    }

    .card-text {
        font-size: 0.8rem;
    }

    .card-link {
        color: #ffffff;
        font-size: 1rem;
        text-decoration: none;
    }

    .card-link:hover {
        color: #f1f1f1;
        font-size: 1rem;
        text-decoration: none;
        cursor: pointer;
    }

    .card-inquiry {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: center;
        background: #000066;
        border-bottom-left-radius: calc(0.25rem - 1px);
        border-bottom-right-radius: calc(0.25rem - 1px);
        height: 20px;
        padding: 1vh;
    }

    .card-description {
        height: 185px;
    }

    .card-img {
        height: 155px;
        object-fit: cover;
    }

    .section-platforms {
        padding-bottom: 5rem;
        padding-top: 5rem
    }

    .section-platforms .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-platforms .row .col-6 {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-right: 5vw !important;
    }

    @media (max-width:1199.98px) {
        .section-platforms {
            max-height: initial;
            min-height: initial;
            overflow: initial
        }
    }

    @media (max-width:991.98px) {
        .section-platforms {
            padding-bottom: 2.5rem;
            padding-top: 2.5rem
        }
    }

    @media (max-width:767.98px) {
        .section-platforms table {
            width: 100%
        }
    }

    @media (max-width:767.98px) {
        .section-platforms table tr {
            display: flex;
            flex-direction: column;
            margin-bottom: 25px
        }
    }

    @media (max-width:767.98px) {
        .section-platforms table tr td {
            text-align: left
        }
    }

    .section-platforms .citation-align {
        border-left: 1px solid #000066
    }

    @media (max-width:1399.98px) {
        .section-platforms .citation-align {
            border-left: 0
        }
    }

    .section-platforms .platform-title {
        align-items: center;
        border-bottom: 1px solid #000;
        display: flex;
        font-size: 21px;
        font-weight: 700;
        height: 140px;
        line-height: 30px;
        width: auto
    }

    @media (max-width:767.98px) {
        .section-platforms .platform-title {
            border-bottom: 0;
            height: auto
        }
    }

    .section-platforms .platform-title p {
        margin-bottom: 0;
        padding-right: 10px;
        width: auto
    }

    @media (max-width:767.98px) {
        .section-platforms .platform-title p {
            padding-right: 0;
            width: 100%
        }
    }

    .section-platforms .platform-paragraph {
        padding-left: 5vw
    }

    @media (max-width:767.98px) {
        .section-platforms .platform-paragraph {
            padding-left: 0
        }
    }

    .section-platforms .platform-paragraph p {
        margin-bottom: 0;
        width: 250px
    }

    @media (max-width:767.98px) {
        .section-platforms .platform-paragraph p {
            width: auto
        }
    }

    //CTA INVESTMENT HOME PAGE SECTION
    .section-investment {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-bottom: 5rem;
        padding-top: 5rem;
        height: 75vh;
    }

    .section-investment .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-investment .row .col-6 {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-right: 5vw !important;
    }

    .section-investment .row .col-6 .inner {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        align-items: flex-end;
        justify-content: center;
    }

    @media (max-width:1199.98px) {
        .section-investment {
            max-height: initial;
            min-height: initial;
            overflow: initial
        }
    }

    @media (max-width:991.98px) {
        .section-investment {
            padding-bottom: 2.5rem;
            padding-top: 2.5rem
        }
    }

    @media (max-width:767.98px) {
        .section-investment table {
            width: 100%
        }
    }

    @media (max-width:767.98px) {
        .section-investment table tr {
            display: flex;
            flex-direction: column;
            margin-bottom: 25px
        }
    }

    @media (max-width:767.98px) {
        .section-investment table tr td {
            text-align: left
        }
    }

    .section-investment .citation-align {
        border-left: 1px solid #000066
    }

    @media (max-width:1399.98px) {
        .section-investment .citation-align {
            border-left: 0
        }
    }

    .section-investment .platform-title {
        align-items: center;
        border-bottom: 1px solid #000;
        display: flex;
        font-size: 21px;
        font-weight: 700;
        height: 140px;
        line-height: 30px;
        width: auto
    }

    @media (max-width:767.98px) {
        .section-investment .platform-title {
            border-bottom: 0;
            height: auto
        }
    }

    .section-investment .platform-title p {
        margin-bottom: 0;
        padding-right: 10px;
        width: auto
    }

    @media (max-width:767.98px) {
        .section-investment .platform-title p {
            padding-right: 0;
            width: 100%
        }
    }

    .section-investment .platform-paragraph {
        padding-left: 5vw
    }

    @media (max-width:767.98px) {
        .section-investment .platform-paragraph {
            padding-left: 0
        }
    }

    .section-investment .platform-paragraph p {
        margin-bottom: 0;
        width: 250px
    }

    @media (max-width:767.98px) {
        .section-investment .platform-paragraph p {
            width: auto
        }
    }

    //END
    .section-title {
        text-align: center;
        padding: 15vh 0vw;
    }

    .section-title h2 {
        color: #000066;
        text-transform: uppercase;
        font-size: 4rem;
        font-weight: 300;
    }

    .section-cta {
        text-align: center;
        padding: 10vh 10vw 5vh 10vw;
    }

    .section-cta button {
        background: #000066;
        border: 1px solid #000066;
        height: 8vh;
        width: 16vw;
        border-radius: 2px;
        margin: 1vw;
        margin-left: 0;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.05vw;
        transition: 500ms !important;
    }

    .section-cta button:hover {
        background: #000066;
        border: 1px solid #000066;
        height: 8vh;
        width: 16vw;
        border-radius: 2px;
        margin: 1vw;
        margin-left: 0;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.05vw;
        transition: 500ms !important;
    }

    .section-full-primary {
        background-color: #fff;
        padding-bottom: 10vh;
        padding-top: 20vh;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-full-primary.no-carousel img {
        height: auto;
        width: 100%
    }

    .section-full-primary.no-carousel .job-image {
        background-position: 50% 50%;
        background-size: cover;
        height: 275px;
        width: 100%
    }

    .section-full-primary.jobs-carousel .red-card {
        height: 275px
    }

    .section-full-primary .row h2 {
        font-size: 1rem;
        color: #000066;
        text-transform: uppercase;
        font-weight: 400;
    }

    @media (max-width:1399.98px) {
        .section-full-primary .row {
            margin-left: -5px;
            margin-right: -5px
        }
    }

    .section-full-primary .row [class*=col] {
        margin-bottom: 25px
    }

    @media (max-width:1399.98px) {
        .section-full-primary .row [class*=col] {
            padding-left: 5px;
            padding-right: 5px
        }
    }

    .section-full-primary .subtitle-wrapper {
        padding-bottom: .5rem
    }

    .section-full-primary .subtitle-wrapper h2 {
        margin-bottom: 0
    }

    @media (max-width:575.98px) {
        .section-full-primary .subtitle-wrapper h2 {
            margin-bottom: .5rem
        }
    }

    .section-full-primary .job-title h3 {
        font-size: 1.75rem;
        font-weight: 800;
        margin-bottom: .25rem
    }

    .section-full-primary .job-description p {
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.25
    }

    .section-team-primary {
        background-color: #fff;
        padding-bottom: 10vh;
        padding-top: 20vh;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .team-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        padding: 5vh 0vw;
    }

    .team-list-line {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        padding: 5vh 0vw 5vh 0vw;
    }

    .team-list-line .card {
        width: 60vw;
        margin: 0;
        height: 100px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: stretch;
        border: none;
    }

    .team-list-line .card .team-name {
        width: 150px;
        text-align: left;
    }

    .team-list-line .card .card-title {
        width: 400px;
        text-align: center;
    }

    .team-list-line .card .card-text {
        width: 150px;
        text-align: left;
    }

    .team-list-line .card .card-description {
        text-align: center;
        height: auto;
        background: #fff;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: baseline;
        padding-top: 1vh;
    }

    .team-list-line .card .card-description a {
        color: #000;
    }

    .team-list-line .card .card-description a:hover {
        color: #000066;
        cursor: pointer;
    }

    .team-list .card {
        width: 250px;
        margin: 0vh 1vw 9vh 1vw;
        height: 550px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: stretch;
        border: none;
    }

    .team-img {
        height: 350px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    .team-img img {
        flex-shrink: 0;
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
    }

    .team-list .card .card-description {
        text-align: center;
        height: 160px;
        background: #fff;
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        padding-top: 1vh;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
    }

    .team-list .card .card-inquiry {
        height: 6vh;
    }

    .team-name {
        font-weight: 700;
        color: #000;
        font-size: 1rem;
        text-align: center;
        border-top: none !important;
    }

    .team-filter {
        width: 100vw;
        padding-top: 10vh;
        display: flex;
        position: relative;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;

    }

    .team-filter .inner {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: baseline;
        width: 50vw;
    }

    .team-filter .inner p {
        color: #000066;
        font: 1rem;
    }

    .section-team-primary.no-carousel img {
        height: auto;
        width: 100%
    }

    .section-team-primary.no-carousel .job-image {
        background-position: 50% 50%;
        background-size: cover;
        height: 275px;
        width: 100%
    }

    .section-team-primary.jobs-carousel .red-card {
        height: 275px
    }

    @media (max-width:1399.98px) {
        .section-team-primary .row {
            margin-left: -5px;
            margin-right: -5px
        }
    }

    .section-team-primary .row [class*=col] {
        margin-bottom: 25px
    }

    @media (max-width:1399.98px) {
        .section-team-primary .row [class*=col] {
            padding-left: 5px;
            padding-right: 5px
        }
    }

    .section-team-primary .subtitle-wrapper {
        padding-bottom: .5rem
    }

    .section-team-primary .subtitle-wrapper h2 {
        margin-bottom: 0
    }

    @media (max-width:575.98px) {
        .section-team-primary .subtitle-wrapper h2 {
            margin-bottom: .5rem
        }
    }

    .section-team-primary .job-title h3 {
        font-size: 1.75rem;
        font-weight: 800;
        margin-bottom: .25rem
    }

    .section-team-primary .job-description p {
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.25
    }

    .section-search-simple .row {
        height: 100%;
    }

    .section-search-simple .row .col-6 {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-search-simple .row .col-6 .citation-align {
        align-items: flex-end;
        padding: 0;

    }

    .section-search-simple .row .col-6 .citation-align .citation-wrapper {
        align-items: flex-end;

        padding-right: 10vw;
        padding-bottom: 0;
    }

    .search-text {
        color: #fff;
        font-size: 2rem;
        text-align: right;
        line-height: 1.5;

    }


    .sign-up-btn-light {
        background: #fff;
        color: #000066;
        border: none;
        border-radius: 1px;
        width: 21vw;
        font-size: 1rem;
        line-height: 2;
        margin-top: 5vh;
        text-transform: uppercase;
        font-weight: 300;
    }

    .sign-up-btn-light:hover {
        background: #000066;
        color: #fff;
        border: none;
        border-radius: 1px;
        width: 21vw;
        font-size: 1rem;
        line-height: 2;
        margin-top: 5vh;
        text-transform: uppercase;
        font-weight: 300;
    }

    @media (max-width:991.98px) {
        .section-search-simple {
            padding-bottom: 2.5rem;
            padding-top: 2.5rem
        }
    }

    @media (max-width:767.98px) {
        .section-search-simple img {
            margin-bottom: 2rem
        }
    }

    .section-image-double {
        padding-bottom: 50px;
        padding-top: 5rem
    }

    @media (max-width:991.98px) {
        .section-image-double {
            padding-bottom: 2.5rem;
            padding-top: 20px
        }
    }

    @media (max-width:991.98px) {
        .section-image-double img {
            margin-bottom: 20px
        }
    }

    .section-image-double .flex-wrapper {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center
    }

    @media (max-width:1199.98px) {
        .section-image-double .flex-wrapper {
            align-items: flex-start
        }
    }

    .section-image-double .flex-wrapper .citation {
        border-color: #000066;
        border-style: solid;
        border-width: 1px 0;
        color: #000;
        padding: 1.5rem 0 1.5rem;
        width: 400px
    }

    @media (max-width:767.98px) {
        .section-image-double .flex-wrapper .citation {
            margin-top: 25px
        }
    }

    @media (max-width:1199.98px) {
        .section-image-double .flex-wrapper .citation {
            padding: 10px 0;
            width: 100%
        }
    }

    .section-image-double .flex-wrapper .citation p {
        font-family: Gilroy;
        font-size: 26px;
        font-weight: 800;
        line-height: 32px;
        margin-bottom: 2rem
    }

    @media (max-width:1599.98px) {
        .section-image-double .flex-wrapper .citation p {
            font-size: 20px;
            line-height: 24px
        }
    }

    @media (max-width:1199.98px) {
        .section-image-double .flex-wrapper .citation p {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 1rem
        }
    }

    @media (max-width:991.98px) {
        .section-image-double .flex-wrapper .citation p {
            font-size: 18px;
            line-height: 22px
        }
    }

    .section-image-double .flex-wrapper .citation span {
        display: block;
        line-height: 1.35
    }

    .section-image-double .flex-wrapper .citation span.author {
        font-weight: 600
    }

    @media (max-width:991.98px) {

        .section-image-double.section-image-4 [class*=col]:nth-child(1) img,
        .section-image-double.section-image-4 [class*=col]:nth-child(2) img {
            margin-bottom: 25px
        }
    }

    @media (max-width:991.98px) {

        .section-image-double.section-image-4 [class*=col]:nth-child(1) img,
        .section-image-double.section-image-4 [class*=col]:nth-child(2) img,
        .section-image-double.section-image-4 [class*=col]:nth-child(3) img {
            margin-bottom: 25px
        }
    }

    .section-gallery .gallery-wrapper {
        padding-bottom: 25px
    }

    .section-gallery .gallery-wrapper .row {
        margin-left: -10px;
        margin-right: -10px
    }

    .section-gallery .gallery-wrapper .row [class*=col] {
        margin: 0 0 10px;
        padding-left: 10px;
        padding-right: 10px;
        height: 300px;
    }

    .section-gallery .gallery-image-wrapper {
        overflow: hidden;
        position: relative;
        height: 250px;
    }

    .section-gallery .gallery-image-wrapper:hover .image-effect {
        left: 0
    }

    .section-gallery .gallery-image-wrapper:hover .image-effect [class*=image] {
        opacity: 1
    }

    .section-gallery .gallery-dots {
        margin: 2.5rem auto;
        text-align: center
    }

    .section-gallery .gallery-dots .btn {
        align-items: center;
        cursor: default;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        opacity: 0;
        padding: 5px
    }

    .section-gallery .image-effect {
        background-color: #000066;
        bottom: 0;
        cursor: pointer;
        font-family: Gilroy;
        left: -100%;
        position: absolute;
        top: 0;
        transition: left .25s ease;
        width: 100%
    }

    .section-gallery .image-effect p {
        font-size: 1rem;
        line-height: 1.25;
        margin: 0
    }

    .section-gallery .image-effect [class*=image] {
        color: #fff;
        opacity: 0;
        transition: opacity .25s ease;
        transition-delay: .15s
    }

    .section-gallery .image-effect .image-text {
        font-size: 1.25rem;
        left: 15px;
        position: absolute;
        right: 15px;
        top: 15px
    }

    .section-gallery .image-effect .image-text h3 {
        font-weight: 800;
        margin-bottom: .25rem
    }

    .section-gallery .image-effect .image-text .image-description {
        font-weight: 300;
        max-width: 60%
    }

    .section-gallery .image-effect .image-infos {
        bottom: 15px;
        font-family: Helvetica, Arial;
        font-size: 1.25rem;
        position: absolute;
        right: 15px;
        text-orientation: sideways-right;
        transform: rotate(180deg);
        writing-mode: vertical-rl
    }

    .section-gallery .image-effect .image-infos .image-locality {
        font-size: 14px;
        font-weight: 300;
        margin-left: .25rem
    }

    .section-gallery .image-effect .image-infos .image-date {
        font-size: 14px;
        font-weight: 800
    }

    .section-gallery .image-effect .image-arrow {
        bottom: 15px;
        font-size: 1.25rem;
        left: 15px;
        position: absolute
    }

    .section-gallery .image-effect .image-arrow:hover .icon-wrapper {
        background-color: #fff;
        color: #000066
    }

    .section-gallery .image-effect .image-arrow .icon-wrapper {
        align-items: center;
        border-radius: 50%;
        display: flex;
        height: 35px;
        justify-content: center;
        width: 35px
    }

    .section-carousel {
        background-color: #fff;
        padding-bottom: 0;
        padding-top: 5rem
    }

    @media (max-width:991.98px) {
        .section-carousel {
            padding-bottom: 20px;
            padding-top: 0
        }
    }

    .section-carousel .owl-carousel .owl-stage {
        display: -webkit-box !important;
        display: -moz-box !important;
        display: -ms-box !important;
        display: box !important
    }

    .section-carousel .owl-carousel .owl-item img {
        display: block;
        height: 400px;
        margin: 0 auto;
        max-height: 400px;
        width: auto
    }

    .section-share {
        background-color: #fff;
        padding-bottom: 0;
        padding: 10vh;
    }

    .section-share .flex-wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center
    }

    .section-share .flex-wrapper .text-wrapper {
        letter-spacing: 1px;
        margin-bottom: .75rem
    }

    .section-share .flex-wrapper .icons-wrapper a {
        color: #343a40;
        margin: 0 .5rem;
        text-decoration: none
    }

    .section-share .flex-wrapper .icons-wrapper a:hover {
        color: #000066
    }

    .section-about .intro {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        padding: 20vh 10vw;
    }

    .section-about .intro .inner_L {
        width: 55%;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-about .intro .inner_L .txt {
        white-space: nowrap;
        text-align: center;
        border: none;
    }

    .section-about .intro .inner_L .txt h2 {
        padding: 0;
        text-transform: uppercase;
    }

    .section-about .intro .inner_R {
        width: 45%;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
    }

    .section-about .intro .inner_R .txt {
        padding: 0;
        text-align: center;
    }

    .section-about .row .col-6 {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    .section-about .row .col-6 .img-wrapper {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: center;
    }

    .about {
        height: auto;
        width: 100vw;
        padding: 20vh 10vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
    }
    .section-team .intro {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        padding: 20vh 10vw;
    }

    .section-team .intro .inner_L {
        width: 55%;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-team .intro .inner_L .txt {
        white-space: nowrap;
        text-align: center;
        border: none;
    }

    .section-team .intro .inner_L .txt h2 {
        padding: 0;
        text-transform: uppercase;
    }

    .section-team .intro .inner_R {
        width: 45%;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
    }

    .section-team .intro .inner_R .txt {
        padding: 0;
        text-align: justify;
    }

    .section-team .intro .inner_R .txt p {
        text-align: justify;
        font-size: 1rem;
    }

    .section-team .row .col-6 {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    .section-team .row .col-6 .img-wrapper {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: center;
    }

    .section-mission {
        height: 100vh;
        width: 100vw;
        padding: 10vh 10vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        background: linear-gradient(rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), url(../images/services-overlay_bg.jpg) no-repeat center center;
        background-size: cover;
    }

    .section-mission .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .mission-row {
        text-align: center;
        display: flex;
        width: 80vw;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        padding: 5vh 0vw;
    }

    .mission-row .col-3 {
        padding: 0vh 5vw 0vh 0vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: flex-start;
        height: auto;
    }

    .mission-row .col-3 p {
        text-align: justify;
        text-align-last: left;
        padding-top: 2vh;
    }

    .section-mission h2 {
        font-size: 7rem;
        color: #000066;
        text-transform: uppercase;
        font-weight: 300;
    }

    .section-mission .row .col-12 {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
        padding: 0;
    }

    .section-mission .col-3 hr {
        height: 5px;
        width: 25%;
        color: #000066;
        opacity: 1 !important;
    }

    .section-mission .dropcap {
        color: #57a6dc;
        font-weight: 600;
        font-size: 5rem;
        line-height: 0.5
    }

    .mission-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        width: 80vw;
    }

    .mission-list {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-end;
        width: 60vw;
        padding-bottom: 10vh;
    }

    .mission-list hr {
        color: #000066;
        opacity: 1;
        height: 1px;
        width: 80%;
        text-align: right;
        margin: 2vh 0vw;
    }

    .mission-list .row {
        width: 90vw;
        height: 25vh;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
        align-content: flex-start;
    }

    .mission-list .row .col-3 {
        display: inline-flex;
        text-align: left;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    .mission-list .row h4 {
        transform: rotate(-90deg);
        font-size: 2rem;
        font-weight: 600;
        color: #000;
        display: inline-flex;
        flex-direction: column;
        text-align: center;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .mission-list .row h4 span {
        font-size: 1.5rem;
        font-weight: 600;
        color: #9a9a9a;
    }

    .mission-list .row .col-6 p {
        font-weight: 600;
        color: #1d2054;
        font-size: 1.75rem;
        line-height: 1.75;
    }

    .mission-header h3 {
        font-size: 4rem;
        font-weight: 600;
        color: #000;
        text-transform: uppercase;
    }

    .stats-banner {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        height: 125vh;
        background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(../images/gfi_stats.jpg) no-repeat center center;
        background-size: cover;
        background-attachment: fixed;
        z-index: auto !important;
    }

    .stats-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-top: 5vh;
    }

    .stats-header h3 {
        font-size: 6rem;
        font-weight: 400;
        color: #fff;
        text-transform: uppercase;
        line-height: 1;
        white-space: nowrap;
        transform: scaleY(1.1) !important;
    }

    .inner-stats {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-bottom: 5vh;
    }

    .stats-banner hr {
        width: 10vw;
        color: #fff;
        height: 1px;
        opacity: 1;
    }

    .stats-col-R {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
        width: 30vw;
        padding-left: 2vw;
        text-align: center;
    }

    .stats-col-R .col-4 {
        height: 20vh;
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: flex-start;
    }

    .stats-banner h2 {
        font-size: 2rem;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 900;
    }

    .stats-col-R h3 {
        color: #fff;
    }

    .stats-col-R h4 {
        font-size: 3rem;
        text-transform: uppercase;
        color: #fff;
        font-weight: 600;
        text-align: left;
        display: flex;
        flex-direction: column;
    }

    .stats-col-R h4 span {
        font-weight: 400;
        font-size: 2rem;
        white-space: nowrap;
    }

    .stats-col-L {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
        width: 30vw;
        text-align: center;
    }

    .stats-col-L .col-4 {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: flex-end;
        height: 20vh;
    }

    .stats-col-L h3 {
        color: #fff;
    }

    .stats-col-L h4 {
        font-size: 3rem;
        text-transform: uppercase;
        color: #fff;
        font-weight: 600;
        text-align: left;
        display: flex;
        flex-direction: column;
    }

    .stats-col-L h4 span {
        font-weight: 400;
        font-size: 2rem;
    }

    .email-banner {
        width: 100vw;
        height: 75vh;
        background: url(../images/cta.jpg) no-repeat center center;
        background-size: cover;
        background-attachment: fixed;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .email-banner .inner {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;

    }

    .email-banner .inner p {
        color: #fff;
        font-weight: 600;
        font-size: 4rem;
        text-transform: uppercase;
        transform: scaleY(1.1) !important;
        text-align: center;
        padding-bottom: 5vh;
    }

    .services {
        height: auto;
        width: 100vw;
        padding: 20vh 10vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        background: #fff;
        padding: 10vh 10vw;


    }

    .services-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-content: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        text-align: left;
    }

    .services-title h3 {
        font-size: 3rem;
        font-weight: 600;
        text-transform: uppercase;
        color: #ffffff;
        display: flex;
        flex-direction: column;

    }

    .services-title span {
        color: #f1f1f1;
        font-weight: 400;
        font-size: 2rem;
        white-space: nowrap;

    }

    .mission-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-content: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        text-align: left;
    }

    .mission-title h3 {
        font-size: 2rem;
        font-weight: 600;
        text-transform: uppercase;
        color: #000066;
        display: flex;
        flex-direction: column;

    }

    .mission-title span {
        color: #939393;
        font-weight: 400;
        font-size: 1.5rem;
        white-space: nowrap;

    }

    .services .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        width: 100vw;
        padding: 10vh 5vw 10vh 5vw;
        text-align: left;

    }

    .services .row .col-12 {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        text-align: right;
        padding: 0;
    }

    .services h2 {
        font-size: 7rem;
        color: #000066;
        text-transform: uppercase;
        font-weight: 300;

    }

    .services-row {
        text-align: center;
        display: flex;
        width: 80vw;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        padding: 10vh 0vw 25vh 0vw;
    }

    .services-row .col-4 {
        margin: 0vh 2.5vw 0vh 2.5vw;
        padding: 2vh 1.5vw 10vh 1.5vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: flex-start;
        height: 410px;
        background: #000066;
        background-size: cover;
    }

    .services-row .col-4 span {
        width: 225px;
        text-align: left;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: baseline;
        transition: 500ms ease;
        transform: translateX(0vw);
    }

    .services-row .col-4 span:hover {
        transition: 500ms ease;
        transform: translateX(1vw)
    }

    .services-row .col-4 span i {
        position: relative;
        padding: 0vh 50% 0vh 0;
        color: #ffff;
    }

    .services-row h3 {
        font-size: 2rem;
        text-transform: uppercase;
        text-align: left;
        line-height: 1;
        color: #fff;
    }

    .services-row .col-4 p {
        text-align: justify;
        text-align-last: left;
        padding-top: 2vh;
        color: #fff;
        hyphens: auto;
        -webkit-hyphens: auto;
    }

    .services-btn {
        border: none;
        border-radius: 2vw;
        width: auto;
        background: transparent;
        color: #fff;
        font-size: 1rem;
        transition: 500ms ease;
        padding: 0;
        text-align: left;
        white-space: nowrap;
    }

    .services-btn:hover {
        width: auto;
        background: transparent;
        color: #f1f1f1;
        font-size: 1rem;
        padding: 0;
        transition: 500ms ease;
        cursor: pointer;
        white-space: nowrap;
    }

    .services-row hr {
        color: #fff;
        height: 1px;
        width: 5vw;
    }

    @media (max-width:991.98px) {
        .section-about {
            padding-bottom: 2.5rem
        }
    }

    .section-about h2 {
        align-items: flex-end;
        color: #000;
        display: flex;
        font-size: 1.5rem;
        font-weight: 400;
        height: 60px;
        line-height: 1;
        margin-bottom: 0;
        padding-bottom: 10px
    }

    .section-about [class*=col] {
        margin-bottom: 75px
    }

    @media (max-width:991.98px) {
        .section-about [class*=col] {
            margin-bottom: 25px
        }
    }

    .section-about [class*=col].related {
        margin-bottom: 0
    }

    @media (max-width:991.98px) {
        .section-about [class*=col].related {
            margin-bottom: 50px
        }
    }

    .section-about [class*=col]:nth-child(1) {
        order: 1
    }

    @media (max-width:991.98px) {
        .section-about [class*=col]:nth-child(1) {
            order: 1
        }
    }

    .section-about [class*=col]:nth-child(2) {
        order: 2
    }

    @media (max-width:991.98px) {
        .section-about [class*=col]:nth-child(2) {
            order: 3
        }
    }

    .section-about [class*=col]:nth-child(3) {
        order: 3
    }

    @media (max-width:991.98px) {
        .section-about [class*=col]:nth-child(3) {
            order: 2
        }
    }

    .section-about [class*=col]:nth-child(4) {
        order: 4
    }

    @media (max-width:991.98px) {
        .section-about [class*=col]:nth-child(4) {
            margin-bottom: 0;
            order: 4
        }
    }

    .section-about .v-line {
        bottom: 5rem;
        top: 60px
    }

    .section-about .img-wrapper {
        margin-bottom: 50px
    }

    @media (max-width:991.98px) {
        .section-about .img-wrapper {
            margin-bottom: 15px
        }
    }

    .section-about .nav-pills {
        margin-bottom: 4rem
    }

    @media (max-width:991.98px) {
        .section-about .nav-pills {
            margin-bottom: 1.5rem
        }
    }

    .section-about .nav-pills .nav-link {
        border-width: 2px 0 1px
    }

    .section-about .tab-content {
        height: auto
    }

    .paragraph-align {
        text-align: justify;
        hyphens: auto;
        -webkit-hyphens: auto;
    }

    .section-about .outline-big-text {
        font-size: 35px
    }

    .section-about .owl-nav {
        bottom: 0;
        color: red;
        left: 0;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0
    }

    .section-about .owl-nav .owl-prev {
        left: -25px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%)
    }

    .section-about .owl-nav .owl-next {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%)
    }

    .section-partners {
        margin-bottom: 2rem
    }

    .section-partners .partners-image {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 30%;
        height: 50vh;
        min-height: 300px;
        margin-right: 1rem
    }

    @media (max-width:991.98px) {
        .section-partners .partners-image {
            height: 40vh;
            min-height: auto;
            margin-right: 0
        }
    }

    .section-partners .partners {
        display: flex;
        flex-direction: column;
        height: 50vh;
        min-height: 300px;
        justify-content: flex-start;
        align-items: stretch;
        margin-left: 1rem;
        overflow: auto;
        max-width: 600px
    }

    @media (max-width:991.98px) {
        .section-partners .partners {
            height: auto;
            min-height: auto;
            margin-left: 0;
            margin-top: 1rem
        }
    }

    .section-partners .partners .partner {
        margin-top: 1.5rem
    }

    @media (min-width:1600px) {
        .section-partners .partners .partner {
            margin-top: 2.5rem
        }
    }

    @media (min-width:1921px) {
        .section-partners .partners .partner {
            margin-top: 3.5rem
        }
    }

    .section-partners .partners .partner:first-child {
        margin-top: 0
    }

    .section-partners .partners .partner .name {
        line-height: 26px;
        font-size: 14px;
        font-weight: 700;
        margin: 0
    }

    .section-partners .partners .partner .function {
        color: #000066
    }

    .section-partners .partners .partner .function::after {
        color: #000;
        content: " | ";
        display: inline;
        margin: 0 .25rem;
        vertical-align: middle
    }

    .section-partners .partners .partner .email {
        color: #000
    }

    .section-partners .partners .partner .description {
        margin-top: .5rem;
        line-height: 26px
    }

    .section-teams .outline-big-text {
        margin-bottom: 1.5rem
    }

    .section-teams .team-carousel {
        position: relative
    }

    .section-teams .team-carousel .member {
        padding: 20px
    }

    .section-teams .team-carousel .member img {
        border-color: rgba(255, 255, 255, 0);
        border-style: solid;
        border-width: 1px 0
    }

    .section-teams .team-carousel .member .member-infos {
        align-items: center;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        opacity: 0;
        padding-top: 1rem
    }

    .section-teams .team-carousel .member .member-infos .function,
    .section-teams .team-carousel .member .member-infos .name,
    .section-teams .team-carousel .member .member-infos .role {
        line-height: 26px
    }

    .section-teams .team-carousel .member .member-infos .name {
        color: #000066;
        font-weight: 700
    }

    .section-teams .team-carousel .member .member-infos .role {
        font-weight: 400
    }

    .section-teams .team-carousel .member .member-infos .function {
        color: #000066
    }

    .section-teams .team-carousel .member .member-infos .email a {
        color: #000
    }

    .section-teams .team-carousel .member:hover img {
        border-color: #000066
    }

    .section-teams .team-carousel .member:hover .member-infos {
        opacity: 1
    }

    .section-teams .team-carousel .owl-nav {
        color: #000066
    }

    .section-teams .team-carousel .owl-nav svg {
        height: auto;
        width: 25px
    }

    .section-teams .team-carousel .owl-nav .owl-next,
    .section-teams .team-carousel .owl-nav .owl-prev {
        align-items: center;
        background-color: #fff;
        border-radius: 50%;
        display: flex;
        height: 40px;
        justify-content: center;
        position: absolute;
        top: calc(50% - 75px);
        width: 40px
    }

    .section-teams .team-carousel .owl-nav .owl-next:active,
    .section-teams .team-carousel .owl-nav .owl-next:focus,
    .section-teams .team-carousel .owl-nav .owl-prev:active,
    .section-teams .team-carousel .owl-nav .owl-prev:focus {
        outline: 0
    }

    .section-teams .team-carousel .owl-nav .owl-next:hover,
    .section-teams .team-carousel .owl-nav .owl-prev:hover {
        background-color: #000066;
        color: #fff
    }

    .section-teams .team-carousel .owl-nav .owl-prev {
        left: 50px;
        transform: translateX(100%) translateY(-50%)
    }

    @media (max-width:991.98px) {
        .section-teams .team-carousel .owl-nav .owl-prev {
            left: 0
        }
    }

    .section-teams .team-carousel .owl-nav .owl-next {
        right: 50px;
        transform: translateX(-100%) translateY(-50%)
    }

    @media (max-width:991.98px) {
        .section-teams .team-carousel .owl-nav .owl-next {
            right: 0
        }
    }

    .section-worked-with-us {
        padding-bottom: 5rem;
        padding-top: 5rem
    }

    @media (max-width:991.98px) {
        .section-worked-with-us {
            padding-bottom: 2.5rem;
            padding-top: 2.5rem
        }
    }

    .section-worked-with-us p {
        line-height: 2;
        margin-bottom: 0;
        padding-right: 0;
        text-align: justify
    }

    @media (max-width:575.98px) {
        .section-worked-with-us p {
            padding-right: 0
        }
    }

    @media (max-width:767.98px) {
        .section-worked-with-us p {
            padding-right: 0
        }
    }

    .section-worked-with-us p span {
        color: #000066;
        padding: 0 .25rem
    }

    .section-contact {
        padding-bottom: 5rem;
        padding-top: 5rem
    }

    @media (max-width:991.98px) {
        .section-contact {
            padding-bottom: 2.5rem;
            padding-top: 0
        }
    }

    .section-contact a {
        color: #343a40
    }

    .section-contact a:hover {
        color: #000066
    }

    .section-contact iframe {
        height: 400px;
        width: 100%
    }

    .section-contact .flex-wrapper {
        display: flex
    }

    @media (max-width:991.98px) {
        .section-contact .flex-wrapper {
            justify-content: flex-start
        }
    }

    .section-contact .fa-ul {
        margin-left: 3rem
    }

    .section-contact .fa-ul li {
        margin-bottom: 2rem
    }

    .section-contact .fa-ul li .fa-li {
        color: #ff6a6a;
        font-size: 1.5rem;
        left: -2.5em;
        margin-top: .5rem
    }

    .section-contact .fa-ul p {
        line-height: 1.75;
        padding-left: 1rem;
        padding-top: .5rem
    }

    .section-contact .fa-ul p svg {
        margin-left: .5rem
    }

    .section-contact .follow-us {
        font-size: 1rem
    }

    .section-contact .follow-us span {
        display: block;
        margin-bottom: .25rem
    }

    .section-contact .follow-us a {
        margin-right: .5rem
    }

    .section-news {
        padding-bottom: 10vh;
        padding-top: 20vh;
    }

    @media (max-width:991.98px) {
        .section-news {
            padding-bottom: 2.5rem
        }
    }

    .section-news .news-wrapper a {
        text-decoration: none
    }

    .section-news .news-wrapper .row {
        margin-left: -10px;
        margin-right: -10px
    }

    .section-news .news-wrapper .row > [class*=col] {
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 5vh;
        padding-top: 5vh;

    }

    .section-news .news-wrapper .news-item::before {
        display: none
    }

    .section-news .news-wrapper .news-item::after {
        display: none
    }

    .section-news .news-wrapper .news-item .img-effect a {
        display: block
    }

    .section-news .news-wrapper .news-title {
        color: #000;
        display: block;
        margin-top: 1rem
    }

    .section-news .news-wrapper .news-title h3 {
        color: #343a40;
        font-size: 16px;
        font-weight: 800;
        letter-spacing: .5px;
        margin-bottom: 3px
    }

    .section-news .news-wrapper .news-infos {
        color: #343a40;
        font-size: 13px
    }

    .section-news .news-wrapper .news-category {
        color: #343a40;
        font-weight: 600;
        padding-right: .25rem
    }

    .section-news .news-wrapper .news-date {
        padding-left: .25rem
    }

    .section-news .news-pagination {
        margin: 2.5rem auto;
        text-align: center
    }

    .section-news .news-pagination ul {
        justify-content: center
    }

    .section-news .news-pagination ul li a {
        align-items: center;
        background: rgba(255, 255, 255, 0);
        border: 1px solid #000066;
        border-radius: 50%;
        display: flex;
        height: 30px;
        justify-content: center;
        margin: 0 3px;
        padding-top: 4px;
        width: 30px
    }

    .section-news .news-pagination ul li a:hover {
        background-color: #000066;
        color: #fff
    }

    .section-news .news-pagination ul li.active a {
        background-color: #000066;
        color: #fff
    }

    .section-news .news-content p {
        line-height: 1.75
    }

    .section-news .news-content p:last-child {
        margin-bottom: 0
    }

    .section-news .news-author {
        border-color: #000066;
        border-style: solid;
        border-width: 1px 0;
        margin-top: 3rem;
        padding: 1rem 0
    }

    .section-news .news-author p {
        margin: 0
    }

    .section-news .news-author .name {
        font-weight: 600
    }

    .section-news .news-share {
        color: #343a40;
        text-align: center
    }

    .section-news .news-share p {
        font-size: 1rem;
        letter-spacing: 1px;
        margin-bottom: .25rem
    }

    .section-news .news-share .icons-wrapper a {
        color: #343a40;
        margin: 0 .5rem
    }

    .section-news .news-share .icons-wrapper a:hover {
        color: #000066
    }

    .section-introduction {
        padding-bottom: 5rem;
        padding-top: 5rem
    }

    @media (max-width:991.98px) {
        .section-introduction {
            padding-bottom: 1.5rem;
            padding-top: 1.5rem
        }
    }

    .section-bio {
        padding-top: 25vh;
        height: 100vh;
    }

    @media (max-width:991.98px) {
        .section-bio {
            padding-bottom: 1.5rem;
            padding-top: 1.5rem
        }
    }

    @media (max-width:1199.98px) {
        .section-bio {
            margin-bottom: 2.5rem
        }
    }

    .bio-name {
        color: #000066;
        font-size: 2rem;
        font-weight: 200;
    }

    .bio-title {
        color: rgba(60, 60, 60, 0.6392156863);
        font-size: 1.15rem;
    }

    .bio-img {
        padding-bottom: 2vh;
    }

    .bio-img img {
        height: 35vh;
        width: auto;
    }

    .bio-contact {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;

    }

    .bio-contact-line {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: stretch;
    }

    .bio-contact-line i {
        padding-right: 1vw;
    }

    .bio-contact-line a {
        color: #000;
        text-decoration: none;
    }

    .section-job {
        margin-bottom: 5rem
    }

    @media (max-width:991.98px) {
        .section-job {
            padding-bottom: 1.5rem;
            padding-top: 1.5rem
        }
    }

    @media (max-width:1199.98px) {
        .section-job {
            margin-bottom: 2.5rem
        }
    }

    .section-job.section-top-page {
        margin-bottom: 0
    }

    .section-job.section-top-page .flex-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%
    }

    .section-job.section-top-page .times-wrapper {
        align-items: flex-start;
        color: #000066;
        display: flex;
        font-size: 1.5rem;
        opacity: .85;
        padding-top: .25rem;
        transition: opacity .25s ease
    }

    .section-job.section-top-page .times-wrapper:hover {
        color: #000066;
        opacity: 1
    }

    .section-job.section-full-primary {
        margin-bottom: 5rem
    }

    .section-job .job-header {
        display: flex;
        flex-direction: column;
        margin-top: -.25rem
    }

    @media (max-width:575.98px) {
        .section-job .job-header {
            padding-left: 50px
        }
    }

    .section-job .job-header .page-title {
        margin-bottom: 1rem
    }

    @media (max-width:575.98px) {
        .section-job .job-header .page-title {
            justify-content: flex-start
        }
    }

    .section-job .job-header .job-title h3 {
        font-size: 1.75rem;
        font-weight: 800;
        margin-bottom: .25rem
    }

    .section-job .job-header .job-description p {
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.25
    }

    .section-job .job-content {
        padding-right: 5rem
    }

    @media (max-width:1399.98px) {
        .section-job .job-content {
            padding-right: 2.5rem
        }
    }

    @media (max-width:991.98px) {
        .section-job .job-content {
            padding-right: 0
        }
    }

    .section-job .job-content h3 {
        color: #000066;
        font-size: .9rem;
        font-weight: 700;
        margin-bottom: 1.5rem;
        text-transform: uppercase
    }

    .section-job .job-content p {
        margin-bottom: 2rem
    }

    @media (max-width:991.98px) {
        .section-job .job-content p {
            text-align: justify
        }
    }

    .section-job .job-content ul {
        margin-bottom: 4rem
    }

    .section-job .job-content ul li {
        margin-bottom: 1rem;
        padding-left: 35px;
        position: relative
    }

    @media (max-width:991.98px) {
        .section-job .job-content ul li {
            text-align: justify
        }
    }

    .section-job .job-content ul li::before {
        background-color: #000066;
        content: '';
        height: 2px;
        left: 0;
        position: absolute;
        top: .75em;
        width: 8px
    }

    .section-job .job-content :last-child {
        margin-bottom: 0
    }

    .section-job .job-contact {
        height: 100%;
        min-height: 400px;
        padding-left: 5rem;
        position: relative
    }

    @media (max-width:1399.98px) {
        .section-job .job-contact {
            padding-left: 2.5rem
        }
    }

    @media (max-width:1199.98px) {
        .section-job .job-contact {
            min-height: auto;
            padding-left: 0;
            padding-top: 2.5rem
        }
    }

    .section-job .job-contact::before {
        background-color: #000066;
        content: '';
        height: 50%;
        left: 0;
        min-height: 400px;
        position: absolute;
        top: 0;
        width: 1px
    }

    @media (max-width:1199.98px) {
        .section-job .job-contact::before {
            display: none
        }
    }

    .section-job .job-contact p {
        margin-bottom: 1.5rem
    }

    .section-job .job-contact p span {
        font-weight: 700
    }

    .section-job .job-contact .btn {
        font-family: Gilroy;
        font-weight: 800
    }

    .section-latest-news {
        display: flex;
        height: auto;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        padding-top: 10vh;
    }

    .section-latest-news .row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
    }

    .section-latest-news .row > [class*=col] {
        padding-left: 10px;
        padding-right: 10px
    }

    .article {
        padding: 10vh 2vw 0vh 2vw;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
    }

    .article-heading {
        padding: 1.5rem 0;
        text-align: center
    }

    .article-heading h1 {
        display: block;
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 0;
        padding: 2.5rem 25px 1.5rem
    }

    .article-heading .tags {
        display: flex;
        justify-content: center;
        margin-top: 2.5rem
    }

    .article-heading .tags li a {
        display: block;
        line-height: 1.25;
        padding: .25rem 1rem 0;
        position: relative
    }

    .article-heading .tags li a::before {
        background-color: #000066;
        bottom: 4px;
        content: '';
        position: absolute;
        right: 0;
        top: 4px;
        width: 1px
    }

    .article-heading .tags li:last-child a::before {
        display: none
    }

    .owl-carousel {
        z-index: 0
    }

    .owl-theme .owl-dots {
        bottom: 5px;
        margin: 0;
        position: absolute;
        right: 15px
    }

    .owl-theme .owl-dots .owl-dot {
        outline: 0
    }

    .owl-theme .owl-dots .owl-dot span {
        background-color: #fff;
        border: 1px solid #000066
    }

    .owl-theme .owl-dots .owl-dot.active span,
    .owl-theme .owl-dots .owl-dot:hover span {
        background-color: #000066;
        border: 1px solid #000066
    }

    .btn {
        border-radius: 0;
        padding: .5rem 1rem
    }

    .btn.big-text {
        font-family: Gilroy;
        font-size: 1.5rem;
        font-weight: 800
    }

    .arrow-wrapper-white .arrow,
    .custom-bounce {
        animation-name: custom-bounce;
        transform-origin: center bottom
    }

    .customFadeInUp {
        animation-name: customFadeInUp
    }

    .customSlideInUp {
        animation-name: customFadeInUp
    }

    @keyframes custom-bounce {

        10.6%,
        16%,
        4%,
        from,
        to {
            animation-timing-function: cubic-bezier(.215, .61, .355, 1);
            transform: translate3d(0, 0, 0)
        }

        8%,
        8.6% {
            animation-timing-function: cubic-bezier(.755, .05, .855, .06);
            transform: translate3d(0, -30px, 0)
        }

        14% {
            animation-timing-function: cubic-bezier(.755, .05, .855, .06);
            transform: translate3d(0, -15px, 0)
        }

        18% {
            transform: translate3d(0, 0, 0)
        }
    }

    @keyframes fade-from-none {
        0% {
            display: none;
            opacity: 0
        }

        1% {
            display: block;
            opacity: 0
        }

        100% {
            display: block;
            opacity: 1
        }
    }

    @keyframes banner-line {
        0% {
            height: 0
        }

        100% {
            height: 100%
        }
    }

    @keyframes banner-arrow {
        0% {
            opacity: 0;
            top: 0;
            transform: translateY(0)
        }

        100% {
            opacity: 1;
            top: 50%;
            transform: translateY(-100%)
        }
    }

    @keyframes customFadeInUp {
        from {
            opacity: 0;
            transform: translate3d(0, 50px, 0);
            visibility: visible
        }

        to {
            opacity: 1;
            transform: translate3d(0, 0, 0)
        }
    }

    @keyframes customSlideInUp {
        from {
            opacity: 0;
            transform: translate3d(0, 25px, 0);
            visibility: visible
        }

        to {
            opacity: 1;
            transform: translate3d(0, 0, 0)
        }
    }

    .admin-container {
        margin-top: -150px;
        padding: 50px
    }

    .admin-container input {
        min-width: 300px
    }

    .admin-container .btn {
        border-radius: 4px;
        line-height: 1;
        padding-top: 10px
    }

    #ie-banner {
        text-align: center;
        padding-top: 15vh;
        position: absolute;
    }

    #ie-banner img {
        max-height: 30vh;
        max-width: 80%;
        width: auto
    }

    .ie #ie-banner {
        display: block
    }

    .ie .section-banner #animation-wrapper {
        display: none
    }

    .ie .section-worked-with-us .col-auto {
        width: 100%
    }

    .ie .section-worked-with-us .section-title.vertical {
        margin-bottom: 15px;
        text-orientation: initial;
        transform: none;
        writing-mode: initial !important
    }

    .ie .section-gallery .image-infos {
        text-align: right;
        transform: none
    }

    .ie-banner-1 {
        width: 100vw;
    }

    .ie-banner-2 {
        width: 100vw;
    }

    .ie-banner-3 {
        width: 100vw;
    }

    .ie-banner-4 {
        width: 100vw;
    }

    .ie-banner-5 {
        width: 80vw;
    }

    .login-section {
        height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-left: 0;
    }

    .login-inner {
        padding-left: 15vw;
        height: 50vh;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: flex-start;
    }

    .login-img {
        background: url(../images/banner/login.jpg) center center no-repeat;
        height: 100vh;
    }

    .title-row {
        display: inline-flex;
    }

    .contact-section {
        height: 100vh;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding: 20vh 10vw;
    }

    .contact-section .row {
        width: 100%;
    }

    .contact-inner {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: stretch;
    }

    .contact-info {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .contact-info-inner {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: flex-start;
        height: 40vh;
    }

    .contact-info-inner .bi {
        margin-right: 2vw;
    }

    .contact-info-inner a {
        color: #000066;
        font-size: 1rem;
        line-height: 1.5;
        text-decoration: none;

    }

    .contact-form {
        height: 40vh;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: stretch;
    }

    .contact-control {
        border: none;
        border-bottom: 1px solid #ced4da;
        border-radius: 0px !important;
    }

    .contact-control:focus {
        border-bottom: 1px solid #000066;
        border-radius: 0px !important;
        box-shadow: none !important;
    }

    .contact-select {
        border: none;
        border-bottom: 1px solid #ced4da;
        border-radius: 0px !important;
    }

    .contact-select:focus {
        border-bottom: 1px solid #000066;
        border-radius: 0px !important;
        box-shadow: none !important;
    }

    //JOIN TEAM FORM
    .join-team-form {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .join-team-form .form-control {
        background: rgba(255, 255, 255, 0.8509803922);
        width: 35vh;
        height: 5vh;
        border-radius: 2px;
    }

    .join-team-form .row {
        padding-bottom: 5vh;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .form-file input[type=file]::-webkit-file-upload-button {
        height: 5vh;

    }

    .form-file input[type=file]::file-selector-button {
        height: 5vh;
    }

    //HOVER UNDERLINE
    .hover-underline {
        position: relative;
    }

    .hover-underline:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        transition: width 0s ease, background .5s ease;
    }

    .hover-underline:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        background: #000066;
        transition: width .5s ease;
    }

    .hover-underline:hover:before {
        width: 100%;
        background: #000066;
        transition: width .5s ease;
    }

    .hover-underline:hover:after {
        width: 100%;
        background: transparent;
        transition: all 0s ease;
    }

    //HOVER UNDERLINE WHITE
    .hover-underline-white {
        position: relative;
    }

    .hover-underline-white:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        transition: width 0s ease, background .5s ease;
    }

    .hover-underline-white:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        background: #f1f1f1;
        transition: width .5s ease;
    }

    .hover-underline-white:hover:before {
        width: 100%;
        background: #f1f1f1;
        transition: width .5s ease;
    }

    .hover-underline-white:hover:after {
        width: 100%;
        background: transparent;
        transition: all 0s ease;
    }

    //HOVER UNDERLINE RED
    .hover-underline-red {
        position: relative;
    }

    .hover-underline-red:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        transition: width 0s ease, background .5s ease;
    }

    .hover-underline-red:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        background: #000066;
        transition: width .5s ease;
    }

    .hover-underline-red:hover:before {
        width: 100%;
        background: #000066;
        transition: width .5s ease;
    }

    .hover-underline-red:hover:after {
        width: 100%;
        background: transparent;
        transition: all 0s ease;
    }

    //ARROW DOWN 
    .arrow-down {
        display: inline-flex;
        position: fixed;
        bottom: 5vh;
        width: 1.5vw;
    }

    //ARROW DOWN HOME PAGE
    .arrow-down-animate {
        animation-name: loop-down;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    @keyframes loop-down {
        0% {
            transform: translateY(0vh);
            opacity: 0
        }

        25% {
            transform: translateY(1vh);
            opacity: 0.5
        }

        50% {
            transform: translateY(2vh);
            opacity: 1
        }

        75% {
            transform: translateY(3vh);
            opacity: 0.5
        }

        100% {
            transform: translateY(4vh);
            opacity: 0
        }
    }

    //COUNTERS
    #counters_1,
    #counters_2,
    #counters_3 {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
        height: 20vh;
        width: 30vw;
    }

    .counter {
        font-size: 7rem;

    }

    .counter-wrapper {
        display: flex;
        height: 10vh;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-end;
    }

    //SWUP
    .transition-fade {
        transition: 0.4s;
        opacity: 1;
    }

    html.is-animating .transition-fade {
        opacity: 0;
    }

    //HOME
    .home-header {
        height: 150vh;
        widows: 100vh;
        object-fit: cover;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        background: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.8)), url(../images/banner/header.jpg) no-repeat center center;
        background-size: cover;
        background-attachment: fixed;
    }

    .first-letter {
        -webkit-initial-letter: 4;
        initial-letter: 4;
        color: orange;
        font-weight: bold;
        margin-right: .75em;
    }

    .intro {
        height: 50vh;
        width: 100vw;
        display: flex;
        z-index: 9500;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        position: absolute;
        padding: 0vh 10vw 5vh 10vw;
        left: 0;
        text-align: left;
        top: 50vh;
    }

    .intro .txt hr {
        width: 95vw;
        height: 2px;
        opacity: 1;
        color: #fff;
    }

    .intro .txt h3 {
        font-size: 6rem;
        font-weight: 400;
        color: #fff;
        text-transform: uppercase;
        line-height: 1;
        white-space: nowrap;
        transform: scaleY(1.1) !important;
    }

    .intro .txt p {
        font-size: 1.15rem;
        font-weight: 400;
        color: #fff;
        line-height: 1.5;
        text-align: justify;
    }

    .dropcap {
        background: transparent;
        color: #FDF9F2;
        float: left;
        font-size: 4.0rem;
        line-height: 1;
        margin: 0.2em 0.1em 0em 0;
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    .dropcap:before,
    .dropcap:after {
        content: "";
        display: block;
    }

    .dropcap:before {
        margin-top: -0.2em;
    }

    .dropcap:after {
        margin-bottom: -0.15em;
    }

    .sr-only:not(:focus):not(:active) {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    .intro-btn {
        background: #fff;
        border: 1px solid #fff;
        height: 8vh;
        width: 16vw;
        border-radius: 2px;
        margin: 1vw;
        margin-left: 0;
        color: #000;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.05vw;
        transition: 500ms !important;
    }

    .intro-btn:hover {
        background: #fff;
        border: 2px solid #fff;
        height: 8vh;
        width: 16vw;
        border-radius: 2px;
        margin: 1vw;
        margin-left: 0;
        color: #000066;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.05vw;
        transition: 500ms !important;
    }

    .video-overlay {
        background: rgba(0, 0, 0, 0.4);
        height: 100vh;
        width: 100vw;
        position: absolute;
        top: 0;
        z-index: 9000;
    }

    .intro_1 {
        height: auto;
        width: 100vw;
        background: #fff;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding: 20vh 10vw;
    }

    .intro_1 h1 {
        color: #000066;
        font-size: 6rem;
        font-weight: 300;
        text-transform: uppercase;
        margin: 0;
        padding-bottom: 2vh;
    }

    .top-img {
        position: absolute;
        width: 15%;
        z-index: 8500;
        right: 5vw;
        bottom: 60vh;
        box-shadow: black;
        -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
        box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
    }

    .mid-img {
        position: absolute;
        right: 7vw;
        bottom: 23vh;
        width: 40%;
        box-shadow: black;
        -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
        box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
    }

    .bot-img {
        position: absolute;
        width: 25%;
        right: 10vw;
        bottom: 20vh;
        box-shadow: black;
        -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
        box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
    }

    .listings-btn {
        background: #fff;
        border: none;
        height: 4vh;
        width: 10vw;
        border-radius: 2px;
        margin: 1vw;
        margin-left: 0;
    }

    //SLIDE OVER SECTIONS
    .info-contain {
        width: 100vw;
        height: 100vh;
        height: 100%;
        height: fill-available;
        transition-duration: 600ms;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.8, 0, 0, 1);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 4;
        background: #fff;
        background-size: cover;
        -ms-transform: translate(100%, 0);
        /* IE 9 */
        -webkit-transform: translate(100%, 0);
        /* Safari */
        transform: translate(100%, 0);
        /* Standard syntax */
        padding-top: 20vh;
        overflow: scroll;
        margin: 0;
    }

    .info-contain.opened {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100vh;
        height: 100%;
        height: fill-available;
        transition-duration: 600ms;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.8, 0, 0, 1);
        -ms-transform: translate(0, 0);
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
        z-index: 9999 !important;
        padding-top: 20vh;
        overflow: scroll;
        margin: 0;
    }

    .overlay-element-innovation {
        width: 100vw;
        display: table;
        float: left;
        height: 100vh;
        color: #fff;
    }

    .overlay-txt {
        display: flex;
        vertical-align: middle;
        text-align: justify;
        text-align-last: left;
        font-size: 16px;
        font-weight: 300;
        padding: 5vh 10vw;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100vh;
        color: #000;
    }

    .overlay-txt h2 {
        font-size: 1.25rem;
        color: #000066;
        text-transform: uppercase;
        font-weight: 900;
    }

    .overlay-txt h3 {
        font-size: 2rem;
        font-weight: 900;
        color: #000;
        text-transform: uppercase;
    }

    .close {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1;
        color: #fff;
        text-shadow: 0 1px 0 rgb(0 0 0 / 50%);
        opacity: 1;
        display: flex;
        width: 10vw;
        position: relative;
        margin-top: 5vh;
        white-space: nowrap;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: stretch;
    }

    .close p {
        font-weight: 400;
        font-size: 0.8rem;
        padding-left: 2vw;
        text-transform: uppercase;
        text-shadow: none;
        color: #000066;
    }

    .close:hover {
        color: #000066;
        text-shadow: 0 1px 0 black;
        transition: all 0.3s;
        cursor: pointer;
        transition: all 0.5s;
        transform: translateX(-1vw);
    }

    .divider {
        width: 50vw;
        margin: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 7vh;
    }

    //PRESS
    .news-img {
        width: auto;
        height: 155px !important;
        transition: 500ms;
    }

    .news-img:hover {
        transition: 500ms;
        transform: scale(1.05)
    }

    .press-img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-top-left-radius: calc(0.25rem - 1px);
        border-top-right-radius: calc(0.25rem - 1px);
    }

    //ANIMATION LOADER//
    #loader-wrapper .loader-section {
        position: fixed;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: 9998;
        text-align: center;
        background: #fff;
    }

    #loader {
        z-index: 9999;
        position: fixed;
        height: 100vh;
        width: 100vw;
        text-align: center;
        background: #fff;
    }

    .loader-logo {
        position: relative;
        top: 3vh;
        height: auto;
        width: auto;
        text-align: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .loader_img_1 {
        animation: loader_1 4s ease;
        opacity: 0;
    }

    .loader_img_2 {
        animation: loader_2 4s ease;
        opacity: 0;
    }

    @keyframes loader_1 {
        0% {
            transform: translate(100vw, 40vh);
            opacity: 0;
        }

        60% {
            transform: translate(0vw, 40vh);
            opacity: 1;
        }

        70% {
            transform: translate(0vw, 40vh);
            opacity: 1;
        }

        100% {
            transform: translate(0vw, 40vh);
            opacity: 0;
        }
    }

    @keyframes loader_2 {
        0% {
            transform: translate(50vw, 40vh);
            opacity: 0;
        }

        40% {
            opacity: 0;
        }

        60% {
            transform: translate(0vw, 40vh);
            opacity: 1;
        }

        70% {
            transform: translate(0vw, 40vh);
            opacity: 1;
        }

        100% {
            transform: translate(0vw, 40vh);
            opacity: 0;
        }
    }

    .loader-text-fade-out {
        opacity: 0;
        animation: fade-text 4s;
        font-weight: 600;
        font-size: 1rem;
    }

    @keyframes fade-text {
        0% {
            opacity: 0;
        }

        30% {
            opacity: 1;
        }

        90% {
            opacity: 1;
        }

        100% {
            opacity: 0;
            visibility: hidden;
        }
    }

    #loader {
        opacity: 0;
        visibility: hidden;
        animation: fade-background 4s;
    }

    @keyframes fade-background {
        0% {
            opacity: 1;
            visibility: visible;
        }

        80% {
            opacity: 1;
            visibility: visible;
        }

        100% {
            opacity: 0;
            visibility: hidden;
        }
    }

    .sign-up-form {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: baseline;
        width: 65vh;

    }

    .sign-up-form .form-control {
        background: #ffffffd9;
        width: 35vh;
        height: 5vh;
        border-radius: 2px;
    }

    .sign-up-form .form-control:focus {
        background: #ffffff;
        width: 35vh;
        height: 5vh;
        border-radius: 10px;
        border-color: #000066
    }

    .sign-up-btn {
        background: #ffffff;
        color: #000;
        height: 5vh;
        width: 10vw;
        border: 1px solid #fff;
        border-radius: 2px;
        font-weight: 600;
        text-transform: uppercase;
    }

    .sign-up-btn:hover {
        color: #000066;
        border-color: #000066;
        font-weight: 600;
        text-transform: uppercase;
    }

    .join-team {
        background: linear-gradient(rgba(241, 241, 241, 0.6), rgba(241, 241, 241, 0.6)), url(../images/join-team.jpg) no-repeat center center;
        background-size: cover;
        height: auto;
        width: 100vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .join-team .inner {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        background: transparent;
        width: 100%;
        height: 50%;
        padding: 10vh 0vw;
    }

    .join-team .inner h3 {
        color: #000066;
        font-size: 7rem;
        font-weight: 300;
        white-space: nowrap;
        text-transform: uppercase;
    }

    .join-team .inner hr {
        width: 25%;
        color: #000066;
        opacity: 1;
        height: 2px;
    }

    .join-team .inner p {
        color: #000066;
        font-size: 1.15rem;
        font-weight: 600;
        white-space: nowrap;
        text-transform: uppercase;
        padding: 5vh 0vw 5vh 0vw;
    }

    .join-btn {
        font-weight: 600;
        height: 8vh;
        width: 15vw;
        background: #fff;
        border: 1px solid #000066;
        border-radius: 2px;
        color: #000066;
        text-transform: uppercase;
        transition: 500ms ease;
    }

    .join-btn:hover {
        font-weight: 600;
        height: 8vh;
        width: 15vw;
        background: #000066;
        border: 1px solid #000066;
        border-radius: 2px;
        color: #fff;
        text-transform: uppercase;
        transition: 500ms ease;
        cursor: pointer;
    }

    .join-text {
        padding: 0 !important;
        font-size: 1rem !important;

    }
}

@media only screen and (min-width: 1246px) {

    body {
        font-family: 'Century Gothic', sans-serif;
    }

    body + services-btn:active {
        overflow: hidden;
    }

    @media only screen and (min-width: 769px) and (max-width: 5000px) {
        .desktop-hide {
            display: none;
        }
    }

    @media only screen and (min-width: 0px) and (max-width: 768px) {
        .mobile-hide {
            display: none;
        }
    }

    b {
        font-weight: bold;
    }

    //DESKTOP MENU NAVBAR 
    .navbar {
        background-color: #fff;
        border-right: 1px solid #eee;
        display: flex;
        padding: 0vh 10vw;
        margin-top: 43px;
        position: fixed;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        top: -2vh;
        transition: left 0.25s ease;
        width: 100vw;
        height: 90px;
        z-index: 9;
        box-shadow: 1px 1px 10px rgb(0 0 0 / 25%);
    }

    .nav-link {
        color: #000;
        text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: 600;
    }

    .nav-link:hover {
        color: #000066;
        cursor: pointer;
    }

    .active-link {
        color: #000066;
    }

    .login-btn {
        color: #000066;
        text-transform: capitalize;
        text-decoration: none;
        font-size: 1rem;
        font-weight: 600;
    }

    .login-form-btn {
        background: #fff;
        color: #000066;
        line-height: 2;
        font-size: 1rem;
        border: 1px solid #000066;
        width: 10vw;
        border-radius: 2px;
        margin-top: 2vh;
        height: 8vh;
        width: 15vw;
        text-transform: uppercase;
        font-weight: 600;
        transition: 500ms ease;
    }

    .login-form-btn:hover {
        background: #000066;
        color: #fff;
        line-height: 2;
        font-size: 1rem;
        border: 1px solid #000066;
        width: 10vw;
        border-radius: 2px;
        margin-top: 2vh;
        height: 8vh;
        width: 15vw;
        text-transform: uppercase;
        font-weight: 600;
        transition: 500ms ease;
    }

    .form-text a {
        color: #6c757d;
        text-decoration: none;
    }

    .form-text a:hover {
        color: #000066;
    }

    .navbar > .container,
    .navbar > .container-fluid {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between
    }

    .navbar-brand {
        display: inline-block;
        padding-top: .3125rem;
        padding-bottom: .3125rem;
        margin-right: 1rem;
        font-size: 1.25rem;
        line-height: inherit;
        white-space: nowrap;
        width: 15vw;
    }

    .navbar-brand:focus,
    .navbar-brand:hover {
        text-decoration: none
    }

    .navbar-nav {
        display: flex;
        flex-direction: row;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
    }

    .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0
    }

    .navbar-nav .dropdown-menu {
        position: static;
        float: none
    }

    .navbar-text {
        display: inline-block;
        padding-top: .5rem;
        padding-bottom: .5rem
    }

    .navbar-collapse {
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center
    }

    .navbar-toggler {
        padding: .25rem .75rem;
        font-size: 1.25rem;
        line-height: 1;
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: .25rem
    }

    .navbar-toggler:focus,
    .navbar-toggler:hover {
        text-decoration: none
    }

    .navbar-toggler:not(:disabled):not(.disabled) {
        cursor: pointer
    }

    .navbar-toggler-icon {
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        vertical-align: middle;
        content: "";
        background: no-repeat center center;
        background-size: 100% 100%
    }

    @media (max-width:575.98px) {

        .navbar-expand-sm > .container,
        .navbar-expand-sm > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:576px) {
        .navbar-expand-sm {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-sm .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-sm .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-sm .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-sm > .container,
        .navbar-expand-sm > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-sm .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-sm .navbar-toggler {
            display: none
        }
    }

    @media (max-width:767.98px) {

        .navbar-expand-md > .container,
        .navbar-expand-md > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:768px) {
        .navbar-expand-md {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-md .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-md .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-md .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-md > .container,
        .navbar-expand-md > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-md .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-md .navbar-toggler {
            display: none
        }
    }

    @media (max-width:991.98px) {

        .navbar-expand-lg > .container,
        .navbar-expand-lg > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:992px) {
        .navbar-expand-lg {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-lg .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-lg .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-lg .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-lg > .container,
        .navbar-expand-lg > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-lg .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-lg .navbar-toggler {
            display: none
        }
    }

    @media (max-width:1199.98px) {

        .navbar-expand-xl > .container,
        .navbar-expand-xl > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:1200px) {
        .navbar-expand-xl {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-xl .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-xl .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-xl .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-xl > .container,
        .navbar-expand-xl > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-xl .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-xl .navbar-toggler {
            display: none
        }
    }

    @media (max-width:1399.98px) {

        .navbar-expand-xxl > .container,
        .navbar-expand-xxl > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:1400px) {
        .navbar-expand-xxl {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-xxl .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-xxl .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-xxl .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-xxl > .container,
        .navbar-expand-xxl > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-xxl .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-xxl .navbar-toggler {
            display: none
        }
    }

    @media (max-width:1599.98px) {

        .navbar-expand-xxxl > .container,
        .navbar-expand-xxxl > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:1600px) {
        .navbar-expand-xxxl {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-xxxl .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-xxxl .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-xxxl .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-xxxl > .container,
        .navbar-expand-xxxl > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-xxxl .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-xxxl .navbar-toggler {
            display: none
        }
    }

    @media (max-width:1920.98px) {

        .navbar-expand-uhd > .container,
        .navbar-expand-uhd > .container-fluid {
            padding-right: 0;
            padding-left: 0
        }
    }

    @media (min-width:1921px) {
        .navbar-expand-uhd {
            flex-flow: row nowrap;
            justify-content: flex-start
        }

        .navbar-expand-uhd .navbar-nav {
            flex-direction: row
        }

        .navbar-expand-uhd .navbar-nav .dropdown-menu {
            position: absolute
        }

        .navbar-expand-uhd .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem
        }

        .navbar-expand-uhd > .container,
        .navbar-expand-uhd > .container-fluid {
            flex-wrap: nowrap
        }

        .navbar-expand-uhd .navbar-collapse {
            display: flex !important;
            flex-basis: auto
        }

        .navbar-expand-uhd .navbar-toggler {
            display: none
        }
    }

    .navbar-expand {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand > .container,
    .navbar-expand > .container-fluid {
        padding-right: 0;
        padding-left: 0
    }

    .navbar-expand .navbar-nav {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        width: 50vw;

    }

    .navbar-expand .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand > .container,
    .navbar-expand > .container-fluid {
        flex-wrap: nowrap
    }

    .navbar-expand .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
    }

    .navbar-expand .navbar-toggler {
        display: none
    }

    .navbar-light .navbar-brand {
        color: rgba(0, 0, 0, .9)
    }

    .navbar-light .navbar-brand:focus,
    .navbar-light .navbar-brand:hover {
        color: rgba(0, 0, 0, .9)
    }

    .navbar-light .navbar-nav .nav-link {
        color: rgba(0, 0, 0, .5)
    }

    .navbar-light .navbar-nav .nav-link:focus,
    .navbar-light .navbar-nav .nav-link:hover {
        color: rgba(0, 0, 0, .7)
    }

    .navbar-light .navbar-nav .nav-link.disabled {
        color: rgba(0, 0, 0, .3)
    }

    .navbar-light .navbar-nav .active > .nav-link,
    .navbar-light .navbar-nav .nav-link.active,
    .navbar-light .navbar-nav .nav-link.show,
    .navbar-light .navbar-nav .show > .nav-link {
        color: rgba(0, 0, 0, .9)
    }

    .navbar-light .navbar-toggler {
        color: rgba(0, 0, 0, .5);
        border-color: rgba(0, 0, 0, .1)
    }

    .navbar-light .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0,0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
    }

    .navbar-light .navbar-text {
        color: rgba(0, 0, 0, .5)
    }

    .navbar-light .navbar-text a {
        color: rgba(0, 0, 0, .9)
    }

    .navbar-light .navbar-text a:focus,
    .navbar-light .navbar-text a:hover {
        color: rgba(0, 0, 0, .9)
    }

    .navbar-dark .navbar-brand {
        color: #fff
    }

    .navbar-dark .navbar-brand:focus,
    .navbar-dark .navbar-brand:hover {
        color: #fff
    }

    .navbar-dark .navbar-nav .nav-link {
        color: rgba(255, 255, 255, .5)
    }

    .navbar-dark .navbar-nav .nav-link:focus,
    .navbar-dark .navbar-nav .nav-link:hover {
        color: rgba(255, 255, 255, .75)
    }

    .navbar-dark .navbar-nav .nav-link.disabled {
        color: rgba(255, 255, 255, .25)
    }

    .navbar-dark .navbar-nav .active > .nav-link,
    .navbar-dark .navbar-nav .nav-link.active,
    .navbar-dark .navbar-nav .nav-link.show,
    .navbar-dark .navbar-nav .show > .nav-link {
        color: #fff
    }

    .navbar-dark .navbar-toggler {
        color: rgba(255, 255, 255, .5);
        border-color: rgba(255, 255, 255, .1)
    }

    .navbar-dark .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
    }

    .navbar-dark .navbar-text {
        color: rgba(255, 255, 255, .5)
    }

    .navbar-dark .navbar-text a {
        color: #fff
    }

    .navbar-dark .navbar-text a:focus,
    .navbar-dark .navbar-text a:hover {
        color: #fff
    }

    //END NAVBAR
    //TICKER
    .ticker {
        width: 100vw;
        height: 27px;
        position: fixed;
        display: block;
        top: 0;
        z-index: 9999;
        padding: 0;
    }

    .ticket .row {
        height: auto;
    }

    .ticket .row .col-md-12 {}

    .breaking-news {
        display: block;
        position: relative;
        background: #3c3c3c;
        height: 27px;
    }

    .news-scroll {
        height: 27px;
        position: relative;
    }

    .news {
        width: 10vw;
        height: 5vh;
        min-height: 5vh;
        background: #000066;
        padding: 0;
        border: 0;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .news-scroll a {
        font-size: 0.8rem;
        font-weight: 300;
        color: #fff;
        text-transform: uppercase;
        text-decoration: none;
    }

    .dot {
        padding-right: 0.5vw;
    }

    .bold {
        font-weight: bold;
    }

    .img-fill {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden
    }

    .img-fill img {
        flex-shrink: 0;
        object-fit: cover;
    }

    footer {
        display: flex;
        background-color: #3c3c3c;
        border-top: 1px solid #888;
        color: #888;
        font-size: 1rem;
        padding: 2vh 0;
        position: relative;
        z-index: auto !important;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        height: 200px;
        z-index: 9500 !important;
    }

    footer a {
        color: #fff;
        font-size: 0.8rem;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;
    }

    footer a:hover {
        color: #f1f1f1
    }

    footer p {
        margin: 0;
        font-size: 0.8rem;
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
    }

    footer svg {
        margin: 0
    }

    footer .footer-row {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 10px;
        align-content: center;
        width: 100vw;
        padding: 0vh 10vw;
    }

    .footer-row .col-6 {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: flex-start;
    }

    .footer-info {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
    }

    .footer-row .col-3 p {
        font-size: 0.8rem;
        text-transform: capitalize;
        font-weight: 400;
        white-space: nowrap;
    }

    .footer-row .col-3 a {
        font-size: 0.8rem;
        text-transform: lowercase;
        font-weight: 400;
        white-space: nowrap;
    }

    .footer-info .col-3 {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: flex-start;
        padding: 0vh 0vw 0vh 2vw;
        height: 10vh;
    }

    .footer-row .col-6 .footer-info .col-3 a {
        font-weight: 600;
        text-transform: uppercase;
    }

    .footer-social {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        padding: 1vh;
    }

    .footer-social a {
        font-size: 1.25rem !important;
    }

    @media (max-width:1024.00px) {
        footer .footer-row {
            align-items: flex-start;
            flex-direction: column;
            justify-content: flex-start
        }
    }

    @media (max-width:767.98px) {
        footer .footer-row {
            align-items: center;
            flex-direction: column;
            justify-content: flex-start
        }
    }

    footer .footer-row:last-child {
        font-size: 11px;
        margin-bottom: 0
    }

    body {
        color: #000;
        font-size: 1rem;
        font-weight: 300;
        line-height: 26px;
        position: relative;
    }

    .scrollbar {
        overflow: auto
    }

    .scrollbar::-webkit-scrollbar {
        width: 10px;
        height: 10px
    }

    .scrollbar::-webkit-scrollbar-track {
        background-color: #e9ecef
    }

    .scrollbar::-webkit-scrollbar-thumb {
        background: #adb5bd
    }

    .scrollbar::-webkit-scrollbar-thumb:hover {
        background: #495057
    }

    main {
        margin-left: 0;
        overflow: hidden;
    }

    section {
        padding-left: 10vw;
        padding-right: 10vw;
        position: relative;
        z-index: 100;
        z-index: 2 !important;
    }

    section.no-mt {
        padding-top: 0
    }

    section.no-mb {
        padding-bottom: 0
    }

    header {
        background-color: #fff;
        position: relative;
        z-index: 100
    }

    img {
        max-width: 100%
    }

    h1 {
        font-weight: 800;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0
    }

    p {
        line-height: 1.75
    }

    strong {
        font-weight: 700
    }

    .mobile-arrow {
        bottom: 0;
        display: flex;
        justify-content: center;
        left: 0;
        margin: 0 auto;
        padding-bottom: 25px;
        position: absolute;
        right: 0
    }

    .mobile-arrow a {
        animation-delay: 2.5s;
        animation-duration: 5s;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        animation-name: custom-bounce;
        display: block;
        transform-origin: center bottom
    }

    .anchors {
        align-items: center;
        display: flex;
        font-size: 1.25rem;
        padding-top: .5rem
    }

    @media (max-width:575.98px) {
        .anchors {
            justify-content: center;
            padding-top: .5rem
        }
    }

    .anchors svg {
        color: #000066;
        height: 1.75rem;
        margin: 0 1.25rem 0 0;
        width: auto
    }

    @media (max-width:575.98px) {
        .anchors svg {
            margin: 0
        }
    }

    .anchors .nav-link {
        color: #000;
        font-size: 14px;
        font-weight: 300;
        margin: 0 1.25rem;
        padding: 8px 0 5px;
        position: relative;
        text-decoration: none
    }

    @media (max-width:575.98px) {
        .anchors .nav-link {
            margin: 0 .75rem
        }
    }

    .anchors .nav-link::before {
        background-color: #000066;
        bottom: 8px;
        content: '';
        height: 1px;
        left: 0;
        opacity: 0;
        position: absolute;
        transition: opacity .25s ease, width .15s ease;
        width: 0
    }

    .anchors .nav-link:first-child {
        margin-left: 0
    }

    .anchors .nav-link:last-child {
        margin-right: 0
    }

    .anchors .nav-link.active::before,
    .anchors .nav-link:hover::before {
        opacity: 1;
        width: 100%
    }

    .negative-mt {
        margin-top: -150px
    }

    .custom-list,
    .section-about ul {
        columns: 2;
        margin-top: 2.5rem;
        width: auto
    }

    @media (max-width:991.98px) {

        .custom-list,
        .section-about ul {
            margin-bottom: 1.5rem;
            margin-top: 1.5rem
        }
    }

    @media (max-width:767.98px) {

        .custom-list,
        .section-about ul {
            columns: 1
        }
    }

    .custom-list li,
    .section-about ul li {
        padding: .25rem 0 .25rem 1.5rem;
        position: relative
    }

    .custom-list li::before,
    .section-about ul li::before {
        border: 1px solid #000066;
        border-radius: 50%;
        content: '';
        height: 7px;
        left: 0;
        position: absolute;
        top: 12px;
        width: 7px
    }

    .primary-border {
        border-right: 1px solid #000066;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        padding: 0 5vw 0 0;
        position: relative
    }

    @media (max-width:991.98px) {
        .primary-border {
            border: 0;
            min-height: auto;
            padding: 1rem 0
        }
    }

    .primary-border.spaced {
        padding: 5rem 0
    }

    @media (max-width:991.98px) {
        .primary-border.spaced {
            padding: 2rem 0
        }
    }

    .page-title {
        color: #000066;
        font-size: 7rem;
        font-weight: 300;
        text-transform: uppercase;
        padding-bottom: 5vh;
    }

    .page-title.outline-big-text {
        font-size: 2.5rem
    }

    .subtitle-wrapper {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding-bottom: 3rem
    }

    @media (max-width:575.98px) {
        .subtitle-wrapper {
            align-items: flex-start;
            flex-direction: column;
            justify-content: flex-start
        }
    }

    @media (max-width:991.98px) {
        .subtitle-wrapper {
            padding-bottom: 1rem
        }
    }

    .text-wrapper p:last-child {
        margin: 0
    }

    .img-wrapper {
        border-color: rgba(255, 255, 255, 0);
        border-style: solid;
        border-width: 1px 0;
        padding: 0;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;
        flex-direction: column;
        align-content: center;
        width: 280px;
    }

    .img-wrapper hr {
        height: 5px;
        color: #000066;
        width: 25%;
    }

    .img-wrapper.static {
        border: 0;
        padding: 0
    }

    .img-wrapper.static .img-legend {
        color: #000;
        padding: 10px 0 0
    }

    .img-wrapper.static .img-legend a {
        color: #000;
        display: block;
        font-size: 16px;
        font-weight: 700;
        padding: .15rem 0 0;
        text-decoration: none
    }

    .img-wrapper img {
        overflow: hidden;
        object-fit: cover;
        min-height: 100%;
        min-width: 100%;
        flex-shrink: 0;
    }

    .img-wrapper .img-legend {
        color: #343a40;
        display: block;
        padding: 15px 0 0
    }

    .img-wrapper .img-legend p {
        line-height: 16px;
        margin: 0
    }

    .img-wrapper .img-legend p a {
        color: #000;
        font-size: 14px;
        font-weight: 800
    }

    .img-wrapper .img-legend .date {
        font-size: 14px;
        font-weight: 300
    }

    .img-wrapper .news-img {
        height: 350px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
    }

    .project-figure {
        position: relative
    }

    .project-figure figcaption {
        bottom: 10px;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 1px;
        position: absolute;
        right: 20px
    }

    .outline-big-text {
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(166, 0, 0, .9);
        color: #000066;
        font-family: Gilroy;
        font-size: 1.5rem;
        font-weight: 800;
        letter-spacing: 1px;
        line-height: 1;
        white-space: nowrap
    }

    .citation-wrapper .outline-big-text {
        font-size: 1.5rem;
    }

    @media (max-width:1399.98px) {
        .outline-big-text {
            font-size: 45px
        }
    }

    @media (max-width:1199.98px) {
        .outline-big-text {
            font-size: 40px
        }
    }

    .outline-big-text.outline-white {
        -webkit-text-stroke-color: rgba(255, 255, 255, .9);
        color: #fff
    }

    .solid-big-text {
        color: #ffffff;
        font-size: 7rem;
        font-weight: 600
    }

    .citation-align {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        padding-left: 5vw;
    }

    .citation-align .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        height: 100%
    }

    .citation-wrapper {
        color: #000066;
        display: flex;
        flex-direction: column;
        font-family: Helvetica;
        font-size: 1.5rem;
        font-weight: 700;
        height: 100%;
        justify-content: center;
        line-height: 1;
        padding-bottom: 2.5vh;
    }

    @media (max-width:991.98px) {
        .citation-wrapper {
            font-size: 1.25rem;
            padding: 1rem 0;
            text-align: center
        }
    }

    .citation-wrapper.hp .outline-big-text,
    .citation-wrapper.hp .solid-big-text {
        font-size: 3rem
    }

    @media (max-width:1599.98px) {

        .citation-wrapper.hp .outline-big-text,
        .citation-wrapper.hp .solid-big-text {
            font-size: 3rem;
        }
    }

    @media (max-width:1199.98px) {

        .citation-wrapper.hp .outline-big-text,
        .citation-wrapper.hp .solid-big-text {
            font-size: 3rem
        }
    }

    .citation-wrapper.hp .solid-small-text {
        font-size: 1rem;

    }

    @media (max-width:1199.98px) {
        .citation-wrapper.hp .solid-small-text {
            font-size: 20px
        }
    }

    .list-align {
        height: 100%
    }

    .list-align > .row {
        height: 100%
    }

    //PROPERTY PAGE
    .property-header {
        padding: 0 !important;
        height: 500px;
    }

    .property-img {
        height: 500px;
        width: 100%;
    }

    .property-img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .property-title {
        width: 100vw;
        height: 15vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        align-items: flex-start;
        justify-content: center;
    }

    .title h2 {
        font-size: 2rem;
        color: #000066;
        font-weight: 600;
        text-transform: capitalize;
    }

    .property-address p {
        font-size: 1.25rem;
        font-weight: 300;
        color: #3c3c3c;
        text-transform: capitalize;
    }

    .property {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        width: 100vw;
        padding: 0vh 10vw;
    }

    .property .col-6 {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
    }

    .property-details {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: nowrap;
        align-content: center;
        padding: 0vh 0vw 0vh 10vw;
    }

    .property-details ul {
        margin-bottom: 1.25rem;
        padding-bottom: 1.25rem;
        position: relative
    }

    .property-details ul::before {
        background-color: #000066;
        bottom: 0;
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        width: 25px
    }

    .property-details ul:last-child {
        margin-bottom: 0;
        padding-bottom: 0
    }

    .property-details ul:last-child::before {
        display: none
    }

    .property-details ul li {
        font-size: 14px
    }

    .property-details ul li span {
        color: #000066;
        font-weight: 600;
        padding-right: .75rem
    }

    .property-inquiry {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-top: 10vh;
    }

    .property-inquiry .row {
        display: inline-flex;
    }

    .property-inquiry .row button {
        text-transform: uppercase;
        background: #000066;
        color: #fff;
        width: 14vw;
        height: 7vh;
        border-radius: 2px;
        transition: 500ms;
        border: none;
    }

    .property-inquiry .row button:hover {
        background: #fff;
        color: #000066;
        width: 16vw;
        height: 8vh;
        border-radius: 2px;
        transition: 500ms;
        border: 1px solid #000066
    }

    .border-link {
        border-style: solid;
        border-width: 1px 0;
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        padding: .2rem .25rem .1rem;
        position: relative;
        transition: color .25s ease;
        white-space: nowrap;
        word-spacing: 2px
    }

    .border-link::before {
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        top: 0;
        transition: width .25s ease;
        width: 0;
        z-index: -1
    }

    .border-link:hover {
        text-decoration: none
    }

    .border-link:hover::before {
        width: 100%
    }

    .border-link.border-link-primary {
        border-color: #000066;
        color: #000066
    }

    .border-link.border-link-primary::before {
        background-color: #000066
    }

    .border-link.border-link-primary:hover {
        color: #fff
    }

    .border-link.border-link-white {
        border-color: #fff;
        color: #fff
    }

    .border-link.border-link-white::before {
        background-color: #fff
    }

    .border-link.border-link-white:hover {
        color: #000066
    }

    .border-link.no-effect::before {
        display: none
    }

    .border-link.no-effect:hover {
        color: #000066
    }

    .link-effect {
        position: relative;
        z-index: 1
    }

    .link-effect::before {
        background-color: #fff;
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        top: 0;
        transition: width .2s ease;
        width: 0;
        z-index: -1
    }

    .link-effect:hover::before {
        width: 100%
    }

    .nav .nav-link {
        color: #000;
        font-weight: 300;
        margin: 0 50px 0 0;
        padding-bottom: .25rem;
        padding-left: 0;
        padding-right: 0;
        position: relative
    }

    .nav .nav-link::before {
        background-color: #000066;
        bottom: 8px;
        content: '';
        height: 1px;
        left: 0;
        opacity: 0;
        position: absolute;
        transition: opacity .25s ease, width .15s ease;
        width: 0
    }

    .nav .nav-link.active::before,
    .nav .nav-link:hover::before {
        opacity: 1;
        width: 100%
    }

    .row-adjust {
        margin-left: -25px;
        margin-right: -25px
    }

    @media (max-width:991.98px) {
        .row-adjust {
            margin-left: -10px;
            margin-right: -10px
        }
    }

    .row-adjust > [class*=col] {
        padding-left: 25px;
        padding-right: 25px
    }

    @media (max-width:991.98px) {
        .row-adjust > [class*=col] {
            padding-left: 10px;
            padding-right: 10px
        }
    }

    .row-align {
        justify-content: center
    }

    .row-big {
        margin-left: -75px;
        margin-right: -75px
    }

    .row-big > [class*=col] {
        padding-left: 75px;
        padding-right: 75px
    }

    .black-filter {
        background-color: rgba(0, 0, 0, .5);
        bottom: 0;
        left: 0;
        opacity: 0;
        position: fixed;
        right: 0;
        top: 0;
        transition: opacity .25s ease;
        z-index: -1
    }

    .black-filter.active {
        opacity: 1;
        z-index: 2000
    }

    .red-card {
        background-color: #000066;
        font-family: Gilroy;
        height: 100%;
        min-height: 275px;
        overflow: hidden;
        padding: 1.5rem 1.5rem 1rem
    }

    .red-card a {
        color: #fff;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        text-decoration: none
    }

    .red-card a:hover {
        color: #fff
    }

    .red-card p {
        line-height: 1
    }

    .red-card .job-title {
        font-size: 35px;
        font-weight: 800
    }

    .red-card .job-description {
        font-size: 35px;
        font-weight: 300;
        margin-bottom: 1.5rem
    }

    .red-card .flex-wrapper {
        display: flex;
        justify-content: space-between
    }

    .red-card .flex-wrapper .apply {
        font-size: 1rem;
        font-weight: 800
    }

    .red-card .flex-wrapper .arrow {
        align-items: center;
        border-radius: 50%;
        display: flex;
        font-size: 1.5rem;
        height: 35px;
        justify-content: center;
        width: 35px
    }

    .red-card .flex-wrapper .arrow:hover {
        background-color: #fff;
        color: #000066
    }

    .scroll-top {
        align-items: center;
        bottom: 0;
        display: flex;
        justify-content: center;
        position: absolute;
        right: 50px
    }

    @media (max-width:991.98px) {
        .scroll-top {
            right: 25px
        }
    }

    @media (max-width:575.98px) {
        .scroll-top {
            right: 15px
        }
    }

    .scroll-top a {
        line-height: 1;
        text-align: center;
        text-decoration: none
    }

    .scroll-top a p {
        line-height: 18px;
        margin: 0;
        text-align: center
    }

    .scroll-top a .icon-wrapper {
        align-items: center;
        border: 1px solid #000066;
        border-radius: 50%;
        color: #000066;
        display: flex;
        height: 35px;
        justify-content: center;
        margin: 0;
        overflow: hidden;
        position: relative;
        transition: color .15s ease;
        width: 35px
    }

    .scroll-top a .icon-wrapper::before {
        background-color: #000066;
        bottom: 0;
        content: '';
        height: 0;
        left: -20px;
        position: absolute;
        right: -20px;
        transition: height .15s ease;
        z-index: -1
    }

    .scroll-top a:hover p {
        color: #000066
    }

    .scroll-top a:hover .icon-wrapper {
        color: #fff
    }

    .scroll-top a:hover .icon-wrapper::before {
        height: 100%
    }

    .v-line {
        bottom: 0;
        left: 0;
        margin: 0 auto;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 4px;
        width: 21px
    }

    @media (max-width:991.98px) {
        .v-line {
            display: none
        }
    }

    .v-line.project-line .arrow {
        bottom: 20vh;
        padding: 2rem 0 3rem;
        top: auto
    }

    .v-line .arrow {
        left: 0;
        margin: 0 auto;
        min-width: 21px;
        position: fixed;
        right: 0;
        top: 50%
    }

    .arrow-wrapper-white {
        bottom: 20vh;
        left: 155px;
        padding: 2rem 0 3rem;
        position: fixed;
        right: 0;
        text-align: center;
        z-index: -1
    }

    @media (max-width:991.98px) {
        .arrow-wrapper-white {
            display: none
        }
    }

    .arrow-wrapper-white.active {
        display: none
    }

    .arrow-wrapper-white .arrow {
        align-items: center;
        animation-delay: 2.5s;
        animation-duration: 5s;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        position: relative
    }

    .container-fluid.no-margin {
        padding: 0
    }

    .section-title {
        text-align: center;
        padding: 5vh 0vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-title hr {
        height: 1px;
        color: #000066;
        width: 10vw;
        opacity: 1;
    }

    .section-title.vertical {
        font-size: 16px;
        font-weight: 700;
        margin: 0;
        text-orientation: sideways-right;
        transform: rotate(180deg);
        writing-mode: vertical-rl
    }

    @media (max-width:575.98px) {
        .section-title.vertical {
            margin-bottom: 15px;
            text-orientation: initial;
            transform: rotate(0);
            writing-mode: initial
        }
    }

    .section-title.red {
        color: #000066
    }

    .section-title.white {
        color: #fff
    }

    .section-banner {
        padding: 0
    }

    .section-banner.banner-spaced {
        padding: 0 50px
    }

    @media (max-width:991.98px) {
        .section-banner.banner-spaced {
            padding-left: 25px;
            padding-right: 25px
        }
    }

    @media (max-width:575.98px) {
        .section-banner.banner-spaced {
            padding-left: 10px;
            padding-right: 10px
        }
    }

    .section-banner iframe {
        height: 500px;
        max-width: 100%;
        width: 800px
    }

    .section-banner .banner {
        background-position: 50% 100%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
        position: relative;
        z-index: -10
    }

    .section-banner .banner.medium-banner {
        background-position: 50% 50%;
        height: 80vh;
        z-index: 2
    }

    .section-banner .banner.small-banner {
        background-position: 50% 50%;
        height: 60vh;
        z-index: 0
    }

    .section-banner .back {
        position: absolute;
        right: 25px;
        top: 25px;
        z-index: 5
    }

    .section-banner .back a {
        color: #fff
    }

    .section-banner .back a svg {
        height: 20px;
        width: auto
    }

    .section-banner .flex-wrapper {
        align-items: center;
        bottom: 0;
        display: flex;
        flex-direction: column;
        height: 100vh;
        justify-content: center;
        pointer-events: none;
        position: fixed;
        z-index: 1;
        flex-wrap: nowrap;
        align-content: center;
        width: 100vw;
    }

    @media (max-width:575.98px) {
        .section-banner .flex-wrapper {
            left: 0
        }
    }

    .section-banner .flex-wrapper .v-line {
        height: 0;
        margin: 50px auto 0;
        position: relative
    }

    @media (max-width:991.98px) {
        .section-banner .flex-wrapper .v-line {
            display: none
        }
    }

    .section-banner .flex-wrapper .v-line .arrow {
        left: 0;
        margin: 0 auto;
        opacity: 1;
        position: absolute;
        right: 0
    }

    .section-banner .flex-wrapper .v-line.active {
        animation: banner-line .85s ease-in;
        animation-delay: .25s;
        animation-fill-mode: forwards
    }

    .section-banner .flex-wrapper .v-line.active .arrow {
        animation: banner-arrow .75s ease;
        animation-delay: 1.1s;
        animation-fill-mode: forwards
    }

    .section-banner .flex-wrapper #animation-wrapper {
        max-width: 40%;
        position: relative;
        width: 100%
    }

    @media (max-width:575.98px) {
        .section-banner .flex-wrapper #animation-wrapper {
            max-width: 70%
        }
    }

    @media (max-width:991.98px) {
        .section-banner .flex-wrapper #animation-wrapper {
            max-width: 60%
        }
    }

    @media (max-width:1199.98px) {
        .section-banner .flex-wrapper #animation-wrapper {
            max-width: 50%
        }
    }

    .section-top-page {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 34vh;
        justify-content: space-evenly;
        z-index: 200;
        padding-top: 0vh;
        align-content: center;
        flex-wrap: nowrap;
    }

    .section-top-page .nav {
        display: flex;
        flex-wrap: nowrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        flex-direction: row;
        align-content: center;
        justify-content: space-around;
        width: 80vw;
        align-items: center;
    }

    .section-top-page .nav .nav-link {
        margin: 0;
        font-size: 0.8rem;
    }

    @media (max-width:575.98px) {
        .section-top-page {
            align-items: center
        }
    }

    .section-top-page.stickyTop {
        background-color: #fff;
        left: 155px;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 900
    }

    @media (max-width:575.98px) {
        .section-top-page.stickyTop {
            left: 0
        }
    }

    @media (max-width:575.98px) {
        .section-top-page .flex-wrapper {
            display: block
        }
    }

    .section-top-page .flex-wrapper .page-title {
        align-items: center;
        display: flex;
        margin-bottom: 0
    }

    @media (max-width:575.98px) {
        .section-top-page .flex-wrapper .page-title {
            justify-content: center
        }
    }

    .section-top-page .dropdown .dropdown-toggle {
        height: 34px;
        line-height: 34px;
        padding: 0 15px
    }

    .section-middle-line {
        padding-bottom: 5rem;
        padding-top: 5rem;
    }

    @media (max-width:991.98px) {
        .section-middle-line {
            padding-bottom: 1.5rem;
            padding-top: 1.5rem
        }
    }

    .section-middle-line.small-margin-top {
        padding-top: 1.5rem
    }

    .section-middle-line.reversed .text-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-start
    }

    @media (max-width:991.98px) {
        .section-middle-line.reversed .text-wrapper {
            margin: 0;
            width: 100%
        }
    }

    .section-middle-line.reversed .primary-border {
        border: 0;
        height: 100%;
        justify-content: flex-start
    }

    .section-middle-line.reversed .primary-border.spaced {
        padding-top: 0
    }

    .section-middle-line.reversed .citation-wrapper {
        justify-content: flex-start
    }

    .section-middle-line.reversed .button-wrapper {
        text-align: center
    }

    @media (max-width:991.98px) {
        .section-middle-line.reversed .button-wrapper {
            margin: 2.5rem auto 0
        }
    }

    .section-middle-line.about-details {
        margin-bottom: 5rem;
        padding: 10vh 10vw
    }

    @media (max-width:991.98px) {
        .section-middle-line.about-details {
            padding: 0 25px
        }
    }

    .about-details .row {
        padding-top: 5vh;
    }

    .about-details .row .col-6 {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
    }

    .about-details .row .col-6 .citation-wrapper {
        font-size: 2.5rem;
        padding-left: 15vw;
    }

    .about-details .row .col-6 .citation-wrapper .outline-big-text {
        font-size: 2rem;
    }

    .about-details .row .col-6 .text-wrapper {
        padding-left: 10vw;
        padding-right: 10vw;
    }

    .section-middle-line.about-details .citation-wrapper {
        text-align: left
    }

    .section-middle-line.about-details .primary-border {
        padding: 0
    }

    @media (max-width:991.98px) {
        .section-middle-line.about-details .text-wrapper {
            margin-bottom: 50px
        }
    }

    .section-middle-line.about-details .row.marged {
        margin-bottom: 100px
    }

    @media (max-width:991.98px) {
        .section-middle-line.about-details .row.marged {
            margin-bottom: 0
        }
    }

    @media (max-width:991.98px) {
        .section-middle-line .text-wrapper p {
            text-align: justify
        }
    }

    th {
        width: 25%;
    }

    .sold .table thead tr th {
        color: #000066;
        text-transform: uppercase;
        font-weight: 600;
    }

    .sold {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
        width: 75vw;
    }

    .sold-img {
        width: 100%;
    }

    .sold .card-description {
        height: auto;
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
    }

    .sold .card-text {
        display: none;
    }

    .sold .card-inquiry {
        display: none;
    }

    .sold-price {
        color: white;
        background: #000066;
        text-align: center;
    }

    .listings {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
    }

    .listings .card {
        width: 250px;
        margin: 10vh 0 0 0;
    }

    .exclusive-listings {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
    }

    .exclusive-listings .card {
        width: 30vh;
        margin: 5vh 0 0 0;
    }

    .property-status {
        font-weight: 700;
        color: #000;
        font-size: 1rem;
        text-align: center;
        border-top: none !important;
        height: 50px;
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: stretch;
    }

    .card {
        height: 515px;
    }

    .card-title {
        font-size: 0.8rem;
        color: #000066;
        line-height: 1.5rem;
        font-weight: 400;
        text-transform: uppercase;
    }

    .card-text {
        font-size: 0.8rem;
    }

    .card-link {
        color: #ffffff;
        font-size: 1rem;
        text-decoration: none;
    }

    .card-link:hover {
        color: #f1f1f1;
        font-size: 1rem;
        text-decoration: none;
        cursor: pointer;
    }

    .card-inquiry {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: center;
        background: #000066;
        border-bottom-left-radius: calc(0.25rem - 1px);
        border-bottom-right-radius: calc(0.25rem - 1px);
        height: 20px;
        padding: 1vh;
    }

    .card-description {
        height: 185px;
    }

    .card-img {
        height: 155px;
        object-fit: cover;
    }

    .section-platforms {
        padding-bottom: 5rem;
        padding-top: 5rem
    }

    .section-platforms .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-platforms .row .col-6 {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-right: 5vw !important;
    }

    @media (max-width:1199.98px) {
        .section-platforms {
            max-height: initial;
            min-height: initial;
            overflow: initial
        }
    }

    @media (max-width:991.98px) {
        .section-platforms {
            padding-bottom: 2.5rem;
            padding-top: 2.5rem
        }
    }

    @media (max-width:767.98px) {
        .section-platforms table {
            width: 100%
        }
    }

    @media (max-width:767.98px) {
        .section-platforms table tr {
            display: flex;
            flex-direction: column;
            margin-bottom: 25px
        }
    }

    @media (max-width:767.98px) {
        .section-platforms table tr td {
            text-align: left
        }
    }

    .section-platforms .citation-align {
        border-left: 1px solid #000066
    }

    @media (max-width:1399.98px) {
        .section-platforms .citation-align {
            border-left: 0
        }
    }

    .section-platforms .platform-title {
        align-items: center;
        border-bottom: 1px solid #000;
        display: flex;
        font-size: 21px;
        font-weight: 700;
        height: 140px;
        line-height: 30px;
        width: auto
    }

    @media (max-width:767.98px) {
        .section-platforms .platform-title {
            border-bottom: 0;
            height: auto
        }
    }

    .section-platforms .platform-title p {
        margin-bottom: 0;
        padding-right: 10px;
        width: auto
    }

    @media (max-width:767.98px) {
        .section-platforms .platform-title p {
            padding-right: 0;
            width: 100%
        }
    }

    .section-platforms .platform-paragraph {
        padding-left: 5vw
    }

    @media (max-width:767.98px) {
        .section-platforms .platform-paragraph {
            padding-left: 0
        }
    }

    .section-platforms .platform-paragraph p {
        margin-bottom: 0;
        width: 250px
    }

    @media (max-width:767.98px) {
        .section-platforms .platform-paragraph p {
            width: auto
        }
    }

    //CTA INVESTMENT HOME PAGE SECTION
    .section-investment {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-bottom: 5rem;
        padding-top: 5rem;
        height: 75vh;
    }

    .section-investment .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-investment .row .col-6 {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-right: 5vw !important;
    }

    .section-investment .row .col-6 .inner {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        align-items: flex-end;
        justify-content: center;
    }

    @media (max-width:1199.98px) {
        .section-investment {
            max-height: initial;
            min-height: initial;
            overflow: initial
        }
    }

    @media (max-width:991.98px) {
        .section-investment {
            padding-bottom: 2.5rem;
            padding-top: 2.5rem
        }
    }

    @media (max-width:767.98px) {
        .section-investment table {
            width: 100%
        }
    }

    @media (max-width:767.98px) {
        .section-investment table tr {
            display: flex;
            flex-direction: column;
            margin-bottom: 25px
        }
    }

    @media (max-width:767.98px) {
        .section-investment table tr td {
            text-align: left
        }
    }

    .section-investment .citation-align {
        border-left: 1px solid #000066
    }

    @media (max-width:1399.98px) {
        .section-investment .citation-align {
            border-left: 0
        }
    }

    .section-investment .platform-title {
        align-items: center;
        border-bottom: 1px solid #000;
        display: flex;
        font-size: 21px;
        font-weight: 700;
        height: 140px;
        line-height: 30px;
        width: auto
    }

    @media (max-width:767.98px) {
        .section-investment .platform-title {
            border-bottom: 0;
            height: auto
        }
    }

    .section-investment .platform-title p {
        margin-bottom: 0;
        padding-right: 10px;
        width: auto
    }

    @media (max-width:767.98px) {
        .section-investment .platform-title p {
            padding-right: 0;
            width: 100%
        }
    }

    .section-investment .platform-paragraph {
        padding-left: 5vw
    }

    @media (max-width:767.98px) {
        .section-investment .platform-paragraph {
            padding-left: 0
        }
    }

    .section-investment .platform-paragraph p {
        margin-bottom: 0;
        width: 250px
    }

    @media (max-width:767.98px) {
        .section-investment .platform-paragraph p {
            width: auto
        }
    }

    //END
    .section-title {
        text-align: center;
        padding: 15vh 0vw;
    }

    .section-title h2 {
        color: #000066;
        text-transform: uppercase;
        font-size: 7rem;
        font-weight: 300;
    }

    .section-cta {
        text-align: center;
        padding: 10vh 10vw 5vh 10vw;
    }

    .section-cta button {
        background: #000066;
        border: 1px solid #000066;
        height: 8vh;
        width: 16vw;
        border-radius: 2px;
        margin: 1vw;
        margin-left: 0;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.05vw;
        transition: 500ms !important;
    }

    .section-cta button:hover {
        background: #000066;
        border: 1px solid #000066;
        height: 8vh;
        width: 16vw;
        border-radius: 2px;
        margin: 1vw;
        margin-left: 0;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.05vw;
        transition: 500ms !important;
    }

    .section-full-primary {
        background-color: #fff;
        padding-bottom: 10vh;
        padding-top: 20vh;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-full-primary.no-carousel img {
        height: auto;
        width: 100%
    }

    .section-full-primary.no-carousel .job-image {
        background-position: 50% 50%;
        background-size: cover;
        height: 275px;
        width: 100%
    }

    .section-full-primary.jobs-carousel .red-card {
        height: 275px
    }

    .section-full-primary .row h2 {
        font-size: 1rem;
        color: #000066;
        text-transform: uppercase;
        font-weight: 400;
    }

    @media (max-width:1399.98px) {
        .section-full-primary .row {
            margin-left: -5px;
            margin-right: -5px
        }
    }

    .section-full-primary .row [class*=col] {
        margin-bottom: 25px
    }

    @media (max-width:1399.98px) {
        .section-full-primary .row [class*=col] {
            padding-left: 5px;
            padding-right: 5px
        }
    }

    .section-full-primary .subtitle-wrapper {
        padding-bottom: .5rem
    }

    .section-full-primary .subtitle-wrapper h2 {
        margin-bottom: 0
    }

    @media (max-width:575.98px) {
        .section-full-primary .subtitle-wrapper h2 {
            margin-bottom: .5rem
        }
    }

    .section-full-primary .job-title h3 {
        font-size: 1.75rem;
        font-weight: 800;
        margin-bottom: .25rem
    }

    .section-full-primary .job-description p {
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.25
    }

    .section-team-primary {
        background-color: #fff;
        padding-bottom: 10vh;
        padding-top: 20vh;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .team-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        padding: 5vh 0vw;
    }

    .team-list-line {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        padding: 5vh 0vw 5vh 0vw;
    }

    .team-list-line .card {
        width: 60vw;
        margin: 0;
        height: 100px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: stretch;
        border: none;
    }

    .team-list-line .card .team-name {
        width: 150px;
        text-align: left;
    }

    .team-list-line .card .card-title {
        width: 400px;
        text-align: center;
    }

    .team-list-line .card .card-text {
        width: 150px;
        text-align: left;
    }

    .team-list-line .card .card-description {
        text-align: center;
        height: auto;
        background: #fff;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: baseline;
        padding-top: 1vh;
    }

    .team-list-line .card .card-description a {
        color: #000;
    }

    .team-list-line .card .card-description a:hover {
        color: #000066;
        cursor: pointer;
    }

    .team-list .card {
        width: 300px;
        margin: 0vh 1vw 9vh 1vw;
        height: 550px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: stretch;
        border: none;
    }

    .team-img {
        height: 350px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    .team-img img {
        flex-shrink: 0;
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
    }

    .team-list .card .card-description {
        text-align: center;
        height: 160px;
        background: #fff;
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        padding-top: 1vh;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
    }

    .team-list .card .card-inquiry {
        height: 6vh;
    }

    .team-name {
        font-weight: 700;
        color: #000;
        font-size: 1rem;
        text-align: center;
        border-top: none !important;
    }

    .team-filter {
        width: 100vw;
        padding-top: 10vh;
        display: flex;
        position: relative;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;

    }

    .team-filter .inner {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: baseline;
        width: 50vw;
    }

    .team-filter .inner p {
        color: #000066;
        font: 1rem;
    }

    .section-team-primary.no-carousel img {
        height: auto;
        width: 100%
    }

    .section-team-primary.no-carousel .job-image {
        background-position: 50% 50%;
        background-size: cover;
        height: 275px;
        width: 100%
    }

    .section-team-primary.jobs-carousel .red-card {
        height: 275px
    }

    @media (max-width:1399.98px) {
        .section-team-primary .row {
            margin-left: -5px;
            margin-right: -5px
        }
    }

    .section-team-primary .row [class*=col] {
        margin-bottom: 25px
    }

    @media (max-width:1399.98px) {
        .section-team-primary .row [class*=col] {
            padding-left: 5px;
            padding-right: 5px
        }
    }

    .section-team-primary .subtitle-wrapper {
        padding-bottom: .5rem
    }

    .section-team-primary .subtitle-wrapper h2 {
        margin-bottom: 0
    }

    @media (max-width:575.98px) {
        .section-team-primary .subtitle-wrapper h2 {
            margin-bottom: .5rem
        }
    }

    .section-team-primary .job-title h3 {
        font-size: 1.75rem;
        font-weight: 800;
        margin-bottom: .25rem
    }

    .section-team-primary .job-description p {
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.25
    }

    .section-search-simple .row {
        height: 100%;
    }

    .section-search-simple .row .col-6 {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-search-simple .row .col-6 .citation-align {
        align-items: flex-end;
        padding: 0;

    }

    .section-search-simple .row .col-6 .citation-align .citation-wrapper {
        align-items: flex-end;

        padding-right: 10vw;
        padding-bottom: 0;
    }

    .search-text {
        color: #fff;
        font-size: 2rem;
        text-align: right;
        line-height: 1.5;

    }


    .sign-up-btn-light {
        background: #fff;
        color: #000066;
        border: none;
        border-radius: 1px;
        width: 21vw;
        font-size: 1rem;
        line-height: 2;
        margin-top: 5vh;
        text-transform: uppercase;
        font-weight: 300;
    }

    .sign-up-btn-light:hover {
        background: #000066;
        color: #fff;
        border: none;
        border-radius: 1px;
        width: 21vw;
        font-size: 1rem;
        line-height: 2;
        margin-top: 5vh;
        text-transform: uppercase;
        font-weight: 300;
    }

    @media (max-width:991.98px) {
        .section-search-simple {
            padding-bottom: 2.5rem;
            padding-top: 2.5rem
        }
    }

    @media (max-width:767.98px) {
        .section-search-simple img {
            margin-bottom: 2rem
        }
    }

    .section-image-double {
        padding-bottom: 50px;
        padding-top: 5rem
    }

    @media (max-width:991.98px) {
        .section-image-double {
            padding-bottom: 2.5rem;
            padding-top: 20px
        }
    }

    @media (max-width:991.98px) {
        .section-image-double img {
            margin-bottom: 20px
        }
    }

    .section-image-double .flex-wrapper {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center
    }

    @media (max-width:1199.98px) {
        .section-image-double .flex-wrapper {
            align-items: flex-start
        }
    }

    .section-image-double .flex-wrapper .citation {
        border-color: #000066;
        border-style: solid;
        border-width: 1px 0;
        color: #000;
        padding: 1.5rem 0 1.5rem;
        width: 400px
    }

    @media (max-width:767.98px) {
        .section-image-double .flex-wrapper .citation {
            margin-top: 25px
        }
    }

    @media (max-width:1199.98px) {
        .section-image-double .flex-wrapper .citation {
            padding: 10px 0;
            width: 100%
        }
    }

    .section-image-double .flex-wrapper .citation p {
        font-family: Gilroy;
        font-size: 26px;
        font-weight: 800;
        line-height: 32px;
        margin-bottom: 2rem
    }

    @media (max-width:1599.98px) {
        .section-image-double .flex-wrapper .citation p {
            font-size: 20px;
            line-height: 24px
        }
    }

    @media (max-width:1199.98px) {
        .section-image-double .flex-wrapper .citation p {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 1rem
        }
    }

    @media (max-width:991.98px) {
        .section-image-double .flex-wrapper .citation p {
            font-size: 18px;
            line-height: 22px
        }
    }

    .section-image-double .flex-wrapper .citation span {
        display: block;
        line-height: 1.35
    }

    .section-image-double .flex-wrapper .citation span.author {
        font-weight: 600
    }

    @media (max-width:991.98px) {

        .section-image-double.section-image-4 [class*=col]:nth-child(1) img,
        .section-image-double.section-image-4 [class*=col]:nth-child(2) img {
            margin-bottom: 25px
        }
    }

    @media (max-width:991.98px) {

        .section-image-double.section-image-4 [class*=col]:nth-child(1) img,
        .section-image-double.section-image-4 [class*=col]:nth-child(2) img,
        .section-image-double.section-image-4 [class*=col]:nth-child(3) img {
            margin-bottom: 25px
        }
    }

    .section-gallery .gallery-wrapper {
        padding-bottom: 25px
    }

    .section-gallery .gallery-wrapper .row {
        margin-left: -10px;
        margin-right: -10px
    }

    .section-gallery .gallery-wrapper .row [class*=col] {
        margin: 0 0 10px;
        padding-left: 10px;
        padding-right: 10px;
        height: 300px;
    }

    .section-gallery .gallery-image-wrapper {
        overflow: hidden;
        position: relative;
        height: 250px;
    }

    .section-gallery .gallery-image-wrapper:hover .image-effect {
        left: 0
    }

    .section-gallery .gallery-image-wrapper:hover .image-effect [class*=image] {
        opacity: 1
    }

    .section-gallery .gallery-dots {
        margin: 2.5rem auto;
        text-align: center
    }

    .section-gallery .gallery-dots .btn {
        align-items: center;
        cursor: default;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        opacity: 0;
        padding: 5px
    }

    .section-gallery .image-effect {
        background-color: #000066;
        bottom: 0;
        cursor: pointer;
        font-family: Gilroy;
        left: -100%;
        position: absolute;
        top: 0;
        transition: left .25s ease;
        width: 100%
    }

    .section-gallery .image-effect p {
        font-size: 1rem;
        line-height: 1.25;
        margin: 0
    }

    .section-gallery .image-effect [class*=image] {
        color: #fff;
        opacity: 0;
        transition: opacity .25s ease;
        transition-delay: .15s
    }

    .section-gallery .image-effect .image-text {
        font-size: 1.25rem;
        left: 15px;
        position: absolute;
        right: 15px;
        top: 15px
    }

    .section-gallery .image-effect .image-text h3 {
        font-weight: 800;
        margin-bottom: .25rem
    }

    .section-gallery .image-effect .image-text .image-description {
        font-weight: 300;
        max-width: 60%
    }

    .section-gallery .image-effect .image-infos {
        bottom: 15px;
        font-family: Helvetica, Arial;
        font-size: 1.25rem;
        position: absolute;
        right: 15px;
        text-orientation: sideways-right;
        transform: rotate(180deg);
        writing-mode: vertical-rl
    }

    .section-gallery .image-effect .image-infos .image-locality {
        font-size: 14px;
        font-weight: 300;
        margin-left: .25rem
    }

    .section-gallery .image-effect .image-infos .image-date {
        font-size: 14px;
        font-weight: 800
    }

    .section-gallery .image-effect .image-arrow {
        bottom: 15px;
        font-size: 1.25rem;
        left: 15px;
        position: absolute
    }

    .section-gallery .image-effect .image-arrow:hover .icon-wrapper {
        background-color: #fff;
        color: #000066
    }

    .section-gallery .image-effect .image-arrow .icon-wrapper {
        align-items: center;
        border-radius: 50%;
        display: flex;
        height: 35px;
        justify-content: center;
        width: 35px
    }

    .section-carousel {
        background-color: #fff;
        padding-bottom: 0;
        padding-top: 5rem
    }

    @media (max-width:991.98px) {
        .section-carousel {
            padding-bottom: 20px;
            padding-top: 0
        }
    }

    .section-carousel .owl-carousel .owl-stage {
        display: -webkit-box !important;
        display: -moz-box !important;
        display: -ms-box !important;
        display: box !important
    }

    .section-carousel .owl-carousel .owl-item img {
        display: block;
        height: 400px;
        margin: 0 auto;
        max-height: 400px;
        width: auto
    }

    .section-share {
        background-color: #fff;
        padding-bottom: 0;
        padding: 10vh;
    }

    .section-share .flex-wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center
    }

    .section-share .flex-wrapper .text-wrapper {
        letter-spacing: 1px;
        margin-bottom: .75rem
    }

    .section-share .flex-wrapper .icons-wrapper a {
        color: #343a40;
        margin: 0 .5rem;
        text-decoration: none
    }

    .section-share .flex-wrapper .icons-wrapper a:hover {
        color: #000066
    }
    .section-about .intro {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        padding: 20vh 10vw;
    }

    .section-about .intro .inner_L {
        width: 55%;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-about .intro .inner_L .txt {
        white-space: nowrap;
        text-align: center;
        border: none;
    }

    .section-about .intro .inner_L .txt h2 {
        padding: 0;
        text-transform: uppercase;
    }

    .section-about .intro .inner_R {
        width: 45%;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
    }

    .section-about .intro .inner_R .txt {
        padding: 0;
        text-align: center;
    }

    .section-about .row .col-6 {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    .section-about .row .col-6 .img-wrapper {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: center;
    }

    .about {
        height: auto;
        width: 100vw;
        padding: 20vh 10vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
    }


    .section-team .intro {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        padding: 20vh 10vw;
    }

    .section-team .intro .inner_L {
        width: 55%;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .section-team .intro .inner_L .txt {
        white-space: nowrap;
        text-align: center;
        border: none;
    }

    .section-team .intro .inner_L .txt h2 {
        padding: 0;
        text-transform: uppercase;
    }

    .section-team .intro .inner_R {
        width: 45%;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
    }

    .section-team .intro .inner_R .txt {
        padding: 0;
        text-align: justify;
    }

    .section-team .intro .inner_R .txt p {
        text-align: justify;
        font-size: 1rem;
    }

    .section-team .row .col-6 {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    .section-team .row .col-6 .img-wrapper {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: center;
    }

    .section-mission {
        height: 100vh;
        width: 100vw;
        padding: 10vh 10vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        background: linear-gradient(rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), url(../images/services-overlay_bg.jpg) no-repeat center center;
        background-size: cover;
    }

    .section-mission .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .mission-row {
        text-align: center;
        display: flex;
        width: 80vw;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        padding: 5vh 0vw;
    }

    .mission-row .col-3 {
        padding: 0vh 5vw 0vh 0vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: flex-start;
        height: auto;
    }

    .mission-row .col-3 p {
        text-align: justify;
        text-align-last: left;
        padding-top: 2vh;
    }

    .section-mission h2 {
        font-size: 7rem;
        color: #000066;
        text-transform: uppercase;
        font-weight: 300;
    }

    .section-mission .row .col-12 {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
        padding: 0;
    }

    .section-mission .col-3 hr {
        height: 5px;
        width: 25%;
        color: #000066;
        opacity: 1 !important;
    }

    .section-mission .dropcap {
        color: #57a6dc;
        font-weight: 600;
        font-size: 5rem;
        line-height: 0.5
    }

    .mission-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        width: 80vw;
    }

    .mission-list {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-end;
        width: 60vw;
        padding-bottom: 10vh;
    }

    .mission-list hr {
        color: #000066;
        opacity: 1;
        height: 1px;
        width: 80%;
        text-align: right;
        margin: 2vh 0vw;
    }

    .mission-list .row {
        width: 90vw;
        height: 25vh;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
        align-content: flex-start;
    }

    .mission-list .row .col-3 {
        display: inline-flex;
        text-align: left;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    .mission-list .row h4 {
        transform: rotate(-90deg);
        font-size: 2rem;
        font-weight: 600;
        color: #000;
        display: inline-flex;
        flex-direction: column;
        text-align: center;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .mission-list .row h4 span {
        font-size: 1.5rem;
        font-weight: 600;
        color: #9a9a9a;
    }

    .mission-list .row .col-6 p {
        font-weight: 600;
        color: #1d2054;
        font-size: 1.75rem;
        line-height: 1.75;
    }

    .mission-header h3 {
        font-size: 4rem;
        font-weight: 600;
        color: #000;
        text-transform: uppercase;
    }

    .stats-banner {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        height: 125vh;
        background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(../images/gfi_stats.jpg) no-repeat center center;
        background-size: cover;
        background-attachment: fixed;
        z-index: auto !important;
    }

    .stats-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-top: 5vh;
    }

    .stats-header h3 {
        font-size: 7rem;
        font-weight: 400;
        color: #fff;
        text-transform: uppercase;
        line-height: 1;
        white-space: nowrap;
        transform: scaleY(1.1) !important;
    }

    .inner-stats {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-bottom: 5vh;
    }

    .stats-banner hr {
        width: 10vw;
        color: #fff;
        height: 1px;
        opacity: 1;
    }

    .stats-col-R {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
        width: 30vw;
        padding-left: 2vw;
        text-align: center;
    }

    .stats-col-R .col-4 {
        height: 20vh;
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: flex-start;
    }

    .stats-banner h2 {
        font-size: 2rem;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 900;
    }

    .stats-col-R h3 {
        color: #fff;
    }

    .stats-col-R h4 {
        font-size: 3rem;
        text-transform: uppercase;
        color: #fff;
        font-weight: 600;
        text-align: left;
        display: flex;
        flex-direction: column;
    }

    .stats-col-R h4 span {
        font-weight: 400;
        font-size: 2rem;
        white-space: nowrap;
    }

    .stats-col-L {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
        width: 30vw;
        text-align: center;
    }

    .stats-col-L .col-4 {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: flex-end;
        height: 20vh;
    }

    .stats-col-L h3 {
        color: #fff;
    }

    .stats-col-L h4 {
        font-size: 3rem;
        text-transform: uppercase;
        color: #fff;
        font-weight: 600;
        text-align: left;
        display: flex;
        flex-direction: column;
    }

    .stats-col-L h4 span {
        font-weight: 400;
        font-size: 2rem;
    }

    .email-banner {
        width: 100vw;
        height: 75vh;
        background: url(../images/cta.jpg) no-repeat center center;
        background-size: cover;
        background-attachment: fixed;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .email-banner .inner {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;

    }

    .email-banner .inner p {
        color: #fff;
        font-weight: 600;
        font-size: 4rem;
        text-transform: uppercase;
        transform: scaleY(1.1) !important;
        text-align: center;
        padding-bottom: 5vh;
    }

    .services {
        height: auto;
        width: 100vw;
        padding: 20vh 10vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        background: #fff;
        padding: 10vh 10vw;


    }

    .services-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-content: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        text-align: left;
    }

    .services-title h3 {
        font-size: 3rem;
        font-weight: 600;
        text-transform: uppercase;
        color: #ffffff;
        display: flex;
        flex-direction: column;

    }

    .services-title span {
        color: #f1f1f1;
        font-weight: 400;
        font-size: 2rem;
        white-space: nowrap;

    }

    .mission-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-content: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        text-align: left;
    }

    .mission-title h3 {
        font-size: 3rem;
        font-weight: 600;
        text-transform: uppercase;
        color: #000066;
        display: flex;
        flex-direction: column;

    }

    .mission-title span {
        color: #939393;
        font-weight: 400;
        font-size: 2rem;
        white-space: nowrap;

    }

    .services .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        width: 100vw;
        padding: 10vh 5vw 10vh 5vw;
        text-align: left;

    }

    .services .row .col-12 {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        text-align: right;
        padding: 0;
    }

    .services h2 {
        font-size: 7rem;
        color: #000066;
        text-transform: uppercase;
        font-weight: 300;

    }

    .services-row {
        text-align: center;
        display: flex;
        width: 80vw;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        padding: 10vh 0vw 25vh 0vw;
    }

    .services-row .col-4 {
        margin: 0vh 2.5vw 0vh 2.5vw;
        padding: 2vh 1.5vw 10vh 1.5vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: flex-start;
        height: 50vh;
        background: #000066;
        background-size: cover;
    }

    .services-row .col-4 span {
        width: 225px;
        text-align: left;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: baseline;
        transition: 500ms ease;
        transform: translateX(0vw);
    }

    .services-row .col-4 span:hover {
        transition: 500ms ease;
        transform: translateX(1vw)
    }

    .services-row .col-4 span i {
        position: relative;
        padding: 0vh 50% 0vh 0;
        color: #ffff;
    }

    .services-row h3 {
        font-size: 2rem;
        text-transform: uppercase;
        text-align: left;
        line-height: 1;
        color: #fff;
    }

    .services-row .col-4 p {
        text-align: justify;
        text-align-last: left;
        padding-top: 2vh;
        color: #fff;
        hyphens: auto;
        -webkit-hyphens: auto;
    }

    .services-btn {
        border: none;
        border-radius: 2vw;
        width: auto;
        background: transparent;
        color: #fff;
        font-size: 1rem;
        transition: 500ms ease;
        padding: 0;
        text-align: left;
        white-space: nowrap;
    }

    .services-btn:hover {
        width: auto;
        background: transparent;
        color: #f1f1f1;
        font-size: 1rem;
        padding: 0;
        transition: 500ms ease;
        cursor: pointer;
        white-space: nowrap;
    }

    .services-row hr {
        color: #fff;
        height: 1px;
        width: 5vw;
    }

    @media (max-width:991.98px) {
        .section-about {
            padding-bottom: 2.5rem
        }
    }

    .section-about h2 {
        align-items: flex-end;
        color: #000;
        display: flex;
        font-size: 1.5rem;
        font-weight: 400;
        height: 60px;
        line-height: 1;
        margin-bottom: 0;
        padding-bottom: 10px
    }

    .section-about [class*=col] {
        margin-bottom: 75px
    }

    @media (max-width:991.98px) {
        .section-about [class*=col] {
            margin-bottom: 25px
        }
    }

    .section-about [class*=col].related {
        margin-bottom: 0
    }

    @media (max-width:991.98px) {
        .section-about [class*=col].related {
            margin-bottom: 50px
        }
    }

    .section-about [class*=col]:nth-child(1) {
        order: 1
    }

    @media (max-width:991.98px) {
        .section-about [class*=col]:nth-child(1) {
            order: 1
        }
    }

    .section-about [class*=col]:nth-child(2) {
        order: 2
    }

    @media (max-width:991.98px) {
        .section-about [class*=col]:nth-child(2) {
            order: 3
        }
    }

    .section-about [class*=col]:nth-child(3) {
        order: 3
    }

    @media (max-width:991.98px) {
        .section-about [class*=col]:nth-child(3) {
            order: 2
        }
    }

    .section-about [class*=col]:nth-child(4) {
        order: 4
    }

    @media (max-width:991.98px) {
        .section-about [class*=col]:nth-child(4) {
            margin-bottom: 0;
            order: 4
        }
    }

    .section-about .v-line {
        bottom: 5rem;
        top: 60px
    }

    .section-about .img-wrapper {
        margin-bottom: 50px
    }

    @media (max-width:991.98px) {
        .section-about .img-wrapper {
            margin-bottom: 15px
        }
    }

    .section-about .nav-pills {
        margin-bottom: 4rem
    }

    @media (max-width:991.98px) {
        .section-about .nav-pills {
            margin-bottom: 1.5rem
        }
    }

    .section-about .nav-pills .nav-link {
        border-width: 2px 0 1px
    }

    .section-about .tab-content {
        height: auto
    }

    .paragraph-align {
        text-align: justify;
        hyphens: auto;
        -webkit-hyphens: auto;
    }

    .section-about .outline-big-text {
        font-size: 35px
    }

    .section-about .owl-nav {
        bottom: 0;
        color: red;
        left: 0;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0
    }

    .section-about .owl-nav .owl-prev {
        left: -25px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%)
    }

    .section-about .owl-nav .owl-next {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%)
    }

    .section-partners {
        margin-bottom: 2rem
    }

    .section-partners .partners-image {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 30%;
        height: 50vh;
        min-height: 300px;
        margin-right: 1rem
    }

    @media (max-width:991.98px) {
        .section-partners .partners-image {
            height: 40vh;
            min-height: auto;
            margin-right: 0
        }
    }

    .section-partners .partners {
        display: flex;
        flex-direction: column;
        height: 50vh;
        min-height: 300px;
        justify-content: flex-start;
        align-items: stretch;
        margin-left: 1rem;
        overflow: auto;
        max-width: 600px
    }

    @media (max-width:991.98px) {
        .section-partners .partners {
            height: auto;
            min-height: auto;
            margin-left: 0;
            margin-top: 1rem
        }
    }

    .section-partners .partners .partner {
        margin-top: 1.5rem
    }

    @media (min-width:1600px) {
        .section-partners .partners .partner {
            margin-top: 2.5rem
        }
    }

    @media (min-width:1921px) {
        .section-partners .partners .partner {
            margin-top: 3.5rem
        }
    }

    .section-partners .partners .partner:first-child {
        margin-top: 0
    }

    .section-partners .partners .partner .name {
        line-height: 26px;
        font-size: 14px;
        font-weight: 700;
        margin: 0
    }

    .section-partners .partners .partner .function {
        color: #000066
    }

    .section-partners .partners .partner .function::after {
        color: #000;
        content: " | ";
        display: inline;
        margin: 0 .25rem;
        vertical-align: middle
    }

    .section-partners .partners .partner .email {
        color: #000
    }

    .section-partners .partners .partner .description {
        margin-top: .5rem;
        line-height: 26px
    }

    .section-teams .outline-big-text {
        margin-bottom: 1.5rem
    }

    .section-teams .team-carousel {
        position: relative
    }

    .section-teams .team-carousel .member {
        padding: 20px
    }

    .section-teams .team-carousel .member img {
        border-color: rgba(255, 255, 255, 0);
        border-style: solid;
        border-width: 1px 0
    }

    .section-teams .team-carousel .member .member-infos {
        align-items: center;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        opacity: 0;
        padding-top: 1rem
    }

    .section-teams .team-carousel .member .member-infos .function,
    .section-teams .team-carousel .member .member-infos .name,
    .section-teams .team-carousel .member .member-infos .role {
        line-height: 26px
    }

    .section-teams .team-carousel .member .member-infos .name {
        color: #000066;
        font-weight: 700
    }

    .section-teams .team-carousel .member .member-infos .role {
        font-weight: 400
    }

    .section-teams .team-carousel .member .member-infos .function {
        color: #000066
    }

    .section-teams .team-carousel .member .member-infos .email a {
        color: #000
    }

    .section-teams .team-carousel .member:hover img {
        border-color: #000066
    }

    .section-teams .team-carousel .member:hover .member-infos {
        opacity: 1
    }

    .section-teams .team-carousel .owl-nav {
        color: #000066
    }

    .section-teams .team-carousel .owl-nav svg {
        height: auto;
        width: 25px
    }

    .section-teams .team-carousel .owl-nav .owl-next,
    .section-teams .team-carousel .owl-nav .owl-prev {
        align-items: center;
        background-color: #fff;
        border-radius: 50%;
        display: flex;
        height: 40px;
        justify-content: center;
        position: absolute;
        top: calc(50% - 75px);
        width: 40px
    }

    .section-teams .team-carousel .owl-nav .owl-next:active,
    .section-teams .team-carousel .owl-nav .owl-next:focus,
    .section-teams .team-carousel .owl-nav .owl-prev:active,
    .section-teams .team-carousel .owl-nav .owl-prev:focus {
        outline: 0
    }

    .section-teams .team-carousel .owl-nav .owl-next:hover,
    .section-teams .team-carousel .owl-nav .owl-prev:hover {
        background-color: #000066;
        color: #fff
    }

    .section-teams .team-carousel .owl-nav .owl-prev {
        left: 50px;
        transform: translateX(100%) translateY(-50%)
    }

    @media (max-width:991.98px) {
        .section-teams .team-carousel .owl-nav .owl-prev {
            left: 0
        }
    }

    .section-teams .team-carousel .owl-nav .owl-next {
        right: 50px;
        transform: translateX(-100%) translateY(-50%)
    }

    @media (max-width:991.98px) {
        .section-teams .team-carousel .owl-nav .owl-next {
            right: 0
        }
    }

    .section-worked-with-us {
        padding-bottom: 5rem;
        padding-top: 5rem
    }

    @media (max-width:991.98px) {
        .section-worked-with-us {
            padding-bottom: 2.5rem;
            padding-top: 2.5rem
        }
    }

    .section-worked-with-us p {
        line-height: 2;
        margin-bottom: 0;
        padding-right: 0;
        text-align: justify
    }

    @media (max-width:575.98px) {
        .section-worked-with-us p {
            padding-right: 0
        }
    }

    @media (max-width:767.98px) {
        .section-worked-with-us p {
            padding-right: 0
        }
    }

    .section-worked-with-us p span {
        color: #000066;
        padding: 0 .25rem
    }

    .section-contact {
        padding-bottom: 5rem;
        padding-top: 5rem
    }

    @media (max-width:991.98px) {
        .section-contact {
            padding-bottom: 2.5rem;
            padding-top: 0
        }
    }

    .section-contact a {
        color: #343a40
    }

    .section-contact a:hover {
        color: #000066
    }

    .section-contact iframe {
        height: 400px;
        width: 100%
    }

    .section-contact .flex-wrapper {
        display: flex
    }

    @media (max-width:991.98px) {
        .section-contact .flex-wrapper {
            justify-content: flex-start
        }
    }

    .section-contact .fa-ul {
        margin-left: 3rem
    }

    .section-contact .fa-ul li {
        margin-bottom: 2rem
    }

    .section-contact .fa-ul li .fa-li {
        color: #ff6a6a;
        font-size: 1.5rem;
        left: -2.5em;
        margin-top: .5rem
    }

    .section-contact .fa-ul p {
        line-height: 1.75;
        padding-left: 1rem;
        padding-top: .5rem
    }

    .section-contact .fa-ul p svg {
        margin-left: .5rem
    }

    .section-contact .follow-us {
        font-size: 1rem
    }

    .section-contact .follow-us span {
        display: block;
        margin-bottom: .25rem
    }

    .section-contact .follow-us a {
        margin-right: .5rem
    }

    .section-news {
        padding-bottom: 10vh;
        padding-top: 20vh;
    }

    @media (max-width:991.98px) {
        .section-news {
            padding-bottom: 2.5rem
        }
    }

    .section-news .news-wrapper a {
        text-decoration: none
    }

    .section-news .news-wrapper .row {
        margin-left: -10px;
        margin-right: -10px
    }

    .section-news .news-wrapper .row > [class*=col] {
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 5vh;
        padding-top: 5vh;

    }

    .section-news .news-wrapper .news-item::before {
        display: none
    }

    .section-news .news-wrapper .news-item::after {
        display: none
    }

    .section-news .news-wrapper .news-item .img-effect a {
        display: block
    }

    .section-news .news-wrapper .news-title {
        color: #000;
        display: block;
        margin-top: 1rem
    }

    .section-news .news-wrapper .news-title h3 {
        color: #343a40;
        font-size: 16px;
        font-weight: 800;
        letter-spacing: .5px;
        margin-bottom: 3px
    }

    .section-news .news-wrapper .news-infos {
        color: #343a40;
        font-size: 13px
    }

    .section-news .news-wrapper .news-category {
        color: #343a40;
        font-weight: 600;
        padding-right: .25rem
    }

    .section-news .news-wrapper .news-date {
        padding-left: .25rem
    }

    .section-news .news-pagination {
        margin: 2.5rem auto;
        text-align: center
    }

    .section-news .news-pagination ul {
        justify-content: center
    }

    .section-news .news-pagination ul li a {
        align-items: center;
        background: rgba(255, 255, 255, 0);
        border: 1px solid #000066;
        border-radius: 50%;
        display: flex;
        height: 30px;
        justify-content: center;
        margin: 0 3px;
        padding-top: 4px;
        width: 30px
    }

    .section-news .news-pagination ul li a:hover {
        background-color: #000066;
        color: #fff
    }

    .section-news .news-pagination ul li.active a {
        background-color: #000066;
        color: #fff
    }

    .section-news .news-content p {
        line-height: 1.75
    }

    .section-news .news-content p:last-child {
        margin-bottom: 0
    }

    .section-news .news-author {
        border-color: #000066;
        border-style: solid;
        border-width: 1px 0;
        margin-top: 3rem;
        padding: 1rem 0
    }

    .section-news .news-author p {
        margin: 0
    }

    .section-news .news-author .name {
        font-weight: 600
    }

    .section-news .news-share {
        color: #343a40;
        text-align: center
    }

    .section-news .news-share p {
        font-size: 1rem;
        letter-spacing: 1px;
        margin-bottom: .25rem
    }

    .section-news .news-share .icons-wrapper a {
        color: #343a40;
        margin: 0 .5rem
    }

    .section-news .news-share .icons-wrapper a:hover {
        color: #000066
    }

    .section-introduction {
        padding-bottom: 5rem;
        padding-top: 5rem
    }

    @media (max-width:991.98px) {
        .section-introduction {
            padding-bottom: 1.5rem;
            padding-top: 1.5rem
        }
    }

    .section-bio {
        padding-top: 25vh;
        height: 100vh;
    }

    @media (max-width:991.98px) {
        .section-bio {
            padding-bottom: 1.5rem;
            padding-top: 1.5rem
        }
    }

    @media (max-width:1199.98px) {
        .section-bio {
            margin-bottom: 2.5rem
        }
    }

    .bio-name {
        color: #000066;
        font-size: 2rem;
        font-weight: 200;
    }

    .bio-title {
        color: rgba(60, 60, 60, 0.6392156863);
        font-size: 1.15rem;
    }

    .bio-img {
        padding-bottom: 2vh;
    }

    .bio-img img {
        height: 35vh;
        width: auto;
    }

    .bio-contact {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;

    }

    .bio-contact-line {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: stretch;
    }

    .bio-contact-line i {
        padding-right: 1vw;
    }

    .bio-contact-line a {
        color: #000;
        text-decoration: none;
    }

    .section-job {
        margin-bottom: 5rem
    }

    @media (max-width:991.98px) {
        .section-job {
            padding-bottom: 1.5rem;
            padding-top: 1.5rem
        }
    }

    @media (max-width:1199.98px) {
        .section-job {
            margin-bottom: 2.5rem
        }
    }

    .section-job.section-top-page {
        margin-bottom: 0
    }

    .section-job.section-top-page .flex-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%
    }

    .section-job.section-top-page .times-wrapper {
        align-items: flex-start;
        color: #000066;
        display: flex;
        font-size: 1.5rem;
        opacity: .85;
        padding-top: .25rem;
        transition: opacity .25s ease
    }

    .section-job.section-top-page .times-wrapper:hover {
        color: #000066;
        opacity: 1
    }

    .section-job.section-full-primary {
        margin-bottom: 5rem
    }

    .section-job .job-header {
        display: flex;
        flex-direction: column;
        margin-top: -.25rem
    }

    @media (max-width:575.98px) {
        .section-job .job-header {
            padding-left: 50px
        }
    }

    .section-job .job-header .page-title {
        margin-bottom: 1rem
    }

    @media (max-width:575.98px) {
        .section-job .job-header .page-title {
            justify-content: flex-start
        }
    }

    .section-job .job-header .job-title h3 {
        font-size: 1.75rem;
        font-weight: 800;
        margin-bottom: .25rem
    }

    .section-job .job-header .job-description p {
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.25
    }

    .section-job .job-content {
        padding-right: 5rem
    }

    @media (max-width:1399.98px) {
        .section-job .job-content {
            padding-right: 2.5rem
        }
    }

    @media (max-width:991.98px) {
        .section-job .job-content {
            padding-right: 0
        }
    }

    .section-job .job-content h3 {
        color: #000066;
        font-size: .9rem;
        font-weight: 700;
        margin-bottom: 1.5rem;
        text-transform: uppercase
    }

    .section-job .job-content p {
        margin-bottom: 2rem
    }

    @media (max-width:991.98px) {
        .section-job .job-content p {
            text-align: justify
        }
    }

    .section-job .job-content ul {
        margin-bottom: 4rem
    }

    .section-job .job-content ul li {
        margin-bottom: 1rem;
        padding-left: 35px;
        position: relative
    }

    @media (max-width:991.98px) {
        .section-job .job-content ul li {
            text-align: justify
        }
    }

    .section-job .job-content ul li::before {
        background-color: #000066;
        content: '';
        height: 2px;
        left: 0;
        position: absolute;
        top: .75em;
        width: 8px
    }

    .section-job .job-content :last-child {
        margin-bottom: 0
    }

    .section-job .job-contact {
        height: 100%;
        min-height: 400px;
        padding-left: 5rem;
        position: relative
    }

    @media (max-width:1399.98px) {
        .section-job .job-contact {
            padding-left: 2.5rem
        }
    }

    @media (max-width:1199.98px) {
        .section-job .job-contact {
            min-height: auto;
            padding-left: 0;
            padding-top: 2.5rem
        }
    }

    .section-job .job-contact::before {
        background-color: #000066;
        content: '';
        height: 50%;
        left: 0;
        min-height: 400px;
        position: absolute;
        top: 0;
        width: 1px
    }

    @media (max-width:1199.98px) {
        .section-job .job-contact::before {
            display: none
        }
    }

    .section-job .job-contact p {
        margin-bottom: 1.5rem
    }

    .section-job .job-contact p span {
        font-weight: 700
    }

    .section-job .job-contact .btn {
        font-family: Gilroy;
        font-weight: 800
    }

    .section-latest-news {
        display: flex;
        height: auto;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        padding-top: 10vh;
    }

    .section-latest-news .row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
    }

    .section-latest-news .row > [class*=col] {
        padding-left: 10px;
        padding-right: 10px
    }

    .article {
        padding: 10vh 2vw 0vh 2vw;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
    }

    .article-heading {
        padding: 1.5rem 0;
        text-align: center
    }

    .article-heading h1 {
        display: block;
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 0;
        padding: 2.5rem 25px 1.5rem
    }

    .article-heading .tags {
        display: flex;
        justify-content: center;
        margin-top: 2.5rem
    }

    .article-heading .tags li a {
        display: block;
        line-height: 1.25;
        padding: .25rem 1rem 0;
        position: relative
    }

    .article-heading .tags li a::before {
        background-color: #000066;
        bottom: 4px;
        content: '';
        position: absolute;
        right: 0;
        top: 4px;
        width: 1px
    }

    .article-heading .tags li:last-child a::before {
        display: none
    }

    .owl-carousel {
        z-index: 0
    }

    .owl-theme .owl-dots {
        bottom: 5px;
        margin: 0;
        position: absolute;
        right: 15px
    }

    .owl-theme .owl-dots .owl-dot {
        outline: 0
    }

    .owl-theme .owl-dots .owl-dot span {
        background-color: #fff;
        border: 1px solid #000066
    }

    .owl-theme .owl-dots .owl-dot.active span,
    .owl-theme .owl-dots .owl-dot:hover span {
        background-color: #000066;
        border: 1px solid #000066
    }

    .btn {
        border-radius: 0;
        padding: .5rem 1rem
    }

    .btn.big-text {
        font-family: Gilroy;
        font-size: 1.5rem;
        font-weight: 800
    }

    .arrow-wrapper-white .arrow,
    .custom-bounce {
        animation-name: custom-bounce;
        transform-origin: center bottom
    }

    .customFadeInUp {
        animation-name: customFadeInUp
    }

    .customSlideInUp {
        animation-name: customFadeInUp
    }

    @keyframes custom-bounce {

        10.6%,
        16%,
        4%,
        from,
        to {
            animation-timing-function: cubic-bezier(.215, .61, .355, 1);
            transform: translate3d(0, 0, 0)
        }

        8%,
        8.6% {
            animation-timing-function: cubic-bezier(.755, .05, .855, .06);
            transform: translate3d(0, -30px, 0)
        }

        14% {
            animation-timing-function: cubic-bezier(.755, .05, .855, .06);
            transform: translate3d(0, -15px, 0)
        }

        18% {
            transform: translate3d(0, 0, 0)
        }
    }

    @keyframes fade-from-none {
        0% {
            display: none;
            opacity: 0
        }

        1% {
            display: block;
            opacity: 0
        }

        100% {
            display: block;
            opacity: 1
        }
    }

    @keyframes banner-line {
        0% {
            height: 0
        }

        100% {
            height: 100%
        }
    }

    @keyframes banner-arrow {
        0% {
            opacity: 0;
            top: 0;
            transform: translateY(0)
        }

        100% {
            opacity: 1;
            top: 50%;
            transform: translateY(-100%)
        }
    }

    @keyframes customFadeInUp {
        from {
            opacity: 0;
            transform: translate3d(0, 50px, 0);
            visibility: visible
        }

        to {
            opacity: 1;
            transform: translate3d(0, 0, 0)
        }
    }

    @keyframes customSlideInUp {
        from {
            opacity: 0;
            transform: translate3d(0, 25px, 0);
            visibility: visible
        }

        to {
            opacity: 1;
            transform: translate3d(0, 0, 0)
        }
    }

    .admin-container {
        margin-top: -150px;
        padding: 50px
    }

    .admin-container input {
        min-width: 300px
    }

    .admin-container .btn {
        border-radius: 4px;
        line-height: 1;
        padding-top: 10px
    }

    #ie-banner {
        text-align: center;
        padding-top: 15vh;
        position: absolute;
    }

    #ie-banner img {
        max-height: 30vh;
        max-width: 80%;
        width: auto
    }

    .ie #ie-banner {
        display: block
    }

    .ie .section-banner #animation-wrapper {
        display: none
    }

    .ie .section-worked-with-us .col-auto {
        width: 100%
    }

    .ie .section-worked-with-us .section-title.vertical {
        margin-bottom: 15px;
        text-orientation: initial;
        transform: none;
        writing-mode: initial !important
    }

    .ie .section-gallery .image-infos {
        text-align: right;
        transform: none
    }

    .ie-banner-1 {
        width: 100vw;
    }

    .ie-banner-2 {
        width: 100vw;
    }

    .ie-banner-3 {
        width: 100vw;
    }

    .ie-banner-4 {
        width: 100vw;
    }

    .ie-banner-5 {
        width: 80vw;
    }

    .login-section {
        height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-left: 0;
    }

    .login-inner {
        padding-left: 15vw;
        height: 50vh;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: flex-start;
    }

    .login-img {
        background: url(../images/banner/login.jpg) center center no-repeat;
        height: 100vh;
    }

    .title-row {
        display: inline-flex;
    }

    .contact-section {
        height: 100vh;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding: 20vh 10vw;
    }

    .contact-section .row {
        width: 100%;
    }

    .contact-inner {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: stretch;
    }

    .contact-info {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .contact-info-inner {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-around;
        align-items: flex-start;
        height: 40vh;
    }

    .contact-info-inner .bi {
        margin-right: 2vw;
    }

    .contact-info-inner a {
        color: #000066;
        font-size: 1rem;
        line-height: 1.5;
        text-decoration: none;

    }

    .contact-form {
        height: 40vh;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: stretch;
    }

    .contact-control {
        border: none;
        border-bottom: 1px solid #ced4da;
        border-radius: 0px !important;
    }

    .contact-control:focus {
        border-bottom: 1px solid #000066;
        border-radius: 0px !important;
        box-shadow: none !important;
    }

    .contact-select {
        border: none;
        border-bottom: 1px solid #ced4da;
        border-radius: 0px !important;
    }

    .contact-select:focus {
        border-bottom: 1px solid #000066;
        border-radius: 0px !important;
        box-shadow: none !important;
    }

    //JOIN TEAM FORM
    .join-team-form {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .join-team-form .form-control {
        background: rgba(255, 255, 255, 0.8509803922);
        width: 35vh;
        height: 5vh;
        border-radius: 2px;
    }

    .join-team-form .row {
        padding-bottom: 5vh;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .form-file input[type=file]::-webkit-file-upload-button {
        height: 5vh;

    }

    .form-file input[type=file]::file-selector-button {
        height: 5vh;
    }

    //HOVER UNDERLINE
    .hover-underline {
        position: relative;
    }

    .hover-underline:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        transition: width 0s ease, background .5s ease;
    }

    .hover-underline:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        background: #000066;
        transition: width .5s ease;
    }

    .hover-underline:hover:before {
        width: 100%;
        background: #000066;
        transition: width .5s ease;
    }

    .hover-underline:hover:after {
        width: 100%;
        background: transparent;
        transition: all 0s ease;
    }

    //HOVER UNDERLINE WHITE
    .hover-underline-white {
        position: relative;
    }

    .hover-underline-white:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        transition: width 0s ease, background .5s ease;
    }

    .hover-underline-white:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        background: #f1f1f1;
        transition: width .5s ease;
    }

    .hover-underline-white:hover:before {
        width: 100%;
        background: #f1f1f1;
        transition: width .5s ease;
    }

    .hover-underline-white:hover:after {
        width: 100%;
        background: transparent;
        transition: all 0s ease;
    }

    //HOVER UNDERLINE RED
    .hover-underline-red {
        position: relative;
    }

    .hover-underline-red:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        transition: width 0s ease, background .5s ease;
    }

    .hover-underline-red:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        height: 1px;
        width: 0;
        background: #000066;
        transition: width .5s ease;
    }

    .hover-underline-red:hover:before {
        width: 100%;
        background: #000066;
        transition: width .5s ease;
    }

    .hover-underline-red:hover:after {
        width: 100%;
        background: transparent;
        transition: all 0s ease;
    }

    //ARROW DOWN 
    .arrow-down {
        display: inline-flex;
        position: fixed;
        bottom: 5vh;
        width: 1.5vw;
    }

    //ARROW DOWN HOME PAGE
    .arrow-down-animate {
        animation-name: loop-down;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    @keyframes loop-down {
        0% {
            transform: translateY(0vh);
            opacity: 0
        }

        25% {
            transform: translateY(1vh);
            opacity: 0.5
        }

        50% {
            transform: translateY(2vh);
            opacity: 1
        }

        75% {
            transform: translateY(3vh);
            opacity: 0.5
        }

        100% {
            transform: translateY(4vh);
            opacity: 0
        }
    }

    //COUNTERS
    #counters_1,
    #counters_2,
    #counters_3 {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
        height: 20vh;
        width: 30vw;
    }

    .counter {
        font-size: 7rem;

    }

    .counter-wrapper {
        display: flex;
        height: 10vh;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: flex-end;
    }

    //SWUP
    .transition-fade {
        transition: 0.4s;
        opacity: 1;
    }

    html.is-animating .transition-fade {
        opacity: 0;
    }

    //HOME
    .home-header {
        height: 150vh;
        widows: 100vh;
        object-fit: cover;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        background: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.8)), url(../images/banner/header.jpg) no-repeat center center;
        background-size: cover;
        background-attachment: fixed;
    }

    .first-letter {
        -webkit-initial-letter: 4;
        initial-letter: 4;
        color: orange;
        font-weight: bold;
        margin-right: .75em;
    }

    .intro {
        height: 50vh;
        width: 100vw;
        display: flex;
        z-index: 9500;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        position: absolute;
        padding: 0vh 10vw 5vh 10vw;
        left: 0;
        text-align: left;
        top: 50vh;
    }

    .intro .txt hr {
        width: 80vw;
        height: 2px;
        opacity: 1;
        color: #fff;
    }

    .intro .txt h3 {
        font-size: 7rem;
        font-weight: 400;
        color: #fff;
        text-transform: uppercase;
        line-height: 1;
        white-space: nowrap;
        transform: scaleY(1.1) !important;
    }

    .intro .txt p {
        font-size: 1.15rem;
        font-weight: 400;
        color: #fff;
        line-height: 1.5;
        text-align: justify;
    }

    .dropcap {
        background: transparent;
        color: #FDF9F2;
        float: left;
        font-size: 4.0rem;
        line-height: 1;
        margin: 0.2em 0.1em 0em 0;
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
    }

    .dropcap:before,
    .dropcap:after {
        content: "";
        display: block;
    }

    .dropcap:before {
        margin-top: -0.2em;
    }

    .dropcap:after {
        margin-bottom: -0.15em;
    }

    .sr-only:not(:focus):not(:active) {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    .intro-btn {
        background: #fff;
        border: 1px solid #fff;
        height: 8vh;
        width: 16vw;
        border-radius: 2px;
        margin: 1vw;
        margin-left: 0;
        color: #000;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.05vw;
        transition: 500ms !important;
    }

    .intro-btn:hover {
        background: #fff;
        border: 2px solid #fff;
        height: 8vh;
        width: 16vw;
        border-radius: 2px;
        margin: 1vw;
        margin-left: 0;
        color: #000066;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.05vw;
        transition: 500ms !important;
    }

    .video-overlay {
        background: rgba(0, 0, 0, 0.4);
        height: 100vh;
        width: 100vw;
        position: absolute;
        top: 0;
        z-index: 9000;
    }

    .intro_1 {
        height: auto;
        width: 100vw;
        background: #fff;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding: 20vh 10vw;
    }

    .intro_1 h1 {
        color: #000066;
        font-size: 7rem;
        font-weight: 300;
        text-transform: uppercase;
        margin: 0;
        padding-bottom: 2vh;
    }

    .top-img {
        position: absolute;
        width: 15%;
        z-index: 8500;
        right: 5vw;
        bottom: 60vh;
        box-shadow: black;
        -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
        box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
    }

    .mid-img {
        position: absolute;
        right: 7vw;
        bottom: 23vh;
        width: 40%;
        box-shadow: black;
        -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
        box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
    }

    .bot-img {
        position: absolute;
        width: 25%;
        right: 10vw;
        bottom: 20vh;
        box-shadow: black;
        -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
        box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(0 0 0 / 0%);
    }

    .listings-btn {
        background: #fff;
        border: none;
        height: 4vh;
        width: 10vw;
        border-radius: 2px;
        margin: 1vw;
        margin-left: 0;
    }

    //SLIDE OVER SECTIONS
    .info-contain {
        width: 100vw;
        height: 100vh;
        height: 100%;
        height: fill-available;
        transition-duration: 600ms;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.8, 0, 0, 1);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 4;
        background: #fff;
        background-size: cover;
        -ms-transform: translate(100%, 0);
        /* IE 9 */
        -webkit-transform: translate(100%, 0);
        /* Safari */
        transform: translate(100%, 0);
        /* Standard syntax */
        padding-top: 20vh;
        overflow: scroll;
        margin: 0;
    }

    .info-contain.opened {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100vh;
        height: 100%;
        height: fill-available;
        transition-duration: 600ms;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.8, 0, 0, 1);
        -ms-transform: translate(0, 0);
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
        z-index: 9999 !important;
        padding-top: 20vh;
        overflow: scroll;
        margin: 0;
    }

    .overlay-element-innovation {
        width: 100vw;
        display: table;
        float: left;
        height: 100vh;
        color: #fff;
    }

    .overlay-txt {
        display: flex;
        vertical-align: middle;
        text-align: justify;
        text-align-last: left;
        font-size: 16px;
        font-weight: 300;
        padding: 10vh 25vw;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100vh;
        color: #000;
    }

    .overlay-txt h2 {
        font-size: 1.25rem;
        color: #000066;
        text-transform: uppercase;
        font-weight: 900;
    }

    .overlay-txt h3 {
        font-size: 2rem;
        font-weight: 900;
        color: #000;
        text-transform: uppercase;
    }

    .close {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1;
        color: #fff;
        text-shadow: 0 1px 0 rgb(0 0 0 / 50%);
        opacity: 1;
        display: flex;
        width: 10vw;
        position: relative;
        margin-top: 5vh;
        white-space: nowrap;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: stretch;
    }

    .close p {
        font-weight: 400;
        font-size: 0.8rem;
        padding-left: 2vw;
        text-transform: uppercase;
        text-shadow: none;
        color: #000066;
    }

    .close:hover {
        color: #000066;
        text-shadow: 0 1px 0 black;
        transition: all 0.3s;
        cursor: pointer;
        transition: all 0.5s;
        transform: translateX(-1vw);
    }

    .divider {
        width: 50vw;
        margin: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 7vh;
    }

    //PRESS
    .news-img {
        width: auto;
        height: 155px !important;
        transition: 500ms;
    }

    .news-img:hover {
        transition: 500ms;
        transform: scale(1.05)
    }

    .press-img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-top-left-radius: calc(0.25rem - 1px);
        border-top-right-radius: calc(0.25rem - 1px);
    }

    //ANIMATION LOADER//
    #loader-wrapper .loader-section {
        position: fixed;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: 9998;
        text-align: center;
        background: #fff;
    }

    #loader {
        z-index: 9999;
        position: fixed;
        height: 100vh;
        width: 100vw;
        text-align: center;
        background: #fff;
    }

    .loader-logo {
        position: relative;
        top: 3vh;
        height: auto;
        width: auto;
        text-align: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .loader_img_1 {
        animation: loader_1 4s ease;
        opacity: 0;
    }

    .loader_img_2 {
        animation: loader_2 4s ease;
        opacity: 0;
    }

    @keyframes loader_1 {
        0% {
            transform: translate(100vw, 40vh);
            opacity: 0;
        }

        60% {
            transform: translate(0vw, 40vh);
            opacity: 1;
        }

        70% {
            transform: translate(0vw, 40vh);
            opacity: 1;
        }

        100% {
            transform: translate(0vw, 40vh);
            opacity: 0;
        }
    }

    @keyframes loader_2 {
        0% {
            transform: translate(50vw, 40vh);
            opacity: 0;
        }

        40% {
            opacity: 0;
        }

        60% {
            transform: translate(0vw, 40vh);
            opacity: 1;
        }

        70% {
            transform: translate(0vw, 40vh);
            opacity: 1;
        }

        100% {
            transform: translate(0vw, 40vh);
            opacity: 0;
        }
    }

    .loader-text-fade-out {
        opacity: 0;
        animation: fade-text 4s;
        font-weight: 600;
        font-size: 1rem;
    }

    @keyframes fade-text {
        0% {
            opacity: 0;
        }

        30% {
            opacity: 1;
        }

        90% {
            opacity: 1;
        }

        100% {
            opacity: 0;
            visibility: hidden;
        }
    }

    #loader {
        opacity: 0;
        visibility: hidden;
        animation: fade-background 4s;
    }

    @keyframes fade-background {
        0% {
            opacity: 1;
            visibility: visible;
        }

        80% {
            opacity: 1;
            visibility: visible;
        }

        100% {
            opacity: 0;
            visibility: hidden;
        }
    }

    .sign-up-form {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: baseline;
        width: 65vh;

    }

    .sign-up-form .form-control {
        background: #ffffffd9;
        width: 35vh;
        height: 5vh;
        border-radius: 2px;
    }

    .sign-up-form .form-control:focus {
        background: #ffffff;
        width: 35vh;
        height: 5vh;
        border-radius: 10px;
        border-color: #000066
    }

    .sign-up-btn {
        background: #ffffff;
        color: #000;
        height: 5vh;
        width: 10vw;
        border: 1px solid #fff;
        border-radius: 2px;
        font-weight: 600;
        text-transform: uppercase;
    }

    .sign-up-btn:hover {
        color: #000066;
        border-color: #000066;
        font-weight: 600;
        text-transform: uppercase;
    }

    .join-team {
        background: linear-gradient(rgba(241, 241, 241, 0.6), rgba(241, 241, 241, 0.6)), url(../images/join-team.jpg) no-repeat center center;
        background-size: cover;
        height: auto;
        width: 100vw;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .join-team .inner {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        background: transparent;
        width: 100%;
        height: 50%;
        padding: 10vh 0vw;
    }

    .join-team .inner h3 {
        color: #000066;
        font-size: 7rem;
        font-weight: 300;
        white-space: nowrap;
        text-transform: uppercase;
    }

    .join-team .inner hr {
        width: 25%;
        color: #000066;
        opacity: 1;
        height: 2px;
    }

    .join-team .inner p {
        color: #000066;
        font-size: 1.15rem;
        font-weight: 600;
        white-space: nowrap;
        text-transform: uppercase;
        padding: 5vh 0vw 5vh 0vw;
    }

    .join-btn {
        font-weight: 600;
        height: 8vh;
        width: 15vw;
        background: #fff;
        border: 1px solid #000066;
        border-radius: 2px;
        color: #000066;
        text-transform: uppercase;
        transition: 500ms ease;
    }

    .join-btn:hover {
        font-weight: 600;
        height: 8vh;
        width: 15vw;
        background: #000066;
        border: 1px solid #000066;
        border-radius: 2px;
        color: #fff;
        text-transform: uppercase;
        transition: 500ms ease;
        cursor: pointer;
    }

    .join-text {
        padding: 0 !important;
        font-size: 1rem !important;

    }
}
//FILTER MENUS
.select-menu {
  max-width: 330px;
  margin: 50px auto;
}
.select-menu .select-btn {
  display: flex;
  height: 55px;
  background: #fff;
  padding: 20px;
  font-size: 18px;
  font-weight: 400;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.select-btn i {
  font-size: 25px;
  transition: 0.3s;
}
.select-menu.active .select-btn i {
  transform: rotate(-180deg);
}
.select-menu .options {
  position: relative;
  padding: 10px;
  margin-top: 10px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: none;
}
.select-menu.active .options {
  display: block;
}
.options .option {
  display: flex;
  height: 55px;
  cursor: pointer;
  padding: 0 16px;
  border-radius: 8px;
  align-items: center;
  background: #fff;
}
.options .option:hover {
  background: #f2f2f2;
}
.option i {
  font-size: 25px;
  margin-right: 12px;
}
.option .option-text {
  font-size: 18px;
  color: #333;
}
